import { getSingleGameEventService } from 'common/api/gameEventsService';
import { useAuthContext } from 'contexts/auth';
import { useCallback, useState } from 'react';
import useApi from './useApi';

const useGameEvents = () => {
  const { isGuestView } = useAuthContext();
  const [gameEvents, setGameEvents] = useState([]);
  const [shouldReloadGameEvents, setShouldReloadGameEvents] = useState(false);
  const { asyncWrapper } = useApi();

  const loadGameEvents = useCallback(
    service => entityId => {
      asyncWrapper(async () => {
        const gameEventsData = await service(entityId)();
        if (typeof gameEventsData === 'object')
          setGameEvents(gameEventsData?.data);
        setShouldReloadGameEvents(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const loadGameEvent = useCallback(
    id => {
      asyncWrapper(async () => {
        const data = await getSingleGameEventService(id, isGuestView)();
        if (typeof data === 'object') setGameEvents([data?.data]);
      });
    },
    [asyncWrapper, isGuestView]
  );

  return {
    gameEvents,
    loadGameEvent,
    loadGameEvents,
    shouldReloadGameEvents,
    setShouldReloadGameEvents,
  };
};

export default useGameEvents;
