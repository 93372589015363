export const PUBLIC_PAGE_TYPE = 'PUBLIC_PAGE_TYPE';
export const PRIVATE_PAGE_TYPE = 'PRIVATE_PAGE_TYPE';
export const FIVE_MINTUES_INTERVAL = 5 * 60 * 1000;

export const PAGE_TYPES = [PUBLIC_PAGE_TYPE, PRIVATE_PAGE_TYPE];

export const YELLOW_NEON_COLOR = 'yellow';
export const BLUE_NEON_COLOR = 'blue';
export const GREEN_NEON_COLOR = 'green';
export const VIOLET_NEON_COLOR = 'violet';
