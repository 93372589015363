import {
  getHoursInDayStrings,
  getMinutesIntervalsInHour,
} from 'common/utils/dates';
import Modal, { DARK_THEME } from 'features/modal';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Children } from 'react/cjs/react.production.min';
import style from './TimeSelector.module.scss';

const hours = getHoursInDayStrings();
const minutes = getMinutesIntervalsInHour(10);

const TimeSelector = ({
  onConfirm,
  onClose,
  title,
  initHours,
  initMinutes,
}) => {
  const [selectedHour, setSelectedHour] = useState(initHours || hours[0]);
  const [selectedMinutes, setSelectedMinutes] = useState(
    initMinutes || minutes[0]
  );

  const handleOnConfirm = useCallback(() => {
    onConfirm(selectedHour, selectedMinutes);
    onClose();
  }, [onClose, onConfirm, selectedHour, selectedMinutes]);

  const renderSelector = useCallback(
    (label, items, onClick, selectedItem) => (
      <div className={style.listWrapper}>
        <label className={style.listLabel}>{label}</label>
        <ul className={style.list}>
          {Children.toArray(
            items.map((item, i) => {
              const activeClassName =
                item === selectedItem ? style.activeItem : '';

              return (
                <li
                  className={`${style.listItem} ${activeClassName}`}
                  onClick={() => {
                    onClick(items[i]);
                  }}
                >
                  {item}
                </li>
              );
            })
          )}
        </ul>
      </div>
    ),
    []
  );

  return (
    <div>
      <Modal
        onClose={onClose}
        cancelLabel='close'
        theme={DARK_THEME}
        title={[{ text: title }]}
        titleSize={4}
        onConfirm={handleOnConfirm}
        customClassName={style.modalWrapper}
      >
        <div className={style.wrapper}>
          {renderSelector('hours', hours, setSelectedHour, selectedHour)}
          {renderSelector(
            'minutes',
            minutes,
            setSelectedMinutes,
            selectedMinutes
          )}
        </div>
      </Modal>
    </div>
  );
};

TimeSelector.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default TimeSelector;
