/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

const GeneralPlayerInformationIcon = ({ color }) => (
  <svg height='24px' viewBox='0 0 24 24' width='24px' fill='#000000'>
    <path d='M0 0h24v24H0z' fill='none' />
    <path
      fill={color}
      d='M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z'
    />
  </svg>
);

GeneralPlayerInformationIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

GeneralPlayerInformationIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default GeneralPlayerInformationIcon;
