/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { PAGE_TYPES } from 'common/constants/general';
import MaxWidthContainer from 'common/components/max-width-container';
import { usePrivateNavContext } from 'contexts/private-nav';
// import AsideView from 'features/aside-view';
import { ASIDE_VIEW_TYPES } from 'features/aside-view/constants';
import './PageWrapper.scss';

const className = 'c-PageWrapper';

const PageWrapper = ({
  type,
  children,
  classNames,
  // asideViewProps,
  fullscreen,
}) => {
  const { closeNavbarDropdowns } = usePrivateNavContext();

  const fullscreenClassName = fullscreen ? `${className}--full-screen` : '';
  const noSidePaddingClassName = fullscreen
    ? `${className}--no-side-padding`
    : '';

  return (
    <div className={`${classNames}`} onClick={closeNavbarDropdowns}>
      <div className={`${className} ${fullscreenClassName}`}>
        <MaxWidthContainer classNames={`${noSidePaddingClassName}`}>
          <main className={`${className}__content`}>{children}</main>
        </MaxWidthContainer>
      </div>
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(PAGE_TYPES),
  className: PropTypes.string,
  asideViewProps: PropTypes.shape({
    isOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    asideViewType: PropTypes.oneOf(ASIDE_VIEW_TYPES),
    asideViewTypeProps: PropTypes.object,
  }),
  fullscreen: PropTypes.bool,
  edgeToEdgeDisplay: PropTypes.bool,
};

PageWrapper.defaultProps = {
  classNames: '',
};

export default PageWrapper;
