import PropTypes from 'prop-types';
import style from './ScreenTitle.module.scss';
import { DARK_MATERIAL_SECONDARY_COLOR } from 'common/constants/theming';

const ScreenTitle = ({ title, Icon }) => (
  <div className={style.wrapper}>
    <Icon color={DARK_MATERIAL_SECONDARY_COLOR} />
    <h2>{title}</h2>
  </div>
);

ScreenTitle.propTypes = {
  Icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ScreenTitle;
