/* eslint-disable max-len */
import PropTypes from 'prop-types';

const BookmarkFilledIcon = ({ color }) => (
  <svg height='24px' viewBox='0 0 24 24' width='24px' fill={color}>
    <path d='M0 0h24v24H0z' fill='none' />
    <path d='M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z' />
  </svg>
);

BookmarkFilledIcon.defaultProps = { color: '#111314' };

BookmarkFilledIcon.propTypes = { color: PropTypes.string };

export default BookmarkFilledIcon;
