import { useCallback, Children } from 'react';
import { useMetaContext } from 'contexts/meta';
import './Toasts.scss';
import {
  NEUTRAL_TOAST_MESSAGE_TYPE,
  NON_CRASH_ERROR_TOAST_MESSAGE_TYPE,
  SUCCESS_TOAST_MESSAGE_TYPE,
} from 'contexts/meta/constants';

const className = 'c-Toasts';

const toastClassNameVariants = {
  [SUCCESS_TOAST_MESSAGE_TYPE]: 'success',
  [NON_CRASH_ERROR_TOAST_MESSAGE_TYPE]: 'error',
  [NEUTRAL_TOAST_MESSAGE_TYPE]: 'neutral',
};

const Toasts = () => {
  const { toastMessages, toastMessageKeys } = useMetaContext();

  const renderToastMessages = useCallback(() => {
    const toastElements = Children.toArray(
      toastMessageKeys.map(key => {
        const linkClassName = toastMessages[key]?.onClick
          ? `${className}--cursor`
          : '';
        return (
          <li
            onClick={toastMessages[key]?.onClick}
            className={`${className}__item ${className}__item--${
              toastClassNameVariants[toastMessages[key]?.type]
            } ${linkClassName}`}
          >
            {toastMessages[key]?.message}
          </li>
        );
      })
    );

    return <ul className={className}>{toastElements}</ul>;
  }, [toastMessageKeys, toastMessages]);

  return <div>{renderToastMessages()}</div>;
};

export default Toasts;
