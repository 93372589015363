/* eslint-disable max-len */

import PropTypes from 'prop-types';

const StarIcon = ({ color }) => (
  <svg height='48' viewBox='0 96 960 960' width='48'>
    <path
      fill={color}
      d='m343 813 137-104 137 104-55-173 126-82H537l-57-173-57 173H272l126 82-55 173Zm137-25L307 920q-9 7-18 6t-17-6q-8-5-11.5-13.5T260 887l66-215-170-122q-9-6-11.5-15.5t.5-17.5q3-8 10-14.5t18-6.5h211l67-224q3-11 11.5-16.5T480 250q9 0 17.5 5.5T509 272l67 224h211q11 0 18 6.5t10 14.5q3 8 .5 17.5T804 550L634 672l66 215q3 11-.5 19.5T688 920q-8 5-17 6t-18-6L480 788Zm0-189Z'
    />
  </svg>
);

StarIcon.defaultProps = {
  color: '#ffeb3b',
};

StarIcon.propTypes = {
  color: PropTypes.string,
};
export default StarIcon;
