import React, { Suspense } from 'react';
import commonPages from './common';
import {
  GAME_EVENT_PAGE_PATH,
  MATCH_PAGE_PATH,
  SETTINGS_PATH,
  STREET_PATH,
  USER_PROFILE_PATH,
} from './constants';
import Loading from 'common/components/loading';

// Lazy load the components
const Street = React.lazy(() => import('pages/street'));
const Settings = React.lazy(() => import('pages/settings'));
const Profile = React.lazy(() => import('pages/profile'));
const MatchPage = React.lazy(() => import('pages/match-page'));
const GameEventPage = React.lazy(() => import('pages/game-event-page'));

const data = [
  {
    path: STREET_PATH,
    element: (
      <Suspense fallback={<Loading />}>
        <Street />
      </Suspense>
    ),
  },
  ...commonPages,
  {
    path: SETTINGS_PATH,
    element: (
      <Suspense fallback={<Loading />}>
        <Settings />
      </Suspense>
    ),
  },
  {
    path: `${USER_PROFILE_PATH}/*`,
    element: (
      <Suspense fallback={<Loading />}>
        <Profile />
      </Suspense>
    ),
  },
  {
    path: MATCH_PAGE_PATH,
    element: (
      <Suspense fallback={<Loading />}>
        <MatchPage />
      </Suspense>
    ),
  },
  {
    path: GAME_EVENT_PAGE_PATH,
    element: (
      <Suspense fallback={<Loading />}>
        <GameEventPage />
      </Suspense>
    ),
  },
];

export default data;
