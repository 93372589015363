import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { useWindowSizeContext } from 'contexts/window-size';

import './Image.scss';

const className = 'c-Image';

const Image = ({ alt, mobileSrc, desktopSrc }) => {
  const renderSources = sources =>
    Children.toArray(sources.map(item => <source {...item} />));

  const { isDesktopView } = useWindowSizeContext();

  return (
    <picture className={className}>
      {isDesktopView && desktopSrc && renderSources(desktopSrc)}
      {mobileSrc && renderSources(mobileSrc)}
      <img src='' alt={alt} />
    </picture>
  );
};

const ImageSrcDataType = {
  srcSet: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  media: PropTypes.string,
};

export const ImagePropTypes = {
  alt: PropTypes.string.isRequired,
  mobileSrc: PropTypes.arrayOf(PropTypes.shape(ImageSrcDataType)),
  desktopSrc: PropTypes.arrayOf(PropTypes.shape(ImageSrcDataType)),
};

Image.propTypes = ImagePropTypes;

export default Image;
