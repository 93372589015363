import {
  REACT_GA_CLIPBOARD_SHARE_PLAYED_MATCH_EVENT,
  REACT_GA_CLIPBOARD_SHARE_SCHEDULED_MATCH_EVENT,
  REACT_GA_EMAIL_SHARE_PLAYED_MATCH_EVENT,
  REACT_GA_EMAIL_SHARE_SCHEDULED_MATCH_EVENT,
  REACT_GA_FB_SHARE_PLAYED_MATCH_EVENT,
  REACT_GA_FB_SHARE_SCHEDULED_MATCH_EVENT,
  REACT_GA_LI_SHARE_PLAYED_MATCH_EVENT,
  REACT_GA_LI_SHARE_SCHEDULED_MATCH_EVENT,
  REACT_GA_TG_SHARE_PLAYED_MATCH_EVENT,
  REACT_GA_TG_SHARE_SCHEDULED_MATCH_EVENT,
  REACT_GA_TW_SHARE_PLAYED_MATCH_EVENT,
  REACT_GA_TW_SHARE_SCHEDULED_MATCH_EVENT,
  REACT_GA_VIBER_SHARE_PLAYED_MATCH_EVENT,
  REACT_GA_VIBER_SHARE_SCHEDULED_MATCH_EVENT,
  REACT_GA_WA_SHARE_PLAYED_MATCH_EVENT,
  REACT_GA_WA_SHARE_SCHEDULED_MATCH_EVENT,
} from 'common/constants/react-google-analytics/events';

// social media
export const TWITTER_ACCOUNT = 'cluster__11';

// share types
export const MATCH_SHARE_TYPE = 'MATCH_SHARE_TYPE';
export const GAME_EVENT_SHARE_TYPE = 'GAME_EVENT_SHARE_TYPE';

export const SHARE_TYPES = [MATCH_SHARE_TYPE];

// buttons
export const EMAIL_SHARE_BUTTON = 'EMAIL_SHARE_BUTTON';
export const FACEBOOK_SHARE_BUTTON = 'FACEBOOK_SHARE_BUTTON';
export const LINKEDING_SHARE_BUTTON = 'LINKEDING_SHARE_BUTTON';
export const TELEGRAM_SHARE_BUTTON = 'TELEGRAM_SHARE_BUTTON';
export const TWITTER_SHARE_BUTTON = 'TWITTER_SHARE_BUTTON';
export const VIBER_SHARE_BUTTON = 'VIBER_SHARE_BUTTON';
export const WHATSAPP_SHARE_BUTTON = 'WHATSAPP_SHARE_BUTTON';

// props
export const commonIconProps = { borderRadius: '4px', size: 32, opacity: '.9' };

// share groups
export const SCHEDULED_MATCH_SHARE_GROUP = 'SCHEDULED_MATCH_SHARE_GROUP';
export const PLAYED_MATCH_SHARE_GROUP = 'PLAYED_MATCH_SHARE_GROUP';
export const GAME_EVENT_SHARE_GROUP = 'GAME_EVENT_SHARE_GROUP';

// react ga events
export const shareRGAEvents = {
  [SCHEDULED_MATCH_SHARE_GROUP]: {
    [EMAIL_SHARE_BUTTON]: REACT_GA_EMAIL_SHARE_SCHEDULED_MATCH_EVENT,
    [FACEBOOK_SHARE_BUTTON]: REACT_GA_FB_SHARE_SCHEDULED_MATCH_EVENT,
    [LINKEDING_SHARE_BUTTON]: REACT_GA_LI_SHARE_SCHEDULED_MATCH_EVENT,
    [TELEGRAM_SHARE_BUTTON]: REACT_GA_TG_SHARE_SCHEDULED_MATCH_EVENT,
    [TWITTER_SHARE_BUTTON]: REACT_GA_TW_SHARE_SCHEDULED_MATCH_EVENT,
    [VIBER_SHARE_BUTTON]: REACT_GA_VIBER_SHARE_SCHEDULED_MATCH_EVENT,
    [WHATSAPP_SHARE_BUTTON]: REACT_GA_WA_SHARE_SCHEDULED_MATCH_EVENT,
  },
  [PLAYED_MATCH_SHARE_GROUP]: {
    [EMAIL_SHARE_BUTTON]: REACT_GA_EMAIL_SHARE_PLAYED_MATCH_EVENT,
    [FACEBOOK_SHARE_BUTTON]: REACT_GA_FB_SHARE_PLAYED_MATCH_EVENT,
    [LINKEDING_SHARE_BUTTON]: REACT_GA_LI_SHARE_PLAYED_MATCH_EVENT,
    [TELEGRAM_SHARE_BUTTON]: REACT_GA_TG_SHARE_PLAYED_MATCH_EVENT,
    [TWITTER_SHARE_BUTTON]: REACT_GA_TW_SHARE_PLAYED_MATCH_EVENT,
    [VIBER_SHARE_BUTTON]: REACT_GA_VIBER_SHARE_PLAYED_MATCH_EVENT,
    [WHATSAPP_SHARE_BUTTON]: REACT_GA_WA_SHARE_PLAYED_MATCH_EVENT,
  },
};

export const copyToClipboardRGAEvents = {
  [SCHEDULED_MATCH_SHARE_GROUP]: REACT_GA_CLIPBOARD_SHARE_SCHEDULED_MATCH_EVENT,
  [PLAYED_MATCH_SHARE_GROUP]: REACT_GA_CLIPBOARD_SHARE_PLAYED_MATCH_EVENT,
};
