import { useCallback, useMemo, useState } from 'react';
import {
  MENTAL_SKILL_TYPES,
  PHYSICAL_SKILL_TYPES,
  TECHNICAL_SKILL_TYPES,
  skillLevelParams,
} from '../constants';
import { calculateUsedBudget } from '../utils';

const useSkills = initData => {
  const removeSkillOfType = useCallback(
    setFn => skill => {
      setFn(prev => {
        const isStrong = prev.strong.includes(skill);
        const isGood = prev.good.includes(skill);

        if (isStrong) {
          return {
            ...prev,
            strong: prev.strong.filter(s => s !== skill),
            usedBudget: prev.usedBudget - skillLevelParams.strong.cost,
          };
        } else if (isGood) {
          return {
            ...prev,
            good: prev.good.filter(s => s !== skill),
            usedBudget: prev.usedBudget - skillLevelParams.good.cost,
          };
        } else {
          return prev;
        }
      });
    },
    []
  );

  const addSkillOfType = useCallback(
    (field, setFn) => (skill, type) => {
      const cost = skillLevelParams[type].cost;

      removeSkillOfType(setFn)(skill);
      setFn(prev => {
        const isBudgetLeft = field.budget - field.usedBudget >= cost;
        const isAlreadyInList = field[type].includes(skill);

        if (!isBudgetLeft || isAlreadyInList) return prev;

        return {
          ...prev,
          [type]: [...prev[type], skill],
          usedBudget: prev.usedBudget + cost,
        };
      });
    },
    [removeSkillOfType]
  );

  const [technicalSkills, setTechnicalSkills] = useState({
    budget: 5,
    usedBudget: calculateUsedBudget(initData?.technical, 1, 2),
    strong: [...(initData?.technical?.strong || [])],
    good: [...(initData?.technical?.good || [])],
  });

  const addTechnicalSkill = useMemo(
    () => addSkillOfType(technicalSkills, setTechnicalSkills),
    [addSkillOfType, technicalSkills]
  );

  const removeTechnicalSkill = useMemo(
    () => removeSkillOfType(setTechnicalSkills),
    [removeSkillOfType]
  );

  const [physicalSkills, setPhysicalSkills] = useState({
    budget: 6,
    usedBudget: calculateUsedBudget(initData?.physical, 1, 2),
    strong: [...(initData?.physical?.strong || [])],
    good: [...(initData?.physical?.good || [])],
  });

  const addPhysicalSkill = useMemo(
    () => addSkillOfType(physicalSkills, setPhysicalSkills),
    [addSkillOfType, physicalSkills]
  );

  const removePhysicalSkill = useMemo(
    () => removeSkillOfType(setPhysicalSkills),
    [removeSkillOfType]
  );

  const [mentalSkills, setMentalSkills] = useState({
    budget: 4,
    usedBudget: calculateUsedBudget(initData?.mental, 1, 2),
    strong: [...(initData?.mental?.strong || [])],
    good: [...(initData?.mental?.good || [])],
  });

  const addMentalSkill = useMemo(
    () => addSkillOfType(mentalSkills, setMentalSkills),
    [addSkillOfType, mentalSkills]
  );

  const removeMentalSkill = useMemo(
    () => removeSkillOfType(setMentalSkills),
    [removeSkillOfType]
  );

  const skillProps = useMemo(
    () => [
      {
        name: 'Technical Skills',
        options: TECHNICAL_SKILL_TYPES,
        skills: technicalSkills,
        addSkill: addTechnicalSkill,
        removeSkill: removeTechnicalSkill,
        isInitFolded: false,
      },
      {
        name: 'Physical Skills',
        options: PHYSICAL_SKILL_TYPES,
        skills: physicalSkills,
        addSkill: addPhysicalSkill,
        removeSkill: removePhysicalSkill,
        isInitFolded: true,
      },
      {
        name: 'Mental Skills',
        options: MENTAL_SKILL_TYPES,
        skills: mentalSkills,
        addSkill: addMentalSkill,
        removeSkill: removeMentalSkill,
        isInitFolded: true,
      },
    ],
    [
      addMentalSkill,
      addPhysicalSkill,
      addTechnicalSkill,
      mentalSkills,
      physicalSkills,
      removeMentalSkill,
      removePhysicalSkill,
      removeTechnicalSkill,
      technicalSkills,
    ]
  );

  return {
    data: {
      technical: {
        strong: technicalSkills?.strong,
        good: technicalSkills.good,
      },
      physical: { strong: physicalSkills.strong, good: physicalSkills.good },
      mental: { strong: mentalSkills.strong, good: mentalSkills.good },
    },

    technicalSkillsProps: {
      skills: technicalSkills,
      addSkill: addTechnicalSkill,
      removeSkill: removeTechnicalSkill,
    },

    physicalSkillsProps: {
      skills: physicalSkills,
      addSkill: addPhysicalSkill,
      removeSkill: removePhysicalSkill,
    },

    mentalSkillsProps: {
      skills: mentalSkills,
      addSkill: addMentalSkill,
      removeSkill: removeMentalSkill,
    },

    skillProps,
  };
};

export default useSkills;
