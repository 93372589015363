/* eslint-disable max-len */
const InfinityIcon = () => (
  <svg width='48' height='48' viewBox='0 0 48 48' fill='none'>
    <path
      d='M19.6564 18.3445C18.5378 17.2256 17.1125 16.4635 15.5607 16.1546C14.0089 15.8458 12.4004 16.004 10.9386 16.6094C9.47681 17.2148 8.22736 18.2401 7.34828 19.5556C6.46921 20.8711 6 22.4178 6 24C6 25.5822 6.46921 27.1288 7.34828 28.4444C8.22736 29.7599 9.47681 30.7852 10.9386 31.3905C12.4004 31.9959 14.0089 32.1542 15.5607 31.8453C17.1125 31.5365 18.5378 30.7744 19.6564 29.6554C21.4314 28.0457 22.9027 26.1301 24 24C25.0973 21.8698 26.5686 19.9542 28.3436 18.3445C29.4622 17.2256 30.8875 16.4635 32.4393 16.1546C33.9911 15.8458 35.5996 16.004 37.0614 16.6094C38.5232 17.2148 39.7726 18.2401 40.6517 19.5556C41.5308 20.8711 42 22.4178 42 24C42 25.5822 41.5308 27.1288 40.6517 28.4444C39.7726 29.7599 38.5232 30.7852 37.0614 31.3905C35.5996 31.9959 33.9911 32.1542 32.4393 31.8453C30.8875 31.5365 29.4622 30.7744 28.3436 29.6554C26.5686 28.0457 25.0973 26.1301 24 24C22.9027 21.8698 21.4314 19.9542 19.6564 18.3445'
      stroke='#039BE5'
      strokeWidth='5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default InfinityIcon;
