import {
  REACT_GA_CONTACT_PAGE,
  REACT_GA_OUR_VISION_PAGE,
  REACT_GA_WHO_ARE_WE_PAGE,
} from 'common/constants/react-google-analytics/pages';

/* eslint-disable max-len */
export const CONTACT_STATIC_PAGE_TYPE = 'CONTACT_STATIC_PAGE_TYPE';
export const WHO_ARE_WE_STATIC_PAGE_TYPE = 'WHO_ARE_WE_STATIC_PAGE_TYPE';
export const OUR_VISION_STATIC_PAGE_TYPE = 'OUR_VISION_STATIC_PAGE_TYPE';

export const publicStaticPageTypes = [
  CONTACT_STATIC_PAGE_TYPE,
  WHO_ARE_WE_STATIC_PAGE_TYPE,
  OUR_VISION_STATIC_PAGE_TYPE,
];

export const reactGAPageParam = {
  CONTACT_STATIC_PAGE_TYPE: REACT_GA_CONTACT_PAGE,
  WHO_ARE_WE_STATIC_PAGE_TYPE: REACT_GA_WHO_ARE_WE_PAGE,
  OUR_VISION_STATIC_PAGE_TYPE: REACT_GA_OUR_VISION_PAGE,
};

export const pageProps = {
  [WHO_ARE_WE_STATIC_PAGE_TYPE]: {
    title: {
      mainText: [
        {
          text: 'The Origin Story',
        },
      ],
    },
    textItems: [
      {
        data: [
          {
            text: `The idea for Cluster-11 was born in my student apartment in Belgrade in 2015. At the time, I was an underperforming economics student and a former semi-professional football player in a city where I had few friends to play with. While I had always enjoyed playing football, traditional club games had lost their appeal. Instead, I found more enjoyment in playing 15-minute pickup games with my friends in a school backyard on weekend afternoons.`,
            isNewLine: true,
          },
        ],
        color: 'light',
      },
      {
        data: [
          {
            text: `Fast forward two years from the moment when the idea was born, and I had taught myself to code, graduated from the University of Belgrade, Faculty of Economics, and returned to my hometown. For the next two years, I focused on improving my coding skills so that I could build the app. In 2019, I landed my first programming job and started working on Cluster-11 in my spare time.`,
            isNewLine: true,
          },
        ],
        color: 'light',
      },
      {
        data: [
          {
            text: `Fast forward another two years to 2021, and I found myself in Dubai working as a top engineer for a startup valued at $50M+. By mid-2022, our paths had diverged, and I took a leap of faith to finally conclude the seven-year process and fully dedicate myself to Cluster-11.`,
            isNewLine: true,
          },
        ],
        color: 'light',
      },
      {
        data: [
          {
            text: `I always knew that it would happen sometime in 2022 because it marked the seventh year of the entire process. The 'Year 7' philosophy is an essential part of my mindset, which states that any significant mission or project takes seven years to go from zero to one. The idea was inspired by the movie 'Batman Begins' when Alfred tells Bruce Wayne how he was absent for seven years from Gotham. Initially, I used it as a motivation for myself, telling myself that 'It took Bruce Wayne 7 years to become Batman, so I have to be patient.' However, I soon began to notice real-life examples of the seven-year principle in action.`,
            isNewLine: true,
          },
          {
            text: `To name a few:`,
            isNewLine: true,
          },
          {
            text: ` - Elon Musk & SpaceX to develop reusable rockets,`,
            isNewLine: true,
          },
          {
            text: ` - Max Verstappen, the most promising Formula 1 driver at the moment, won his first title`,
            isNewLine: true,
          },
          {
            text: `in his 7th year in Formula 1,`,
            isNewLine: true,
          },
          {
            text: ` - Lewis Hamilton, the best Formula 1 driver in history had to wait 7 years for`,
            isNewLine: true,
          },
          {
            text: `his second title, which traced a path for other titles and for him to become the GOAT,`,
            isNewLine: true,
          },
          {
            text: ` - Nikola Jokic, the 2 time NBA MVP, won his first MVP title seven years after he was drafted by Denver Nuggets,`,
            isNewLine: true,
          },
          {
            text: ` - Facebook turned into Meta, the most ambitious project in the company's history, 7 years after they bought Oculus etc.`,
            isNewLine: true,
          },
        ],
        color: 'light',
      },
      {
        data: [
          {
            text: `At the time of writing, my 'Year 7' is nearing its end. While I don't know what the future holds, I do know that Cluster-11 is now a part of who I am, and I will continue to build this dream of mine no matter how long it takes to become a new paradigm for how we, as a species, compete. I hope my story will inspire others to pursue their own dreams and to never settle for less. Along my journey, I faced many adversities, and at every turn, people questioned my ability to go to the next level. For seven years, I spent most of my time alone, experiencing countless sleepless nights and hard days where it seemed that nothing would change, and that the sun would never rise again. But by pushing through each day, taking it one inch at a time and not dwelling too much on the future, I found the recipe to keep going. It feels unreal to go from having just an idea, with no resources or skills at my disposal, to the point where the app is going live. This is only the end of a chapter, and there is still much work to be done, but it is an important checkpoint. At the end of the day, it is crucial that we believe in ourselves and become our biggest fans.`,
            isNewLine: true,
          },
        ],
        color: 'light',
      },
      {
        data: [{ text: `Work Hard Stay Focused Don't Doubt Never Settle` }],
        color: 'light',
      },
      {
        data: [
          {
            text: `Aleksandar Bulovic, Founder & CEO of cluster-11.`,
            isNewLine: true,
          },
          { text: `Dubai, UAE, December 2022.` },
        ],
        color: 'light',
      },
    ],
  },
  [OUR_VISION_STATIC_PAGE_TYPE]: {
    title: {
      mainText: [
        {
          text: 'Our Dream',
        },
      ],
    },
    textItems: [
      {
        data: [
          {
            text: `Cluster-11 started its life as a place for football enthusiasts where they could organize their football life by creating clubs, adding their friends to club rosters, and arranging matches with other clubs. Its purpose is to provide tools for players to own their achievements and turn those Wednesday night and Sunday afternoon football games into something more. Our dream is to give an equal chance to every player, regardless of their sex, race, age, social or any other status, to pursue their dream of playing football always and everywhere and to be able to live off football for as long as they want. Instead of cheering for professional players & clubs, we dream of millions of players cheering for themselves and their own clubs while they travel the world and play football at different exotic places, meet different people and cultures, and share the passion for the greatest game humankind has ever invented.`,
          },
        ],
        color: 'light',
      },
      {
        data: [
          {
            text: `Our dream does not end there. It expands further. We dream of changing the paradigm of how we as a human species compete, by uniting all football players and clubs at Cluster-11. In today's connected world, clubs and competitions have outgrown national borders a long time ago. The best football clubs in the world are loved by millions from around the globe. We dream of new professional football clubs and associations that will be a treasure for the entire planet. We dream of complete freedom for everyone, where there are no restrictions for clubs to play in any city or compete in any competition they want. We dream of clubs started and owned by players and fans, because they are the ones who make those clubs special. Lastly, we envision a world where outsiders from small or big cities, from Serbia, Argentina, India, and all other places, have a chance to start something with their friends and reach the stars by achieving their dreams of playing football at the top level, without needing to know someone or have strong support from anyone.`,
            isNewLine: true,
          },
        ],
        color: 'light',
      },
    ],
  },
  [CONTACT_STATIC_PAGE_TYPE]: {
    title: {
      mainText: [
        {
          text: 'Contact',
        },
      ],
    },
    textItems: [
      {
        data: [
          { text: 'support: ', isSmaller: true, isNewLine: true },
          { text: 'support@cluster-11.com', isBolder: true },
        ],

        color: 'light',
      },
      {
        data: [
          { text: 'join us: ', isSmaller: true, isNewLine: true },
          { text: 'careers@cluster-11.com', isBolder: true },
        ],

        color: 'light',
      },
      {
        data: [
          { text: 'partherships: ', isSmaller: true, isNewLine: true },
          { text: 'partnerships@cluster-11.com', isBolder: true },
        ],

        color: 'light',
      },
    ],
  },
};
