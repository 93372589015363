// CLUB EVENTS
export const CLUB_LOGO_MINTED_EVENT = 'CLUB_LOGO_MINTED_EVENT';
export const CLUB_CREATED_EVENT = 'CLUB_CREATED_EVENT';
export const CLUB_DELETED_EVENT = 'CLUB_DELETED_EVENT';
export const CLUB_BIO_UPDATED_EVENT = 'CLUB_BIO_UPDATED_EVENT';
export const OFFER_RESOLVED_EVENT = 'OFFER_RESOLVED_EVENT';
export const CLUB_PROFILE_IMAGE_UPLOADED_EVENT =
  'CLUB_PROFILE_IMAGE_UPLOADED_EVENT';

// TRANSFER EVENTS
export const PLAYER_MADE_TRANSFER_OFFER_EVENT =
  'PLAYER_MADE_TRANSFER_OFFER_EVENT';
export const PLAYER_CANCELED_TRANSFER_OFFER_EVENT =
  'PLAYER_CANCELED_TRANSFER_OFFER_EVENT';
export const PLAYER_ENDED_CLUB_MEMBERSHIP_EVENT =
  'PLAYER_ENDED_CLUB_MEMBERSHIP_EVENT';
export const PLAYER_ACCEPTED_TRANSFER_OFFER_EVENT =
  'PLAYER_ACCEPTED_TRANSFER_OFFER_EVENT';

export const CLUB_MADE_TRANSFER_OFFER_EVENT = 'CLUB_MADE_TRANSFER_OFFER_EVENT';
export const CLUB_CANCELED_TRANSFER_OFFER_EVENT =
  'CLUB_CANCELED_TRANSFER_OFFER_EVENT';
export const CLUB_ENDED_CLUB_MEMBERSHIP_EVENT =
  'CLUB_ENDED_CLUB_MEMBERSHIP_EVENT';
export const CLUB_ACCEPTED_TRANSFER_OFFER_EVENT =
  'CLUB_ACCEPTED_TRANSFER_OFFER_EVENT';

// DOM EVENTS
export const OUTSIDE_CLICK_EVENT = 'OUTSIDE_CLICK_EVENT';

// USER EVENTS
export const USER_IMAGE_UPLOADED_EVENT = 'USER_IMAGE_UPLOADED_EVENT';
export const USER_PLAYER_CARD_UPDATED_EVENT = 'USER_PLAYER_CARD_UPDATED_EVENT';

// ACTIVE ROLE EVENTS
export const ACTIVE_ROLE_IN_EVENT = 'ACTIVE_ROLE_IN_EVENT';
export const ACTIVE_ROLE_OUT_EVENT = 'ACTIVE_ROLE_OUT_EVENT';

// EVENTS CAUSED BY RT EVENTS
// -- transfers
export const NEW_LIVE_TRANSFER_OFFER = 'NEW_LIVE_TRANSFER_OFFER';
export const NEW_LIVE_TRANSFER_RESOLVED_NOTIFICATION =
  'NEW_LIVE_TRANSFER_RESOLVED_NOTIFICATION';

// -- matches
export const NEW_LIVE_MATCH_OFFER = 'NEW_LIVE_MATCH_OFFER';
export const NEW_LIVE_MATCH_RESULT_OFFER = 'NEW_LIVE_MATCH_RESULT_OFFER';
export const LIVE_MATCH_SCHEDULED_EVENT = 'LIVE_MATCH_SCHEDULED_EVENT';
export const LIVE_MATCH_RESOLVED_EVENT = 'LIVE_MATCH_RESOLVED_EVENT';
export const LIVE_MATCH_CANCELED_EVENT = 'LIVE_MATCH_CANCELED_EVENT';
export const LIVE_MATCH_ABANDONED_EVENT = 'LIVE_MATCH_ABANDONED_EVENT';

// GAME EVENTS
export const GAME_EVENT_UPDATE = 'GAME_EVENT_UPDATE';
export const GAME_EVENT_DELETE = 'GAME_EVENT_DELETE';

export const EVENTS_LIST = {
  [CLUB_CREATED_EVENT]: [],
  [CLUB_DELETED_EVENT]: [],
  [CLUB_BIO_UPDATED_EVENT]: [],
  [CLUB_PROFILE_IMAGE_UPLOADED_EVENT]: [],
  [OFFER_RESOLVED_EVENT]: [],
  [PLAYER_MADE_TRANSFER_OFFER_EVENT]: [],
  [PLAYER_CANCELED_TRANSFER_OFFER_EVENT]: [],
  [PLAYER_ENDED_CLUB_MEMBERSHIP_EVENT]: [],
  [PLAYER_ACCEPTED_TRANSFER_OFFER_EVENT]: [],
  [CLUB_MADE_TRANSFER_OFFER_EVENT]: [],
  [CLUB_CANCELED_TRANSFER_OFFER_EVENT]: [],
  [CLUB_ENDED_CLUB_MEMBERSHIP_EVENT]: [],
  [CLUB_ACCEPTED_TRANSFER_OFFER_EVENT]: [],
  [OUTSIDE_CLICK_EVENT]: [],
  [CLUB_LOGO_MINTED_EVENT]: [],
  [USER_IMAGE_UPLOADED_EVENT]: [],
  [USER_PLAYER_CARD_UPDATED_EVENT]: [],
  [ACTIVE_ROLE_IN_EVENT]: [],
  [ACTIVE_ROLE_OUT_EVENT]: [],
  [NEW_LIVE_TRANSFER_OFFER]: [],
  [NEW_LIVE_TRANSFER_RESOLVED_NOTIFICATION]: [],
  [NEW_LIVE_MATCH_OFFER]: [],
  [NEW_LIVE_MATCH_RESULT_OFFER]: [],
  [LIVE_MATCH_SCHEDULED_EVENT]: [],
  [LIVE_MATCH_RESOLVED_EVENT]: [],
  [LIVE_MATCH_CANCELED_EVENT]: [],
  [LIVE_MATCH_ABANDONED_EVENT]: [],
  [GAME_EVENT_UPDATE]: [],
  [GAME_EVENT_DELETE]: [],
};
