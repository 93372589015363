import Button from './Button.jsx';
import {
  BUTTON_REGULAR_VARIANT,
  BUTTON_LIGHT_VARIANT,
  BUTTON_NO_BG_VARIANT,
  BUTTON_PRIMARY_TEXT_TYPE,
  BUTTON_DARK_TEXT_TYPE,
  BUTTON_UNSET_TEXT_TYPE,
} from './constants.js';

export {
  BUTTON_REGULAR_VARIANT,
  BUTTON_LIGHT_VARIANT,
  BUTTON_NO_BG_VARIANT,
  BUTTON_PRIMARY_TEXT_TYPE,
  BUTTON_DARK_TEXT_TYPE,
  BUTTON_UNSET_TEXT_TYPE,
};

export default Button;
