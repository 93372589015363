import React, { Children, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import ArrowDropdownIcon from 'common/components/icons/arrow-drop-down-icon';

import style from './YearsMenu.module.scss';

const YearsMenu = ({
  isOpened,
  onOpen,
  onClose,
  label,
  items,
  onSelect,
  selected,
  onShowPrevYears,
  onShowNextYears,
}) => {
  const expandedClassName = isOpened ? style.listExpanded : '';
  const [listItemUniversalKey, setListItemUniversalKey] = useState(Date.now());

  const openedListIconClassName = isOpened
    ? style.iconWrapperRotated
    : style.iconWrapper;

  const yearsSwitchesProps = [
    {
      onClick: e => {
        onShowPrevYears(e);
        setListItemUniversalKey(Date.now());
      },
      toUp: true,
    },
    {
      onClick: e => {
        onShowNextYears(e);
        setListItemUniversalKey(Date.now());
      },
      toUp: false,
    },
  ];

  const renderMenu = () => {
    const listItems = Children.toArray(
      items.map(item => {
        const selectedClassName = item === selected ? style.selected : '';
        return (
          <li
            key={`${item}__${listItemUniversalKey}`}
            className={`${style.cell} ${selectedClassName} ${style['opacity-animation']}`}
            onClick={() => onSelect(item)}
          >
            {item}
          </li>
        );
      })
    );

    const renderYearsSwitches = () =>
      Children.toArray(
        yearsSwitchesProps.map(({ onClick, toUp }) => (
          <Button variant='no-bg' onClick={onClick}>
            <ArrowDropdownIcon fill='black' toUp={toUp} />
          </Button>
        ))
      );

    return (
      <div className={`${style.list} ${expandedClassName}`}>
        <div className={style.yearSwitches}>
          {isOpened && (
            <div className={style['yearSwitches--inner-wrapper']}>
              {renderYearsSwitches()}
            </div>
          )}
        </div>
        <ul className={style.listYears}>{listItems}</ul>
      </div>
    );
  };

  const toggle = e => (isOpened ? onClose() : onOpen(e));

  return (
    <div className={style.wrapper}>
      <Button onClick={toggle} narrow variant='no-bg' textType='dark'>
        <div className={style.yearSelectorButton}>
          {label()}
          <div className={openedListIconClassName}>
            <ArrowDropdownIcon />
          </div>
        </div>
      </Button>
      {renderMenu()}
    </div>
  );
};

export const YearsMenuPropTypes = {
  isOpened: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  label: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onShowPrevYears: PropTypes.func.isRequired,
  onShowNextYears: PropTypes.func.isRequired,
};

YearsMenu.propTypes = YearsMenuPropTypes;

export default YearsMenu;
