import Headline from 'common/components/headline';
import Text from 'common/components/text';
import { useWindowSizeContext } from 'contexts/window-size';
import { PublicPageWrapper } from 'features/page-wrapper';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import {
  pageProps,
  publicStaticPageTypes,
  reactGAPageParam,
} from './constants';
import style from './PublicStaticPage.module.scss';
import ReactGA from 'react-ga';

const PublicStaticPage = ({ type }) => {
  const { title, textItems } = pageProps?.[type];
  const { isMobileView, isTabletView } = useWindowSizeContext();

  const headlineSize = useMemo(() => {
    if (isMobileView) {
      return 3;
    } else if (isTabletView) {
      return 2;
    } else {
      return 1;
    }
  }, [isMobileView, isTabletView]);

  const renderText = useCallback(
    () =>
      textItems?.map(item => (
        <div className={style.textWrapper}>
          <Text {...item} />
        </div>
      )),
    [textItems]
  );

  useEffect(() => {
    ReactGA.pageview(reactGAPageParam[type]);
  }, [type]);

  return (
    <PublicPageWrapper classNames={style.wrapper}>
      <div className={style.content}>
        <Headline {...title} size={headlineSize} />
        {renderText()}
      </div>
    </PublicPageWrapper>
  );
};

PublicStaticPage.propTypes = {
  type: PropTypes.oneOf(publicStaticPageTypes),
};

export default PublicStaticPage;
