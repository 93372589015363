import PropTypes from 'prop-types';
import EventInputBox from '../base-components/event-input-box';
import style from './EventInputClassic.module.scss';
import { useMemo } from 'react';

const EventInputClassic = ({
  inputLabel,
  value,
  optional,
  error,
  valueInlineLabel,
  type,
  onChange,
  smallFont,
}) => {
  const smallFontClassName = useMemo(() => smallFont ? style['input--small-font'] : '', [smallFont]);

  return (
    <EventInputBox
      optional={optional}
      error={error}
      inputLabel={inputLabel}
      hasValue={!!value}
    >
      <div className={style.wrapper}>
        <input
          className={`${style.input} ${smallFontClassName}`}
          onChange={onChange}
          type={type}
          value={value}
        />
        <label className={style.inlineLabel}>{valueInlineLabel}</label>
      </div>
    </EventInputBox>
  );
};

export const EventInputClassicPropTypes = {
  optional: PropTypes.bool,
  error: PropTypes.bool,
  inputLabel: PropTypes.string.isRequired,
  valueInlineLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['text', 'number']).isRequired,
  onChange: PropTypes.func.isRequired,
  smallFont: PropTypes.bool,
};

EventInputClassic.propTypes = EventInputClassicPropTypes;

export default EventInputClassic;
