import PropTypes from 'prop-types';
import style from './PositionsScreen.module.scss';
import '../../style.scss';
import { useCallback, useState, useMemo } from 'react';
import Button from 'common/components/button';
import {
  GOALKEEPER_POSITION_AREA,
  POSITION_TABS,
  positionsMap,
} from 'features/player-form/constants';
import ScreenTitle from '../screen-title';
import PlayerMovementsIcon from 'common/components/icons/player-movements-icon';
import CloseIcon from 'common/components/icons/close-icon';
import { DARK_MATERIAL_TERTIARY_COLOR } from 'common/constants/theming';
import { QUICK_ANIMATION_DURATION_MS } from 'common/constants/integers';

const PositionsScreen = ({
  fieldSidesProps,
  primaryArea,
  naturalPositionProps,
  secondaryPositionsProps,
}) => {
  const [selectedTab, setSelectedTab] = useState(GOALKEEPER_POSITION_AREA);
  const [positionsViewLoading, setPositionsViewLoading] = useState(false);

  const handlePositionsViewLoadingWithBounce = useCallback(cb => {
    setPositionsViewLoading(true);
    setTimeout(() => {
      setPositionsViewLoading(false);
      cb();
    }, QUICK_ANIMATION_DURATION_MS);
  }, []);

  const renderTabs = useCallback(
    () =>
      POSITION_TABS.map(tab => {
        const isSelectedClassName =
          tab === selectedTab ? `${style['tabItem--selected']}` : '';
        return (
          <Button
            onClick={() => {
              handlePositionsViewLoadingWithBounce(() => setSelectedTab(tab));
            }}
            key={tab}
            customClassNames={`${style.tabItem} ${isSelectedClassName}`}
          >
            {tab}
          </Button>
        );
      }),
    [handlePositionsViewLoadingWithBounce, selectedTab]
  );

  const renderFieldSidesSelectors = useCallback(() => {
    return fieldSidesProps?.options?.map(option => {
      const isSelected = fieldSidesProps.selected.includes(option);

      const onClick = () => {
        if (isSelected) {
          fieldSidesProps.remove(option);
        } else {
          fieldSidesProps.add(option);
        }
      };

      const isSelectedClassName = isSelected
        ? `${style['tabItem--selected-multiple']}`
        : '';

      return (
        <Button
          onClick={onClick}
          customClassNames={`${style.tabItem} ${isSelectedClassName}`}
        >
          {option}
        </Button>
      );
    });
  }, [fieldSidesProps]);

  const renderFieldAreaPositions = useCallback(() => {
    return positionsMap[selectedTab].map(position => {
      const onNaturalClick = () => {
        naturalPositionProps.set(position);
      };

      const onSecondaryClick = () => {
        secondaryPositionsProps.onToggle(position);
      };

      const optionClassName = `player-form-option`;
      const isNaturalPositionClassName =
        naturalPositionProps.selected === position
          ? `${optionClassName}__option--primary`
          : '';

      const isSecondaryPositionClassName =
        secondaryPositionsProps.selectedPositions.includes(position)
          ? `${optionClassName}__option--secondary`
          : '';

      return (
        <div className={`${optionClassName}__wrapper`}>
          <label className={`${optionClassName}__label`}>{position}</label>
          <div className={`${optionClassName}__option`}>
            <Button
              onClick={onNaturalClick}
              customClassNames={`${isNaturalPositionClassName}`}
            >
              natural
            </Button>
          </div>
          <div className={`${optionClassName}__option`}>
            <Button
              onClick={onSecondaryClick}
              customClassNames={isSecondaryPositionClassName}
            >
              secondary
            </Button>
          </div>
        </div>
      );
    });
  }, [naturalPositionProps, secondaryPositionsProps, selectedTab]);

  const renderSelectedSecondaryPositions = useCallback(() => {
    return secondaryPositionsProps.selectedPositions.map(position => {
      return (
        <div className={style.positionButtonWrapper}>
          <Button
            key={position}
            onClick={() => secondaryPositionsProps.onToggle(position)}
            customClassNames={style.positionButton}
          >
            {position} <CloseIcon color={DARK_MATERIAL_TERTIARY_COLOR} />
          </Button>
        </div>
      );
    });
  }, [secondaryPositionsProps]);

  // TODO create a component for this
  // component should be a wrapper that has a conditional render
  const smoothConditionalRenderClassName =
    secondaryPositionsProps?.selectedPositions?.length > 0
      ? style['smoothConditionalRender--show']
      : style['smoothConditionalRender--hide'];

  const buttonSets = useMemo(
    () => [
      {
        label: 'Field Sides',
        contentCb: renderFieldSidesSelectors,
      },
      {
        label: 'Field Area',
        contentCb: renderTabs,
      },
    ],
    [renderFieldSidesSelectors, renderTabs]
  );

  const renderButtonsSet = useCallback(
    sets =>
      sets.map(({ label, contentCb }) => (
        <div className={style.section} key={label}>
          <h4 className={style['section__title']}>{label}</h4>
          <div className={style.tabs}>{contentCb()}</div>
        </div>
      )),
    []
  );

  const positionsAnimationClassName = positionsViewLoading
    ? style['positionsView--loading']
    : style['positionsView--loaded'];

  return (
    <div className={style['wrapper']}>
      <ScreenTitle Icon={PlayerMovementsIcon} title='Positions' />
      <div>
        <div className='player-form__skill-title'>
          <div className={style.playerNaturalPositionWrapper}>
            <h2 className='player-form__category-label'>
              Primary Area:
              <b> {primaryArea?.toUpperCase() || '???'}</b>
            </h2>
            <h3 className='player-form__sub-info'>
              <b>natural position:</b> {naturalPositionProps.selected || 'N/A'}{' '}
            </h3>
          </div>
        </div>

        <div
          className={`${style.selectedPositionsWrapper}  ${smoothConditionalRenderClassName}`}
        >
          <h3 className='player-form__sub-info'>secondary positions</h3>

          <div className={style['selectedPositionsWrapper__items']}>
            {renderSelectedSecondaryPositions()}
          </div>
        </div>
      </div>

      {renderButtonsSet(buttonSets)}

      {/* Positions */}
      <div
        className={`${style.section} ${style.positionsView} ${positionsAnimationClassName}`}
      >
        {renderFieldAreaPositions()}
      </div>
    </div>
  );
};

PositionsScreen.propTypes = {
  fieldSidesProps: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.string),
    selected: PropTypes.arrayOf(PropTypes.string),
    add: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }),
  primaryArea: PropTypes.string,
  naturalPositionProps: PropTypes.shape({
    selected: PropTypes.string,
    set: PropTypes.func.isRequired,
  }),
  secondaryPositionsProps: PropTypes.shape({
    onToggle: PropTypes.func.isRequired,
    selectedPositions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default PositionsScreen;
