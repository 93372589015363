import CopyToClipboard from 'features/share/components/copy-to-clipboard';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import './ListItem.scss';
import ReactGA from 'react-ga';
import { REACT_GA_FORM_FIELD_VALUE_SHARE_EVENT } from 'common/constants/react-google-analytics/events';

const className = 'c-ListItem';

const ListItem = ({
  label,
  fieldValue,
  actionButton,
  skipEmpty,
  highlighted,
  addClipboardCopy,
}) => {
  const onClipboardCopy = useCallback(() => {
    navigator.clipboard.writeText(fieldValue);
    ReactGA.event(REACT_GA_FORM_FIELD_VALUE_SHARE_EVENT);
  }, [fieldValue]);

  if (skipEmpty && !fieldValue) return null;

  const highlightedClassName = highlighted ? `${className}--highlighted` : '';

  return (
    <li className={`${className}__item`}>
      <label className={`${className}__label ${highlightedClassName}`}>
        {label}
      </label>
      <span className={`${className}__value`}>{fieldValue}</span>
      {addClipboardCopy && (
        <CopyToClipboard
          onClick={onClipboardCopy}
          className={`${className}__clipboard`}
        />
      )}
      {actionButton}
    </li>
  );
};

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  actionButton: PropTypes.object,
  skipEmpty: PropTypes.bool,
  highlighted: PropTypes.bool,
  addClipboardCopy: PropTypes.bool,
};

export default ListItem;
