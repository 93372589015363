export const NODE_ENV = process.env.NODE_ENV;
export const API_BASE = process.env.REACT_APP_API_BASE;
export const BASE_URL = process.env.REACT_APP_ROOT_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const FOR_INVESTORS_URL = process.env.REACT_APP_FOR_INVESTORS_URL;
export const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const IG_URL = process.env.REACT_APP_IG_URL;
export const LI_URL = process.env.REACT_APP_LI_URL;
export const TW_URL = process.env.REACT_APP_TW_URL;
export const MEDIA_URL = process.env.REACT_APP_MEDIA_BUCKET;

global.setImmediate =
  global.setImmediate || ((fn, ...args) => global.setTimeout(fn, 0, ...args));
