/* eslint-disable max-len */
import style from './EventInputQuestionMark.module.scss';

const EventInputQuestionMark = () => (
  <div className={style.wrapper}>
    <svg height='24' viewBox='0 96 960 960' width='24'>
      <path d='m515 976-10-110h-40q-143 0-244-101T120 521q0-143 101.5-244T467 176q71 0 131.5 25.5T704 273q45 46 70.5 108.5T800 518q0 134-75.5 258.5T515 976Zm-48-223q16 0 27-11t11-27q0-16-11-27t-27-11q-16 0-27 11t-11 27q0 16 11 27t27 11Zm-2-136q10 0 17.5-7t9.5-18q3-14 12.5-27t29.5-33q27-27 38-49.5t11-48.5q0-45-30.5-74T471 331q-35 0-64.5 15.5T359 390q-6 9-2 18.5t15 14.5q8 3 17 0t15-11q11-16 27.5-23.5T468 381q30 0 47 14.5t17 38.5q0 19-11 38.5T482 521q-21 22-30 36.5T441 592q-1 10 6 17.5t18 7.5Zm100 189v62q82-69 128.5-162.5T740 518q0-124-77.5-203T467 236q-120 0-203.5 83.5T180 521q0 118 83.5 201.5T465 806h100ZM460 552Z' />
    </svg>
  </div>
);

export default EventInputQuestionMark;
