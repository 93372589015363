import { useEventsContext } from 'contexts/events';
import { CLUB_LOGO_MINTED_EVENT } from 'contexts/events/constants';
import { useCallback, useEffect, useState } from 'react';

// TODO come back to update logo images on upload event
const useGMItems = initGMItems => {
  const { subscribe } = useEventsContext();
  const [gmItems, setGMItems] = useState([]);

  const updateGMItemsOnLogoMinting = useCallback(
    eventData => {
      const updatedGMItems = gmItems?.map(item => {
        if (item?._id === eventData?.clubId) {
          item.logo = eventData?.clubLogo;
        }

        return item;
      });

      setGMItems(updatedGMItems);
    },
    [gmItems]
  );

  useEffect(() => {
    setGMItems(initGMItems);

    return setGMItems(initGMItems);
  }, [initGMItems]);

  useEffect(() => {
    subscribe(CLUB_LOGO_MINTED_EVENT, updateGMItemsOnLogoMinting);
  }, [subscribe, updateGMItemsOnLogoMinting]);

  return {
    gmItems,
  };
};

export default useGMItems;
