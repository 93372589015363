import EventCard from 'common/components/event-card';
import PropTypes from 'prop-types';
import { useProps } from './hooks';
import style from './GameEvent.module.scss';
import {
  DELETE_GAME_EVENT_MODAL,
  EDIT_GAME_EVENT_MODAL,
  SHARE_IN_MODAL,
} from './constants';
import Modal, { DARK_THEME } from 'features/modal';
import Share from 'features/share';
import {
  GAME_EVENT_SHARE_GROUP,
  GAME_EVENT_SHARE_TYPE,
} from 'features/share/constants';
import CreateEditGameEvent from '../create-edit-game-event';
import { EDIT_GAME_EVENT_MODE } from '../constants';
import Headline from 'common/components/headline';
import Button from 'common/components/button';

const GameEvent = ({ gameData, onClose }) => {
  const {
    gameCardProps,
    shareProps,
    deleteGameEventModalProps,
    inModalOpened,
    closeInModal,
  } = useProps({
    matchData: gameData,
    onClose,
  });

  return (
    <div className={style.wrapper}>
      <EventCard
        {...gameCardProps}
        onClose={onClose}
        customClassName={style.eventCard}
      />
      {/* -- In Modals -- */}
      {inModalOpened === SHARE_IN_MODAL && (
        <Modal
          theme={DARK_THEME}
          fullScreen
          withoutWrapper
          onClose={closeInModal}
          customModalContentClassName={style.modalContent}
        >
          <Share
            type={GAME_EVENT_SHARE_TYPE}
            data={gameData}
            customShareProps={shareProps}
            size='xl'
            shareGroup={GAME_EVENT_SHARE_GROUP}
          />
        </Modal>
      )}

      {inModalOpened === EDIT_GAME_EVENT_MODAL && (
        <CreateEditGameEvent
          mode={EDIT_GAME_EVENT_MODE}
          gameData={gameData}
          onClose={closeInModal}
        />
      )}

      {inModalOpened === DELETE_GAME_EVENT_MODAL && (
        <Modal
          {...deleteGameEventModalProps?.modal}
          theme={DARK_THEME}
          withoutWrapper
          customModalContentClassName={style.modalContent}
        >
          <div
            className={`mobile-modal-content--center ${style.modalContentWrapper}`}
          >
            <Headline {...deleteGameEventModalProps.headline} />
            <Button
              onClick={deleteGameEventModalProps?.button?.onClick}
              variant={deleteGameEventModalProps?.button.variant}
            >
              {deleteGameEventModalProps?.button?.label}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

GameEvent.propTypes = {
  gameData: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
};

export default GameEvent;
