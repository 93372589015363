import { useForm } from 'common/hooks';
import { useAuthContext } from 'contexts/auth';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  initPasswordState,
  passwordFormFields,
  PASSWORD_UPDATED_MESSAGE,
  RESET_PASSWORD_PUBLIC_PAGE_TYPE,
  VALIDATE_ACCOUNT_PUBLIC_PAGE_TYPE,
} from '../constants';
import { resetPasswordSchema } from '../schemas';

const useProps = type => {
  const location = useLocation();
  const token = location?.search?.split('?')?.[1]?.split('=')?.[1];

  const { validateAccount, resetPassword } = useAuthContext();
  const [message, setMessage] = useState('');
  const [
    shouldShowResetPasswordInputForm,
    setShouldShowResetPasswordInputForm,
  ] = useState(type === RESET_PASSWORD_PUBLIC_PAGE_TYPE);

  const handlePasswordResetSubmit = useCallback(
    data => {
      setShouldShowResetPasswordInputForm(false);
      resetPassword(token, { password: data?.password }, () =>
        setMessage(PASSWORD_UPDATED_MESSAGE)
      );
    },
    [resetPassword, token]
  );

  const { submit: submitPasswordReset, getInputProps: getPasswordInputProps } =
    useForm(
      initPasswordState,
      passwordFormFields,
      resetPasswordSchema,
      handlePasswordResetSubmit
    );

  useEffect(() => {
    if (type === VALIDATE_ACCOUNT_PUBLIC_PAGE_TYPE) {
      validateAccount(token, setMessage);
    }
  }, [token, type, validateAccount]);

  return {
    message: [{ text: message }],
    submitPasswordReset,
    resetPasswordInputProps: getPasswordInputProps(),
    shouldShowResetPasswordInputForm,
  };
};

export default useProps;
