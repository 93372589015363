const baseAssetsPath = '/assets';

export const galaxyVideoCover = {
  videoMediaProps: {
    sources: [
      {
        src: 'assets/video/spaceLoop.mp4',
        type: 'video/mp4',
      },
      {
        src: 'assets/video/spaceLoop.webm',
        type: 'video/webm',
      },
    ],
    videoProps: {
      loop: true,
      autoPlay: true,
      playsInline: true,
    },
  },
};

export const DEFAULT_USER_IMAGE = {
  alt: 'c-11 logo',
  mobileSrc: [
    {
      srcSet: `${baseAssetsPath}/webp/boots-2.webp`,
      type: 'image/webp',
      media: '(min-width: 120px)',
    },
    {
      srcSet: `${baseAssetsPath}/jpg/boots-2.jpg`,
      type: 'image/jpg',
      media: '(min-width: 120px)',
    },
  ],
  desktopSrc: [
    {
      srcSet: `${baseAssetsPath}/webp/boots-2.webp`,
      type: 'image/webp',
      media: '(min-width: 400px)',
    },
    {
      srcSet: `${baseAssetsPath}/jpg/boots-2.jpg`,
      type: 'image/jpg',
      media: '(min-width: 400px)',
    },
  ],
};

export const DEFAULT_CLUB_LOGO = {
  alt: 'monoliths',
  mobileSrc: [
    {
      srcSet: `${baseAssetsPath}/webp/neon-monoliths-logo.webp`,
      type: 'image/webp',
      media: '(min-width: 120px)',
    },
    {
      srcSet: `${baseAssetsPath}/jpg/neon-monoliths-logo.jpg`,
      type: 'image/jpg',
      media: '(min-width: 120px)',
    },
  ],
  desktopSrc: [
    {
      srcSet: `${baseAssetsPath}/webp/neon-monoliths-logo.webp`,
      type: 'image/webp',
      media: '(min-width: 400px)',
    },
    {
      srcSet: `${baseAssetsPath}/jpg/neon-monoliths-logo.jpg`,
      type: 'image/jpg',
      media: '(min-width: 400px)',
    },
  ],
};

// card content types
export const CLUB_CARD_LOGO = 'club-card-logo';
export const MATCH_CARD_LOGO = 'match-card-logo';
export const TRANSFER_CARD_LOGO = 'transfer-card-logo';
