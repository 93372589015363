/* eslint-disable max-len */
export const CREATE_GAME_EVENT_BTN_TOOLTIP = 'create game';

// screens NAMES
export const FORM_SCREEN = 'create-game-form-screen';
export const CONFIRM_SCREEN = 'create-game-confirm-screen';
export const SHARE_SCREEN = 'create-game-share-screen';

// input props

// -- time-date props
export const DEFAULT_TIME = '2000';
export const CALENDAR_MODAL = 'CALENDAR_MODAL';
export const TIMESLOT_MODAL = 'TIMESLOT_MODAL';
export const TIME_SELECTOR_TITLE = 'Set Game Time';

// -- game type props
export const PICKUP_GAME_CONSTANTS = {
  type: 'pickup game',
  description:
    'A non-competitive game of football where anyone can join in and play.',
};
export const TEAM_VS_TEAM_GAME_CONSTANTS = {
  type: 'team vs team',
  description:
    'Both teams will have the option to sign up by filling out a form indicating their team name, player names, and contact information.',
};
export const GAME_AGAINST_OTHER_TEAM = {
  type: 'vs other team',
  description:
    'Use this option to sign up your team to play against other team.',
};
export const gameTypeStaticProps = {
  inputLabel: 'Game Type',
  optionProps: [PICKUP_GAME_CONSTANTS, GAME_AGAINST_OTHER_TEAM],
};

// -- skill level props
/*
Beginner;
Amateur;
Semi-Pro;
Professional;
World Class; 
Legendary; // Allow Unlock mode 
Ultimate (only in FUT). // Allow Unlock mode
*/
export const BEGINNER_SKILL_LEVEL = 'Beginner';
export const AMATEUR_SKILL_LEVEL = 'Amateur';
export const SEMI_PRO_SKILL_LEVEL = 'Semi-Pro';
export const PROFESSIONAL_SKILL_LEVEL = 'Professional';
export const INTERNATIONAL_SKILL_LEVEL = 'International';
export const WORLD_CLASS_SKILL_LEVEL = 'World Class'; // NOTE PAID-FEATURE lock for a pro members only

export const BEGINNER_SKILL_LEVEL_CONSTANTS = {
  type: BEGINNER_SKILL_LEVEL,
  description:
    'Recommended for beginners who are looking for a friendly and supportive environment to play football.',
};
export const AMATEUR_SKILL_LEVEL_CONSTANTS = {
  type: AMATEUR_SKILL_LEVEL,
  description:
    'For those who have some experience playing football but may not be as confident or skilled as more experienced players.',
};
export const SEMI_PRO_SKILL_LEVEL_CONSTANTS = {
  type: SEMI_PRO_SKILL_LEVEL,
  description:
    'The semi-pro game is perfect for players who have been playing football for a while and are looking for a competitive game.',
};
export const PROFESSIONAL_SKILL_LEVEL_CONSTANTS = {
  type: PROFESSIONAL_SKILL_LEVEL,
  description:
    'This option is for players with a high level of football skills, including those who have played professionally or at a high amateur level.',
};
export const INTERNATIONAL_SKILL_LEVEL_CONSTANTS = {
  type: INTERNATIONAL_SKILL_LEVEL,
  description: 'Only for the most skilled and experienced players.',
};
export const WORLD_CLASS_SKILL_LEVEL_CONSTANTS = {
  type: WORLD_CLASS_SKILL_LEVEL,
  description: 'The elite level. Only for the best of the best.',
};

// TODO refactor to shared constants
export const skillLevelStaticProps = {
  inputLabel: 'Experience Level',
  optionProps: [
    BEGINNER_SKILL_LEVEL_CONSTANTS,
    AMATEUR_SKILL_LEVEL_CONSTANTS,
    SEMI_PRO_SKILL_LEVEL_CONSTANTS,
    PROFESSIONAL_SKILL_LEVEL_CONSTANTS,
  ],
};

// -- duration props
export const durationStaticProps = {
  inputLabel: 'Game Duration',
  valueInlineLabel: 'minutes',
  type: 'number',
  defaultValue: 60,
};

// -- number of players props
export const numberOfPlayersStaticProps = {
  inputLabel: 'Number Of Players',
  valueInlineLabel: 'aside',
  type: 'number',
  defaultValue: 7,
};

// -- contact props
export const hostContactStaticProps = {
  inputLabel: 'Host Contact',
  optional: true,
  type: 'text',
};

// -- tags
export const TAGS_INPUT_LABEL = {
  label: 'Notes',
  description: '(Team 1, Team 2, price, ...)',
};
