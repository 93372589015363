import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from './components/error-page';
import { staticProps } from './constants';
import { goToStreetPage } from 'common/utils/routing';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage {...staticProps} onGoBack={goToStreetPage} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
