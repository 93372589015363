/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

// NOTE a weird bug where color props is ignored
const EditIcon = ({ color }) => (
  <svg height='24px' viewBox='0 0 24 24' width='24px' fill='#000000'>
    <path d='M0 0h24v24H0z' fill='none' />
    <path
      // NOTE had to use a manual override
      fill={ICON_DARK_THEME}
      d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'
    />
  </svg>
);

EditIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

EditIcon.propTypes = {
  color: PropTypes.string,
};

export default EditIcon;
