import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';

import style from './SubmitHandlers.module.scss';

const SubmitHandlers = ({ onCancel, onConfirm, cancelLabel, confirmLabel }) => {
  const handlerProps = [
    { label: cancelLabel, onClick: onCancel },
    { label: confirmLabel, onClick: onConfirm },
  ];

  const renderHandlers = () =>
    Children.toArray(
      handlerProps.map(({ label, onClick }) => (
        <Button
          withRipples
          narrow
          transparent
          textType='primary'
          onClick={onClick}
        >
          {label}
        </Button>
      ))
    );

  return <div className={style.wrapper}>{renderHandlers()}</div>;
};

SubmitHandlers.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
};

export default SubmitHandlers;
