import { TEST_ENVIRONMENT } from 'common/constants/config';
import {
  DESKTOP_WIDTH,
  TABLET_WIDTH,
  MOBILE_WIDTH,
} from 'common/constants/domData';
import { NODE_ENV } from '_config';

export const checkIsMobile = () => window.innerWidth < MOBILE_WIDTH;
export const checkIsTablet = () => window.innerWidth < TABLET_WIDTH;
// think of widths between tablet and desktop as large tablet
export const checkIsDesktop = () => window.innerWidth > DESKTOP_WIDTH;

export const isInViewport = (el, verticalPadding = 0) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top + verticalPadding >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= window.innerHeight + 2 + verticalPadding &&
    rect.right <= window.innerWidth + 2
  );
};

export const setupPortalTestEnvironment = () => {
  if (NODE_ENV === TEST_ENVIRONMENT) {
    let portal = document.getElementById('portal');
    if (!portal) {
      portal = document.createElement('div');
      portal.setAttribute('id', 'portal');
      document.body.appendChild(portal);
      console.log('portal node added');
    }
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export const onClipboardCopy = value => {
  navigator.clipboard.writeText(value);
};
