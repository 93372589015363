export const getMatchName = item => {
  const dayCallSign = item?.matchDate?.dayKey || '';
  const timeslotCallSign = item?.matchDate?.timeslot || '';
  const locationCallSign = item?.location || 'undisclosed-location';

  const timedateCallSign =
    dayCallSign && timeslotCallSign
      ? `${dayCallSign}-${timeslotCallSign}`
      : `${item?.country}-${locationCallSign}`;

  return `${item?.city}-${timedateCallSign}`;
};
