import { useCallback, useEffect, useState } from 'react';

// NOTE exitFlag to signal that animation has finished and you can remove the element
// exitFlag: Boolean
// NOTE exitClassName a class to animate element before it is hidden
const useAnimationExit = (initFlag, classNames, delay) => {
  const [exitFlag, setExitFlag] = useState(false);
  const [skippedInitClosedState, setSkippedInitClosedState] = useState(false);
  const [exitClassNames, setExitClassNames] = useState('');

  const triggerExit = useCallback(
    flag => {
      if (flag && skippedInitClosedState) {
        setExitClassNames(classNames);
        const timeout = setTimeout(() => {
          setExitFlag(true);
          setExitClassNames('');
          clearTimeout(timeout);
        }, delay);
      } else {
        setExitFlag(flag);
        setSkippedInitClosedState(true);
      }
    },
    [classNames, delay, skippedInitClosedState]
  );

  useEffect(() => {
    triggerExit(initFlag);
  }, [initFlag, triggerExit]);

  return { exitFlag, exitClassNames, exitClassNamesArr: exitClassNames?.split(' ') };
};

export default useAnimationExit;
