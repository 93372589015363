import PropTypes from 'prop-types';
import Image, { ImagePropTypes } from 'common/components/image';
import { CTAPropTypes } from 'common/constants/prop-types';
import style from './CTASection.module.scss';
import SignUpNowCTA from '../sign-up-now-cta';

const CTASection = ({ img, title, subtitle, cta }) => (
  <div className={style.wrapper}>
    <div className={style.imgWrapper}>
      <Image {...img} />
    </div>
    <div className={style.imgOverlay}></div>

    {/* Content */}
    <div className={style.content}>
      {/* Title */}
      <h3>{title}</h3>

      {/* Subtitle */}
      <h4>{subtitle}</h4>

      {/* CTA */}
      <div>
        <SignUpNowCTA {...cta} />
      </div>
    </div>
  </div>
);

CTASection.propTypes = {
  img: PropTypes.shape(ImagePropTypes).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cta: CTAPropTypes.isRequired,
};

export default CTASection;
