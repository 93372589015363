import PropTypes from 'prop-types';
import Image, { ImagePropTypes } from 'common/components/image';
import { CTAPropTypes } from 'common/constants/prop-types';
import SignUpNowCTA from '../sign-up-now-cta';
import style from './Banner.module.scss';
import { useRef } from 'react';
import MainMessageSection from '../main-message-section';
import { TextPropTypes } from 'common/components/text';

const Banner = ({ signUpCTA, coverImg, logoImg, description }) => {
  const contentRef = useRef();

  return (
    <div className={style.wrapper}>
      <div className={style.cover}>
        <div className={style.overlay}></div>
        <Image {...coverImg} />
      </div>

      {/* Content */}
      <div className={style.content} ref={contentRef}>
        {/* left side */}
        <div className={style.leftScreen}>
          <MainMessageSection text={description} />
          <div className={style.ctaWrapper}>
            <SignUpNowCTA {...signUpCTA} />
          </div>
        </div>

        {/* right side */}
        <div className={style.rightScreen}>
          <div className={style.logoWrapper}>
            <Image {...logoImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  signUpCTA: CTAPropTypes.isRequired,
  coverImg: PropTypes.shape(ImagePropTypes).isRequired,
  logoImg: PropTypes.shape(ImagePropTypes).isRequired,
  description: PropTypes.shape(TextPropTypes),
};

Banner.defaultProps = {};

export default Banner;
