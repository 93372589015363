import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { checkIsDesktop, checkIsTablet, checkIsMobile } from 'common/utils/dom';
import {
  DESKTOP_VIEW,
  MOBILE_VIEW,
  TABLET_VIEW,
} from 'common/constants/domData';

const WindowSizeContext = createContext();

export const WindowSizeContextProvider = ({ children }) => {
  const [isDesktopView, setIsDesktopView] = useState(checkIsDesktop());
  const [isTabletView, setIsTabletView] = useState(checkIsTablet());
  const [isMobileView, setIsMobileView] = useState(checkIsMobile());

  const windowSize = useMemo(() => {
    if (isDesktopView) return DESKTOP_VIEW;
    if (isTabletView) return TABLET_VIEW;
    if (isMobileView) return MOBILE_VIEW;
  }, [isDesktopView, isMobileView, isTabletView]);

  const updateModeOnWindowChange = () => {
    setIsDesktopView(checkIsDesktop());
    setIsTabletView(checkIsTablet());
    setIsMobileView(checkIsMobile());
  };

  const initListener = useCallback(() => {
    window.addEventListener('resize', () => updateModeOnWindowChange());
  }, []);

  const removeListener = useCallback(() => {
    window.removeEventListener('resize', updateModeOnWindowChange);
  }, []);

  useEffect(() => {
    initListener();

    return removeListener();
  }, [initListener, removeListener]);

  const value = { isDesktopView, isTabletView, isMobileView, windowSize };

  return (
    <WindowSizeContext.Provider value={value}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export const useWindowSizeContext = () => useContext(WindowSizeContext);
