import PropTypes from 'prop-types';
import {
  ARRANGE_MATCH_MODE_CONFIRM_MODAL,
  ARRANGE_MATCH_MODE_EDIT_FORM,
  MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL,
  PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL,
  PROPOSE_MATCH_RESULT_MODE_EDIT_FORM,
  RESOLVED_MATCH_MODAL,
  RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL,
} from './constants';

export const MatchModalPropTypes = PropTypes.oneOf([
  ARRANGE_MATCH_MODE_EDIT_FORM,
  ARRANGE_MATCH_MODE_CONFIRM_MODAL,
  MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL,
  PROPOSE_MATCH_RESULT_MODE_EDIT_FORM,
  PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL,
  RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL,
  RESOLVED_MATCH_MODAL,
]);
