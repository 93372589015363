import { useAuthContext } from 'contexts/auth';
import PrivatePageWrapper from '../private';
import PublicPageWrapper from '../public';

const SmartPageWrapper = ({ children, ...props }) => {
  const { isGuestView } = useAuthContext();

  if (isGuestView) {
    return <PublicPageWrapper {...props}>{children}</PublicPageWrapper>;
  } else {
    return <PrivatePageWrapper {...props}>{children}</PrivatePageWrapper>;
  }
};

export default SmartPageWrapper;
