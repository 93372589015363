import PropTypes from 'prop-types';
import { Children, useCallback } from 'react';
import {
  copyToClipboardRGAEvents,
  GAME_EVENT_SHARE_GROUP,
  PLAYED_MATCH_SHARE_GROUP,
  SCHEDULED_MATCH_SHARE_GROUP,
  shareRGAEvents,
  SHARE_TYPES,
} from './constants';
import { getMatchUrl, getShareProps } from './utils';
import './Share.scss';
import CopyToClipboard from './components/copy-to-clipboard';
import ReactGA from 'react-ga';

const className = 'c-Share';

const Share = ({ type, data, shareGroup, size, customShareProps }) => {
  const onCopyToClipboard = useCallback(() => {
    const urlToShare = customShareProps?.url || getMatchUrl(data?._id);

    navigator.clipboard.writeText(urlToShare);
    if (shareGroup) {
      ReactGA.event(copyToClipboardRGAEvents[shareGroup]);
    }
  }, [customShareProps?.url, data?._id, shareGroup]);

  const renderShareButtons = useCallback(() => {
    const props = getShareProps({ type, data, customShareProps });
    const commonIconProps = { borderRadius: '4px', size: 32, opacity: '.9' };
    const itemSizeClassName = `${className}__item--${size}`;

    const onShareEvent = () => {
      if (shareGroup) {
        ReactGA.event(shareRGAEvents?.[shareGroup]?.[type]);
      }
    };

    const elements = Children.toArray(
      props?.map(({ Component, Icon, ...otherProps }) => (
        <li
          className={`${className}__item ${itemSizeClassName}`}
          onClick={onShareEvent}
        >
          <Component {...otherProps}>
            <Icon {...commonIconProps} />
          </Component>
        </li>
      ))
    );

    elements.unshift(
      <li className={`${className}__item ${itemSizeClassName}`}>
        <CopyToClipboard onClick={onCopyToClipboard} />
      </li>
    );

    return elements;
  }, [customShareProps, data, onCopyToClipboard, shareGroup, size, type]);

  return <div className={className}>{renderShareButtons()}</div>;
};

Share.defaultProps = {
  size: 'lg',
};

Share.propTypes = {
  type: PropTypes.oneOf(SHARE_TYPES),
  shareGroup: PropTypes.oneOf([
    SCHEDULED_MATCH_SHARE_GROUP,
    PLAYED_MATCH_SHARE_GROUP,
    GAME_EVENT_SHARE_GROUP,
  ]),
  size: PropTypes.oneOf(['lg', 'xl']),
  data: PropTypes.object.isRequired,
  customShareProps: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    hashtags: PropTypes.array,
  }),
};

export default Share;
