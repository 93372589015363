import React from 'react';
import PropTypes from 'prop-types';
import {
  BUTTON_UNSET_TEXT_TYPE,
  BUTTON_DARK_TEXT_TYPE,
  BUTTON_LIGHT_VARIANT,
  BUTTON_NO_BG_VARIANT,
  BUTTON_SHALLOW_VARIANT,
  BUTTON_PRIMARY_TEXT_TYPE,
  BUTTON_REGULAR_VARIANT,
  BUTTON_DARK_BLACK_VARIANT,
  BUTTON_EMPHASIZED_VARIANT,
  BUTTON_MAIN_ON_SURFACE_VARIANT,
} from './constants';
import './Button.scss';
import { useCallback } from 'react';
import ButtonRippleEffect from 'common/components/animation-components/button-ripple-effect';

const className = 'c-Button';

const Button = ({
  children,
  onClick,
  isDisabled,
  variant,
  textType,
  transparent,
  narrow,
  standardWidth,
  animate,
  square,
  rounded,
  withRipples,
  customClassNames,
  ...otherProps
}) => {
  const activeAnimateClassName = animate
    ? `${className}--with-active-animation`
    : '';
  const squareClassName = square ? `${className}--square` : '';
  const roundedClassName = rounded ? `${className}--rounded` : '';
  const classNames = [
    transparent ? `${className}--transparent` : '',
    narrow ? `${className}--narrow` : '',
    standardWidth ? `${className}--standard-width` : '',
    activeAnimateClassName,
    squareClassName,
    roundedClassName,
  ].join(' ');

  const renderButton = useCallback(
    withClick => {
      const handler = withClick && !isDisabled ? onClick : null;

      const disabledClassName = isDisabled ? `${className}--disabled` : '';

      return (
        <button
          onClick={handler}
          {...otherProps}
          disabled={isDisabled}
          className={`${className} ${className}--${variant} ${className}--${textType} ${classNames} ${disabledClassName} ${customClassNames}`}
        >
          {children}
        </button>
      );
    },
    [
      children,
      classNames,
      customClassNames,
      isDisabled,
      onClick,
      otherProps,
      textType,
      variant,
    ]
  );

  return withRipples ? (
    <ButtonRippleEffect
      onClickHandler={isDisabled ? null : onClick}
      narrow={narrow}
      variant={variant}
      standardWidth={standardWidth}
    >
      {renderButton(false)}
    </ButtonRippleEffect>
  ) : (
    renderButton(true)
  );
};

Button.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    BUTTON_REGULAR_VARIANT,
    BUTTON_LIGHT_VARIANT,
    BUTTON_DARK_BLACK_VARIANT,
    BUTTON_NO_BG_VARIANT,
    BUTTON_SHALLOW_VARIANT,
    BUTTON_EMPHASIZED_VARIANT,
    BUTTON_MAIN_ON_SURFACE_VARIANT,
  ]),
  textType: PropTypes.oneOf([
    BUTTON_PRIMARY_TEXT_TYPE,
    BUTTON_DARK_TEXT_TYPE,
    BUTTON_UNSET_TEXT_TYPE,
  ]),
  transparent: PropTypes.bool,
  narrow: PropTypes.bool,
  animate: PropTypes.bool,
  square: PropTypes.bool,
  rounded: PropTypes.bool,
  standardWidth: PropTypes.bool,
  withRipples: PropTypes.bool,
  customClassNames: PropTypes.string,
};

Button.defaultProps = {
  variant: BUTTON_REGULAR_VARIANT,
  textType: BUTTON_UNSET_TEXT_TYPE,
};

export default Button;
