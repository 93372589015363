export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'Septembar',
  'October',
  'November',
  'December',
];

export const dayLetters = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const DATE_YYYY_MONTH_D_FORMAT = 'yyyy, LLLL do';
export const DAY_FORMAT = 'E, LLLL do';
export const MONTH_NAME_FORMAT = 'LLLL';
export const NUMERIC_STANDARD_DATE = 'yyyy-MM-dd';
