import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useLocation } from 'react-router';
import useActiveRole from './hooks/useActiveRole';
import useShortlists from './hooks/useShortlists';

// NOTE last visited url feature for remembering the page on hard refresh is not needed anymore with react router 6

const UserSessionContext = createContext();

const LAST_URL_LOCAL_STORAGE_ITEM = 'last-url';

export const UserSessionContextProvider = ({ children }) => {
  const location = useLocation();
  const { activeRole } = useActiveRole();
  const { updatePlayersShortlist } = useShortlists();
  const [lastVisitedUrl, setLastVisitedUrl] = useState(null);

  const rememberLastURLBeforeReload = useCallback(() => {
    localStorage.setItem(LAST_URL_LOCAL_STORAGE_ITEM, location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const url = localStorage.getItem(LAST_URL_LOCAL_STORAGE_ITEM);
    setLastVisitedUrl(url);
    localStorage.removeItem(LAST_URL_LOCAL_STORAGE_ITEM);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', rememberLastURLBeforeReload, {
      once: true,
      capture: true,
    });

    return window.removeEventListener(
      'beforeunload',
      rememberLastURLBeforeReload
    );
  }, [rememberLastURLBeforeReload]);

  const value = {
    lastVisitedUrl,
    activeRole,
    updatePlayersShortlist,
  };

  return (
    <UserSessionContext.Provider value={value}>
      {children}
    </UserSessionContext.Provider>
  );
};

export const useUserSessionContext = () => useContext(UserSessionContext);
