import { useToggler } from 'common/hooks';
import { useCallback, useState } from 'react';
import {
  ARRANGE_MATCH_MODE_EDIT_FORM,
  ARRANGE_MATCH_MODE_RESPONSE_MESSAGE_MODAL,
  MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL,
  MATCH_TERMS_RESPONSE_MODE_RESPONSE_MESSAGE_MODAL,
  PROPOSE_MATCH_RESULT_MODE_EDIT_FORM,
  PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL,
  PROPOSE_MATCH_RESULT_MODE_RESPONSE_MESSAGE_MODAL,
  RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL,
  RESOLVE_MATCH_RESULT_MODE_RESPONSE_MESSAGE_MODAL,
  ARRANGE_MATCH_MODE_CONFIRM_MODAL,
} from '../constants';

// NOTE some handlers are not used at the moment because Navbar is rendering match based on its own setMatch hook
const useModals = initMatchModal => {
  const {
    opened: customRulesModalOpened,
    close: closeRulesSectionModal,
    open: openCustomRulesModal,
  } = useToggler(false);

  // match modals
  const [matchModal, setMatchModal] = useState(initMatchModal);

  // NEXT-VER not used in the current version
  const clearMatchModal = useCallback(() => {
    setMatchModal(null);
  }, []);

  // arrange match mode modals
  const openArrangeMatchModeEditForm = useCallback(() => {
    setMatchModal(ARRANGE_MATCH_MODE_EDIT_FORM);
  }, []);
  const openArrangeMatchModeConfirmModal = useCallback(() => {
    setMatchModal(ARRANGE_MATCH_MODE_CONFIRM_MODAL);
  }, []);

  // NEXT-VER not used in the current version
  const openArrangeMatchModeResponseMessageModal = useCallback(() => {
    setMatchModal(ARRANGE_MATCH_MODE_RESPONSE_MESSAGE_MODAL);
  }, []);

  // match terms response mode modals
  const openMatchTermsResponseModeConfirmModal = useCallback(() => {
    setMatchModal(MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL);
  }, []);

  // NEXT-VER not used in the current version
  const openMatchTermsResponseModeResponseMessageModal = useCallback(() => {
    setMatchModal(MATCH_TERMS_RESPONSE_MODE_RESPONSE_MESSAGE_MODAL);
  }, []);

  // propose match result mode modals
  const openProposeMatchResultModeEditForm = useCallback(() => {
    setMatchModal(PROPOSE_MATCH_RESULT_MODE_EDIT_FORM);
  }, []);
  const openProposeMatchResultModeConfirmModal = useCallback(() => {
    setMatchModal(PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL);
  }, []);
  // NEXT-VER not used in the current version
  const openProposeMatchResultModeResponseMessageModal = useCallback(() => {
    setMatchModal(PROPOSE_MATCH_RESULT_MODE_RESPONSE_MESSAGE_MODAL);
  }, []);

  // resolve match result mode modals
  // NEXT-VER not used in the current version
  const openResolveMatchResultModeConfirmModal = useCallback(() => {
    setMatchModal(RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL);
  }, []);

  // NEXT-VER not used in the current version
  const openResolveMatchResultModeResponseMessageModal = useCallback(() => {
    setMatchModal(RESOLVE_MATCH_RESULT_MODE_RESPONSE_MESSAGE_MODAL);
  }, []);

  return {
    // custom rules section
    closeRulesSectionModal,
    customRulesModalOpened,
    openCustomRulesModal,

    // match modals
    matchModal,
    clearMatchModal,
    // // arrange match mode modals
    openArrangeMatchModeEditForm,
    openArrangeMatchModeConfirmModal,
    openArrangeMatchModeResponseMessageModal,
    // // match terms response mode modals
    openMatchTermsResponseModeConfirmModal,
    openMatchTermsResponseModeResponseMessageModal,
    // // propose match result mode modals
    openProposeMatchResultModeEditForm,
    openProposeMatchResultModeConfirmModal,
    openProposeMatchResultModeResponseMessageModal,
    // // resolve match result mode modals
    openResolveMatchResultModeConfirmModal,
    openResolveMatchResultModeResponseMessageModal,
  };
};

export default useModals;
