import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import {
  GAME_EVENT_PAGE_PATH,
  MATCH_PAGE_PATH,
  SIGNUP_PATH,
} from './constants';
import MatchPage from 'pages/match-page';
import LandingPage from 'pages/landing-page';
import commonPages from './common';
import GameEventPage from 'pages/game-event-page';

const RegisterPage = lazy(() => import('pages/register-page'));

const RedirectToLandingPage = () => <Navigate to='/' replace />;

const data = [
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: SIGNUP_PATH,
    element: <RegisterPage />,
  },
  {
    path: MATCH_PAGE_PATH,
    element: <MatchPage />,
  },
  {
    path: GAME_EVENT_PAGE_PATH,
    element: <GameEventPage />,
  },
  ...commonPages,
  {
    path: '/',
    element: <RedirectToLandingPage />,
  },
];

export default data;
