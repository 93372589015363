/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

const PlayerMovementsIcon = ({ color }) => (
  <svg
    enable-background='new 0 0 24 24'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='#000000'
  >
    <g>
      <rect fill='none' height='24' width='24' />
    </g>
    <g>
      <g>
        <g>
          <path
            fill={color}
            d='M15,3l2.3,2.3l-2.89,2.87l1.42,1.42L18.7,6.7L21,9V3H15z M3,9l2.3-2.3l2.87,2.89l1.42-1.42L6.7,5.3L9,3H3V9z M9,21 l-2.3-2.3l2.89-2.87l-1.42-1.42L5.3,17.3L3,15v6H9z M21,15l-2.3,2.3l-2.87-2.89l-1.42,1.42l2.89,2.87L15,21h6V15z'
          />
        </g>
      </g>
    </g>
  </svg>
);

PlayerMovementsIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

PlayerMovementsIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default PlayerMovementsIcon;
