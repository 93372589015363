import { FIFTEEN_MINUTES } from 'common/constants/integers';

const { subYears, isFuture, format, isToday, isTomorrow } = require('date-fns');

export const getMinRequiredBirthYear = () => subYears(new Date(), 18);

export const calculateAge = date => {
  try {
    const formattedBirthday = new Date(date);

    const birthMonth = formattedBirthday.getMonth();
    const birthDate = formattedBirthday.getDate();
    const currentYear = new Date().getFullYear();
    const thisYearBirthday = new Date(currentYear, birthMonth, birthDate);

    let age =
      parseInt(new Date().getFullYear()) -
      parseInt(formattedBirthday.getFullYear());
    if (isFuture(thisYearBirthday)) age--;

    return age;
  } catch (err) {
    return 'NA';
  }
};

export const formatAsMonthDayString = date => {
  try {
    const formattedDate = new Date(date);

    const day = formattedDate.getDate();
    const month = formattedDate.getMonth();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 9 ? `0${month + 1}` : month + 1;

    return `${formattedDay} - ${formattedMonth}`;
  } catch (err) {
    return 'N/A';
  }
};

export const getDateMM_DD_YYYY = date => {
  // mm-dd-yyyy or Date
  try {
    let formattedDate;
    if (typeof date === 'object') {
      formattedDate = new Date(date);
    } else if (typeof date === 'string') {
      const [monthParam, dayParam, yearParam] = date.split('-');
      const monthAdjustedParam = monthParam - 1;
      formattedDate = new Date(yearParam, monthAdjustedParam, dayParam);
    }

    const day = formattedDate.getDate();
    const month = formattedDate.getMonth();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 9 ? `0${month + 1}` : month + 1;

    const year = formattedDate.getFullYear();

    return `${formattedMonth}-${formattedDay}-${year}`;
  } catch (err) {
    return 'NA';
  }
};

export const getHoursInDayStrings = () => {
  const hours = [];

  for (let i = 0; i < 24; i++) {
    const hourToAdd = i > 9 ? i.toString() : '0' + i;
    hours.push(hourToAdd);
  }

  return hours;
};

export const getMinutesIntervalsInHour = interval => {
  const minuteIntervals = [];

  for (let i = 0; i < 60; i += interval) {
    const minuteIntervalToAdd = i < 10 ? '0' + i : i.toString();
    minuteIntervals.push(minuteIntervalToAdd);
  }

  return minuteIntervals;
};

// order = ['d', 'm', 'y']
export const rearrangeDateString = (date, currOrder, requiredOrder) => {
  const dateItems = date.split('-');

  const dateObj = {};

  dateItems.forEach((item, i) => {
    dateObj[currOrder[i]] = item;
  });

  const rearrangedDateString = requiredOrder
    .map(item => dateObj[item])
    .join('-');

  return rearrangedDateString;
};

export const formatTimeslotForCalendar = timeslot =>
  `${timeslot.substr(0, 2)}:${timeslot.substr(2, 2)}`;

export const getMatchDate__DD_MM = date =>
  `${date.substr(3, 2)}-${date.substr(0, 2)}`;
// matchDate from match object
/*
matchDate: {
  dayKey: "10-03-2022",
  timeslot: "0100"
}
*/

export const getDateFromMatchDateField = matchDate => {
  const formattedTimeString = `:${matchDate?.timeslot?.substring(
    0,
    2
  )}:${matchDate.timeslot.substring(2)}`;
  const [month, day, year] = matchDate.dayKey.split('-');
  const formattedDateString = `${year}-${month}-${day}`;

  const formattedDateTimeString = `${formattedDateString}${formattedTimeString}`;

  return new Date(formattedDateTimeString);
};

export const canMatchBeResolvedBasedOnMatchTime = matchDate => {
  // NOTE based on settings from API service
  try {
    const dateTreshold = new Date(Date.now() + FIFTEEN_MINUTES);
    const formattedMatchDate = getDateFromMatchDateField(matchDate);

    return new Date(formattedMatchDate) < dateTreshold;
  } catch (err) {
    return false;
  }
};

// hh:mm, Month dd, yyyy
export const getTimeDate_hh_mm_month_day_yyyy = date => {
  try {
    const dateObj = new Date(date);
    const dateFormat = 'h:mmaaa, MMM do, yyyy';
    const formattedDate = format(dateObj, dateFormat);

    return formattedDate;
  } catch (err) {
    return date;
  }
};

// dayKey: mm-dd-yyyy
export const formatDayKeyAsDateInput = dayKey => {
  const arr = dayKey.split('-');
  return `${arr[2]}-${arr[0]}-${arr[1]}`;
};

export const getMatchDateItems = ({ dayKey, timeslot }) => {
  try {
    const dateObj = new Date(
      `${formatDayKeyAsDateInput(dayKey)}T${timeslot.substr(
        0,
        2
      )}:${timeslot.substr(2, 2)}`
    );
    const isMatchToday = isToday(dateObj);
    const isMatchTomorrow = isTomorrow(dateObj);
    const dateFormat = 'd LLL|EEEE|h:mmaaa';
    const dateArray = format(dateObj, dateFormat).split('|');

    const dayInWeek = isMatchToday
      ? 'Today'
      : isMatchTomorrow
      ? 'Tomorrow'
      : dateArray[1];
    return { date: dateArray[0], dayInWeek, time: dateArray[2] };
  } catch (err) {
    return { date: 'N/A', dayInWeek: 'N/A', time: 'N/A' };
  }
};

/*
{
  dateKey || dayKey,
  timeslot
}
*/

export const getGameEventDateItems = matchDate => {
  const formattedMatchDate = {
    dayKey: matchDate?.dayKey || matchDate?.dateKey,
    timeslot: matchDate?.timeslot,
  };

  return getMatchDateItems(formattedMatchDate);
};

export const getLocalTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;
