import PropTypes from 'prop-types';
import style from './GeneralScreen.module.scss';
import EventInputWithOptions, {
  EventInputWithOptionsPropTypes,
} from 'common/components/event-inputs/event-input-with-options';
import ScreenTitle from '../screen-title';
import GeneralPlayerInformationIcon from 'common/components/icons/general-player-information-icon';
import EventInputTextArea from 'common/components/event-inputs/event-input-textarea';

const GeneralScreen = ({
  descriptionProps,
  interestedInOptionProps,
  personalityOptionProps,
  experienceOptionProps,
  footOptionProps,
}) => {
  return (
    <div className={style['wrapper']}>
      <ScreenTitle Icon={GeneralPlayerInformationIcon} title='General' />

      {/* Description */}
      <EventInputTextArea {...descriptionProps} />

      {/* Interests */}
      <EventInputWithOptions {...interestedInOptionProps} />

      {/* Personality */}
      <EventInputWithOptions {...personalityOptionProps} />

      {/* Experience */}
      <EventInputWithOptions {...experienceOptionProps} />

      {/* Foot */}
      <EventInputWithOptions {...footOptionProps} />
    </div>
  );
};

GeneralScreen.propTypes = {
  descriptionProps: PropTypes.shape({
    // TODO -> EventInputTextAreaPropTypes
    inputLabel: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }),
  interestedInOptionProps: PropTypes.shape(EventInputWithOptionsPropTypes),
  footOptionProps: PropTypes.shape(EventInputWithOptionsPropTypes),
  experienceOptionProps: PropTypes.shape(EventInputWithOptionsPropTypes),
  personalityOptionProps: PropTypes.shape(EventInputWithOptionsPropTypes),
};

export default GeneralScreen;
