import React from 'react';
import PropTypes from 'prop-types';

import './ChevronRightIcon.scss';

const className = 'c-ChevronRightIcon';
const leftClassName = `${className}--left`;

const ChevronRightIcon = ({ reversed }) => (
  <svg
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='#000000'
    className={`${className} ${reversed ? leftClassName : ''}`}
  >
    <path d='M0 0h24v24H0z' fill='none' />
    <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
  </svg>
);

ChevronRightIcon.propTypes = {
  reversed: PropTypes.bool,
};

export default ChevronRightIcon;
