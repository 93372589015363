const ChartWithOverlineIcon = () => (
  <svg width='48' height='48' viewBox='0 0 48 48' fill='none'>
    <line
      x1='11.5'
      y1='29.5'
      x2='11.5'
      y2='39.5'
      stroke='#039BE5'
      strokeWidth='5'
      strokeLinecap='round'
    />
    <path
      d='M10.366 18.634L29.4185 7.63403'
      stroke='#039BE5'
      strokeWidth='2'
      strokeLinecap='round'
    />
    <line
      x1='24'
      y1='19.5'
      x2='24'
      y2='39.5'
      stroke='#039BE5'
      strokeWidth='5'
      strokeLinecap='round'
    />
    <line
      x1='36.5'
      y1='8.5'
      x2='36.5'
      y2='39.5'
      stroke='#039BE5'
      strokeWidth='5'
      strokeLinecap='round'
    />
  </svg>
);

export default ChartWithOverlineIcon;
