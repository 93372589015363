import MapIcon from 'common/components/icons/map-icon';
import ShareIcon from 'common/components/icons/share-icon';
import StarIcon from 'common/components/icons/star-icon/StarIcon';
import { getGameEventDateItems, getLocalTimezone } from 'common/utils/dates';
import { goToExternalURL } from 'common/utils/routing';
import AddToCalendarButton from 'features/add-to-calendar-button';
import { getAddToCalendarProps } from 'features/match/utils';
import { useCallback, useMemo, useState } from 'react';
import {
  ADD_TO_CALENDAR_BUTTON_LABEL,
  DELETE_GAME_EVENT_MODAL,
  EDIT_GAME_EVENT_MODAL,
  GAME_EVENT_NAME_CALENDAR_EVENT_NAME,
  GOOGLE_MAPS_BUTTON_LABEL,
  NOT_AVAILABLE_LABEL,
  SHARE_BUTTON_LABEL,
  SHARE_IN_MODAL,
} from '../constants';
import TrashCanIcon from 'common/components/icons/trash-can-icon';
import EditIcon from 'common/components/icons/edit-icon';
import BookmarkIcon from 'common/components/icons/bookmark-icon';
import { useAuthContext } from 'contexts/auth';
import { BUTTON_LIGHT_VARIANT } from 'common/components/button';
import { useGameEventAPI } from 'features/game-event-features/hooks';
import BookmarkFilledIcon from 'common/components/icons/bookmark-filled-icon';
import { isUserSubscribedToGameEvent } from 'features/game-event-features/utils';
import { getGameEventURL } from 'features/share/utils';
import { useNavigation } from 'common/hooks';
import { onClipboardCopy } from 'common/utils/dom';
import { useMetaContext } from 'contexts/meta';

const useProps = ({ matchData, onClose }) => {
  const { user, isGuestView } = useAuthContext();
  const { setNeutralToastMessage } = useMetaContext();
  const { navigateToUserPage } = useNavigation();
  const { deleteGameEvent, toggleGameEventSubscriptions } = useGameEventAPI();

  const [inModalOpened, setInModalOpened] = useState(null);

  const openShareInModal = useCallback(() => {
    setInModalOpened(SHARE_IN_MODAL);
  }, []);

  const openEditGameEventModal = useCallback(() => {
    setInModalOpened(EDIT_GAME_EVENT_MODAL);
  }, []);

  const openDeleteGameEventModal = useCallback(() => {
    setInModalOpened(DELETE_GAME_EVENT_MODAL);
  }, []);

  const closeInModal = useCallback(() => {
    setInModalOpened(null);
  }, []);

  const controlProps = useMemo(() => {
    const formattedProps = [];

    const isUserGameEventCreator = matchData?.createdBy === user?._id;
    if (user && isUserGameEventCreator) {
      const userSubscribed = isUserSubscribedToGameEvent(
        matchData?._id,
        user?.gameEventSubscriptions
      );

      formattedProps.push({
        id: userSubscribed ? 'remove-bookmark-btn' : 'add-bookmark-btn',
        Icon: userSubscribed ? BookmarkFilledIcon : BookmarkIcon,
        iconProps: {
          color: '#cbe6ff',
        },
        handler: () => toggleGameEventSubscriptions(matchData?._id),
      });
    }

    if (isUserGameEventCreator) {
      formattedProps.push({
        id: 'edit-btn',
        Icon: EditIcon,
        iconProps: { color: '#cbe6ff' },
        handler: openEditGameEventModal,
      });

      formattedProps.push({
        id: 'delete-btn',
        Icon: TrashCanIcon,
        iconProps: { color: '#cbe6ff' },
        handler: openDeleteGameEventModal,
      });
    }

    return formattedProps;
  }, [
    matchData?._id,
    matchData?.createdBy,
    openDeleteGameEventModal,
    openEditGameEventModal,
    toggleGameEventSubscriptions,
    user,
  ]);

  const deleteGameEventModalProps = useMemo(() => {
    return {
      modal: {
        onClose: closeInModal,
      },
      headline: {
        mainText: [{ text: 'Are you sure you want to cancel the game?' }],
        size: 3,
      },
      button: {
        onClick: () => {
          deleteGameEvent(matchData?._id, onClose);
        },
        variant: BUTTON_LIGHT_VARIANT,
        label: 'Yes!',
      },
    };
  }, [closeInModal, deleteGameEvent, matchData?._id, onClose]);

  const gameCardProps = useMemo(() => {
    const {
      organizer,
      city,
      location,
      locationURL,
      duration,
      numberOfPlayers,
      tags,
      gameType,
      skillLevel,
      coverImgData,
      matchDate,
    } = matchData;
    const formattedMatchDate = {
      dayKey: matchDate?.dayKey || matchDate?.dateKey,
      timeslot: matchDate?.timeslot,
    };
    const { date, dayInWeek, time } = getGameEventDateItems(matchDate);
    return {
      mainInformation: {
        above: date,
        central: dayInWeek,
        beneath: time,
      },
      subInformation: [
        {
          text: organizer?.firstName || 'no data provided',
          imgURL: organizer?.image,
          isLink: !isGuestView,
          onClick: () => {
            const userId = organizer?._id;
            if (userId && !isGuestView) navigateToUserPage(userId);
          },
        },
        { text: `📍 ${city || NOT_AVAILABLE_LABEL}` },
        { text: location },
      ],
      shareButtons: [
        {
          Icon: ShareIcon,
          label: SHARE_BUTTON_LABEL,
          onClick: openShareInModal,
        },
        {
          Icon: MapIcon,
          label: GOOGLE_MAPS_BUTTON_LABEL,
          onClick: () => {
            goToExternalURL(locationURL, true);
          },
          hidden: !locationURL,
        },
        {
          Component: AddToCalendarButton,
          componentProps: getAddToCalendarProps({
            eventName: GAME_EVENT_NAME_CALENDAR_EVENT_NAME,
            location,
            city,
            matchDate: formattedMatchDate,
            timezone: getLocalTimezone(),
          }),
          label: ADD_TO_CALENDAR_BUTTON_LABEL,
        },
      ],
      otherInformation: [
        organizer?.contact
          ? {
              value: `${organizer?.contact}`,
              onClick: () => {
                setNeutralToastMessage('Copied to clipboard');
                onClipboardCopy(organizer?.contact);
              },
            }
          : null,
        duration ? { value: `${duration} minutes` } : null,
        numberOfPlayers ? { value: `${numberOfPlayers} Aside` } : null,
      ].filter(Boolean),
      tags,
      eventType: gameType,
      eventBadge: { Icon: StarIcon, label: skillLevel },
      isPublic: true,
      coverImgData,
      controlProps,
    };
  }, [
    controlProps,
    isGuestView,
    matchData,
    navigateToUserPage,
    openShareInModal,
    setNeutralToastMessage,
  ]);

  // TODO refactor shareProps formatting to one location if possible
  const shareProps = useMemo(() => {
    const { dayInWeek, time } = getGameEventDateItems({
      dayKey: matchData?.matchDate?.dateKey,
      timeslot: matchData?.matchDate?.timeslot,
    });

    return {
      title: `football game - ${dayInWeek}, ${time} @ ${matchData?.location}`,
      description: `powered by cluster-11`,
      url: getGameEventURL(matchData?._id),
    };
  }, [
    matchData?._id,
    matchData?.location,
    matchData?.matchDate?.dateKey,
    matchData?.matchDate?.timeslot,
  ]);

  return {
    gameCardProps,
    shareProps,
    deleteGameEventModalProps,
    inModalOpened,

    closeInModal,
  };
};

export default useProps;
