import PropTypes from 'prop-types';
import { PRIVATE_PAGE_TYPE } from 'common/constants/general';
import PageWrapper from '../../PageWrapper';
import { ASIDE_VIEW_TYPES } from 'features/aside-view/constants';
import Banner from 'common/components/banner';
import { banners } from '../../constants';

const PrivatePageWrapper = ({
  children,
  classNames,
  fullscreen,
  asideViewProps,
  bannerType,
}) => {
  return (
    <PageWrapper
      type={PRIVATE_PAGE_TYPE}
      classNames={classNames}
      fullscreen={fullscreen}
      asideViewProps={asideViewProps}
    >
      {bannerType !== undefined && <Banner img={banners[bannerType]} />}
      {children}
    </PageWrapper>
  );
};

PrivatePageWrapper.propTypes = {
  asideViewProps: PropTypes.shape({
    isOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    asideViewType: PropTypes.oneOf(ASIDE_VIEW_TYPES),
    asideViewTypeProps: PropTypes.object,
  }),
  classNames: PropTypes.string,
  fullscreen: PropTypes.bool,
  bannerType: PropTypes.number,
};

export default PrivatePageWrapper;
