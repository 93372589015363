import { useState } from 'react';
import { getFormattedDateData } from '../utils';
import dateAdd from 'date-fns/add';
import dateSet from 'date-fns/set';
import Calendar from 'classes/calendar';

const useCurrentDates = initDate => {
  const [selectedDate, setSelectedDate] = useState(
    getFormattedDateData(initDate)
  );
  const [dateInView, setDateInView] = useState(getFormattedDateData(initDate));

  const selectDate = date => {
    setSelectedDate(getFormattedDateData(date));
  };

  const selectDateInView = date => {
    setDateInView(getFormattedDateData(date));
  };

  const switchToPrevMonth = () => {
    const prevMonthDate = dateAdd(dateInView?.dateObj, { months: -1 });
    setDateInView(getFormattedDateData(prevMonthDate));
  };
  const switchToNextMonth = () => {
    const nextMonthDate = dateAdd(dateInView?.dateObj, { months: 1 });
    setDateInView(getFormattedDateData(nextMonthDate));
  };

  const setMonthInView = month => {
    const updatedDate = dateSet(dateInView?.dateObj, { month });
    setDateInView(getFormattedDateData(updatedDate));
  };

  const setYearInView = year => {
    const updatedDate = dateSet(dateInView?.dateObj, { year });
    setDateInView(getFormattedDateData(updatedDate));
  };

  const getYears = (numberOfPastDecades, numberOfFutureDecades) => {
    const calendar = new Calendar();

    const years = calendar.getYearsByDecades(
      dateInView.dateObj,
      numberOfPastDecades,
      numberOfFutureDecades
    );

    return years;
  };

  return {
    selectedDate,
    selectDate,
    dateInView,
    switchToPrevMonth,
    switchToNextMonth,
    selectDateInView,
    setMonthInView,
    setYearInView,
    getYears,
  };
};

export default useCurrentDates;
