import Button from 'common/components/button';
import { goToExternalURL } from 'common/utils/routing';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import EventInputBox from '../base-components/event-input-box';
import style from './EventInputWithAddButton.module.scss';

const EventInputWithAddButton = ({
  inputLabel,
  value,
  buttons,
  hasValue,
  optional,
  error,
}) => {
  const renderValue = useCallback(rowProps => {
    if (!rowProps.length) return null;

    const textToRender = rowProps?.map((item, i) => {
      if (item.link)
        return (
          <Button
            rounded
            narrow
            onClick={() => {
              goToExternalURL(item.link);
            }}
            key={i}
          >
            {item.text}
          </Button>
        );

      const largeTextClassName = item.isLarge ? style['value-text--large'] : '';

      return (
        <p className={`${style['value-text']} ${largeTextClassName}`} key={i}>
          {item.text}
        </p>
      );
    });

    return <div className={style['input-value-row']}>{textToRender}</div>;
  }, []);

  const renderButtons = useCallback(
    () =>
      buttons?.map(({ Icon, label, isDisabled, onClick }, i) => (
        <Button
          onClick={onClick}
          key={i}
          rounded
          narrow
          isDisabled={isDisabled}
        >
          {Icon ? <Icon /> : label}
        </Button>
      )),
    [buttons]
  );

  return (
    <EventInputBox
      inputLabel={inputLabel}
      hasValue={hasValue}
      optional={optional}
      error={error}
    >
      <div className={style.wrapper}>
        {/* Value */}
        <div className={style.inputValueWrapper}>
          {renderValue(value?.firstRow)}
          {renderValue(value?.secondRow)}
        </div>

        {/* Add Button */}
        <div className={style.inputButtonsWrapper}>{renderButtons()}</div>
      </div>
    </EventInputBox>
  );
};

EventInputWithAddButton.defaultProps = {
  value: {
    firstRow: [],
    secondRow: [],
  },
};

const SetInputValueButtonPropTypes = PropTypes.shape({
  Icon: PropTypes.any,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
});

const RowInputValuePropTypes = PropTypes.shape({
  text: PropTypes.string.isRequired,
  isLarge: PropTypes.bool,
  link: PropTypes.string,
});

export const EventInputWithAddButtonPropTypes = {
  inputLabel: PropTypes.string.isRequired,
  value: PropTypes.shape({
    firstRow: PropTypes.arrayOf(RowInputValuePropTypes),
    secondRow: PropTypes.arrayOf(RowInputValuePropTypes),
  }),
  buttons: PropTypes.arrayOf(SetInputValueButtonPropTypes),
  hasValue: PropTypes.bool,
  optional: PropTypes.bool,
  error: PropTypes.bool,
};

EventInputWithAddButton.propTypes = EventInputWithAddButtonPropTypes;

export default EventInputWithAddButton;
