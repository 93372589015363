/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

const LogoutIcon = ({ color }) => (
  <svg
    className='c-LogoutIcon'
    enableBackground='new 0 0 24 24'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='#000000'
  >
    <g>
      <rect fill='none' height='24' width='24' />
      <rect fill='none' height='24' width='24' />
    </g>
    <g>
      <path
        fill={color}
        d='M10.3,7.7L10.3,7.7c-0.39,0.39-0.39,1.01,0,1.4l1.9,1.9H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h9.2l-1.9,1.9 c-0.39,0.39-0.39,1.01,0,1.4l0,0c0.39,0.39,1.01,0.39,1.4,0l3.59-3.59c0.39-0.39,0.39-1.02,0-1.41L11.7,7.7 C11.31,7.31,10.69,7.31,10.3,7.7z M20,19h-7c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h7c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-7 c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h7V19z'
      />
    </g>
  </svg>
);

LogoutIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

LogoutIcon.propTypes = {
  color: PropTypes.string,
};

export default LogoutIcon;
