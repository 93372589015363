import PropTypes from 'prop-types';
import { ICON_DARK_THEME } from 'common/constants/theming';

const DoneIcon = ({ color }) => (
  <svg height='24px' viewBox='0 0 24 24' width='24px' fill='#000000'>
    <path d='M0 0h24v24H0z' fill='none' />
    <path fill={color} d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' />
  </svg>
);

DoneIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

DoneIcon.propTypes = {
  color: PropTypes.string,
};

export default DoneIcon;
