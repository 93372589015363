const baseAssetsPath = '/assets';

export const CLASSIC_LOGO = 'classic';
export const BLUE_STARS_LOGO = 'blue-stars';

export const logoSources = {
  [CLASSIC_LOGO]: {
    alt: 'c-11 logo',
    mobileSrc: [
      // {
      //   srcSet: `${baseAssetsPath}/webp/logo120x111.webp`,
      //   type: 'image/webp',
      //   media: '(min-width: 120px)',
      // },
      {
        srcSet: `${baseAssetsPath}/png/v2-logo.png`,
        type: 'image/png',
        media: '(min-width: 120px)',
      },
    ],
    desktopSrc: [
      // {
      //   srcSet: `${baseAssetsPath}/webp/v2-logo.webp`,
      //   type: 'image/webp',
      //   media: '(min-width: 400px)',
      // },
      {
        srcSet: `${baseAssetsPath}/png/v2-logo.png`,
        type: 'image/png',
        media: '(min-width: 400px)',
      },
    ],
  },
  [BLUE_STARS_LOGO]: {
    alt: 'c-11 blue stars logo',
    mobileSrc: [
      // {
      //   srcSet: `${baseAssetsPath}/webp/logo120x111.webp`,
      //   type: 'image/webp',
      //   media: '(min-width: 120px)',
      // },
      {
        srcSet: `${baseAssetsPath}/png/logo-v2-blue-stars.png`,
        type: 'image/png',
        media: '(min-width: 120px)',
      },
    ],
    desktopSrc: [
      // {
      //   srcSet: `${baseAssetsPath}/webp/logo-v2-blue-stars.webp`,
      //   type: 'image/webp',
      //   media: '(min-width: 400px)',
      // },
      {
        srcSet: `${baseAssetsPath}/png/logo-v2-blue-stars.png`,
        type: 'image/png',
        media: '(min-width: 400px)',
      },
    ],
  },
};
