/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { Link } from 'react-router-dom';
import { useAuthContext } from 'contexts/auth';
import { useCallback } from 'react';
import { useNavigation } from 'common/hooks';
import { BEGINNER_SKILL_LEVEL } from 'features/game-event-features/create-edit-game-event/constants';

const HeaderUserMenu = () => {
  const { user, logout } = useAuthContext();
  const { navigateToUserPage } = useNavigation();
  const { navigateToMyGames } = useNavigation();

  const goToProfilePage = useCallback(() => {
    navigateToUserPage(user?._id);
  }, [navigateToUserPage, user?._id]);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={user?.image?.url} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {user?.firstName} {user?.lastName}
              <span className='badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2'>
                {user?.calculatedExperienceLevel ||
                  user?.playerGeneralInfo?.experienceLevel ||
                  BEGINNER_SKILL_LEVEL}
              </span>
            </div>
            <a
              href='#'
              className='fw-semibold text-muted text-hover-primary fs-7'
            >
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5' onClick={goToProfilePage}>
        <div className='menu-link px-5'>My Profile</div>
      </div>

      <div className='menu-item px-5' onClick={navigateToMyGames}>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Games</span>
          <span className='menu-badge'>
            {/* <span className='badge badge-light-danger badge-circle fw-bold fs-7'>
              3 // NOTE comeback to this with active games label
            </span> */}
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>
                  Notifications
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5 my-1'>
        <Link to='/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
