/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useNavigation } from 'common/hooks';
import { VALIDATION_FAILED } from 'common/hooks/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CITY_FORM_KEY,
  COUNTRY_FORM_KEY,
  DURATION_FORM_KEY,
  LOCATION_FORM_KEY,
  NUM_OF_PLAYERS_FORM_KEY,
  WITH_GK_FORM_KEY,
  PROPOSE_MATCH_RESULT_MODE_EDIT_FORM,
  RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL,
  PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL,
} from '../constants';
import { validateExistenceOfDatetimeSlot } from '../utils';
import useCustomRulesProps from './useCustomRulesProps';
import useDurationProps from './useDurationProps';
import useLocationProps from './useLocationProps';
import useOutcomeProps from './useOutcomeProps';
import usePlayerRulesProps from './usePlayerRulesProps';
import useTimedateProps from './useTimedateProps';

const useProps = (homeTeamName, awayTeamName, initMatchData = {}, onClose) => {
  const { navigateToClubPage } = useNavigation();

  const [matchData, setMatchData] = useState(
    Object.assign(
      {
        locationFormData: {},
        timedateData: {},
        playerRulesData: {},
        durationData: {},
        customRulesData: [],
        gameResultData: {},
      },
      initMatchData
    )
  );

  const {
    onSave: onSaveLocationProps,
    locationFormData,
    ...locationProps
  } = useLocationProps({
    [CITY_FORM_KEY]: {
      value: matchData?.locationFormData?.[CITY_FORM_KEY] || '',
    },
    [LOCATION_FORM_KEY]: {
      value: matchData?.locationFormData?.[LOCATION_FORM_KEY] || '',
    },
    [COUNTRY_FORM_KEY]: {
      value: matchData?.locationFormData?.[COUNTRY_FORM_KEY] || '',
    },
  });
  const {
    timedateData,
    selectedTimeslot,
    setSelectedTimeslot,
    ...timedateProps
  } = useTimedateProps();
  const {
    onSave: onSavePlayerRulesProps,
    playerRulesData,
    ...playerRulesProps
  } = usePlayerRulesProps({
    [NUM_OF_PLAYERS_FORM_KEY]: {
      value: matchData?.playerRulesData?.[NUM_OF_PLAYERS_FORM_KEY] || '',
    },
    [WITH_GK_FORM_KEY]: {
      value: matchData?.playerRulesData?.[WITH_GK_FORM_KEY] || '',
    },
  });
  const {
    onSave: onSaveDurationProps,
    durationData,
    ...durationSectionProps
  } = useDurationProps({
    [DURATION_FORM_KEY]: {
      value: matchData?.durationData?.[DURATION_FORM_KEY] || '',
    },
  });
  const { customRulesData, ...customRulesProps } = useCustomRulesProps();
  const { gameResultData, ...outcomeProps } = useOutcomeProps(
    homeTeamName,
    awayTeamName,
    {
      homeGoals: initMatchData?.gameResultData?.homeGoals,
      awayGoals: initMatchData?.gameResultData?.awayGoals,
      outcome: initMatchData?.gameResultData?.outcome,
    }
  );

  const isSubmitMatchTermsEnabled = useMemo(() => {
    const timeSlotProvided = validateExistenceOfDatetimeSlot(
      matchData?.timedateData
    );

    return timeSlotProvided;
  }, [matchData?.timedateData]);

  //  UNIVERSAL HANDLERS -------------------------------------------------
  const submitCreateMatch = useCallback(async () => {
    const locationValidationStatus = await onSaveLocationProps();
    const playerRulesStatus = await onSavePlayerRulesProps();
    const durationStatus = await onSaveDurationProps();

    return locationValidationStatus === VALIDATION_FAILED;
  }, [onSaveDurationProps, onSaveLocationProps, onSavePlayerRulesProps]);

  // ----------------------------------------------------------------------

  const getOutcomeSectionProps = useCallback(
    matchModal => {
      if (
        [
          PROPOSE_MATCH_RESULT_MODE_EDIT_FORM,
          PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL,
          RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL,
        ].includes(matchModal)
      ) {
        return {
          ...outcomeProps,
        };
      } else {
        return null;
      }
    },
    [outcomeProps]
  );

  // match data updates
  useEffect(() => {
    const updatedData = Object.assign(matchData, { timedateData });

    setMatchData(updatedData);
  }, [timedateData]);

  useEffect(() => {
    const updatedData = Object.assign(matchData, { locationFormData });

    setMatchData(updatedData);
  }, [JSON.stringify(locationFormData)]);

  useEffect(() => {
    const updatedData = Object.assign(matchData, { playerRulesData });

    setMatchData(updatedData);
  }, [JSON.stringify(playerRulesData)]);

  useEffect(() => {
    const updatedData = Object.assign(matchData, { durationData });

    setMatchData(updatedData);
  }, [JSON.stringify(durationData)]);

  useEffect(() => {
    const updatedData = Object.assign(matchData, { customRulesData });

    setMatchData(updatedData);
  }, [JSON.stringify(customRulesData)]);

  useEffect(() => {
    const updatedData = Object.assign(matchData, { gameResultData });

    setMatchData(updatedData);
  }, [JSON.stringify(gameResultData)]);

  // section props
  const locationSectionProps = useMemo(
    () => ({
      ...locationProps,
    }),
    [locationProps]
  );

  const timedateSectionProps = useMemo(
    () => ({
      selectedTimeslot,
      setSelectedTimeslot,
      ...timedateProps,
    }),
    [selectedTimeslot, setSelectedTimeslot, timedateProps]
  );

  const playersRulesSectionProps = useMemo(
    () => ({
      ...playerRulesProps,
    }),
    [playerRulesProps]
  );

  const formattedDurationSectionProps = useMemo(
    () => ({
      ...durationSectionProps,
    }),
    [durationSectionProps]
  );

  const formattedCustomRulesProps = useMemo(
    () => ({
      ...customRulesProps,
      customRulesData,
    }),
    [customRulesData, customRulesProps]
  );

  const getArrangeMatchModalContentProps = useCallback(
    ({
      openCustomRulesModal,
      isMobileView,
      customRulesModalOpened,
      closeRulesSectionModal,
      matchModal,
    }) =>
      [
        locationSectionProps,
        timedateSectionProps,
        playersRulesSectionProps,
        formattedDurationSectionProps,
        {
          ...formattedCustomRulesProps,
          onEdit: openCustomRulesModal,
          isMobileView,
          isEditMode: customRulesModalOpened,
          onClose: closeRulesSectionModal,
        },
        getOutcomeSectionProps(matchModal),
      ].filter(Boolean),
    [
      formattedCustomRulesProps,
      formattedDurationSectionProps,
      getOutcomeSectionProps,
      locationSectionProps,
      playersRulesSectionProps,
      timedateSectionProps,
    ]
  );

  const getHeadlineProps = useCallback(
    (homeTeam, awayTeam) => ({
      homeTeam: {
        name: `${homeTeam?.name}, ${homeTeam?.city}`,
        id: homeTeam?._id,
        logo: homeTeam?.logo,
        imageURL: homeTeam?.imageURL,
        onClick: () => {
          onClose();
          navigateToClubPage(homeTeam?._id);
        },
      },
      awayTeam: {
        name: `${awayTeam?.name}, ${awayTeam?.city}`,
        id: awayTeam?._id,
        logo: awayTeam?.logo,
        imageURL: awayTeam?.imageURL,
        onClick: () => {
          onClose();
          navigateToClubPage(awayTeam?._id);
        },
      },
    }),
    [navigateToClubPage]
  );

  return {
    // global props
    isSubmitMatchTermsEnabled,
    submitCreateMatch,
    matchData,

    // timedate section
    timedateSectionProps,

    // outcome section
    getOutcomeSectionProps,

    getArrangeMatchModalContentProps,

    // modal headline props
    getHeadlineProps,
  };
};

export default useProps;
