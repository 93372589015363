import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import './AddToCalendarButton.scss';

const AddToCalendarButton = ({
  name,
  description,
  location,
  timezone,
  startDate,
  endDate,
  startTime,
  endTime,
}) => {
  useEffect(atcb_init, []);

  return (
    <div className='atcb'>
      {'{'}
      "name":"{name}", "description":"{description}", "startDate":"
      {startDate}", "endDate":"{endDate}", "startTime":"{startTime}",
      "endTime":"{endTime}", "location":"{location}", "label":"Add to Calendar",
      "options":[ "Apple", "Google", "iCal", "Microsoft365", "Outlook.com",
      "Yahoo" ], "timeZone":"{timezone}", "iCalFileName":"Reminder-Event",
      "trigger": "click", "lightMode": "dark"
      {'}'}
    </div>
  );
};

AddToCalendarButton.defaultProps = {
  name: 'cluster-11 event',
  location: 'N/A',
  timezone: 'currentBrowser',
  startDate: '2022-08-30', // TEMP
  endDate: '2022-08-31', // TEMP
  startTime: '11:11', // TEMP
  endTime: '11:12', // TEMP
};

AddToCalendarButton.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.string,
  timezone: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

export default AddToCalendarButton;
