import { useCallback } from 'react';
import PropTypes from 'prop-types';
import './EventCard.scss';
import Image from '../image';
import { formatImage, formatImageWithWebp } from 'common/utils/formatting';
import Button from '../button';
import { STREET_PATH } from 'routes/constants';
import LogoIconLink from 'common/components/LogoIconLink';
import Modal from 'features/modal';
import ControlButtons, { ControlButtonsPropTypes } from '../control-buttons';

const className = 'c-EventCard';

// TODO move to S3 or take prop for it
const s3Assets = 'https://c-11-app-assets.s3.me-central-1.amazonaws.com';
const bg = `${s3Assets}/webp/empty-football-field-inf-section.webp`;

const EventCard = ({
  mainInformation,
  eventType,
  eventBadge,
  subInformation,
  shareButtons,
  otherInformation,
  tags,
  isPublic,
  coverImgData,
  onClose,
  controlProps,
}) => {
  const renderBanner = useCallback(() => {
    const { Icon } = eventBadge;
    const formattedImage = coverImgData
      ? formatImageWithWebp(coverImgData)
      : formatImage(bg);

    return (
      <div className={`${className}__banner`}>
        <div className={`${className}__banner__bg`}>
          <div className={`${className}__banner__bg__overlay`}></div>
          <Image {...formattedImage} />
        </div>
        <div className={`${className}__badges`}>
          {eventType && (
            <p className={`${className}__badges__type`}>{eventType}</p>
          )}
          <div className={`${className}__badges__item`}>
            {Icon && <Icon />}
            <p>
              <span>skill level: </span>
              {eventBadge?.label || 'n/a'}
            </p>
          </div>
        </div>
        <div className={`${className}__banner__information`}>
          <p>{mainInformation.above}</p>
          <h3>{mainInformation.central}</h3>
          <p>{mainInformation.beneath}</p>
        </div>
      </div>
    );
  }, [
    eventBadge,
    coverImgData,
    eventType,
    mainInformation.above,
    mainInformation.central,
    mainInformation.beneath,
  ]);

  const renderSubInformation = useCallback(() => {
    const itemsToRender = subInformation?.map(
      ({ text, imgURL, onClick, isLink }, i) => {
        const linkClassName = isLink
          ? `${className}__subInformation__item--link`
          : '';

        return (
          <div
            className={`${className}__subInformation__item ${linkClassName}`}
            onClick={onClick}
            key={i}
          >
            {imgURL && (
              <div className={`${className}__subInformation__item__img`}>
                <Image {...formatImage(imgURL)} />
              </div>
            )}
            <p>{text}</p>
          </div>
        );
      }
    );

    return <div className={`${className}__category`}>{itemsToRender}</div>;
  }, [subInformation]);

  const renderButtons = useCallback(() => {
    const itemsToRender = shareButtons?.map(
      ({ label, Icon, Component, componentProps, onClick, hidden }, i) => {
        if (hidden) return null;
        if (Component) {
          return (
            <div key={i} className={`${className}__share-buttons__item`}>
              <Component {...componentProps} />
            </div>
          );
        } else {
          return (
            <div key={i} className={`${className}__share-buttons__item`}>
              <Button onClick={onClick} narrow rounded>
                {Icon && <Icon />}
                {label}
              </Button>
            </div>
          );
        }
      }
    );

    return (
      <div className={`${className}__category ${className}__share-buttons`}>
        {itemsToRender}
      </div>
    );
  }, [shareButtons]);

  const renderOtherInformation = useCallback(() => {
    const itemsToRender = otherInformation?.map((item, i) => {
      const onClickClassName = item?.onClick
        ? `${className}__otherInformation__item--clickable`
        : '';

      return (
        <p
          key={i}
          className={`${className}__otherInformation__item ${onClickClassName}`}
          onClick={item?.onClick}
        >
          {item.value}
        </p>
      );
    });

    return (
      <div className={`${className}__otherInformation`}>{itemsToRender}</div>
    );
  }, [otherInformation]);

  const renderTags = useCallback(() => {
    const tagsToRender = tags?.map((item, i) => (
      <p className={`${className}__tags__item`} key={i}>
        {item}
      </p>
    ));

    return <div className={`${className}__tags`}>{tagsToRender}</div>;
  }, [tags]);

  const renderCTA = useCallback(
    () => (
      <div className={`${className}__cta`}>
        <p>powered by</p>
        <LogoIconLink to={STREET_PATH} />
      </div>
    ),
    []
  );

  return (
    <Modal
      fullScreen
      withoutWrapper
      onClose={onClose}
      customClassName={`${className}__modal`}
      highlightCloseButton
    >
      <div className={className}>
        <ControlButtons items={controlProps} />

        {renderBanner()}

        {renderSubInformation()}

        {renderButtons()}

        {renderOtherInformation()}

        {renderTags()}

        {isPublic && renderCTA()}
      </div>
    </Modal>
  );
};

EventCard.defaultProps = {
  subInformation: [],
  shareButtons: [],
  otherInformation: [],
  tags: [],
  eventType: 'Not provided',
  controlProps: [],
};

const EventCardShareButtonPropTypes = PropTypes.shape({
  Icon: PropTypes.any,
  Component: PropTypes.any,
  componentProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
});

EventCard.propTypes = {
  mainInformation: PropTypes.shape({
    above: PropTypes.string,
    central: PropTypes.string.isRequired,
    beneath: PropTypes.string,
  }),
  subInformation: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      img: PropTypes.string,
      Icon: PropTypes.any,
    })
  ),
  shareButtons: PropTypes.arrayOf(EventCardShareButtonPropTypes),
  otherInformation: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      onClick: PropTypes.bool,
    })
  ),
  tags: PropTypes.arrayOf(PropTypes.string),
  eventType: PropTypes.string,
  eventBadge: PropTypes.shape({
    Icon: PropTypes.any,
    label: PropTypes.string,
  }),
  isPublic: PropTypes.bool,
  coverImgData: PropTypes.shape({
    jpgURL: PropTypes.string.isRequired,
    jpgDesktopURL: PropTypes.string.isRequired,
    webpURL: PropTypes.string.isRequired,
    webpDesktopURL: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
  controlProps: PropTypes.arrayOf(PropTypes.shape(ControlButtonsPropTypes)),
};

export default EventCard;
