const TermsAndConditions = () => (
  <span>
    <a
      href='https://app.websitepolicies.com/policies/view/yt407ati'
      target='_blank'
      rel='noreferrer'
    >
      Terms & Conditions
    </a>
    <script
      src='https://cdn.websitepolicies.io/lib/embed/embed.min.js'
      defer
    ></script>
  </span>
);

export default TermsAndConditions;
