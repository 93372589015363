import PropTypes from 'prop-types';
import './NotificationsBadge.scss';

const className = 'c-NotificationsBadge';

const NotificationsBadge = ({ numberOfUnread }) => {
  return numberOfUnread > 0 || numberOfUnread === 'new' ? (
    <div className={className}>
      <span>{numberOfUnread}</span>
    </div>
  ) : null;
};

NotificationsBadge.defaultProps = {
  numberOfUnread: 0,
};

NotificationsBadge.propTypes = {
  numberOfUnread: PropTypes.number,
};

export default NotificationsBadge;
