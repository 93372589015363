import PropTypes from 'prop-types';

const TransferOfferIcon = ({ color }) => (
  <svg
    enableBackground='new 0 0 24 24'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='#fafafacc'
  >
    <g>
      <rect fill='none' height='24' width='24' />
    </g>
    <g>
      <g>
        <path
          stroke={color}
          d='M17,18H7V6h7V1H7C5.9,1,5,1.9,5,3v18c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2v-5h-2V18z'
        />
        <polygon
          stroke={color}
          points='18,14 23,9 21.59,7.59 19,10.17 19,3 17,3 17,10.17 14.41,7.59 13,9'
        />
      </g>
    </g>
  </svg>
);

TransferOfferIcon.defaultProps = { color: '#111314' };
TransferOfferIcon.propTypes = { color: PropTypes.string };

export default TransferOfferIcon;
