import PropTypes from 'prop-types';

import './ArrowDropdownIcon.scss';

const className = 'c-ArrowDropdownIcon';
const toUpClassName = `${className}--up`;

const ArrowDropdownIcon = ({ toUp, fill }) => (
  <svg
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='#000000'
    className={`${className} ${toUp ? toUpClassName : ''}`}
  >
    <path d='M0 0h24v24H0z' fill='none' />
    <path d='M7 10l5 5 5-5z' fill={fill} />
  </svg>
);

ArrowDropdownIcon.defaultProps = {
  fill: 'none',
};

ArrowDropdownIcon.propTypes = {
  toUp: PropTypes.bool,
};

export default ArrowDropdownIcon;
