import {
  MIN_VALID_LENGTH,
  MIN_VALID_LENGTH_ERR_MSG,
  MISSING_CLUB_NAME_ERR_MSG,
  MAX_VALID_CLUB_NAME_LENGTH_ERR_MSG,
  MAX_VALID_CLUB_NAME_LENGTH,
} from 'common/constants/validations';
const yup = require('yup');

export const createClubSchema = yup.object().shape({
  name: yup
    .string()
    .min(MIN_VALID_LENGTH, MIN_VALID_LENGTH_ERR_MSG)
    .max(MAX_VALID_CLUB_NAME_LENGTH, MAX_VALID_CLUB_NAME_LENGTH_ERR_MSG)
    .required(MISSING_CLUB_NAME_ERR_MSG),
});
