import React, { Children } from 'react';
import PropTypes from 'prop-types';

import './Text.scss';
import ReactCountryFlag from 'react-country-flag';

const className = 'c-Text';

const TEXT_COLORS = [
  'primary',
  'primary-transparent',
  'primary-pale',
  'dark',
  'light',
];

const Text = ({ data, color }) => {
  const renderText = () =>
    Children.toArray(
      data.map(({ text, flag, isBolder, isSmaller, isNewLine, ...props }) => {
        const bolderClassName = isBolder ? `${className}__bolder` : '';
        const smallFontClassName = isSmaller ? `${className}__small-font` : '';
        const isNewLineClassName = isNewLine ? `${className}__new-line` : '';

        return (
          <span
            {...props}
            className={`${className}__color--${color} ${bolderClassName} ${smallFontClassName} ${isNewLineClassName}`}
          >
            {text}
            {flag && <ReactCountryFlag countryCode={flag} svg />}
          </span>
        );
      })
    );

  return (
    <div className={className}>
      <p>{renderText()}</p>
    </div>
  );
};

export const TextItemDataType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  flag: PropTypes.string,
  isBolder: PropTypes.bool,
  isSmaller: PropTypes.bool,
  isNewLine: PropTypes.bool,
});

export const TextPropTypes = {
  data: PropTypes.arrayOf(TextItemDataType),
  color: PropTypes.oneOf(TEXT_COLORS),
};

Text.propTypes = TextPropTypes;

export default Text;
