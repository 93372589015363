import {
  getDescriptiveDateWithoutYear,
  getMonthName,
  getNumericDateString,
} from 'common/utils/formatting';
import CalendarClass from 'classes/calendar';

export const getFormattedDateData = date => {
  const calendar = new CalendarClass();
  const dateObj = new Date(date);

  const dayDescriptive = getDescriptiveDateWithoutYear(dateObj);
  const monthNumber = dateObj.getMonth();
  const month = getMonthName(dateObj);
  const year = dateObj.getFullYear();
  const weeks = calendar.getWeeks(dateObj);
  const numericDateString = getNumericDateString(dateObj);

  return {
    dateObj,
    dayDescriptive,
    month,
    monthNumber,
    year,
    weeks,
    numericDateString,
  };
};
