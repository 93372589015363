import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMeteor } from '@fortawesome/free-solid-svg-icons';

import './MeteorIcon.scss';

const className = 'c-MeteorIcon';

const MeteorIcon = ({ size }) => (
  <FontAwesomeIcon icon={faMeteor} size={size} className={className} />
);

MeteorIcon.propTypes = {
  size: PropTypes.string,
};

MeteorIcon.defaultProps = {
  size: '2x',
};

export default MeteorIcon;
