import React from 'react';
import PropTypes from 'prop-types';
import Input from '../input';
import Calendar from 'features/calendar';

import './CalendarInput.scss';

const className = 'c-CalendarInput';

const CalendarInput = ({
  name,
  label,
  value,
  valueToRender,
  onOpen,
  isOpen,
  onClose,
  onConfirm,
}) => (
  <div className={className}>
    <div onClick={onOpen} className={`${className}__input-wrapper`}>
      <Input
        label={label}
        name={name}
        value={valueToRender}
        onClick={onOpen}
        type='text'
      />
    </div>
    <Calendar
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      date={value}
    />
  </div>
);

export const CalendarInputPropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valueToRender: PropTypes.string,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

CalendarInput.propTypes = CalendarInputPropTypes;

export default CalendarInput;
