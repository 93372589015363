import { getDateMM_DD_YYYY } from 'common/utils/dates';
import { useCallback, useReducer, useState } from 'react';
import TimedateSection from '../components/timedate-section';
import {
  ADD_DAY,
  ADD_TIME,
  DATE_SELECTOR_MODAL,
  REMOVE_DAY,
  REMOVE_TIME,
  TIME_SELECTOR_MODAL,
} from '../constants';

const useTimedateProps = () => {
  const [timedateSelectorModal, setTimedateSelectorModal] = useState(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);

  const openTimeSelectorModal = useCallback(() => {
    setTimedateSelectorModal(TIME_SELECTOR_MODAL);
  }, []);
  const openDateSelectorModal = useCallback(() => {
    setTimedateSelectorModal(DATE_SELECTOR_MODAL);
  }, []);

  const closeSelectorModal = useCallback(() => {
    setTimedateSelectorModal(null);
  }, []);

  const getDateKey = date => getDateMM_DD_YYYY(date);
  const [timedateItems, setTimedateItems] = useReducer((state, action) => {
    let updatedState;
    let updatedDay;
    let updatedTimeslots;

    const dateKey = getDateKey(action.payload.date);
    switch (action.type) {
      case ADD_DAY:
        if (state[dateKey]) {
          return state;
        } else {
          const updatedState = Object.assign({}, state, {
            [dateKey]: { date: action.payload.date, timeslots: [] },
          });

          return updatedState;
        }

      case REMOVE_DAY:
        updatedState = Object.assign({}, state);
        delete updatedState[dateKey];

        return updatedState;

      case ADD_TIME:
        updatedTimeslots = new Set(state[dateKey]?.timeslots || []);
        updatedTimeslots.add(action.payload.timeslot);

        updatedDay = Object.assign({}, state[dateKey], {
          timeslots: [...updatedTimeslots],
        });

        updatedState = Object.assign({}, state, {
          [dateKey]: updatedDay,
        });
        return updatedState;

      case REMOVE_TIME:
        updatedTimeslots = new Set(state[dateKey]?.timeslots || []);
        updatedTimeslots.delete(action.payload.timeslot);

        updatedDay = Object.assign({}, state[dateKey], {
          timeslots: [...updatedTimeslots],
        });

        updatedState = Object.assign({}, state, {
          [dateKey]: updatedDay,
        });
        return updatedState;

      default:
        return state;
    }
  }, []);

  const [activeDay, setActiveDay] = useState(null);

  // actionCreators
  const addDay = useCallback(date => {
    setTimedateItems({ type: ADD_DAY, payload: { date: date?.dateObj } });
  }, []);
  const removeDay = useCallback(() => {
    setTimedateItems({ type: REMOVE_DAY, payload: { date: activeDay } });
    setActiveDay(null);
  }, [activeDay]);
  const addTime = useCallback(
    (hours, minutes) => {
      // hours '00' minutes '20'
      const timeslot = hours + minutes;

      setTimedateItems({
        type: ADD_TIME,
        payload: {
          timeslot,
          date: activeDay,
        },
      });
    },
    [activeDay]
  );
  const removeTime = useCallback(
    timeslot => {
      setTimedateItems({
        type: REMOVE_TIME,
        payload: { timeslot, date: activeDay },
      });
    },
    [activeDay]
  );

  // item (days & timeslots) formatters
  const getDayItems = useCallback(() => {
    const dayKeys = Object.keys(timedateItems) || [];

    const formattedDays = dayKeys?.map(key => {
      const dateObject = timedateItems[key];

      return {
        date: dateObject?.date,
        onClick: () => setActiveDay(key),
        isSelected: activeDay === key,
      };
    });

    return formattedDays;
  }, [activeDay, timedateItems]);

  const getTimeslotItemsForSelectedDay = useCallback(() => {
    const timeslotItems = timedateItems?.[activeDay]?.timeslots || [];
    const formattedTimeslotItems = timeslotItems?.map(item => ({
      value: item,
      onClick: () => removeTime(item),
    }));

    return formattedTimeslotItems;
  }, [activeDay, removeTime, timedateItems]);

  return {
    Component: TimedateSection,
    dayItems: getDayItems(),
    timeslotItems: getTimeslotItemsForSelectedDay(),
    controlButtons: [
      {
        label: 'add day',
        onClick: openDateSelectorModal,
        isDisabled: false,
      },
      {
        label: 'remove day',
        onClick: removeDay,
        isDisabled: false,
      },

      {
        label: 'add time slot',
        onClick: openTimeSelectorModal,
        isDisabled: !activeDay,
      },
    ],

    addDay,
    addTime,

    timeSelectorOpened: timedateSelectorModal === TIME_SELECTOR_MODAL,
    dateSelectorOpened: timedateSelectorModal === DATE_SELECTOR_MODAL,
    closeSelectorModal,
    timedateData: timedateItems,
    selectedTimeslot,
    setSelectedTimeslot,
  };
};

export default useTimedateProps;
