import {
  COMPONENT_VARIANT_MEDIUM,
  CONFIRM_ICON,
  MORE_ICON,
  REJECT_ICON,
} from 'common/components/cards/notification-offer-card/constants';
import { DEFAULT_USER_IMAGE } from 'common/constants/common-props';
import { useNavigation } from 'common/hooks';
import { useUserSessionContext } from 'contexts/user-session';
import { ITEMS_LIST_ASIDE_TYPE } from 'features/aside-view/constants';
import {
  TRANSFER_OFFER_ACCEPTED_STATUS,
  TRANSFER_OFFER_REJECTED_STATUS,
} from 'features/transfer-button/constants';
import { useTransfers } from 'features/transfer-button/hooks';
import { useMemo, useCallback, useState, useEffect } from 'react';
import {
  MATCH_OFFER_TYPE,
  MATCH_RESOLVE_TYPE,
  MATCH_RESULT_PROPOSE_TYPE,
  TRANSFER_OFFER_FROM_CLUB_TYPE,
  TRANSFER_OFFER_FROM_PLAYER_TYPE,
} from 'common/constants/offers';
import { getMatchName } from './utils';
import { useAuthContext } from 'contexts/auth';
import useGMItems from './useGMItems';
import { usePrivateNavContext } from 'contexts/private-nav';
import { formatImage } from 'common/utils/formatting';
import { liveOfferEvents } from 'contexts/user-session/hooks/constants';
import { useEventsContext } from 'contexts/events';
import { getTimeDate_hh_mm_month_day_yyyy } from 'common/utils/dates';
import { REACT_GA_TOGGLE_OFFERS_ASIDE_EVENT } from 'common/constants/react-google-analytics/events';
import ReactGA from 'react-ga';

const useProps = () => {
  const { user, logout } = useAuthContext();

  // offers aside
  const { offersAsideOpened, closeOffersAside, toggleOffersAside } =
    usePrivateNavContext();
  const offersAsideToggleHandler = useCallback(
    e => {
      ReactGA.event(REACT_GA_TOGGLE_OFFERS_ASIDE_EVENT);
      toggleOffersAside(e);
    },
    [toggleOffersAside]
  );

  const { activeRole } = useUserSessionContext();
  const { navigateToClubPage, navigateToUserPage } = useNavigation();
  const { respondToTransferOffer } = useTransfers();
  const { gmItems } = useGMItems(user?.gmClubs);
  const { subscribe } = useEventsContext();

  const [selectedMatch, setSelectedMatch] = useState(null);
  const closeMatchModal = useCallback(() => {
    setSelectedMatch(null);
  }, []);

  // TODO if data for player missing use activeRole or auth
  const getOfferItemsProps = useCallback(
    offers => {
      const formattedProps = offers?.map(item => {
        let img;
        let onImgClick;
        let message;
        let controlButtons;

        const cardType = item?.type;

        const subText = [
          { text: getTimeDate_hh_mm_month_day_yyyy(item?.updatedAt) },
        ];

        if (cardType === TRANSFER_OFFER_FROM_CLUB_TYPE) {
          img = formatImage(item?.club?.imageURL);
          onImgClick = () => navigateToClubPage(item?.club?._id);
          message = [
            {
              text: `${item?.club?.name}, ${item?.club?.city}`,
              isBolder: true,
            },
            { text: ' made a transfer offer.' },
          ];

          controlButtons = [
            {
              iconType: CONFIRM_ICON,
              onClick: () => {
                respondToTransferOffer(item?._id, {
                  responseToOffer: TRANSFER_OFFER_ACCEPTED_STATUS,
                });
              },
            },
            {
              iconType: REJECT_ICON,
              onClick: () => {
                respondToTransferOffer(item?._id, {
                  responseToOffer: TRANSFER_OFFER_REJECTED_STATUS,
                });
              },
            },
          ];
        } else if (cardType === TRANSFER_OFFER_FROM_PLAYER_TYPE) {
          img = item?.player?.image?.url
            ? formatImage(item?.player?.image?.url)
            : DEFAULT_USER_IMAGE;
          onImgClick = () => navigateToUserPage(item?.player?._id);
          message = [
            {
              text: `${item?.player?.firstName} ${item?.player?.lastName}`,
              isBolder: true,
            },
            { text: ' asked to join your club.' },
          ];
          controlButtons = [
            {
              iconType: CONFIRM_ICON,
              onClick: () => {
                respondToTransferOffer(item?._id, {
                  responseToOffer: TRANSFER_OFFER_ACCEPTED_STATUS,
                });
              },
            },
            {
              iconType: REJECT_ICON,
              onClick: () => {
                respondToTransferOffer(item?._id, {
                  responseToOffer: TRANSFER_OFFER_REJECTED_STATUS,
                });
              },
            },
          ];
        } else if (cardType === MATCH_OFFER_TYPE) {
          img = formatImage(item?.homeTeam?.imageURL);
          onImgClick = () => navigateToClubPage(item?.createdBy);
          message = [
            {
              text: `${item?.homeTeam?.name}, ${item?.homeTeam?.city}`,
              isBolder: true,
            },
            { text: ' made a match offer.' },
          ];
          controlButtons = [
            {
              iconType: MORE_ICON,
              onClick: () => {
                setSelectedMatch(item);
              },
            },
          ];
        } else if (cardType === MATCH_RESULT_PROPOSE_TYPE) {
          img = formatImage(item?.awayTeam?.imageURL);
          onImgClick = () => navigateToClubPage(item?.updatedBy);
          message = [
            { text: `Match ` },
            {
              text: getMatchName(item),
              isBolder: true,
            },
            { text: ' against' },
            {
              text: ` ${item?.awayTeam?.name}, ${item?.awayTeam?.city}`,
              isBolder: true,
            },
            { text: ' to be resolved.' },
          ];
          controlButtons = [
            {
              iconType: MORE_ICON,
              onClick: () => {
                setSelectedMatch(item);
              },
            },
          ];
        } else if (cardType === MATCH_RESOLVE_TYPE) {
          img = formatImage(item?.homeTeam?.imageURL);
          onImgClick = () => navigateToClubPage(item?.updatedBy);
          message = [
            {
              text: `${item?.homeTeam?.name}, ${item?.homeTeam?.city}`,
              isBolder: true,
            },
            { text: ' proposed a result to match ' },
            {
              text: getMatchName(item),
              isBolder: true,
            },
          ];
          controlButtons = [
            {
              iconType: MORE_ICON,
              onClick: () => {
                setSelectedMatch(item);
              },
            },
          ];
        }

        return {
          variant: COMPONENT_VARIANT_MEDIUM,
          img,
          onImgClick,
          message,
          subText,
          controlButtons,
        };
      });

      return formattedProps;
    },
    [navigateToClubPage, navigateToUserPage, respondToTransferOffer]
  );

  const offersAsideViewProps = useMemo(
    () => ({
      isOpened: offersAsideOpened,
      onClose: closeOffersAside,
      asideViewType: ITEMS_LIST_ASIDE_TYPE,
      asideViewTypeProps: {
        title: 'Offers',
        viewLink: '/feature-view', // TODO standalone feature page to be implemented
        items: getOfferItemsProps(activeRole?.offers),
      },
    }),
    [
      activeRole?.offers,
      closeOffersAside,
      getOfferItemsProps,
      offersAsideOpened,
    ]
  );

  useEffect(() => {
    liveOfferEvents.forEach(event => {
      subscribe(event, closeOffersAside);
    });
  }, [closeOffersAside, subscribe]);

  return {
    user,
    gmItems,
    logout,
    toggleOffersAside: offersAsideToggleHandler,
    offersAsideOpened,
    offersAsideViewProps,

    selectedMatch,
    closeMatchModal,
  };
};

export default useProps;
