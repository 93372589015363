export const ICON_DARK_THEME = '#f2f2f2';
export const PRIMARY_DARK_COLOR = '#006db3';
export const SUCCESS_COLOR = '#4caf50ee';
export const SUCCESS_COLOR_SOLID = '#4caf50';
export const DANGER_COLOR = '#ef5350ee';
export const DANGER_COLOR_SOLID = '#ef5350';
export const WARNING_COLOR = '#ffeb3bee';
export const WARNING_COLOR_SOLID = '#ffeb3b';
export const WHITE_COLOR = '#f2f2f2';
export const DARK_MATERIAL_PRIMARY_COLOR = '#90cdff';
export const DARK_MATERIAL_SECONDARY_COLOR = '#5ed4fd';
export const DARK_MATERIAL_TERTIARY_COLOR = '#dfb7ff';
export const BEGINNER_SKILL_LEVEL_COLOR = '#004d61';
export const AMATEUR_SKILL_LEVEL_COLOR = '#1a5674';
export const SEMI_PRO_SKILL_LEVEL_COLOR = '#336088';
export const PRO_SKILL_LEVEL_COLOR = '#4d6a9d';
export const INTERNATIONAL_SKILL_LEVEL_COLOR = '#6674b1';
export const WORLD_CLASS_SKILL_LEVEL_COLOR = '#612b8f';

export const LOW_COMPATIBILITY_COLOR = '#FF3366';
export const MEDIUM_COMPATIBILITY_COLOR = '#FF9900';
export const GOOD_COMPATIBILITY_COLOR = '#33CC33';
export const STRONG_COMPATIBILITY_COLOR = '#00FFFF';
export const EXCELLENT_COMPATIBILITY_COLOR = '#9933FF';
