/* eslint-disable max-len */
export const MIN_VALID_LENGTH = 2;
export const MAX_VALID_LENGTH = 40;
export const MAX_VALID_LOCATION_LENGTH = 500;
export const MAX_VALID_CLUB_NAME_LENGTH = 30;

export const MIN_VALID_LENGTH_ERR_MSG = `Minimum length is ${MIN_VALID_LENGTH}.`;
export const MAX_VALID_LENGTH_ERR_MSG = `Maximum length is ${MAX_VALID_LENGTH}.`;
export const MAX_VALID_LOCATION_LENGTH_ERR_MSG = `Maximum length is ${MAX_VALID_LOCATION_LENGTH}.`;
export const MAX_VALID_CLUB_NAME_LENGTH_ERR_MSG = `Maximum length is ${MAX_VALID_CLUB_NAME_LENGTH}.`;

const IS_REQUIRED_TEMPLATE_MSG = 'is required!';
export const MISSING_FIRST_NAME_ERR_MSG = `First Name ${IS_REQUIRED_TEMPLATE_MSG}`;
export const MISSING_LAST_NAME_ERR_MSG = `Last Name ${IS_REQUIRED_TEMPLATE_MSG}`;
export const MISSING_BIRTHDATE_ERR_MSG = 'Select Birth Date.';
export const MISSING_COUNTRY_ERR_MSG = 'Select your nationality.';
export const MISSING_CITY_ERR_MSG = 'City is required!';
export const MISSING_LOCATION_ERR_MSG = 'Field location is required!';
export const MISSING_EMAIL_ERR_MSG = 'Email is required!';
export const INVALID_EMAIL_FORMAT_ERR_MSG = 'Invalid email format!';
export const MISSING_PASSWORD_ERR_MSG = 'Password is required!';
export const INVALID_PASSWORD_FORMAT_ERR_MSG =
  'Your password must contain at least 1 digit, 1 one lowercase character, 1 one uppercase charachter! Minimum length should be 8 and maximum length 32 characters!';
export const MISSING_CONFIRMED_PASSWORD_ERR_MSG =
  'Please confirm your password.';
export const PASSWORDS_NOT_MATCHING_ERR_MSG = 'Passwords not matching!';
export const MISSING_CLUB_NAME_ERR_MSG = 'Team name is required';
