import React, { Children, useCallback } from 'react';
import PropTypes from 'prop-types';

import './Headline.scss';

const className = 'c-Headline';

const getHeading = (headingSize, text) => (
  <h1 className={`${className}__main-text ${className}__h${headingSize}`}>
    {text}
  </h1>
);

const Headline = ({ mainText, subText, size, variant, withOverText }) => {
  const reversedOrderClassName = withOverText ? `${className}--reversed` : '';

  const renderText = useCallback(
    items =>
      Children.toArray(
        items?.map(({ text, highlighted, isNewLine, isEmphasized }) => {
          const highlightedClassName = highlighted
            ? `${className}--highlighted`
            : '';
          const newLineClassName = isNewLine ? `${className}--new-line` : '';
          const emphasizedClassName = isEmphasized
            ? `${className}--emphasized`
            : '';

          return (
            <span
              className={`${highlightedClassName} ${newLineClassName} ${emphasizedClassName} ${className}__variant--${variant}`}
            >
              {text}
            </span>
          );
        })
      ),
    [variant]
  );

  const renderMainText = useCallback(
    () => getHeading(size, renderText(mainText)),
    [mainText, renderText, size]
  );

  const renderSubText = useCallback(
    () => <h3 className={`${className}__sub-text`}>{renderText(subText)}</h3>,
    [renderText, subText]
  );

  return (
    <div className={`${className} ${reversedOrderClassName}`}>
      {renderMainText()}
      {subText && renderSubText()}
    </div>
  );
};

export const HeadlineTextDataType = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    highlighted: PropTypes.bool,
    isEmphasized: PropTypes.bool,
  })
);

export const HeadlinePropsDataType = {
  mainText: HeadlineTextDataType.isRequired,
  subText: HeadlineTextDataType,
  size: PropTypes.oneOf([1, 2, 3, 4]),
  variant: PropTypes.oneOf(['primary', 'dark', 'light']),
  withOverText: PropTypes.bool,
};

Headline.propTypes = HeadlinePropsDataType;

Headline.defaultTypes = {
  size: 1,
  variant: 'light',
};

export default Headline;
