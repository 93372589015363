import React from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_LIGHT_VARIANT } from '../button';
import { CTAPropTypes } from 'common/constants/prop-types';
import { NavLink } from 'react-router-dom';
import { STREET_PATH } from 'routes/constants';
import LogoIconLink from 'common/components/LogoIconLink';

import './PublicNav.scss';

const className = 'c-PublicNav';

const PublicNav = ({ loginCTA }) => {
  return (
    <nav className={className}>
      <NavLink className={`${className}__home-link`} to='/'>
        <LogoIconLink to={STREET_PATH} />
      </NavLink>

      {loginCTA && (
        <div className={`${className}__auth-wrapper`}>
          <Button
            variant={BUTTON_LIGHT_VARIANT}
            narrow
            onClick={loginCTA.onClick}
          >
            {loginCTA.label}
          </Button>
        </div>
      )}
    </nav>
  );
};

PublicNav.propTypes = {
  variant: PropTypes.string,
  loginCTA: CTAPropTypes.isRequired,
};

export default PublicNav;
