import { ToggleHelpDrawer } from './help-drawer/ToggleHelpDrawer';
import { HelpDrawer } from './help-drawer/HelpDrawer';
// import { PurchaseButton } from './purchase/PurchaseButton';

// NOTE add needed aside features or uncomment the existing ones
const RightToolbar = () => {
  return (
    <>
      <div className='engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 top-50 end-0 transform-90 mt-20 gap-2'>
        <ToggleHelpDrawer />
        {/* <PurchaseButton /> */}
      </div>

      <HelpDrawer />
    </>
  );
};

export { RightToolbar };
