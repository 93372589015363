import { PublicPageWrapper } from 'features/page-wrapper';
import style from './PublicActionPage.module.scss';
import PropTypes from 'prop-types';
import {
  pageTitles,
  reactGAPage,
  RESET_PASSWORD_PUBLIC_PAGE_TYPE,
  VALIDATE_ACCOUNT_PUBLIC_PAGE_TYPE,
} from './constants';
import SectionTitle from 'common/components/section-title';
import { useProps } from './hooks';
import Text from 'common/components/text';
import Form from 'common/components/form';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

const PublicActionPage = ({ type }) => {
  const {
    message,
    resetPasswordInputProps,
    shouldShowResetPasswordInputForm,
    submitPasswordReset,
  } = useProps(type);

  useEffect(() => {
    ReactGA.pageview(reactGAPage[type]);
  }, [type]);

  return (
    <PublicPageWrapper classNames={style.wrapper}>
      <div className={style.content}>
        {pageTitles[type] && <SectionTitle title={pageTitles[type]} />}
        {shouldShowResetPasswordInputForm && (
          <Form
            inputs={resetPasswordInputProps}
            onConfirm={submitPasswordReset}
          />
        )}
        <div className={style.message}>
          <Text data={message} color='light' />
        </div>
      </div>
    </PublicPageWrapper>
  );
};

PublicActionPage.propTypes = {
  type: PropTypes.oneOf([
    VALIDATE_ACCOUNT_PUBLIC_PAGE_TYPE,
    RESET_PASSWORD_PUBLIC_PAGE_TYPE,
  ]),
};

export default PublicActionPage;
