/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { ICON_DARK_THEME } from 'common/constants/theming';

const ClubIcon = ({ color }) => (
  <svg height='24px' viewBox='0 0 24 24' width='24px' fill='#000000'>
    <path
      fill={color}
      d='M11.19 1.36l-7 3.11C3.47 4.79 3 5.51 3 6.3V11c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V6.3c0-.79-.47-1.51-1.19-1.83l-7-3.11c-.51-.23-1.11-.23-1.62 0zM12 11.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z'
    />
  </svg>
);

ClubIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

ClubIcon.propTypes = {
  color: PropTypes.string,
};

export default ClubIcon;
