import { useForm } from 'common/hooks';
import { useState } from 'react';
import InlineRulesSection from '../components/inline-rules-section';
import { locationFormFields } from '../constants';
import { locationSchema } from '../schemas';

const useLocationProps = initState => {
  const [locationFormData, setLocationFormData] = useState(initState);

  const { submit: onSave, getInputProps } = useForm(
    locationFormData,
    locationFormFields,
    locationSchema,
    setLocationFormData,
    true,
    true,
    true
  );

  return {
    Component: InlineRulesSection,
    inputs: getInputProps(),
    onSave,
    locationFormData,
  };
};

export default useLocationProps;
