import { useForm } from 'common/hooks';
import { useState } from 'react';
import InlineRulesSection from '../components/inline-rules-section';
import { durationFormFields } from '../constants';
import { durationSchema } from '../schemas';

const useDurationProps = initState => {
  const [durationFormData, setDurationFormData] = useState(initState);

  const { submit: onDurationSave, getInputProps: getDurationFormInputProps } =
    useForm(
      initState,
      durationFormFields,
      durationSchema,
      setDurationFormData,
      true,
      true,
      true
    );

  return {
    Component: InlineRulesSection,
    inputs: getDurationFormInputProps(),
    onSave: onDurationSave,
    durationData: durationFormData,
  };
};

export default useDurationProps;
