// import reportWebVitals from './reportWebVitals';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import { initAutoRefreshToken } from 'common/api/authService';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = document.getElementById('root');

const mountApp = () => {
  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    root
  );
};

const setup = () => {
  // to try to refresh access token on a failed request due to invalid access token
  initAutoRefreshToken();
  mountApp();
};

setup();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
