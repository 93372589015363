import style from './GameEventPage.module.scss';
import SmartPageWrapper from 'features/page-wrapper/variants/smart-page-wrapper';
import { useLocation } from 'react-router-dom';
import { useCommonClassNames, useGameEvents } from 'common/hooks';
import { useEffect, useMemo } from 'react';
import { splitPath } from 'common/utils/routing';
import { REACT_GA_GAME_EVENT_PAGE } from 'common/constants/react-google-analytics/pages';
import ReactGA from 'react-ga';
import { GameEvent } from 'features/game-event-features';

const GameEventPage = () => {
  const location = useLocation();
  const gameEventId = useMemo(
    () => splitPath(location?.pathname)?.[2],
    [location?.pathname]
  );
  const { animationClassName } = useCommonClassNames(false);
  const { gameEvents, loadGameEvent } = useGameEvents();

  useEffect(() => {
    if (gameEventId) loadGameEvent(gameEventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameEventId]);

  useEffect(() => {
    ReactGA.pageview(REACT_GA_GAME_EVENT_PAGE);
  }, []);

  return (
    <SmartPageWrapper fullscreen>
      <div
        className={`${style.wrapper} ${style[`wrapper${animationClassName}`]}`}
      >
        {gameEvents[0] && <GameEvent gameData={gameEvents[0]} />}
      </div>
    </SmartPageWrapper>
  );
};

export default GameEventPage;
