import React, { Children } from 'react';
import PropTypes from 'prop-types';

import './SectionTitle.scss';

const className = 'c-SectionTitle';

const SectionTitle = ({ title }) => {
  const renderText = () =>
    Children.toArray(
      title.map(({ text, highlighted }) => {
        const highlightedClassName = highlighted
          ? `${className}--highlighted`
          : '';

        return (
          <span className={`${className}__span ${highlightedClassName}`}>
            {text}
          </span>
        );
      })
    );

  return <h2 className={className}>{renderText()}</h2>;
};

export const SectionTitleTextDataType = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlighted: PropTypes.bool,
    isEmphasized: PropTypes.bool,
  })
);

SectionTitle.propTypes = {
  title: SectionTitleTextDataType,
};

export default SectionTitle;
