import { useAuthContext } from 'contexts/auth';
import { useState, useEffect } from 'react';
import { PLAYER_USER_ROLE } from './constants';
import useShortlists from './useShortlists';

const useActiveRole = () => {
  const { user } = useAuthContext();

  const [activeRole, setActiveRole] = useState({});

  const { updateShortlistData, getShortlistMethodType } = useShortlists({
    roleType: activeRole?.type,
    gmId: activeRole?.gmId,
    initShortlistData: [],
    id: activeRole?.id,
  });

  useEffect(() => {
    setActiveRole({
      id: user?._id,
      type: PLAYER_USER_ROLE,
      label: '',
      city: user?.geoInfo?.city,
    });
  }, [user?._id, user?.geoInfo?.city]);

  return {
    activeRole,
    sendLastReadNotificationUpdate: () => {},
    updateShortlistData,
    getShortlistMethodType,
  };
};

export default useActiveRole;
