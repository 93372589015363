import { formatAsMonthDayString } from 'common/utils/dates';
import { TIMEDATE_LABEL } from 'features/match/constants';
import { splitTimeslotIntoHoursMinutesPair } from 'features/match/utils';
import PropTypes from 'prop-types';
import { Children } from 'react';
import style from './ReadonlyTimeslots.module.scss';

const ReadonlyTimeslots = ({ data }) => {
  const dayKeys = Object.keys(data);

  const renderDay = item => {
    const timeslotsToRender = Children.toArray(
      item?.timeslots?.map(timeslot => {
        const [hours, minutes] = splitTimeslotIntoHoursMinutesPair(timeslot);

        return <li className={style.timeslot}>{`${hours}:${minutes}`}</li>;
      })
    );

    return (
      <div className={style.days}>
        <label className={style.dateLabel}>
          {formatAsMonthDayString(item?.date)}
        </label>
        <ul>{timeslotsToRender}</ul>
      </div>
    );
  };

  const daysToRender = Children.toArray(
    dayKeys?.map(key => renderDay(data?.[key]))
  );

  return (
    <div className={style.wrapper}>
      <label className={style.label}>{TIMEDATE_LABEL}</label>
      {daysToRender}
    </div>
  );
};

ReadonlyTimeslots.propTypes = {
  data: PropTypes.object,
};

export default ReadonlyTimeslots;
