import PropTypes from 'prop-types';
import {
  useProps,
  useModals,
  useMatchServices,
  useModalControlButtonProps,
} from './hooks';
import { useWindowSizeContext } from 'contexts/window-size';
import { useCallback, useMemo } from 'react';
import {
  ARRANGE_MATCH_MODE_EDIT_FORM,
  GAME_RESULT_INPUTS,
  initMatchModalBasedOnType,
  MATCH_RESOLVED_STATUS,
  MATCH_SCHEDULED_STATUS,
  shareableMatchesBasedOnStatus,
} from './constants';
import {
  formatReadOnlyMatchData,
  getAddToCalendarProps,
  getFormattedMatchProps,
  getInitMatchModalForMatchSectionVariant,
} from './utils';
import { useUserSessionContext } from 'contexts/user-session';
import AddToCalendarButton from 'features/add-to-calendar-button';
import MatchView from './components/match-view';
import RenderWrapper from './components/render-wrapper';
import { useAuthContext } from 'contexts/auth';
import Share from 'features/share';
import {
  MATCH_SHARE_TYPE,
  PLAYED_MATCH_SHARE_GROUP,
  SCHEDULED_MATCH_SHARE_GROUP,
} from 'features/share/constants';
import { canMatchBeResolvedBasedOnMatchTime } from 'common/utils/dates';
import {
  REACT_GA_PLAYED_MATCH_MODAL,
  REACT_GA_SCHEDULED_MATCH_MODAL,
} from 'common/constants/react-google-analytics/modals';
import { REACT_GA_CALENDAR_MATCH_EVENT } from 'common/constants/react-google-analytics/events';
import ReactGA from 'react-ga';

const Match = ({
  onClose,
  data,
  createMatchMode,
  forMatchSection,
  withoutModalVariant,
}) => {
  const { activeRole } = useUserSessionContext();
  const { isGuestView } = useAuthContext();
  const { homeTeam, awayTeam, initMatchData } = useMemo(
    () =>
      getFormattedMatchProps(data, {
        ...activeRole?.gmClub,
        logo: activeRole?.logo,
      }),
    [activeRole?.gmClub, activeRole?.logo, data]
  );

  const initMatchModal = useMemo(() => {
    if (forMatchSection) {
      return getInitMatchModalForMatchSectionVariant(
        data?.status,
        activeRole?.id,
        data?.homeTeam?._id,
        data?.awayTeam?._id
      );
    } else {
      return createMatchMode
        ? ARRANGE_MATCH_MODE_EDIT_FORM
        : initMatchModalBasedOnType[data?.type];
    }
  }, [
    activeRole?.id,
    createMatchMode,
    data?.awayTeam?._id,
    data?.homeTeam?._id,
    data?.status,
    data?.type,
    forMatchSection,
  ]);

  const { isMobileView } = useWindowSizeContext();
  const {
    // global props
    isSubmitMatchTermsEnabled,
    submitCreateMatch,

    matchData,

    // sections
    timedateSectionProps,
    getOutcomeSectionProps,
    getArrangeMatchModalContentProps,

    // headline props
    getHeadlineProps,
  } = useProps(homeTeam?.name, awayTeam?.name, initMatchData, onClose);

  const {
    customRulesModalOpened,
    closeRulesSectionModal,
    openCustomRulesModal,

    // match modals
    matchModal,

    // // arrange match mode modals
    openArrangeMatchModeEditForm,
    openArrangeMatchModeConfirmModal,

    // // propose match result mode modals
    openProposeMatchResultModeEditForm,

    // // resolve match result mode modals
    openProposeMatchResultModeConfirmModal,
  } = useModals(initMatchModal);

  const {
    // meta
    isLoading,

    // services
    createMatch,
    acceptMatch,
    rejectMatch,
    abandonMatch,
    proposeMatchResult,
    acceptMatchResult,
  } = useMatchServices();

  const { getModalControlButtonProps } = useModalControlButtonProps({
    matchId: data?._id,
    createMatch,
    rejectMatch,
    abandonMatch,
    submitCreateMatch,
    openArrangeMatchModeConfirmModal,
    openArrangeMatchModeEditForm,
    matchData,
    homeTeam,
    awayTeam,
    onClose,
    acceptMatchResult,
    proposeMatchResult,

    // modals
    openProposeMatchResultModeConfirmModal,
    openProposeMatchResultModeEditForm,

    // form sections
    // timedate section
    timedateSectionProps,
    acceptMatch,
  });

  const proposeMatchResultModalContentProps = useMemo(() => {
    const formattedMatchData = Object.assign({}, matchData);
    delete formattedMatchData[GAME_RESULT_INPUTS];
    delete formattedMatchData.timedateData;

    return {
      formProps: [
        {
          ...getOutcomeSectionProps(matchModal),
        },
      ],
      readOnlyItems: formatReadOnlyMatchData(initMatchData),
    };
  }, [getOutcomeSectionProps, initMatchData, matchData, matchModal]);

  const renderShareButtons = useCallback(() => {
    const shareGroup =
      data?.status === MATCH_SCHEDULED_STATUS
        ? SCHEDULED_MATCH_SHARE_GROUP
        : data?.status === MATCH_RESOLVED_STATUS
        ? PLAYED_MATCH_SHARE_GROUP
        : null;
    return shareableMatchesBasedOnStatus.includes(data?.status) ? (
      <Share type={MATCH_SHARE_TYPE} data={data} shareGroup={shareGroup} />
    ) : null;
  }, [data]);

  const trackOnCalendarEvent = useCallback(() => {
    ReactGA.event(REACT_GA_CALENDAR_MATCH_EVENT);
  }, []);

  const matchResolvableBasedOnTime = useMemo(
    () => canMatchBeResolvedBasedOnMatchTime(matchData?.matchDate),
    [matchData?.matchDate]
  );

  const matchReactGAModalView = useMemo(() => {
    if (data?.status === MATCH_SCHEDULED_STATUS) {
      return REACT_GA_SCHEDULED_MATCH_MODAL;
    } else if (data?.status === MATCH_RESOLVED_STATUS) {
      return REACT_GA_PLAYED_MATCH_MODAL;
    } else {
      return null;
    }
  }, [data?.status]);

  return (
    <RenderWrapper
      matchModal={matchModal}
      withoutModalVariant={withoutModalVariant}
      isMobileView={isMobileView}
      isSubmitMatchTermsEnabled={isSubmitMatchTermsEnabled}
      getHeadlineProps={getHeadlineProps}
      getModalControlButtonProps={getModalControlButtonProps}
      matchResolvableBasedOnTime={matchResolvableBasedOnTime}
      homeTeam={homeTeam}
      awayTeam={awayTeam}
      onClose={onClose}
      activeRoleId={activeRole?.id}
      reactGAModalView={matchReactGAModalView}
    >
      {data?.status === MATCH_SCHEDULED_STATUS && (
        <div onClick={trackOnCalendarEvent}>
          <AddToCalendarButton {...getAddToCalendarProps(data)} />
        </div>
      )}
      <MatchView
        matchModal={matchModal}
        isMobileView={isMobileView}
        isLoading={isLoading}
        openCustomRulesModal={openCustomRulesModal}
        customRulesModalOpened={customRulesModalOpened}
        closeRulesSectionModal={closeRulesSectionModal}
        getArrangeMatchModalContentProps={getArrangeMatchModalContentProps}
        formatReadOnlyMatchData={formatReadOnlyMatchData}
        matchData={matchData}
        matchResolvableBasedOnTime={matchResolvableBasedOnTime}
        initMatchData={initMatchData}
        proposeMatchResultModalContentProps={
          proposeMatchResultModalContentProps
        }
        selectedTimeslot={timedateSectionProps?.selectedTimeslot}
        setSelectedTimeslot={timedateSectionProps?.setSelectedTimeslot}
        homeTeamName={homeTeam?.name}
        awayTeamName={awayTeam?.name}
        isGuestView={isGuestView}
      />
      {renderShareButtons()}
    </RenderWrapper>
  );
};

Match.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
  createMatchMode: PropTypes.bool,
  forMatchSection: PropTypes.bool,
  withoutModalVariant: PropTypes.bool,
};

export default Match;
