import { useMemo, useState, useEffect } from 'react';

const useProps = (staticTabProps = [], initTab) => {
  const [selectedTab, setSelectedTab] = useState(
    initTab || staticTabProps[0]?.label
  );
  const tabProps = useMemo(
    () =>
      staticTabProps?.map(item => ({
        label: item?.label,
        onClick: () => {
          item?.onClick();
          setSelectedTab(item?.label);
        },
        isSelected: item?.label === selectedTab,
      })),
    [selectedTab, staticTabProps]
  );

  useEffect(() => {
    if (initTab) {
      setSelectedTab(initTab);
    }
  }, [initTab]);

  return {
    tabProps,
  };
};

export default useProps;
