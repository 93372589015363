import Button, { BUTTON_REGULAR_VARIANT } from 'common/components/button';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import EventInputBox from '../base-components/event-input-box';
import style from './EventInputWithTags.module.scss';

const EventInputWithTags = ({
  inputLabel,
  tags,
  addTag,
  addTagOnEnterKeyPress,
  removeTag,
  onChange,
  currentTag,
  smallFont
}) => {
  const smallFontClassName = useMemo(() => smallFont ? style['input--small-font'] : '', [smallFont]);

  const renderTags = useCallback(
    () =>
      tags?.map(({ value, id }) => (
        <Button narrow rounded onClick={() => removeTag(id)}>
          {value}
        </Button>
      )),
    [removeTag, tags]
  );

  return (
    <EventInputBox inputLabel={inputLabel} optional>
      <div className={style.wrapper}>
        <input
          className={`${style.input} ${smallFontClassName}`}
          onChange={onChange}
          type='text'
          value={currentTag}
          onKeyDown={addTagOnEnterKeyPress}
        />
        <Button
          onClick={addTag}
          narrow
          rounded
          variant={BUTTON_REGULAR_VARIANT}
        >
          add
        </Button>
        <div className={style.tags}>{renderTags()}</div>
      </div>
    </EventInputBox>
  );
};

EventInputWithTags.defaultProps = {
  tags: [],
};

export const EventInputWithTagsPropTypes = {
  inputLabel: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  addTag: PropTypes.func.isRequired,
  addTagOnEnterKeyPress: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  currentTag: PropTypes.string,
  smallFont: PropTypes.bool,
};

EventInputWithTags.propTypes = EventInputWithTagsPropTypes;

export default EventInputWithTags;
