import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { dayLetters } from 'common/constants/dateData';

import style from './CalendarTable.module.scss';

const WeekRow = ({ data, onSelect, selected }) => {
  const renderWeekDays = () =>
    Children.toArray(
      data.map(item => {
        const selectedClassName = selected === item ? style.selectedCell : '';

        return (
          <li
            className={`${style.cell} ${selectedClassName}`}
            onClick={() => {
              onSelect(item);
            }}
          >
            {item}
          </li>
        );
      })
    );

  return <ul className={style.calendarRow}>{renderWeekDays()}</ul>;
};
const CalendarTable = ({ weeks, onSelect, selected }) => {
  const renderDayLabelsRow = () =>
    Children.toArray(
      dayLetters.map(item => (
        <li className={`${style.cell} ${style.dayLabel}`}>{item}</li>
      ))
    );

  const renderWeeksRows = () =>
    Children.toArray(
      weeks.map(item => (
        <WeekRow data={item} onSelect={onSelect} selected={selected} />
      ))
    );

  return (
    <div className={`${style.wrapper}`}>
      <ul className={`${style.calendarRow}`}>{renderDayLabelsRow()}</ul>
      {renderWeeksRows()}
    </div>
  );
};

CalendarTable.propTypes = {
  onSelect: PropTypes.func.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.array),
  selected: PropTypes.number,
};

export default CalendarTable;
