/* eslint-disable max-len */
import PropTypes from 'prop-types';

const BookmarkIcon = ({ color }) => (
  <svg height='24' viewBox='0 96 960 960' width='24'>
    <path
      fill={color}
      d='m260 845 220-93 220 93V271H260v574Zm-18 73q-15 7-28.5-2T200 891V271q0-24 18-42t42-18h440q24 0 42 18t18 42v620q0 16-13.5 25t-28.5 2L480 816 242 918Zm18-647h440-440Z'
    />
  </svg>
);

BookmarkIcon.defaultProps = { color: '#111314' };

BookmarkIcon.propTypes = { color: PropTypes.string };

export default BookmarkIcon;
