import { createClubService } from 'common/api/clubsService';
import { useApi, useForm } from 'common/hooks';
import { useEventsContext } from 'contexts/events';
import { CLUB_CREATED_EVENT } from 'contexts/events/constants';
import { useMetaContext } from 'contexts/meta';
import { useMemo, useCallback, useEffect } from 'react';
import { CLUBS_PAGE_PATH } from 'routes/constants';
import {
  CLUB_CREATED_MESSAGE,
  createClubFormFields,
  CREATE_CLUB_FORM_KEYS,
} from '../constants';
import { createClubSchema } from '../schema';
import ReactGA from 'react-ga';
import { REACT_GA_CREATE_TEAM_EVENT } from 'common/constants/react-google-analytics/events';

const useCreateClub = ({ closeModal }) => {
  const { apiWrapper } = useApi();
  const { setNeutralToastMessage } = useMetaContext();
  const { emit } = useEventsContext();

  const initCreateClubForm = useMemo(() => {
    const keys = CREATE_CLUB_FORM_KEYS;
    const initCreateClubForm = {};
    keys.forEach(key => {
      initCreateClubForm[key] = { value: '', error: null };
    });

    return initCreateClubForm;
  }, []);

  const createClub = useCallback(
    async data => {
      const response = await apiWrapper(
        () => createClubService(data),
        CLUB_CREATED_MESSAGE
      );

      if (response?.data) {
        const { name, city, _id } = response?.data;
        setNeutralToastMessage(
          `Go to ${name}, ${city}`,
          `${CLUBS_PAGE_PATH}/${_id}`
        );

        emit(CLUB_CREATED_EVENT, response.data);
        ReactGA.event(REACT_GA_CREATE_TEAM_EVENT);
        closeModal();
      }
    },
    [apiWrapper, closeModal, emit, setNeutralToastMessage]
  );

  const {
    submit: onCreateClub,
    getInputProps: getCreateClubInputProps,
    refreshFormFieldValues: refreshCreateClubFormFieldValues,
  } = useForm(
    initCreateClubForm,
    createClubFormFields,
    createClubSchema,
    createClub
  );

  useEffect(() => {
    refreshCreateClubFormFieldValues(initCreateClubForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onCreateClub,
    getCreateClubInputProps,
  };
};

export default useCreateClub;
