/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './AnimateSwitch.scss';

const className = 'c-AnimateSwitch';

const AnimateSwitch = ({
  Component,
  targetPropKey,
  targetPropValue,
  isReversed,
  flagProps,
  ...props
}) => {
  const [activeClassName, setActiveClassName] = useState('');
  const [currentTargetValue, setCurrentTargetValue] = useState(targetPropValue);
  const [initFlag, setInitFlag] = useState(flagProps);

  let exitClassNameVariant = `${className}__exit`;
  let enterClassNameVariant = `${className}__enter`;

  if (isReversed) {
    exitClassNameVariant = `${className}__exit--reversed`;
    enterClassNameVariant = `${className}__enter--reversed`;
  }

  useEffect(() => {
    const isInit = initFlag === flagProps;

    let exitClassName = exitClassNameVariant;
    let enterClassName = enterClassNameVariant;

    if (isInit) {
      exitClassName = '';
      enterClassName = '';
    }

    setActiveClassName(exitClassName);

    const timeout = setTimeout(() => {
      setActiveClassName(enterClassName);
      setCurrentTargetValue(targetPropValue);
      setInitFlag(null);
      clearTimeout(timeout);
    }, 100);
  }, [...flagProps, exitClassNameVariant, enterClassNameVariant]);

  const getProps = () => ({
    ...props,
    [targetPropKey]: currentTargetValue,
  });

  return (
    <div className={`${className} ${activeClassName}`}>
      <Component {...getProps()} />
    </div>
  );
};

AnimateSwitch.propTypes = {
  Component: PropTypes.any,
  targetPropKey: PropTypes.string.isRequired,
  targetPropValue: PropTypes.any,
  flagProps: PropTypes.array,
  isReversed: PropTypes.bool,
};

AnimateSwitch.defaultProps = {
  flagProps: [],
};

export default AnimateSwitch;
