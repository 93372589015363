export const BUTTON_REGULAR_VARIANT = 'regular';
export const BUTTON_LIGHT_VARIANT = 'light';
export const BUTTON_DARK_BLACK_VARIANT = 'dark-black';
export const BUTTON_EMPHASIZED_VARIANT = 'emphasized';
export const BUTTON_MAIN_ON_SURFACE_VARIANT = 'main-on-surface';
export const BUTTON_NO_BG_VARIANT = 'no-bg';
export const BUTTON_SHALLOW_VARIANT = 'shallow';
export const NO_RIPPLE_EFFECT_VARIANT = 'transparent';

export const BUTTON_PRIMARY_TEXT_TYPE = 'primary';
export const BUTTON_DARK_TEXT_TYPE = 'dark';
export const BUTTON_UNSET_TEXT_TYPE = 'type-unset';
