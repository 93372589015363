import EventInputClassic, {
  EventInputClassicPropTypes,
} from 'common/components/event-inputs/event-input-classic';
import EventInputSelectImage, {
  EventInputSelectImagePropTypes,
} from 'common/components/event-inputs/event-input-select-image';
import EventInputWithAddButton, {
  EventInputWithAddButtonPropTypes,
} from 'common/components/event-inputs/event-input-with-add-button';
import EventInputWithOptions, {
  EventInputWithOptionsPropTypes,
} from 'common/components/event-inputs/event-input-with-options';
import EventInputWithTags, {
  EventInputWithTagsPropTypes,
} from 'common/components/event-inputs/event-input-with-tags';
import PropTypes from 'prop-types';
import style from './GameEventForm.module.scss';

const GameEventForm = ({
  surfaceTypeInputProps,
  timedateProps,
  locationProps,
  gameTypeProps,
  skillLevelProps,
  gameDurationProps,
  numberOfPlayersProps,
  hostContactProps,
  tagsProps,
  error,
}) => (
  <div className={style.wrapper}>
    {/* Select Surface Type */}
    <div className={style['surface-selector-wrapper']}>
      <EventInputSelectImage {...surfaceTypeInputProps} />
    </div>
    {/* Date & Time */}
    <EventInputWithAddButton {...timedateProps} error={error} />
    {/* Location */}
    <EventInputWithAddButton {...locationProps} />
    {/* Game Type */}
    <EventInputWithOptions {...gameTypeProps} error={error} />
    {/* Skill Level */}
    <EventInputWithOptions {...skillLevelProps} error={error} />
    {/* Duration */}
    <EventInputClassic {...gameDurationProps} error={error} />
    {/* Number of Players */}
    <EventInputClassic {...numberOfPlayersProps} error={error} />
    {/* Host Contact */}
    <EventInputClassic {...hostContactProps} smallFont />
    {/* Add Tags */}
    <EventInputWithTags {...tagsProps} smallFont />
  </div>
);

GameEventForm.propTypes = {
  surfaceTypeInputProps: PropTypes.shape(EventInputSelectImagePropTypes),
  timedateProps: PropTypes.shape(EventInputWithAddButtonPropTypes),
  locationProps: PropTypes.shape(EventInputWithAddButtonPropTypes),
  gameTypeProps: PropTypes.shape(EventInputWithOptionsPropTypes),
  skillLevelProps: PropTypes.shape(EventInputWithOptionsPropTypes),
  gameDurationProps: PropTypes.shape(EventInputClassicPropTypes),
  numberOfPlayersProps: PropTypes.shape(EventInputClassicPropTypes),
  hostContactProps: PropTypes.shape(EventInputClassicPropTypes),
  tagsProps: PropTypes.shape(EventInputWithTagsPropTypes),
};

export default GameEventForm;
