import {
  MAX_VALID_LENGTH,
  MAX_VALID_LENGTH_ERR_MSG,
  MAX_VALID_LOCATION_LENGTH,
  MAX_VALID_LOCATION_LENGTH_ERR_MSG,
  MIN_VALID_LENGTH,
  MIN_VALID_LENGTH_ERR_MSG,
  MISSING_CITY_ERR_MSG,
  MISSING_COUNTRY_ERR_MSG,
  MISSING_LOCATION_ERR_MSG,
} from 'common/constants/validations';
import {
  CITY_FORM_KEY,
  COUNTRY_FORM_KEY,
  DURATION_FORM_KEY,
  LOCATION_FORM_KEY,
  NUM_OF_PLAYERS_FORM_KEY,
  WITH_GK_FORM_KEY,
} from './constants';
const yup = require('yup');

export const locationSchema = yup.object().shape({
  [CITY_FORM_KEY]: yup
    .string()
    .min(MIN_VALID_LENGTH, MIN_VALID_LENGTH_ERR_MSG)
    .max(MAX_VALID_LENGTH, MAX_VALID_LENGTH_ERR_MSG)
    .required(MISSING_CITY_ERR_MSG),
  [COUNTRY_FORM_KEY]: yup
    .string()
    .min(MIN_VALID_LENGTH, MIN_VALID_LENGTH_ERR_MSG)
    .max(MAX_VALID_LENGTH, MAX_VALID_LENGTH_ERR_MSG)
    .required(MISSING_COUNTRY_ERR_MSG),
  [LOCATION_FORM_KEY]: yup
    .string()
    .min(MIN_VALID_LENGTH, MIN_VALID_LENGTH_ERR_MSG)
    .max(MAX_VALID_LOCATION_LENGTH, MAX_VALID_LOCATION_LENGTH_ERR_MSG)
    .required(MISSING_LOCATION_ERR_MSG),
});

export const durationSchema = yup.object().shape({
  [DURATION_FORM_KEY]: yup.number().notRequired().positive().integer(),
});

export const playersRulesSchema = yup.object().shape({
  [NUM_OF_PLAYERS_FORM_KEY]: yup.number().notRequired().positive().integer(),
  [WITH_GK_FORM_KEY]: yup.boolean(),
});
