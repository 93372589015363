import PropTypes from 'prop-types';
import { useCallback } from 'react';
import ScreenTitle from '../screen-title';
import PlayerSkillsIcon from 'common/components/icons/player-skills-icon';
import SkillSelector from '../skill-selector';
import { SKILL_LEVEL_STRONG, SKILL_LEVEL_GOOD } from '../../constants';
import '../../style.scss';
import style from './SkillsScreen.module.scss';
import FoldUnfoldWrapper from 'common/components/fold-unfold-wrapper';

const SkillsScreen = ({ skillProps }) => {
  const renderSkillSelectors = useCallback(
    () =>
      skillProps.map(skillProp => {
        const { name, options, skills, addSkill, removeSkill, isInitFolded } =
          skillProp;

        const skillsOptions = options.map(option => {
          const isSkillAddedToStrong = skills.strong.includes(option);
          const isSkillAddedToGood = skills.good.includes(option);
          const isStrongDisabled =
            skills.usedBudget + 2 > skills.budget && !isSkillAddedToStrong;
          const isGoodDisabled =
            skills.usedBudget + 1 > skills.budget && !isSkillAddedToGood;

          const skillOptions = [
            {
              name: SKILL_LEVEL_STRONG,
              onClick: () => {
                if (isSkillAddedToStrong) {
                  removeSkill(option);
                } else {
                  addSkill(option, SKILL_LEVEL_STRONG);
                }
              },
              isDisabled: isStrongDisabled,
              isSelected: isSkillAddedToStrong,
            },
            {
              name: SKILL_LEVEL_GOOD,
              onClick: () => {
                if (isSkillAddedToGood) {
                  removeSkill(option);
                } else {
                  addSkill(option, SKILL_LEVEL_GOOD);
                }
              },
              isDisabled: isGoodDisabled,
              isSelected: isSkillAddedToGood,
            },
          ];

          return <SkillSelector name={option} options={skillOptions} />;
        });

        const renderHeader = () => (
          <div className='player-form__skill-title'>
            <h2 className='player-form__category-label'>{name}</h2>
            <h3 className='player-form__sub-info'>
              budget: {skills.usedBudget} / {skills.budget}
            </h3>
          </div>
        );

        return (
          <div key={name} className={style.wrapper}>
            <FoldUnfoldWrapper
              isInitFolded={isInitFolded}
              renderHeader={renderHeader}
            >
              <div>{skillsOptions}</div>
            </FoldUnfoldWrapper>
          </div>
        );
      }),
    [skillProps]
  );

  return (
    <div className='player-form'>
      <ScreenTitle Icon={PlayerSkillsIcon} title='Skills' />
      {renderSkillSelectors()}
    </div>
  );
};

export const SkillCategoryPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  skills: PropTypes.shape({
    budget: PropTypes.number.isRequired,
    usedBudget: PropTypes.number.isRequired,
    strong: PropTypes.arrayOf(PropTypes.string).isRequired,
    good: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  addSkill: PropTypes.func.isRequired,
  removeSkill: PropTypes.func.isRequired,
});

SkillsScreen.defaultProps = {
  skillProps: [],
};

SkillsScreen.propTypes = {
  skillProps: PropTypes.arrayOf(SkillCategoryPropTypes).isRequired,
};

export default SkillsScreen;
