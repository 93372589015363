import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { QUICK_ANIMATION_DURATION_MS } from 'common/constants/integers';
import './FadeInOutWrapper.scss';

const className = 'c-FadeInOutWrapper';

const FadeInOutWrapper = ({
  children,
  isVisible,
  initVisible,
  customClassName,
}) => {
  const [shouldShow, setShouldShow] = useState(initVisible);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldShow(isVisible);
      clearTimeout(timeout);
    }, QUICK_ANIMATION_DURATION_MS);
  }, [isVisible]);

  return shouldShow ? (
    <div
      className={`${className} ${
        isVisible ? `${className}--active` : `${className}--inactive`
      } ${customClassName}`}
    >
      {children}
    </div>
  ) : null;
};

FadeInOutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
  initVisible: PropTypes.bool,
  customClassName: PropTypes.string,
};

export default FadeInOutWrapper;
