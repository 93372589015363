import Form from 'common/components/form';
import Modal, { DARK_THEME } from 'features/modal';
import PropTypes from 'prop-types';

const LocationForm = ({ inputs, onConfirm }) => (
  <Modal theme={DARK_THEME} fullScreen withoutWrapper onClose={onConfirm}>
    <Form inputs={inputs} onConfirm={onConfirm} />
  </Modal>
);

LocationForm.defaultProps = {
  inputs: [],
};

LocationForm.propTypes = {
  inputs: PropTypes.array,
  onConfirm: PropTypes.func.isRequired,
};

export default LocationForm;
