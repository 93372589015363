/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

const AddCircleIcon = ({ color }) => (
  <svg height='36' viewBox='0 96 960 960' width='36' stroke='white'>
    <path
      fill={color}
      d='M483.175 776q12.825 0 21.325-8.625T513 746V610h138q12 0 20.5-8.675 8.5-8.676 8.5-21.5 0-12.825-8.625-21.325T650 550H513V405q0-12-8.675-20.5-8.676-8.5-21.5-8.5-12.825 0-21.325 8.625T453 406v144H309q-12 0-20.5 8.675-8.5 8.676-8.5 21.5 0 12.825 8.625 21.325T310 610h143v137q0 12 8.675 20.5 8.676 8.5 21.5 8.5ZM479.5 976Q395 976 322 945.5T195 861q-54-54-84.5-127T80 576.5Q80 492 110.5 419T195 292q54-54 127-85t157.5-31q84.5 0 157.5 31t127 85q54 54 85 127t31 157.5q0 84.5-31 157.5t-85 127q-54 54-127 84.5T479.5 976Zm.5-400Zm0 340q140 0 240-100t100-240q0-140-100-240T480 236q-140 0-240 100T140 576q0 140 100 240t240 100Z'
    />
  </svg>
);

AddCircleIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

AddCircleIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default AddCircleIcon;
