import PropTypes from 'prop-types';
import EventInputBox from '../base-components/event-input-box';
import style from './EventInputTextArea.module.scss';

const EventInputTextArea = ({
  inputLabel,
  value,
  placeholder,
  optional,
  error,
  onChange,
}) => (
  <EventInputBox
    optional={optional}
    error={error}
    inputLabel={inputLabel}
    hasValue={!!value}
  >
    <div className={style.wrapper}>
      <textarea
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      ></textarea>
    </div>
  </EventInputBox>
);

export const EventInputClassicPropTypes = {
  optional: PropTypes.bool,
  error: PropTypes.bool,
  inputLabel: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

EventInputTextArea.propTypes = EventInputClassicPropTypes;

export default EventInputTextArea;
