import {
  ARRANGE_MATCH_MODE_CONFIRM_MODAL,
  ARRANGE_MATCH_MODE_EDIT_FORM,
  MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL,
  PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL,
  PROPOSE_MATCH_RESULT_MODE_EDIT_FORM,
  RESOLVED_MATCH_MODAL,
  RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL,
} from 'features/match/constants';
import { MatchModalPropTypes } from 'features/match/propTypes';
import PropTypes from 'prop-types';
import MatchForm from '../match-form';
import ReadOnlyItemsModalContent from '../read-only-items-modal-content';

const MatchView = ({
  // control flow
  matchModal,
  isMobileView,
  isLoading,
  openCustomRulesModal,
  customRulesModalOpened,
  closeRulesSectionModal,

  // formatting functions
  getArrangeMatchModalContentProps,
  formatReadOnlyMatchData,

  // data
  matchData,
  matchResolvableBasedOnTime,
  initMatchData,
  proposeMatchResultModalContentProps,
  selectedTimeslot,
  setSelectedTimeslot,

  homeTeamName,
  awayTeamName,
  isGuestView,
}) => {
  switch (matchModal) {
    case ARRANGE_MATCH_MODE_EDIT_FORM:
      return (
        <MatchForm
          formProps={getArrangeMatchModalContentProps({
            openCustomRulesModal,
            isMobileView,
            customRulesModalOpened,
            closeRulesSectionModal,
            matchModal,
          })}
        />
      );
    case ARRANGE_MATCH_MODE_CONFIRM_MODAL:
      return (
        <ReadOnlyItemsModalContent
          items={formatReadOnlyMatchData({
            ...matchData,
            gameResultData: {},
          })}
          isLoading={isLoading}
        />
      );

    case MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL:
      const formattedItems = formatReadOnlyMatchData({
        ...initMatchData,
        gameResultData: {},
      });

      return (
        <ReadOnlyItemsModalContent
          withTimeChosing
          isLoading={isLoading}
          items={formattedItems}
          selectedTimeslot={selectedTimeslot}
          onTimeslotSelect={setSelectedTimeslot}
        />
      );

    case PROPOSE_MATCH_RESULT_MODE_EDIT_FORM:
      if (matchResolvableBasedOnTime) {
        return <MatchForm {...proposeMatchResultModalContentProps} />;
      } else {
        <ReadOnlyItemsModalContent
          items={formatReadOnlyMatchData(
            { ...initMatchData, gameResultData: {} },
            {
              homeTeam: homeTeamName,
              awayTeam: awayTeamName,
            }
          )}
          withHomepageCTA={isGuestView}
        />;
      }
    // eslint-disable-next-line no-fallthrough
    case PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL:
      const data = {
        ...initMatchData,
        gameResultData: matchData?.gameResultData,
      };
      return (
        <ReadOnlyItemsModalContent
          items={formatReadOnlyMatchData(data, {
            homeTeam: homeTeamName,
            awayTeam: awayTeamName,
          })}
          isLoading={isLoading}
        />
      );

    case RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL:
    case RESOLVED_MATCH_MODAL:
    default:
      return (
        <ReadOnlyItemsModalContent
          items={formatReadOnlyMatchData(initMatchData, {
            homeTeam: homeTeamName,
            awayTeam: awayTeamName,
          })}
          withHomepageCTA={isGuestView}
        />
      );
  }
};

MatchView.propTypes = {
  matchModal: MatchModalPropTypes,
  getArrangeMatchModalContentProps: PropTypes.func.isRequired,
  formatReadOnlyMatchData: PropTypes.func.isRequired,
  openCustomRulesModal: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool,
  isLoading: PropTypes.bool,
  customRulesModalOpened: PropTypes.bool,
  closeRulesSectionModal: PropTypes.func.isRequired,
  matchData: PropTypes.object,
  initMatchData: PropTypes.object,
  proposeMatchResultModalContentProps: PropTypes.object,
  selectedTimeslot: PropTypes.any,
  setSelectedTimeslot: PropTypes.func.isRequired,
  homeTeamName: PropTypes.string,
  awayTeamName: PropTypes.string,
  matchResolvableBasedOnTime: PropTypes.bool,
};

export default MatchView;
