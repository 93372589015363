import React from 'react';
import PropTypes from 'prop-types';
import { GLOBAL_LOADING_TYPE, LOCAL_LOADING_TYPE } from './constants';
import './Loading.scss';

const className = 'c-Loading';

const Loading = ({ variant }) => {
  if (variant === LOCAL_LOADING_TYPE) {
    return (
      <div className={`${className} ${className}__local-loading`}>
        <div className={`${className}__loader`}></div>
      </div>
    );
  } else {
    return (
      <div className={`${className}__outer-wrapper ${className}`}>
        <div className={`${className} ${className}__global-loading`}>
          <div className={`${className}__loader`}></div>
        </div>
      </div>
    );
  }
};

Loading.propTypes = {
  variant: PropTypes.oneOf([GLOBAL_LOADING_TYPE, LOCAL_LOADING_TYPE]),
};

Loading.defaultProps = {
  variant: GLOBAL_LOADING_TYPE,
};

export default Loading;
