import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import './FoldUnfoldWrapper.scss';
import ExpandIcon from '../icons/expand-icon';
import { DARK_MATERIAL_TERTIARY_COLOR } from 'common/constants/theming';

const className = 'c-FoldUnfoldWrapper';

const FoldUnfoldWrapper = ({ children, isInitFolded, renderHeader }) => {
  const [folded, setFolded] = useState(isInitFolded);
  const bodyRef = useRef();

  const toggleFold = useCallback(() => {
    setFolded(!folded);
  }, [folded]);

  const foldedBodyClassName = useMemo(
    () => (folded ? `${className}__body--folded` : ''),
    [folded]
  );

  const foldedHeaderClassName = useMemo(
    () => (folded ? `${className}__folded-header` : ''),
    [folded]
  );

  useEffect(() => {
    if (!folded) {
      bodyRef.current.scrollTop = 0;
    }
  }, [folded]);

  return (
    <div className={`${className}`}>
      <div
        className={`${className}__fold-unfold-header ${foldedHeaderClassName}`}
        onClick={toggleFold}
      >
        {renderHeader && renderHeader()}

        <span>
          <ExpandIcon fill={DARK_MATERIAL_TERTIARY_COLOR} />
        </span>
      </div>

      <div
        className={`${className}__body ${foldedBodyClassName}`}
        ref={bodyRef}
      >
        {children}
      </div>
    </div>
  );
};

FoldUnfoldWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  renderHeader: PropTypes.func,
  isInitFolded: PropTypes.bool,
};

export default FoldUnfoldWrapper;
