import PropTypes from 'prop-types';
import { useCallback } from 'react';
import Headline from 'common/components/headline';
import Image from 'common/components/image';
import style from './InfoSectionsV2.module.scss';

const InfoSectionsV2 = ({ sectionsSetProps }) => {
  const renderSections = useCallback(
    () =>
      sectionsSetProps?.map(
        ({ bgImg, title, description, smallBubbleText, graphicImg }) => (
          <div className={style.wrapper}>
            <div className={style.background}>
              {/* Background */}
              <div className={style.overlay}></div>
              <Image {...bgImg} />
            </div>
            {/* Mobile Layout */}
            <div className={style.mobileWrapper}>
              {/* Left Column */}

              {/* Title */}
              <div className={style.title}>
                <Headline {...title} size={1} variant='primary' />
              </div>

              <div className={style.graphicWrapper}>
                {/* Screenshot / Graphic */}
                <div className={style.graphicOverlay}></div>
                <Image {...graphicImg} />
              </div>

              {/* Description */}
              <div className={style.textWrapper}>
                <div className={style.description}>
                  <p className={style.text}>{description}</p>
                </div>

                {/* Hook up message */}
                <div className={style.smallBubbleTextWrapper}>
                  <p className={`${style.text} ${style.smallBubbleText}`}>
                    {smallBubbleText}
                  </p>
                </div>
              </div>
            </div>

            {/* Desktop Layout */}
            <div className={style.desktopWrapper}>
              {/* Left Column */}
              <div className={style.leftColumn}>
                {/* Title */}
                <div className={style.title}>
                  <Headline {...title} size={1} variant='primary' />
                </div>

                {/* Description */}
                <div className={style.textWrapper}>
                  <div className={style.description}>
                    <p className={style.text}>{description}</p>
                  </div>

                  {/* Hook up message */}
                  <div className={style.smallBubbleTextWrapper}>
                    <p className={`${style.text} ${style.smallBubbleText}`}>
                      {smallBubbleText}
                    </p>
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className={style.rightColumn}>
                <div className={style.graphicWrapper}>
                  {/* Screenshot / Graphic */}
                  <div className={style.graphicOverlay}></div>
                  <Image {...graphicImg} />
                </div>
              </div>
            </div>
          </div>
        )
      ),
    [sectionsSetProps]
  );

  return <div className={style.mainWrapper}>{renderSections()}</div>;
};

InfoSectionsV2.defaultProps = {
  sectionsSetProps: [],
};

InfoSectionsV2.propTypes = {
  sectionsSetProps: PropTypes.array,
};

export default InfoSectionsV2;
