import { useMemo, useState } from 'react';
import {
  experienceStaticProps,
  footStaticProps,
  interestedInStaticProps,
  personalityStaticProps,
  descriptionStaticProps,
} from '../constants';

const useGeneral = initPlayerData => {
  const [description, setDescription] = useState(initPlayerData?.description);

  const getDynamicInputProps = (staticData, setFn, currentValue) => ({
    inputLabel: staticData?.inputLabel,
    valueInlineLabel: staticData?.valueInlineLabel,
    placeholder: staticData?.placeholder,
    value: currentValue,
    type: staticData?.type,
    onChange: e => {
      setFn(e.target.value);
    },
    optional: staticData.optional,
  });

  const descriptionProps = useMemo(
    () =>
      getDynamicInputProps(descriptionStaticProps, setDescription, description),
    [description]
  );

  // option inputs
  const formatDynamicOptionProps = (staticData, setFn, selectedOption) =>
    staticData.map(({ type, description }) => ({
      value: type,
      description,
      onSelect: () => {
        setFn(type);
      },
      isSelected: type === selectedOption,
    }));

  // interested in
  const [interestedIn, setInterestedIn] = useState(
    initPlayerData?.playerGeneralInfo?.interestedIn || null
  );

  const interestedInOptionProps = useMemo(
    () => ({
      inputLabel: interestedInStaticProps.inputLabel,
      optionProps: formatDynamicOptionProps(
        interestedInStaticProps.optionProps,
        setInterestedIn,
        interestedIn
      ),
    }),
    [interestedIn]
  );

  // foot
  const [foot, setFoot] = useState(
    initPlayerData?.playerGeneralInfo?.foot || null
  );

  const footOptionProps = useMemo(
    () => ({
      inputLabel: footStaticProps.inputLabel,
      optionProps: formatDynamicOptionProps(
        footStaticProps.optionProps,
        setFoot,
        foot
      ),
    }),
    [foot]
  );

  // experience level
  const [experienceLevel, setExperienceLevel] = useState(
    initPlayerData?.playerGeneralInfo?.experienceLevel || null
  );

  const experienceOptionProps = useMemo(
    () => ({
      inputLabel: experienceStaticProps.inputLabel,
      optionProps: formatDynamicOptionProps(
        experienceStaticProps.optionProps,
        setExperienceLevel,
        experienceLevel
      ),
    }),
    [experienceLevel]
  );

  // personality
  const [personality, setPersonality] = useState(
    initPlayerData?.playerGeneralInfo?.personality || null
  );

  const personalityOptionProps = useMemo(
    () => ({
      inputLabel: personalityStaticProps.inputLabel,
      optionProps: formatDynamicOptionProps(
        personalityStaticProps.optionProps,
        setPersonality,
        personality
      ),
    }),
    [personality]
  );

  return {
    data: { description, interestedIn, foot, experienceLevel, personality },

    // extended props
    descriptionProps,
    interestedInOptionProps,
    footOptionProps,
    experienceOptionProps,
    personalityOptionProps,
  };
};

export default useGeneral;
