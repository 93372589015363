import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import ArrowDropdownIcon from '../icons/arrow-drop-down-icon';

import './Menu.scss';

const className = 'c-Menu';

const Menu = ({
  isOpened,
  onOpen,
  onClose,
  label,
  items,
  onSelect,
  selected,
}) => {
  const expandedClassName = isOpened ? `${className}__list__expanded` : '';
  const openedListIconClassName = isOpened
    ? `${className}__icon-wrapper--rotated`
    : `${className}__icon-wrapper`;
  const renderMenu = () => {
    const listItems = Children.toArray(
      items.map(({ value, label }) => {
        const selectedClassName =
          value === selected ? `${className}__item--selected` : '';

        return (
          <li
            className={`${className}__item ${selectedClassName}`}
            onClick={() => onSelect(value)}
          >
            {label}
          </li>
        );
      })
    );

    return (
      <ul className={`${className}__list ${expandedClassName}`}>{listItems}</ul>
    );
  };

  const toggle = e => (isOpened ? onClose() : onOpen(e));

  return (
    <div className={`${className}`}>
      <Button onClick={toggle} narrow variant='no-bg' textType='dark'>
        <div>
          {label()}
          <div className={openedListIconClassName}>
            <ArrowDropdownIcon />
          </div>
        </div>
      </Button>
      {renderMenu()}
    </div>
  );
};

export const MenuPropTypes = {
  isOpened: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  label: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Menu.propTypes = MenuPropTypes;

export default Menu;
