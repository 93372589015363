import { useEventsContext } from 'contexts/events';
import { OFFER_RESOLVED_EVENT } from 'contexts/events/constants';
import { useCallback } from 'react';
import {
  ARRANGE_MATCH_MODE_CONFIRM_MODAL,
  ARRANGE_MATCH_MODE_EDIT_FORM,
  MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL,
  PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL,
  PROPOSE_MATCH_RESULT_MODE_EDIT_FORM,
  RESOLVED_MATCH_MODAL,
  RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL,
} from '../constants';

const useModalControlButtonProps = ({
  matchId,
  createMatch,
  rejectMatch,
  abandonMatch,
  submitCreateMatch,
  openArrangeMatchModeConfirmModal,
  matchData,
  onClose,
  homeTeam,
  awayTeam,
  acceptMatchResult,
  proposeMatchResult,
  openArrangeMatchModeEditForm,

  // modals
  openProposeMatchResultModeConfirmModal,
  openProposeMatchResultModeEditForm,

  // form sections
  // timedate section
  timedateSectionProps,
  acceptMatch,
}) => {
  const { emit } = useEventsContext();

  const emitResolvedOffer = useCallback(
    id => {
      emit(OFFER_RESOLVED_EVENT, id);
    },
    [emit]
  );

  const getModalControlButtonProps = useCallback(
    (key, validated, isAwayTeamViewer, isConfirmDisabled) => {
      const buttons = {
        [ARRANGE_MATCH_MODE_EDIT_FORM]: {
          confirmLabel: 'confirm',
          onConfirm: validated
            ? async () => {
                const validationErros = await submitCreateMatch();
                if (!validationErros) {
                  openArrangeMatchModeConfirmModal();
                }
              }
            : null,
          cancelLabel: 'close',
        },
        [ARRANGE_MATCH_MODE_CONFIRM_MODAL]: {
          confirmLabel: 'confirm',
          onConfirm: async () => {
            let formattedDuration;
            let formattedPlayersNumber;

            try {
              formattedDuration = parseInt(matchData?.durationData?.duration);
              formattedPlayersNumber = parseInt(
                matchData?.playerRulesData?.numOfPlayers
              );
            } catch (err) {
              formattedDuration = undefined;
              formattedPlayersNumber = undefined;
            }
            const formattedData = {
              awayTeam: awayTeam?.id || awayTeam?._id,
              homeTeam: homeTeam?.id,
              city: matchData?.locationFormData?.city,
              country: matchData?.locationFormData?.country,
              location: matchData?.locationFormData?.location,
              customRules: matchData?.customRulesData,
              numOfPlayersInField: formattedPlayersNumber,
              withGoalkeeper: !!matchData?.playerRulesData?.withGoalkeeper,
              duration: formattedDuration,
              proposedTimedates: matchData?.timedateData,
            };
            await createMatch(formattedData, onClose);
          },
          cancelLabel: 'cancel',
          onCancel: () => {
            openArrangeMatchModeEditForm();
          },
        },

        [MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL]: {
          confirmLabel: 'accept',
          // isConfirmDisabled,
          onConfirm:
            timedateSectionProps?.selectedTimeslot && isAwayTeamViewer
              ? () => {
                  acceptMatch(
                    matchId,
                    {
                      ...matchData,
                      matchDate: timedateSectionProps?.selectedTimeslot,
                    },
                    () => {
                      onClose();
                      emitResolvedOffer(matchId);
                    }
                  );
                }
              : null,
          onCancel: () => {
            rejectMatch(matchId, () => {
              onClose();
              emitResolvedOffer(matchId);
            });
            // NEXT-VER (if `Are you sure?` gonna be introduced) openMatchTermsResponseModeResponseMessageModal();
          },
          cancelLabel: 'reject',
        },

        [PROPOSE_MATCH_RESULT_MODE_EDIT_FORM]: {
          confirmLabel: 'confirm',
          isConfirmDisabled,
          onConfirm: () => {
            openProposeMatchResultModeConfirmModal();
          },
          cancelLabel: 'close',
          customButtons: [
            {
              label: 'abandon',
              onClick: () => {
                abandonMatch(matchId, onClose);
              },
            },
          ],
        },
        [PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL]: {
          confirmLabel: 'confirm',
          isConfirmDisabled,
          onConfirm: async () => {
            const formattedPayload = {
              outcome: matchData?.gameResultData?.outcome,
            };

            if (
              matchData?.gameResultData?.homeGoals !== '' &&
              matchData?.gameResultData?.awayGoals !== ''
            ) {
              formattedPayload.homeGoals = matchData?.gameResultData?.homeGoals;
              formattedPayload.awayGoals = matchData?.gameResultData?.awayGoals;
            }

            await proposeMatchResult(matchId, formattedPayload, onClose);
          },
          cancelLabel: 'cancel',
          onCancel: () => {
            openProposeMatchResultModeEditForm();
          },
        },

        [RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL]: {
          confirmLabel: 'accept',
          isConfirmDisabled:
            isConfirmDisabled || !matchData?.gameResultData?.outcome,
          onConfirm: isAwayTeamViewer
            ? async () => {
                await acceptMatchResult(matchId, () => {
                  onClose();
                  emitResolvedOffer(matchId);
                });
              }
            : null,
          cancelLabel: isAwayTeamViewer ? 'abandon' : 'close',
          onCancel: isAwayTeamViewer
            ? () => {
                abandonMatch(matchId, () => {
                  onClose();
                  emitResolvedOffer(matchId);
                });
              }
            : null,
        },
        [RESOLVED_MATCH_MODAL]: {
          cancelLabel: 'close',
        },
      };

      return buttons[key];
    },
    [
      timedateSectionProps?.selectedTimeslot,
      submitCreateMatch,
      openArrangeMatchModeConfirmModal,
      awayTeam?.id,
      awayTeam?._id,
      homeTeam?.id,
      matchData,
      createMatch,
      onClose,
      openArrangeMatchModeEditForm,
      acceptMatch,
      matchId,
      emitResolvedOffer,
      rejectMatch,
      openProposeMatchResultModeConfirmModal,
      abandonMatch,
      proposeMatchResult,
      openProposeMatchResultModeEditForm,
      acceptMatchResult,
    ]
  );

  return {
    getModalControlButtonProps,
  };
};

export default useModalControlButtonProps;
