import Cluster11Image from 'common/components/cluster-11-image';
import { BLUE_STARS_LOGO } from 'common/components/cluster-11-image/constants';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import style from './LogoIconLink.module.scss';

const LogoIconLink = ({ to }) => (
  <NavLink to={to}>
    <div className={style['wrapper']}>
      <Cluster11Image iconMode variant={BLUE_STARS_LOGO} />
      <h4>cluster-11</h4>
    </div>
  </NavLink>
);

LogoIconLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default LogoIconLink;
