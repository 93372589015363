import { apiPost, apiPatch, apiDelete } from './apiWrapper';
import { CLUB_MEMBERSHIPS_ENDPOINT, TRANSFERS_ENDPOINT } from './constants';

export const makeTransferOfferService = apiPost(TRANSFERS_ENDPOINT);

export const transferOfferResponseService = (id, data) =>
  apiPatch(`${TRANSFERS_ENDPOINT}/${id}`)(data);

export const cancelTransferOfferService = id =>
  apiDelete(`${TRANSFERS_ENDPOINT}/${id}`);

export const endClubMembershipService = data =>
  apiPatch(CLUB_MEMBERSHIPS_ENDPOINT)(data);
