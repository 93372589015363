import Button from 'common/components/button';
import { BUTTON_MAIN_ON_SURFACE_VARIANT } from 'common/components/button/constants';
import PropTypes from 'prop-types';
import style from './GameEventConfirmScreen.module.scss';

const GameEventConfirmScreen = ({ showGamePreview, buttonLabel }) => (
  <div className={style.wrapper}>
    <Button
      onClick={showGamePreview}
      rounded
      variant={BUTTON_MAIN_ON_SURFACE_VARIANT}
    >
      {buttonLabel}
    </Button>
  </div>
);

GameEventConfirmScreen.defaultProps = {
  showGamePreview: PropTypes.func.isRequired,
  buttonLabel: 'SHOW GAME PREVIEW',
};

GameEventConfirmScreen.propTypes = {
  showGamePreview: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default GameEventConfirmScreen;
