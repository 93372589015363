import { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  BUTTON_REGULAR_VARIANT,
  BUTTON_LIGHT_VARIANT,
  BUTTON_DARK_BLACK_VARIANT,
} from 'common/components/button/constants';
import './ButtonRippleEffect.scss';

const className = 'c-ButtonRippleEffect';

const ButtonRippleEffect = ({
  children,
  onClickHandler,
  narrow,
  variant,
  standardWidth,
}) => {
  const buttonRef = useRef();
  const [rippleStyle, setRippleStyle] = useState({});
  const [count, setCount] = useState(0);

  const cleanUp = useCallback(() => {
    setRippleStyle({});
  }, []);

  /* Debounce Code to call the Ripple removing function */
  const callCleanUp = useCallback((cleanup, delay) => {
    const bounce = setTimeout(() => {
      cleanup();
      clearTimeout(bounce);
    }, delay);
  }, []);

  const showRipple = useCallback(
    e => {
      const rippleContainer = e.currentTarget;
      const size = rippleContainer.offsetWidth;
      const pos = rippleContainer.getBoundingClientRect();
      const x = e.pageX - pos.x - size / 2;
      const y = e.pageY - pos.y - size / 2;

      const spanStyles = {
        top: y + 'px',
        left: x + 'px',
        height: size + 'px',
        width: size + 'px',
      };
      const nextCount = count + 1;

      setRippleStyle(curr => ({ ...curr, [nextCount]: spanStyles }));
      setCount(nextCount);
    },
    [count]
  );

  const renderRippleSpan = useCallback(() => {
    const spanArray = Object.keys(rippleStyle);
    if (spanArray && spanArray.length > 0) {
      return spanArray.map((key, index) => {
        return (
          <span
            key={'spanCount_' + index}
            className=''
            style={{ ...rippleStyle[key] }}
          ></span>
        );
      });
    } else {
      return null;
    }
  }, [rippleStyle]);

  const narrowClassName = narrow ? `${className}--narrow` : '';
  const standardWidthClassName = standardWidth
    ? `${className}--standard-width`
    : '';

  return (
    <div
      ref={buttonRef}
      className={`${className} ${className}__ripple ${narrowClassName} ${standardWidthClassName}`}
      onClick={onClickHandler}
    >
      {children}
      <div
        className={`${className}__rippleContainer ${className}__rippleContainer--${variant}`}
        onMouseDown={showRipple}
        onMouseUp={callCleanUp(cleanUp, 2000)}
      >
        {renderRippleSpan()}
      </div>
    </div>
  );
};

ButtonRippleEffect.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  narrow: PropTypes.bool,
  standardWidth: PropTypes.bool,
  variant: PropTypes.oneOf([
    BUTTON_REGULAR_VARIANT,
    BUTTON_LIGHT_VARIANT,
    BUTTON_DARK_BLACK_VARIANT,
  ]),
};

export default ButtonRippleEffect;
