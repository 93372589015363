import {
  formatFormWithoutValidation,
  validateForm,
  validateFormSync,
} from 'common/utils/validations';
import { useMemo, useReducer, useState, useCallback } from 'react';
import { getDefaultDateFormattedString } from 'common/utils/formatting';
import { BUTTON_SELECTORS, VALIDATION_FAILED } from './constants';

const CHANGE_FIELD_VALUE = 'CHANGE_FIELD_VALUE';
const VALIDATE_FIELDS = 'VALIDATE_FIELDS';
const CLEAR_FIELDS = 'CLEAR_FIELDS';
const REFRESH_FIELDS = 'REFRESH_FIELDS';

const getFormReducer = initState => (state, action) => {
  switch (action.type) {
    case CHANGE_FIELD_VALUE:
      return Object.assign({}, state, {
        [action.key]: { value: action.value, error: action.error },
      });
    case VALIDATE_FIELDS:
      return Object.assign({}, state, {
        [action.key]: { value: action.value, error: action.error },
      });
    case CLEAR_FIELDS:
      return initState;
    case REFRESH_FIELDS:
      return action.payload;
    default:
      return state;
  }
};

const useForm = (
  initState,
  formFields,
  schema,
  service,
  keepData,
  skipValidation,
  syncValidation
) => {
  const [formState, dispatch] = useReducer(
    getFormReducer(initState),
    initState
  );
  const [calendarOpened, setCalendarOpened] = useState(false);

  const closeCalendar = useCallback(() => {
    setCalendarOpened(false);
  }, []);

  const onFieldChange = useCallback(
    key => e => {
      dispatch({ type: CHANGE_FIELD_VALUE, value: e.target.value, key });
    },
    []
  );

  const selectButtonSelectorOption = useCallback(
    (value, key) => e => {
      e.preventDefault();
      dispatch({ type: CHANGE_FIELD_VALUE, value, key });
    },
    []
  );

  const refreshFormFieldValues = useCallback(payload => {
    dispatch({ type: REFRESH_FIELDS, payload });
  }, []);

  const resetForm = useCallback(() => {
    dispatch({ type: CLEAR_FIELDS });
  }, []);

  const validate = useMemo(
    () => validateForm(formState, schema, dispatch, VALIDATE_FIELDS),
    [formState, schema]
  );

  const validateSync = useMemo(
    () => validateFormSync(formState, schema, dispatch, VALIDATE_FIELDS),
    [formState, schema]
  );

  const submit = useCallback(
    async e => {
      e?.preventDefault();
      const validatedFormData = skipValidation
        ? formatFormWithoutValidation(formState)
        : syncValidation
        ? validateSync(formState)
        : await validate(formState);

      if (validatedFormData) {
        service(validatedFormData);
        if (!keepData) {
          dispatch({ type: CLEAR_FIELDS });
        }
      } else {
        return VALIDATION_FAILED;
      }
    },
    [
      skipValidation,
      formState,
      syncValidation,
      validateSync,
      validate,
      service,
      keepData,
    ]
  );

  const getDateInputProps = useCallback(
    ({ key, label, type }) => ({
      name: key,
      label,
      type,
      value: formState[key]?.value,
      error: formState[key]?.error,
      valueToRender: getDefaultDateFormattedString(formState[key]?.value),
      onOpen: () => {
        setCalendarOpened(true);
      },
      isOpen: calendarOpened,
      onClose: closeCalendar,
      onConfirm: value => {
        dispatch({
          type: CHANGE_FIELD_VALUE,
          key,
          value: value?.dateObj,
          valueToSend: value?.numericDateString,
        });
        closeCalendar();
      },
    }),
    [calendarOpened, closeCalendar, formState]
  );

  const getSelectInputProps = useCallback(
    ({ key, label, type, options }) => ({
      name: key,
      label,
      type,
      options,
      value: formState?.[key]?.value,
      error: formState?.[key]?.error,
      onChange: onFieldChange(key),
    }),
    [formState, onFieldChange]
  );

  const getButtonSelectorsInputProps = useCallback(
    ({ key, label, type, options }) => ({
      name: key,
      label,
      type,
      options: options?.map(({ value, text }) => ({
        onClick: selectButtonSelectorOption(value, key),
        text,
        isSelected: value === formState?.[key]?.value,
      })),
      value: formState?.[key]?.value,
      error: formState?.[key]?.error,
      onChange: onFieldChange(key),
    }),
    [formState, onFieldChange, selectButtonSelectorOption]
  );

  const getInputProps = useCallback(
    () =>
      formFields.map(({ key, label, type, options, ...customProps }) => {
        if (type === 'date') {
          return getDateInputProps({ key, label, type });
        } else if (type === 'select') {
          return getSelectInputProps({ key, label, type, options });
        } else if (type === BUTTON_SELECTORS) {
          return getButtonSelectorsInputProps({ key, label, type, options });
        } else {
          return {
            name: key,
            label,
            type,
            ...customProps,
            value: formState?.[key]?.value,
            error: formState?.[key]?.error,
            onChange: onFieldChange(key),
          };
        }
      }),
    [
      formFields,
      formState,
      getButtonSelectorsInputProps,
      getDateInputProps,
      getSelectInputProps,
      onFieldChange,
    ]
  );

  return {
    getInputProps,
    submit,
    resetForm,
    refreshFormFieldValues,
  };
};

export default useForm;
