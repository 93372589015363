import { formatAsMonthDayString } from 'common/utils/dates';
import {
  SELECT_TIMESLOT_LABEL,
  TIMEDATE_LABEL,
} from 'features/match/constants';
import { splitTimeslotIntoHoursMinutesPair } from 'features/match/utils';
import PropTypes from 'prop-types';
import { Children, useCallback } from 'react';
import style from './ChooseTimeslot.module.scss';

const ChooseTimeslot = ({ data, selectedItem, onSelect }) => {
  const dayKeys = Object.keys(data);

  const handleSelectingItem = useCallback(
    (dayKey, timeslot) => {
      onSelect({
        dayKey,
        timeslot,
      });
    },
    [onSelect]
  );

  const renderDay = (key, item) => {
    const timeslotsToRender = Children.toArray(
      item?.timeslots?.map(timeslot => {
        const [hours, minutes] = splitTimeslotIntoHoursMinutesPair(timeslot);

        const isSelectedItem =
          selectedItem?.dayKey === key && selectedItem?.timeslot === timeslot;

        const activeClassName = isSelectedItem ? style.selectedTimeslot : '';

        return (
          <li
            className={`${style.timeslot} ${activeClassName}`}
            onClick={() => handleSelectingItem(key, timeslot)}
          >{`${hours}:${minutes}`}</li>
        );
      })
    );

    return (
      <div className={style.days}>
        <label className={style.dateLabel}>
          {formatAsMonthDayString(item?.date)}
        </label>
        <ul>{timeslotsToRender}</ul>
      </div>
    );
  };

  const daysToRender = Children.toArray(
    dayKeys?.map(key => renderDay(key, data?.[key]))
  );

  return (
    <div className={style.wrapper}>
      <label className={style.label}>
        {TIMEDATE_LABEL}{' '}
        <span className={style.labelNote}>{SELECT_TIMESLOT_LABEL}</span>
      </label>
      {daysToRender}
    </div>
  );
};

export const SelectedTimeslotItemPropTypes = {
  dayKey: PropTypes.string.isRequired,
  timeslot: PropTypes.string.isRequired,
};

ChooseTimeslot.propTypes = {
  data: PropTypes.object,
  selectedItem: PropTypes.shape(SelectedTimeslotItemPropTypes),
  onSelect: PropTypes.func.isRequired,
};

export default ChooseTimeslot;
