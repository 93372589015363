import Button, { BUTTON_PRIMARY_TEXT_TYPE } from 'common/components/button';
import PropTypes from 'prop-types';
import style from './SignUpNowCTA.module.scss';

const SignUpNowCTA = ({ onClick, label }) => (
  <div className={style.wrapper}>
    <Button
      withRipples
      variant={BUTTON_PRIMARY_TEXT_TYPE}
      narrow
      onClick={onClick}
    >
      {label}
    </Button>
  </div>
);

SignUpNowCTA.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default SignUpNowCTA;
