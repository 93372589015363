import {
  BLUE_NEON_COLOR,
  GREEN_NEON_COLOR,
  VIOLET_NEON_COLOR,
  YELLOW_NEON_COLOR,
} from './general';
import {
  ATTACKING_POSITION_AREA,
  DEFENSE_POSITION_AREA,
  GOALKEEPER_POSITION_AREA,
  MIDFIELD_POSITION_AREA,
} from 'features/player-form/constants';

export const YELLOW_NEON = YELLOW_NEON_COLOR;
export const BLUE_NEON = BLUE_NEON_COLOR;
export const GREEN_NEON = GREEN_NEON_COLOR;
export const VIOLET_NEON = VIOLET_NEON_COLOR;

export const userColors = [YELLOW_NEON, BLUE_NEON, GREEN_NEON, VIOLET_NEON];

export const DEFAULT_USER_IMAGE =
  'https://c-11-user-images.s3.me-central-1.amazonaws.com/default-user-img.png';

export const PlayerExperienceTypes = {
  BEGINNER: 'Beginner',
  AMATEUR: 'Amateur',
  SEMI_PRO: 'Semi-Pro',
  PROFESSIONAL: 'Professional',
  INTERNATIONAL: 'International',
  WORLD_CLASS: 'World Class',
};

export const PLAYER_EXPERIENCE_LEVELS = [
  PlayerExperienceTypes.BEGINNER,
  PlayerExperienceTypes.AMATEUR,
  PlayerExperienceTypes.SEMI_PRO,
  PlayerExperienceTypes.PROFESSIONAL,
  PlayerExperienceTypes.INTERNATIONAL,
  PlayerExperienceTypes.WORLD_CLASS,
];

export const NUMERICAL_PLAYER_EXPERIENCE_LEVELS = {
  [PlayerExperienceTypes.BEGINNER]: 1,
  [PlayerExperienceTypes.AMATEUR]: 2,
  [PlayerExperienceTypes.SEMI_PRO]: 3,
  [PlayerExperienceTypes.PROFESSIONAL]: 4,
  [PlayerExperienceTypes.INTERNATIONAL]: 5,
  [PlayerExperienceTypes.WORLD_CLASS]: 6,
};

export const POSITION_AREA_TYPE_ICONS = {
  [GOALKEEPER_POSITION_AREA]: 'home',
  [DEFENSE_POSITION_AREA]: 'shield',
  [MIDFIELD_POSITION_AREA]: 'share',
  [ATTACKING_POSITION_AREA]: 'focus',
};
