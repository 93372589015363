/* eslint-disable max-len */
import ChartIcon from 'common/components/icons/chart-icon';
import ChartWithOverlineIcon from 'common/components/icons/chart-with-overline-icon';
import InfinityIcon from 'common/components/icons/infinity-icon';
import { EMPTY_INPUT_VALUE } from 'common/constants/forms';

const baseAssetsPath = '/assets';
const s3Assets = 'https://c-11-app-assets.s3.me-central-1.amazonaws.com';

export const FEATURE_STATUS_ACTIVE = 'active';
export const FEATURE_STATUS_COMING_SOON = 'coming soon';

export const staticProps = {
  banner: {
    coverImg: {
      alt: 'field',
      mobileSrc: [
        {
          srcSet: `${baseAssetsPath}/webp/clusterii-heroimage-mobile.webp`,
          type: 'image/webp',
        },
        {
          srcSet: `${baseAssetsPath}/png/clusterii-heroimage-mobile.png`,
          type: 'image/png',
        },
      ],
      desktopSrc: [
        {
          srcSet: `${baseAssetsPath}/webp/clusterii-heroimage-desktop.webp`,
          type: 'image/webp',
          media: '(min-width: 576px)',
        },
        {
          srcSet: `${baseAssetsPath}/png/clusterii-heroimage-desktop.png`,
          type: 'image/png',
          media: '(min-width: 576px)',
        },
      ],
    },
    logoImg: {
      alt: 'logo',
      mobileSrc: [
        // {
        //   srcSet: `${baseAssetsPath}/webp/clusterii-heroimage-mobile.webp`,
        //   type: 'image/webp',
        // },
        {
          srcSet: `${baseAssetsPath}/png/logo-v2-blue-stars.png`,
          type: 'image/png',
        },
      ],
      desktopSrc: [
        // {
        //   srcSet: `${baseAssetsPath}/webp/clusterii-heroimage-desktop.webp`,
        //   type: 'image/webp',
        //   media: '(min-width: 576px)',
        // },
        {
          srcSet: `${baseAssetsPath}/png/logo-v2-blue-stars.png`,
          type: 'image/png',
          media: '(min-width: 576px)',
        },
      ],
    },
  },
  mainMessageSection: {
    mobile: [
      {
        text: `Join the future of football game organizing and connect with a community of football enthusiasts.`,
        isNewLine: true,
      },
    ],
    desktop: [
      {
        text: `Join the future of football game organizing and connect with a community of football enthusiasts.`,
        isNewLine: true,
      },
    ],
  },
  infoSectionV2: [
    {
      bgImg: {
        alt: 'empty field',
        mobileSrc: [
          {
            srcSet: `${s3Assets}/webp/empty-football-field-inf-section.webp`,
            type: 'image/webp',
            media: '(min-width: 120px)',
          },
          {
            srcSet: `${s3Assets}/jpg/empty-football-field-inf-section.jpg`,
            type: 'image/jpg',
            media: '(min-width: 120px)',
          },
        ],
        desktopSrc: [
          {
            srcSet: `${s3Assets}/webp/empty-football-field-inf-section.webp`,
            type: 'image/webp',
            media: '(min-width: 400px)',
          },
          {
            srcSet: `${s3Assets}/jpg/empty-football-field-inf-section.jpg`,
            type: 'image/jpg',
            media: '(min-width: 400px)',
          },
        ],
      },
      title: { mainText: [{ text: 'Create Game' }] },
      description: `Finding pickup football games has never been easier.`,
      smallBubbleText: `Our platform allows you to easily find players nearby, create and join events, and track attendance, so you can spend more time playing and less time organizing.`,
      graphicImg: {
        alt: 'field',
        mobileSrc: [
          {
            srcSet: `${s3Assets}/webp/play-match.webp`,
            type: 'image/webp',
          },
          {
            srcSet: `${s3Assets}/jpg/play-match.jpg`,
            type: 'image/jpg',
          },
        ],
        desktopSrc: [
          {
            srcSet: `${s3Assets}/webp/play-match.webp`,
            type: 'image/webp',
            media: '(min-width: 576px)',
          },
          {
            srcSet: `${s3Assets}/jpg/play-match.jpg`,
            type: 'image/jpg',
            media: '(min-width: 576px)',
          },
        ],
      },
    },
    {
      bgImg: {
        alt: 'football field',
        mobileSrc: [
          {
            srcSet: `${s3Assets}/webp/football-field-section-default.webp`,
            type: 'image/webp',
            media: '(min-width: 120px)',
          },
          {
            srcSet: `${s3Assets}/jpg/football-field-section-default.jpg`,
            type: 'image/jpg',
            media: '(min-width: 120px)',
          },
        ],
        desktopSrc: [
          {
            srcSet: `${s3Assets}/webp/football-field-section-default.webp`,
            type: 'image/webp',
            media: '(min-width: 400px)',
          },
          {
            srcSet: `${s3Assets}/jpg/football-field-section-default.jpg`,
            type: 'image/jpg',
            media: '(min-width: 400px)',
          },
        ],
      },
      title: {
        mainText: [{ text: 'Start Your Team' }],
      },
      description: `cluster-11 makes it simple to create and organize games against other teams.`,
      smallBubbleText: `You can build your team, invite players and manage your team's events, schedule games with other teams, and communicate with them, all in one place`,

      graphicImg: {
        alt: 'field',
        mobileSrc: [
          {
            srcSet: `${s3Assets}/webp/create-club.webp`,
            type: 'image/webp',
          },
          {
            srcSet: `${s3Assets}/jpg/create-club.jpg`,
            type: 'image/jpg',
          },
        ],
        desktopSrc: [
          {
            srcSet: `${s3Assets}/webp/create-club.webp`,
            type: 'image/webp',
            media: '(min-width: 576px)',
          },
          {
            srcSet: `${s3Assets}/jpg/create-club.jpg`,
            type: 'image/jpg',
            media: '(min-width: 576px)',
          },
        ],
      },
    },
    {
      bgImg: {
        alt: 'many fields',
        mobileSrc: [
          {
            srcSet: `${s3Assets}/webp/many-football-fields-info-section.webp`,
            type: 'image/webp',
            media: '(min-width: 120px)',
          },
          {
            srcSet: `${s3Assets}/jpg/many-football-fields-info-section.jpg`,
            type: 'image/jpg',
            media: '(min-width: 120px)',
          },
        ],
        desktopSrc: [
          {
            srcSet: `${s3Assets}/webp/many-football-fields-info-section.webp`,
            type: 'image/webp',
            media: '(min-width: 400px)',
          },
          {
            srcSet: `${s3Assets}/jpg/many-football-fields-info-section.jpg`,
            type: 'image/jpg',
            media: '(min-width: 400px)',
          },
        ],
      },
      title: {
        mainText: [{ text: 'Your Player Profile' }],
      },
      description: `You can showcase your skills, connect with other players, join teams, and get invited to games.`,
      smallBubbleText: `Find and join games that match your skill level and availability. Join games with players from different communities and challenge yourself with different styles of play and improve your skills.`,
      graphicImg: {
        alt: 'field',
        mobileSrc: [
          {
            srcSet: `${s3Assets}/webp/create-leagues.webp`,
            type: 'image/webp',
          },
          {
            srcSet: `${s3Assets}/jpg/create-leagues.jpg`,
            type: 'image/jpg',
          },
        ],
        desktopSrc: [
          {
            srcSet: `${s3Assets}/webp/create-leagues.webp`,
            type: 'image/webp',
            media: '(min-width: 576px)',
          },
          {
            srcSet: `${s3Assets}/jpg/create-leagues.jpg`,
            type: 'image/jpg',
            media: '(min-width: 576px)',
          },
        ],
      },
    },
    {
      bgImg: {
        alt: 'city lights',
        mobileSrc: [
          {
            srcSet: `${s3Assets}/webp/city-lights-info-section.webp`,
            type: 'image/webp',
            media: '(min-width: 120px)',
          },
          {
            srcSet: `${s3Assets}/jpg/city-lights-info-section.jpg`,
            type: 'image/jpg',
            media: '(min-width: 120px)',
          },
        ],
        desktopSrc: [
          {
            srcSet: `${s3Assets}/webp/city-lights-info-section.webp`,
            type: 'image/webp',
            media: '(min-width: 400px)',
          },
          {
            srcSet: `${s3Assets}/jpg/city-lights-info-section.jpg`,
            type: 'image/jpg',
            media: '(min-width: 400px)',
          },
        ],
      },
      title: { mainText: [{ text: 'Cluster Of Football Communities' }] },
      description: 'All pickup groups, teams, players, matches at one place!',
      smallBubbleText: `Get access to all local football communities, connect with more players, join more games and events, and expand your opportunities to play and compete.`,

      graphicImg: {
        alt: 'field',
        mobileSrc: [
          {
            srcSet: `${s3Assets}/webp/football-worlds.webp`,
            type: 'image/webp',
          },
          {
            srcSet: `${s3Assets}/jpg/football-worlds.jpg`,
            type: 'image/jpg',
          },
        ],
        desktopSrc: [
          {
            srcSet: `${s3Assets}/webp/football-worlds.webp`,
            type: 'image/webp',
            media: '(min-width: 576px)',
          },
          {
            srcSet: `${s3Assets}/jpg/football-worlds.jpg`,
            type: 'image/jpg',
            media: '(min-width: 576px)',
          },
        ],
      },
    },
  ],
  playModesSection: {
    title: 'PLAY MODES',
    cards: [
      {
        Icon: InfinityIcon,
        name: 'Pickup games',
        mode: FEATURE_STATUS_ACTIVE,
        description:
          'Join one of many communities. Play at the skill level you preffer and at the time that works best for you.',
      },
      {
        Icon: ChartIcon,
        name: 'Team vs Team',
        mode: FEATURE_STATUS_ACTIVE,
        description:
          'Start your own team. Join the "team captain communities" and arrange games between teams.',
      },
      {
        Icon: ChartWithOverlineIcon,
        name: 'Pro',
        mode: FEATURE_STATUS_COMING_SOON,
        description:
          'Get competitive, earn points & reputation. Move up the levels to compete with players & teams with higher ranks.',
      },
    ],
  },
  nftSection: {
    img: {
      alt: 'nft',
      mobileSrc: [
        {
          srcSet: `${baseAssetsPath}/webp/neon-beams.webp`,
          type: 'image/webp',
        },
        {
          srcSet: `${baseAssetsPath}/png/clusterii-NFTbgimage-desktop.png`,
          type: 'image/png',
        },
      ],
      desktopSrc: [
        {
          srcSet: `${baseAssetsPath}/webp/neon-beams.webp`,
          type: 'image/webp',
          media: '(min-width: 576px)',
        },
        {
          srcSet: `${baseAssetsPath}/png/clusterii-NFTbgimage-desktop.png`,
          type: 'image/png',
          media: '(min-width: 576px)',
        },
      ],
    },
    title: 'YOUR ',
    dynamicTitles: ['CLUB', 'LEAGUE', 'ASSOCIATION'],
    subtitle: 'Is Non Fungible Token (NFT).',
    text: 'The Future Is Decentralized. Football Is What We Create.',
  },
  ctaSection: {
    img: {
      alt: 'bird eye field',
      mobileSrc: [
        {
          srcSet: `${s3Assets}/webp/field-bird-eye.webp`,
          type: 'image/webp',
        },
        {
          srcSet: `${s3Assets}/jpg/field-bird-eye.jpg`,
          type: 'image/jpg',
        },
      ],
      desktopSrc: [
        {
          srcSet: `${s3Assets}/webp/field-bird-eye.webp`,
          type: 'image/webp',
          media: '(min-width: 576px)',
        },
        {
          srcSet: `${s3Assets}/jpg/field-bird-eye.jpg`,
          type: 'image/jpg',
          media: '(min-width: 576px)',
        },
      ],
    },
    title: 'Take your football experience to the next level',
    subtitle: 'And join the largest network of local football communities!',
  },
};

const emailFormField = {
  key: 'email',
  label: 'email',
  type: 'email',
};
const passwordFormField = {
  key: 'password',
  label: 'password',
  type: 'password',
};

export const LOGIN_MODE = 'LOGIN_MODE';
export const VALIDATE_ACCOUNT_MODE = 'VALIDATE_ACCOUNT_MODE';
export const RESET_PASSWORD_MODE = 'RESET_PASSWORD_MODE';

export const formFields = {
  [LOGIN_MODE]: [emailFormField, passwordFormField],
  [VALIDATE_ACCOUNT_MODE]: [emailFormField],
  [RESET_PASSWORD_MODE]: [emailFormField],
};

export const loginModalDynamicTitle = {
  [LOGIN_MODE]: [{ text: 'Login' }],
  [VALIDATE_ACCOUNT_MODE]: [{ text: 'Validate Account' }],
  [RESET_PASSWORD_MODE]: [{ text: 'Reset Password' }],
};

export const initLoginState = {
  email: { ...EMPTY_INPUT_VALUE },
  password: { ...EMPTY_INPUT_VALUE },
};

export const onSignUpLabel = 'sign up now';
export const loginLabel = 'login';
export const validateAccountLabel = 'validate account';
export const resetPasswordLabel = 'forgot password?';
