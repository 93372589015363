import { useLayout } from '../layout/core';
import { ThemeModeComponent } from '../assets/ts/layout';

// NOTE for Metronic assets
export const toAbsoluteUrl = pathname => '/assets/' + pathname;

export const useIllustrationsPath = illustrationName => {
  const { config } = useLayout();

  const extension = illustrationName.substring(
    illustrationName.lastIndexOf('.'),
    illustrationName.length
  );
  const illustration =
    ThemeModeComponent.getMode() === 'dark'
      ? `${illustrationName.substring(
          0,
          illustrationName.lastIndexOf('.')
        )}-dark`
      : illustrationName.substring(0, illustrationName.lastIndexOf('.'));
  return toAbsoluteUrl(
    `media/illustrations/${config.illustrations?.set}/${illustration}${extension}`
  );
};
