import { createContext, useContext } from 'react';

const PublicNavContext = createContext();

// LEGACY - decide if still needed
export const PublicNavContextProvider = ({ children }) => {
  const value = {};

  return (
    <PublicNavContext.Provider value={value}>
      {children}
    </PublicNavContext.Provider>
  );
};

export const usePublicNavContext = () => useContext(PublicNavContext);
