import PropTypes from 'prop-types';
import Text, { TextPropTypes } from 'common/components/text';
import style from './MainMessageSection.module.scss';

const MainMessageSection = ({ text }) => (
  <div className={style.wrapper}>
    <h1 className={style.title}>cluster-11</h1>
    <div className={style.textWrapper}>
      <Text data={text} />
    </div>
  </div>
);

MainMessageSection.propTypes = {
  text: PropTypes.shape(TextPropTypes),
};

export default MainMessageSection;
