// import Labs from 'pages/labs';
import PublicActionPage from 'pages/public-action-page';
import {
  RESET_PASSWORD_PUBLIC_PAGE_TYPE,
  VALIDATE_ACCOUNT_PUBLIC_PAGE_TYPE,
} from 'pages/public-action-page/constants';
import PublicStaticPage from 'pages/public-static-page';
import {
  CONTACT_STATIC_PAGE_TYPE,
  OUR_VISION_STATIC_PAGE_TYPE,
  WHO_ARE_WE_STATIC_PAGE_TYPE,
} from 'pages/public-static-page/constants';
import {
  CONTACT_PAGE,
  OUR_VISION_PAGE,
  RESET_PASSWORD_PUBLIC_PAGE_PATH,
  VALIDATE_ACCOUNT_PUBLIC_PAGE_PATH,
  WHO_ARE_WE_PAGE,
} from './constants';

const commonPages = [
  // {
  //   // DEVS
  //   path: '/labs',
  //   element: <Labs />,
  // },
  {
    path: VALIDATE_ACCOUNT_PUBLIC_PAGE_PATH,
    element: <PublicActionPage type={VALIDATE_ACCOUNT_PUBLIC_PAGE_TYPE} />,
  },
  {
    path: RESET_PASSWORD_PUBLIC_PAGE_PATH,
    element: <PublicActionPage type={RESET_PASSWORD_PUBLIC_PAGE_TYPE} />,
  },
  {
    path: CONTACT_PAGE,
    element: <PublicStaticPage type={CONTACT_STATIC_PAGE_TYPE} />,
  },
  {
    path: WHO_ARE_WE_PAGE,
    element: <PublicStaticPage type={WHO_ARE_WE_STATIC_PAGE_TYPE} />,
  },
  {
    path: OUR_VISION_PAGE,
    element: <PublicStaticPage type={OUR_VISION_STATIC_PAGE_TYPE} />,
  },
];

export default commonPages;
