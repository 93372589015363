/* eslint-disable max-len */
import style from './EventInputWarningMark.module.scss';

const EventInputWarningMark = () => (
  <div className={style.wrapper}>
    <svg height='24px' viewBox='0 0 24 24' width='24px' fill='#93000a'>
      <path d='M0 0h24v24H0z' fill='none' />
      <path
        fill='#93000a'
        d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'
      />
    </svg>
  </div>
);

export default EventInputWarningMark;
