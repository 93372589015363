import { REACT_GA_TOGGLE_NOTIFICATIONS_EVENT } from 'common/constants/react-google-analytics/events';
import { useUserSessionContext } from 'contexts/user-session';
import ReactGA from 'react-ga';

const { createContext, useContext, useState, useCallback } = require('react');

const PrivateNavContext = createContext();

export const PrivateNavContextProvider = ({ children }) => {
  const { sendLastReadNotificationUpdate } = useUserSessionContext();
  const [linksDropdownOpened, setIsLinksDropdownOpened] = useState(false);
  const [userDropdownOpened, setIsUserDropdownOpened] = useState(false);
  const [userRoleDropdownOpened, setUserRoleDropdownOpened] = useState(false);
  const [notificationsDropdownOpened, setNotificationsDropdownOpened] =
    useState(false);
  const [searchOpened, setSearchOpened] = useState(false);
  const [offersAsideOpened, setOffersAsideOpened] = useState(false);

  const openSearch = useCallback(() => {
    setSearchOpened(true);
  }, []);

  const closeSearch = useCallback(() => {
    setSearchOpened(false);
  }, []);

  const closeNotificationsDropdown = useCallback(() => {
    setNotificationsDropdownOpened(false);
  }, []);

  const toggleLinksDropdown = e => {
    e.stopPropagation();
    if (!linksDropdownOpened) {
      setIsUserDropdownOpened(false);
      setUserRoleDropdownOpened(false);
      closeNotificationsDropdown();
      setOffersAsideOpened(false);
    }

    setIsLinksDropdownOpened(currentState => !currentState);
  };

  const toggleUserDropdown = e => {
    e.stopPropagation();
    if (!userDropdownOpened) {
      setIsLinksDropdownOpened(false);
      setUserRoleDropdownOpened(false);
      closeNotificationsDropdown();
      setOffersAsideOpened(false);
    }
    setIsUserDropdownOpened(currentState => !currentState);
  };

  const toggleUserRoleDropdown = e => {
    e.stopPropagation();
    if (!userRoleDropdownOpened) {
      setIsUserDropdownOpened(false);
      setIsLinksDropdownOpened(false);
      closeNotificationsDropdown();
      setOffersAsideOpened(false);
    }
    setUserRoleDropdownOpened(currentState => !currentState);
  };

  const toggleNotificationsDropdown = useCallback(
    e => {
      e.stopPropagation();

      if (!notificationsDropdownOpened) {
        setIsUserDropdownOpened(false);
        setIsLinksDropdownOpened(false);
        setUserRoleDropdownOpened(false);
        setOffersAsideOpened(false);
      }

      setNotificationsDropdownOpened(currentState => {
        if (currentState) {
          sendLastReadNotificationUpdate();
        }

        ReactGA.event(REACT_GA_TOGGLE_NOTIFICATIONS_EVENT);

        return !currentState;
      });
    },
    [notificationsDropdownOpened, sendLastReadNotificationUpdate]
  );

  // offers
  const closeOffersAside = useCallback(() => {
    setOffersAsideOpened(false);
  }, []);

  const toggleOffersAside = useCallback(
    e => {
      e.stopPropagation();

      if (!offersAsideOpened) {
        setIsLinksDropdownOpened(false);
        setUserRoleDropdownOpened(false);
        setIsUserDropdownOpened(false);
        closeNotificationsDropdown();
      }

      setOffersAsideOpened(currentState => !currentState);
    },
    [closeNotificationsDropdown, offersAsideOpened]
  );

  const closeNavbarDropdowns = useCallback(() => {
    setIsLinksDropdownOpened(false);
    setIsUserDropdownOpened(false);
    setUserRoleDropdownOpened(false);
    closeNotificationsDropdown();
    setOffersAsideOpened(false);
  }, [closeNotificationsDropdown]);

  const value = {
    // links
    linksDropdownOpened,
    toggleLinksDropdown,

    // user dropdown
    userDropdownOpened,
    toggleUserDropdown,

    // user role dropdown
    userRoleDropdownOpened,
    toggleUserRoleDropdown,

    // notifications dropdown
    notificationsDropdownOpened,
    toggleNotificationsDropdown,

    // search dropdown
    searchOpened,
    openSearch,
    closeSearch,

    // offers aside
    closeOffersAside,
    toggleOffersAside,
    offersAsideOpened,

    closeNavbarDropdowns,
  };

  return (
    <PrivateNavContext.Provider value={value}>
      {children}
    </PrivateNavContext.Provider>
  );
};

export const usePrivateNavContext = () => useContext(PrivateNavContext);
