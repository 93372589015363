import {
  CLUBS_PAGE_PATH,
  CONTACT_PAGE,
  GAME_EVENTS_PAGE_PATH,
  MATCHES_PAGE_PATH,
  OUR_VISION_PAGE,
  RESET_PASSWORD_PUBLIC_PAGE_PATH,
  SETTINGS_PATH,
  SIGNUP_PATH,
  USER_PROFILE_BASE_PATH,
  VALIDATE_ACCOUNT_PUBLIC_PAGE_PATH,
  WHO_ARE_WE_PAGE,
} from 'routes/constants';

export const REACT_GA_SIGNUP_PAGE = SIGNUP_PATH;
export const REACT_GA_STREET_AND_LANDING_PAGE = '/';
export const REACT_GA_VALIDATE_ACCOUNT_PUBLIC_PAGE =
  VALIDATE_ACCOUNT_PUBLIC_PAGE_PATH;
export const REACT_GA_RESET_PASSWORD_PUBLIC_PAGE =
  RESET_PASSWORD_PUBLIC_PAGE_PATH;
export const REACT_GA_SETTINGS_PAGE = SETTINGS_PATH;
export const REACT_GA_USER_PROFILE_PAGE = USER_PROFILE_BASE_PATH;
export const REACT_GA_CLUB_PAGE = CLUBS_PAGE_PATH;
export const REACT_GA_MATCH_PAGE = MATCHES_PAGE_PATH;
export const REACT_GA_GAME_EVENT_PAGE = GAME_EVENTS_PAGE_PATH;
export const REACT_GA_CONTACT_PAGE = CONTACT_PAGE;
export const REACT_GA_WHO_ARE_WE_PAGE = WHO_ARE_WE_PAGE;
export const REACT_GA_OUR_VISION_PAGE = OUR_VISION_PAGE;
