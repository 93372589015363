import { updateShortlistService } from 'common/api/shortlistsService';
import { useCallback, useState } from 'react';
import { shortlistUpdateMessages } from './constants';
import { useApi } from 'common/hooks';

const useShortlists = () => {
  const { apiWrapper } = useApi();
  const [shortlistData] = useState([]);

  const updateShortlistData = useCallback(async () => {
    // NOTE not used anymore
  }, []);

  const getShortlistMethodType = useCallback(() => {
    // NOTE should not be used anymore
  }, []);

  // NOTE used only for the updated shortlist API which is to be used for players shortlist only
  const updatePlayersShortlist = useCallback(
    (id, actionType) =>
      apiWrapper(
        async () => {
          await updateShortlistService(id, { actionType });
        },
        shortlistUpdateMessages[actionType],
        null,
        true
      ),
    [apiWrapper]
  );

  return {
    shortlistData,
    updateShortlistData,
    getShortlistMethodType,

    updatePlayersShortlist,
  };
};

export default useShortlists;
