import { KTIcon } from '_metronic/helpers';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SCREEN_CARD_PATTERN_BG } from 'pages/street/constants';

const QuickLinks = ({ title, linkItems }) => {
  const renderLinks = useCallback(() => {
    const items = linkItems.map((item, index) => {
      const oddItemclassName = item.isOdd ? 'border-end' : '';
      const dynamicClassName = item.isActive ? 'bg-hover-light' : 'disabled';

      return (
        <div className='col-6' onClick={item.onClick}>
          <a
            href='#'
            key={index}
            className={`d-flex flex-column flex-center h-100 p-6 border-bottom ${oddItemclassName} ${dynamicClassName}`}
          >
            <KTIcon iconName={item.icon} className='fs-3x text-primary mb-2' />
            <span className='fs-5 fw-semibold text-gray-800 mb-0'>
              {item.title}
            </span>
            <span className='fs-7 text-gray-500'>{item.description}</span>
          </a>
        </div>
      );
    });

    return items;
  }, [linkItems]);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
      data-kt-menu='true'
    >
      <div
        className='d-flex position-relative flex-column flex-center bgi-no-repeat opacity-75 rounded-top px-9 py-10 overflow-hidden'
        style={{
          backgroundImage: `url('${SCREEN_CARD_PATTERN_BG}')`,
        }}
      >
        <h3 className='text-white z-1 fw-semibold mb-3'>{title}</h3>
        <div
          className='rounded-top-4 position-absolute w-100 h-100'
          style={{
            top: 0,
            left: 0,
            opacity: 0.7,
            background: '#1a1c1e',
          }}
        ></div>
      </div>

      <div className='row g-0'>{renderLinks()}</div>

      <div className='py-2 text-center border-top'>
        <Link
          to='/crafted/pages/profile'
          className='btn btn-color-gray-600 btn-active-color-primary'
        >
          View All <KTIcon iconName='arrow-right' className='fs-5' />
        </Link>
      </div>
    </div>
  );
};

QuickLinks.defaultProps = {
  title: 'Quick Actions',
  linkItems: [],
};

QuickLinks.propTypes = {
  title: PropTypes.string,
  linkItems: PropTypes.arrayOf(PropTypes.object),
};

export { QuickLinks };
