import Share from 'features/share';
import {
  GAME_EVENT_SHARE_GROUP,
  MATCH_SHARE_TYPE,
} from 'features/share/constants';
import PropTypes from 'prop-types';
import style from './GameEventShareScreen.module.scss';

const GameEventShareScreen = ({ shareProps }) => (
  <div className={style.wrapper}>
    <h3>SHARE THE GAME</h3>
    <Share
      type={MATCH_SHARE_TYPE}
      data={{}}
      customShareProps={shareProps}
      size='xl'
      shareGroup={GAME_EVENT_SHARE_GROUP}
    />
  </div>
);

GameEventShareScreen.propTypes = {
  shareProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    gameEventId: PropTypes.string.isRequired,
  }),
};

export default GameEventShareScreen;
