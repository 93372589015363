/* eslint-disable max-len */
import Button from 'common/components/button';
import ArrowBackIcon from 'common/components/icons/arrow-back-icon';
import Image from 'common/components/image';
import { formatImageWithWebp } from 'common/utils/formatting';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import style from './EventInputSelectImage.module.scss';

const EventInputSelectImage = ({ inputItems, selectedItem, selectItem }) => {
  const length = inputItems.length;

  const nextSlide = useCallback(() => {
    selectItem(selectedItem === length - 1 ? 0 : selectedItem + 1);
  }, [selectItem, selectedItem, length]);

  const prevSlide = useCallback(() => {
    selectItem(selectedItem === 0 ? length - 1 : selectedItem - 1);
  }, [selectItem, selectedItem, length]);

  const renderImages = useMemo(
    () =>
      inputItems?.map((slide, index) => (
        <div
          className={
            index === selectedItem ? style['slide--active'] : style['slide']
          }
          key={index}
        >
          {index === selectedItem && (
            <Image {...formatImageWithWebp(slide.image)} />
          )}
        </div>
      )),
    [inputItems, selectedItem]
  );

  return (
    <section className={style.slider}>
      <div className={style.leftArrow}>
        <Button onClick={prevSlide} className={style.leftArrow}>
          <ArrowBackIcon />
        </Button>
      </div>

      <div className={style.imageWrapper}>{renderImages}</div>

      <div className={style.rightArrow}>
        <Button onClick={nextSlide} className={style.rightArrow}>
          <ArrowBackIcon />
        </Button>
      </div>
    </section>
  );
};

const EventInputItemPropTypes = PropTypes.shape({
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    jpgURL: PropTypes.string.isRequired,
    jpgDesktopURL: PropTypes.string.isRequired,
    webpURL: PropTypes.string.isRequired,
    webpDesktopURL: PropTypes.string.isRequired,
  }).isRequired,
});

export const EventInputSelectImagePropTypes = {
  inputItems: PropTypes.arrayOf(EventInputItemPropTypes),
  selectItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.number.isRequired,
};

EventInputSelectImage.propTypes = EventInputSelectImagePropTypes;

export default EventInputSelectImage;
