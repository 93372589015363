import Loading from 'common/components/loading';
import React, { Suspense } from 'react';
import { PublicNavContextProvider } from 'contexts/public-nav';
import { PrivateNavContextProvider } from 'contexts/private-nav';
import { WindowSizeContextProvider } from 'contexts/window-size';
import { MetaContextProvider } from 'contexts/meta';
import { AuthContextProvider } from 'contexts/auth';
import { UserSessionContextProvider } from 'contexts/user-session';
import { EventsContextProvider } from 'contexts/events';
import ErrorBoundary from 'pages/error-boundary';
import AppContainer from './AppContainer';
import CacheBuster from 'react-cache-buster';
import packageJson from '../../package.json';
import { LayoutProvider } from '_metronic/layout/core';
import { ThemeModeProvider } from '_metronic/partials/layout/theme-mode/ThemeModeProvider';
import { MasterInit } from '_metronic/layout/MasterInit';
import { QueryClient, QueryClientProvider } from 'react-query';

// css
import './App.scss';

const isProduction =
  process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging';

const queryClient = new QueryClient();

const App = () => (
  <CacheBuster
    currentVersion={packageJson.version}
    isEnabled={isProduction}
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <Suspense fallback={Loading}>
      <ErrorBoundary>
        <EventsContextProvider>
          <MetaContextProvider>
            <AuthContextProvider>
              <UserSessionContextProvider>
                <WindowSizeContextProvider>
                  <PublicNavContextProvider>
                    <PrivateNavContextProvider>
                      <QueryClientProvider client={queryClient}>
                        <LayoutProvider>
                          <ThemeModeProvider>
                            <MasterInit />
                            <AppContainer />
                          </ThemeModeProvider>
                        </LayoutProvider>
                      </QueryClientProvider>
                    </PrivateNavContextProvider>
                  </PublicNavContextProvider>
                </WindowSizeContextProvider>
              </UserSessionContextProvider>
            </AuthContextProvider>
          </MetaContextProvider>
        </EventsContextProvider>
      </ErrorBoundary>
    </Suspense>
  </CacheBuster>
);

export default App;
