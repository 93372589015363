import { useState } from 'react';

const useError = () => {
  const [gameEventFormError, setGameEventFormError] = useState(false);

  return {
    gameEventFormError,
    setGameEventFormError,
  };
};

export default useError;
