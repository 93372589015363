import { BASE_URL } from '_config';
import { GAME_EVENTS_PAGE_PATH, MATCHES_PAGE_PATH } from 'routes/constants';
import {
  GAME_EVENT_SHARE_TYPE,
  MATCH_SHARE_TYPE,
  TWITTER_ACCOUNT,
} from './constants';

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from 'react-share';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from 'react-share';
import { getGameEventDateItems } from 'common/utils/dates';

export const getMatchUrl = id => `${BASE_URL}${MATCHES_PAGE_PATH}/${id}`;
export const getGameEventURL = id =>
  `${BASE_URL}${GAME_EVENTS_PAGE_PATH}/${id}`;

/*
custom: {
  title, description, url, hashtags
}
*/

export const getMatchShareProps = (match, custom) => {
  const matchPageUrl = custom?.url || getMatchUrl(match?._id);
  const title =
    custom?.title ||
    `c-11 game: ${match?.awayTeam?.name} @ ${match?.homeTeam?.name}`;
  const description = custom?.description || title;
  const hashtags = custom?.hashtags || ['cluster-11', 'football', 'soccer'];

  return [
    {
      url: matchPageUrl,
      subject: title,
      body: description,
      Component: EmailShareButton,
      Icon: EmailIcon,
    },
    {
      url: matchPageUrl,
      quote: title,
      hashtag: hashtags[0],
      Component: FacebookShareButton,
      Icon: FacebookIcon,
    },
    {
      url: matchPageUrl,
      title,
      summary: title,
      source: '',
      Component: LinkedinShareButton,
      Icon: LinkedinIcon,
    },
    {
      url: matchPageUrl,
      title,
      Component: TelegramShareButton,
      Icon: TelegramIcon,
    },
    {
      url: matchPageUrl,
      title,
      via: 'cluster-11',
      hashtags,
      Component: TwitterShareButton,
      Icon: TwitterIcon,
    },
    {
      url: matchPageUrl,
      title,
      Component: ViberShareButton,
      Icon: ViberIcon,
    },
    {
      url: matchPageUrl,
      title,
      Component: WhatsappShareButton,
      Icon: WhatsappIcon,
    },
  ];
};

export const getGameEventShareProps = (event, custom) => {
  const { dayInWeek, time } = getGameEventDateItems(event);

  const url = getGameEventURL(event?._id);
  const title = custom?.title || `football game @ ${event?.location}`;
  const description = `📆 ${dayInWeek} @ ${time}`;
  const hashtags = custom?.hashtags || [
    'cluster-11',
    'football',
    'soccer',
    event.city,
  ];

  return [
    {
      url,
      subject: title,
      body: description,
      Component: EmailShareButton,
      Icon: EmailIcon,
    },
    {
      url,
      quote: title,
      hashtag: hashtags[0],
      Component: FacebookShareButton,
      Icon: FacebookIcon,
    },
    {
      url,
      title,
      summary: title,
      source: '',
      Component: LinkedinShareButton,
      Icon: LinkedinIcon,
    },
    {
      url,
      title,
      Component: TelegramShareButton,
      Icon: TelegramIcon,
    },
    {
      url,
      title,
      via: TWITTER_ACCOUNT,
      hashtags,
      Component: TwitterShareButton,
      Icon: TwitterIcon,
    },
    {
      url,
      title,
      Component: ViberShareButton,
      Icon: ViberIcon,
    },
    {
      url,
      title,
      Component: WhatsappShareButton,
      Icon: WhatsappIcon,
    },
  ];
};

export const getShareProps = ({ type, data, customShareProps }) => {
  switch (type) {
    case MATCH_SHARE_TYPE:
      return getMatchShareProps(data, customShareProps);

    case GAME_EVENT_SHARE_TYPE:
      return getGameEventShareProps(data, customShareProps);
    default:
      return [];
  }
};
