import { useMetaContext } from 'contexts/meta';
import { useCallback, useReducer, useState } from 'react';
import CustomRulesSection from '../components/custom-rules-section';
import {
  ADD_CUSTOM_RULE,
  CUSTOM_RULE_ADDED,
  REMOVE_CUSTOM_RULE,
} from '../constants';

const useCustomRulesProps = () => {
  const { setNeutralToastMessage } = useMetaContext();
  const [customRuleInputValue, setCustomRuleInputValue] = useState('');
  const onCustomRuleInputChange = useCallback(e => {
    setCustomRuleInputValue(e.target.value);
  }, []);

  const [customRules, setCustomRules] = useReducer((state, action) => {
    let newState;
    switch (action.type) {
      case ADD_CUSTOM_RULE:
        newState = new Set(state);
        newState.add(action.payload);
        return newState;
      case REMOVE_CUSTOM_RULE:
        newState = new Set(state);
        newState.delete(action.payload);
        return newState;
      default:
        return state;
    }
  }, new Set([]));

  const addCustomRule = useCallback(() => {
    setCustomRules({ type: ADD_CUSTOM_RULE, payload: customRuleInputValue });
    setNeutralToastMessage(CUSTOM_RULE_ADDED);
  }, [customRuleInputValue, setNeutralToastMessage]);
  const removeCustomRule = useCallback(itemToRemove => {
    setCustomRules({ type: REMOVE_CUSTOM_RULE, payload: itemToRemove });
  }, []);

  const onAddCustomRule = useCallback(() => {
    addCustomRule();
    setCustomRuleInputValue('');
  }, [addCustomRule]);

  return {
    Component: CustomRulesSection,
    customRulesData: [...customRules],
    addCustomRule: onAddCustomRule,
    removeCustomRule,
    customRuleInputValue,
    onCustomRuleInputChange,
  };
};

export default useCustomRulesProps;
