import React, { Children } from 'react';
import Headline, { HeadlineTextDataType } from 'common/components/headline';

import style from './CurrentDayInfo.module.scss';

const CurrentDayInfo = ({ year, date }) => {
  const renderHeadlines = () =>
    Children.toArray(
      [year, date].map(item => (
        <Headline mainText={item} size={3} variant='primary' />
      ))
    );

  return <div className={style.wrapper}>{renderHeadlines()}</div>;
};

CurrentDayInfo.propTypes = {
  year: HeadlineTextDataType,
  date: HeadlineTextDataType,
};

export default CurrentDayInfo;
