import PropTypes from 'prop-types';
import { useCallback } from 'react';
import style from './TabsGroup.module.scss';

const className = 'c-Tabs';

const TabsGroup = ({ tabsData }) => {
  const renderTabs = useCallback(
    () =>
      tabsData?.map(({ label, onClick, isSelected }) => {
        const selectedClassName = isSelected ? style['tab--is-selected'] : '';

        return (
          <button
            className={`${style.tab} ${selectedClassName}`}
            onClick={onClick}
          >
            {label}
          </button>
        );
      }),
    [tabsData]
  );

  return <div className={`${style.wrapper} ${className}`}>{renderTabs()}</div>;
};

const TabPropTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

TabsGroup.defaultProps = {
  tabsData: [],
};

TabsGroup.propTypes = {
  tabsData: PropTypes.arrayOf(TabPropTypes),
};

export default TabsGroup;
