import {
  REACT_GA_IG_LINK,
  REACT_GA_LI_LINK,
  REACT_GA_TW_LINK,
} from 'common/constants/react-google-analytics/events';
import { PRIVACY_POLICY_URL, IG_URL, LI_URL, TW_URL } from '_config';
import { CONTACT_PAGE, OUR_VISION_PAGE } from 'routes/constants';
import InstagramIcon from '../icons/instagram-icon/InstagramIcon';
import LinkedInIcon from '../icons/linkedin-icon';
import TermsAndConditions from '../terms-and-conditions';
import ReactGA from 'react-ga';
import TwitterIcon from '../icons/twitter-icon';

export const columnOneLinks = [
  {
    toRender: () => <TermsAndConditions />,
  },
  {
    toRender: () => (
      <span>
        <a href={PRIVACY_POLICY_URL} target='_blank' rel='noreferrer'>
          Privacy Policy
        </a>
      </span>
    ),
  },
];

export const columnTwoLinks = [
  // {
  //   label: 'The Origin Story',
  //   link: WHO_ARE_WE_PAGE,
  // },
  {
    label: 'Our Dream',
    link: OUR_VISION_PAGE,
  },
  {
    label: 'Contact',
    link: CONTACT_PAGE,
  },
];

export const socialMediaData = [
  {
    Icon: InstagramIcon,
    link: IG_URL || 'https://instagram.com/cluster__11',
    cb: () => {
      ReactGA.event(REACT_GA_IG_LINK);
    },
  },
  {
    Icon: TwitterIcon,
    link: TW_URL || 'https://twitter.com/cluster__11',
    cb: () => {
      ReactGA.event(REACT_GA_TW_LINK);
    },
  },
  {
    Icon: LinkedInIcon,
    link: LI_URL || 'https://www.linkedin.com/company/cluster-11/',
    cb: () => {
      ReactGA.event(REACT_GA_LI_LINK);
    },
  },
];
