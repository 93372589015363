import { createContext, useContext, useState, useCallback } from 'react';
import { EVENTS_LIST } from './constants';

const EventsContext = createContext();

// containers should subscribe on its init

export const EventsContextProvider = ({ children }) => {
  const [eventsList, setEventsList] = useState(EVENTS_LIST);
  /*
    cbDTO = {
      cb: () => {},
      id: 'some-id',
    } or just cb
    // TODO migrate all subscriptions to the new format
  */
  const subscribe = useCallback((eventName, cbDTO) => {
    // add cb to events list of subscribers
    setEventsList(curr => {
      const eventsListCopy = { ...curr };

      if (!eventsListCopy[eventName]) {
        eventsListCopy[eventName] = [];
      }

      eventsListCopy[eventName].push(cbDTO);

      return eventsListCopy;
    });
  }, []);

  const unsubscribe = useCallback((event, id) => {
    setEventsList(curr => {
      const eventsListCopy = { ...curr };

      if (!eventsListCopy[event]) {
        return eventsListCopy;
      }

      eventsListCopy[event] = eventsListCopy[event].filter(
        cbDTO => cbDTO?.id !== id
      );

      return eventsListCopy;
    });
  }, []);

  const emit = useCallback(
    (eventName, data) => {
      if (eventsList[eventName]) {
        eventsList[eventName].forEach(cbDTO => {
          if (typeof cbDTO === 'function') {
            cbDTO(data);
          } else if (typeof cbDTO?.cb === 'function') {
            cbDTO.cb(data);
          }
        });
      }
    },
    [eventsList]
  );

  const value = {
    subscribe,
    unsubscribe,
    emit,
  };

  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  );
};

export const useEventsContext = () => useContext(EventsContext);
