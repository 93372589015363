import PropTypes from 'prop-types';

const MenuItemPropTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  type: PropTypes.string.isRequired,
  menuPlacement: PropTypes.string,
  menuTrigger: PropTypes.string,
  icon: PropTypes.string,
  hasArrow: PropTypes.bool,
  isMega: PropTypes.bool,
  subItems: PropTypes.array,
};
const MenuInnerPropTypes = PropTypes.shape({
  items: PropTypes.arrayOf(PropTypes.shape(MenuItemPropTypes)),
});

export default MenuInnerPropTypes;
