const { useState } = require('react');

const usePasswordVisibilityToggler = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(currentVisibility => !currentVisibility);
  };

  return { passwordVisible, togglePasswordVisibility };
};

export default usePasswordVisibilityToggler;
