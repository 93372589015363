import PropTypes from 'prop-types';
import VisibilityIcon from '../icons/visibility-icon/VisibilityIcon';
import VisibilityOffIcon from '../icons/visibility-off-icon/VisibilityOffIcon';

import './PasswordVisibilityToggler.scss';

const className = 'c-PasswordVisibilityToggler';

const PasswordVisibilityToggler = ({ isVisible, onClick }) => {
  const icon = isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />;

  return (
    <button className={className} onClick={onClick}>
      {icon}
    </button>
  );
};

export const PasswordVisibilityTogglerPropTypes = {
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
};

PasswordVisibilityToggler.propTypes = PasswordVisibilityTogglerPropTypes;

export default PasswordVisibilityToggler;
