import { EMPTY_INPUT_VALUE } from 'common/constants/forms';
import {
  REACT_GA_RESET_PASSWORD_PUBLIC_PAGE,
  REACT_GA_VALIDATE_ACCOUNT_PUBLIC_PAGE,
} from 'common/constants/react-google-analytics/pages';

const PASSWORD_KEY = 'password';
const CONFIRMED_NEW_PASSWORD_KEY = 'confirmedNewPassword';

export const PASSWORD_UPDATED_MESSAGE =
  'Password changed. You can login with the new password now.';

export const initPasswordState = {
  [PASSWORD_KEY]: { ...EMPTY_INPUT_VALUE },
  [CONFIRMED_NEW_PASSWORD_KEY]: { ...EMPTY_INPUT_VALUE },
};

export const passwordFormFields = [
  {
    key: PASSWORD_KEY,
    label: 'new password',
    type: 'password',
  },
  {
    key: CONFIRMED_NEW_PASSWORD_KEY,
    label: 'confirm new password',
    type: 'password',
  },
];

// public action page types
export const VALIDATE_ACCOUNT_PUBLIC_PAGE_TYPE =
  'VALIDATE_ACCOUNT_PUBLIC_PAGE_TYPE';
export const RESET_PASSWORD_PUBLIC_PAGE_TYPE =
  'RESET_PASSWORD_PUBLIC_PAGE_TYPE';

export const pageTitles = {
  [VALIDATE_ACCOUNT_PUBLIC_PAGE_TYPE]: [{ text: 'Validating account...' }],
  [RESET_PASSWORD_PUBLIC_PAGE_TYPE]: [{ text: 'Reset password' }],
};

export const reactGAPage = {
  [VALIDATE_ACCOUNT_PUBLIC_PAGE_TYPE]: REACT_GA_VALIDATE_ACCOUNT_PUBLIC_PAGE,
  [RESET_PASSWORD_PUBLIC_PAGE_TYPE]: REACT_GA_RESET_PASSWORD_PUBLIC_PAGE,
};
