import { usePreload } from './hooks';
import style from './Preload.module.scss';

const Preload = () => {
  const { preloadComponents } = usePreload();

  return <div className={style.wrapper}>{preloadComponents()}</div>;
};

export default Preload;
