import React from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_NO_BG_VARIANT } from 'common/components/button';
import CloseIcon from 'common/components/icons/close-icon';

const CloseButton = ({ onClose }) => (
  <div className='c-CloseButton'>
    <Button onClick={onClose} variant={BUTTON_NO_BG_VARIANT}>
      <CloseIcon color='#aaaaaa' />
    </Button>
  </div>
);

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CloseButton;
