import { useCallback, useMemo, useState } from 'react';

const useLocation = (initGameData, initCity) => {
  const [nameAndAddress, setNameAndAddress] = useState(
    initGameData?.location || ''
  );
  const [city, setCity] = useState(initGameData?.city || initCity || '');
  const [googleMapsLink, setGoogleMapsLink] = useState(
    initGameData?.locationURL || ''
  );

  // modal
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const openLocationModal = useCallback(() => {
    setLocationModalOpen(true);
  }, []);

  const closeLocationModal = useCallback(() => {
    setLocationModalOpen(false);
  }, []);

  const locationFormProps = useMemo(() => {
    return {
      inputs: [
        {
          label: 'Name & Address',
          name: 'name-and-address',
          value: nameAndAddress,
          onChange: e => {
            setNameAndAddress(e.target.value);
          },
          type: 'text',
        },
        {
          label: 'Google Maps Link',
          name: 'google-maps-link',
          value: googleMapsLink,
          onChange: e => {
            setGoogleMapsLink(e.target.value);
          },
          type: 'text',
        },
        {
          label: 'City',
          name: 'city',
          value: city,
          onChange: e => {
            setCity(e.target.value);
          },
          type: 'text',
        },
      ],
      onConfirm: closeLocationModal,
    };
  }, [city, closeLocationModal, googleMapsLink, nameAndAddress]);

  return {
    nameAndAddress,
    city,
    googleMapsLink,
    locationFormProps,

    // modal
    locationModalOpen,
    openLocationModal,
    closeLocationModal,
  };
};

export default useLocation;
