import { useState, useEffect, useCallback } from 'react';

const useInstallApp = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [shouldShowInstallButton, setShouldShowInstallButton] = useState(false);

  const installAppHandler = useCallback(async () => {
    setShouldShowInstallButton(false);
    deferredPrompt?.prompt();

    const { outcome } = await deferredPrompt.userChoice;

    if (outcome === 'accepted') {
      setDeferredPrompt(null);
    }
  }, [deferredPrompt]);

  const clearInstallPrompt = useCallback(() => {
    setDeferredPrompt(null);
    setShouldShowInstallButton(false);
  }, []);

  const onInstallPrompt = useCallback(e => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();

    setDeferredPrompt(e);
    // Update UI to notify the user they can add to home screen
    setShouldShowInstallButton(true);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', onInstallPrompt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('appinstalled', () => {
      // Hide the app-provided install promotion
      setShouldShowInstallButton(false);
      // Clear the deferredPrompt so it can be garbage collected
      setDeferredPrompt(null);
    });
  }, []);

  return {
    shouldShowInstallButton,
    installAppHandler,
    clearInstallPrompt,
  };
};

export default useInstallApp;
