import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import NotificationsBadge from '../notifications-badge';
import './IconWithLabelCTA.scss';

export const HORIZONTAL_LAYOUT = 'horizontal';
export const VERTICAL_LAYOUT = 'vertical';

const className = 'c-IconWithLabelCTA';

const IconWithLabelCTA = ({
  Icon,
  iconProps,
  label,
  handler,
  link,
  layout,
  onlyLabel,
  numberOfUnread,
}) => {
  const layoutClassNameVariant =
    layout === VERTICAL_LAYOUT ? VERTICAL_LAYOUT : HORIZONTAL_LAYOUT;
  const layoutClassName = `${className}--layout--${layoutClassNameVariant}`;

  const renderLabel = useCallback(
    () => (
      <span className={layoutClassName}>
        {!onlyLabel && <Icon {...iconProps} />}
        {label && <span>{label}</span>}
        <NotificationsBadge numberOfUnread={numberOfUnread} />
      </span>
    ),
    [iconProps, label, layoutClassName, numberOfUnread, onlyLabel]
  );

  return (
    <button className={className} onClick={handler}>
      {link ? (
        <NavLink to={link}>
          {renderLabel()}
          <NotificationsBadge numberOfUnread={numberOfUnread} />
        </NavLink>
      ) : (
        renderLabel()
      )}
    </button>
  );
};

IconWithLabelCTA.propTypes = {
  Icon: PropTypes.any.isRequired,
  label: PropTypes.string,
  handler: PropTypes.func,
  link: PropTypes.string,
  layout: PropTypes.oneOf([HORIZONTAL_LAYOUT, VERTICAL_LAYOUT]),
  iconProps: PropTypes.object,
  onlyLabel: PropTypes.bool,
  numberOfUnread: PropTypes.number,
};

IconWithLabelCTA.defaultProps = {
  layout: VERTICAL_LAYOUT,
  handler: () => {},
  iconProps: {},
  numberOfUnread: 0,
};

export default IconWithLabelCTA;
