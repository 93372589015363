export const SIGNUP_PATH = '/signup';
export const STREET_PATH = '/';
export const LANDING_PAGE = '/';
export const VALIDATE_ACCOUNT_PUBLIC_PAGE_PATH = '/validate-user';
export const RESET_PASSWORD_PUBLIC_PAGE_PATH = '/password-reset';
export const SETTINGS_PATH = '/settings';
export const USER_PROFILE_BASE_PATH = '/user-profile';
export const USER_PROFILE_PATH = `${USER_PROFILE_BASE_PATH}/:id`;
export const CLUBS_PAGE_PATH = '/clubs';
export const CLUB_PAGE_PATH = `${CLUBS_PAGE_PATH}/:id`;
export const MATCHES_PAGE_PATH = '/matches';
export const MATCH_PAGE_PATH = `${MATCHES_PAGE_PATH}/:id`;
export const GAME_EVENTS_PAGE_PATH = '/game-events';
export const GAME_EVENT_PAGE_PATH = `${GAME_EVENTS_PAGE_PATH}/:id`;
export const CONTACT_PAGE = '/contact';
export const WHO_ARE_WE_PAGE = '/origin-story';
export const OUR_VISION_PAGE = '/our-dream';
