import Headline from 'common/components/headline';
import style from './ComingSoonOverlay.module.scss';

const text = [
  { text: 'Coming', isNewLine: true, isBolder: true, isEmphasized: true },
  { text: 'Soon', isBolder: true, isEmphasized: true },
];

const ComingSoonOverlay = () => (
  <div className={style.wrapper}>
    <div className={style.textWrapper}>
      <Headline mainText={text} color='primary' variant='primary' />
    </div>
  </div>
);

export default ComingSoonOverlay;
