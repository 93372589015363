import PropTypes from 'prop-types';

const ExpandIcon = ({ fill }) => (
  <svg
    className='c-ExpandIcon'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill={fill}
  >
    <path d='M0 0h24v24H0z' fill='none' />
    <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' />
  </svg>
);

ExpandIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default ExpandIcon;
