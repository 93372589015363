/* eslint-disable eqeqeq */
import { COUNTRIES, COUNTRY_NAMES } from 'common/constants/geoData';
import {
  formatTimeslotForCalendar,
  getLocalTimezone,
  rearrangeDateString,
} from 'common/utils/dates';
import { BASE_URL } from '_config';
import {
  ARRANGING_MATCH_STATUS,
  AWAY_TEAM_VIEWER,
  AWAY_TEAM_WIN,
  COUNTRY_FORM_KEY,
  CUSTOM_RULES_INPUTS,
  CUSTOM_RULES_LABEL,
  DRAW,
  GAME_RESULT_INPUTS,
  HOME_TEAM_VIEWER,
  HOME_TEAM_WIN,
  matchDetailItemLabels,
  MATCH_ABANDONED_STATUS,
  MATCH_CANCELED_STATUS,
  MATCH_DATE,
  MATCH_DATE_LABEL,
  MATCH_NOT_ARRANGED_STATUS,
  MATCH_RESOLVED_STATUS,
  MATCH_RESULT_KEY,
  MATCH_SCHEDULED_STATUS,
  MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL,
  PROPOSE_MATCH_RESULT_MODE_EDIT_FORM,
  PUBLIC_VIEWER,
  RESOLVED_MATCH_MODAL,
  RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL,
  RESULT_LABEL,
  STANDARD_INPUT_FORMAT_INPUT_FIELDS,
  TIMEDATE_INPUTS,
  TIMEDATE_LABEL,
  WITH_GK_FORM_KEY,
} from './constants';

export const getResultBasedOnGoals = (homeGoals, awayGoals) => {
  let outcome = null;

  try {
    if (homeGoals > awayGoals) {
      outcome = HOME_TEAM_WIN;
    } else if (awayGoals > homeGoals) {
      outcome = AWAY_TEAM_WIN;
    } else {
      outcome = DRAW;
    }
  } catch (err) {
    outcome = null;
  }

  return { outcome, homeGoals, awayGoals };
};

const formatClassicInputField = item => {
  const itemKeys = Object.keys(item);

  const formattedItem = [];

  itemKeys.forEach(fieldItemKey => {
    if (typeof item[fieldItemKey] === 'object') {
      let withValueKey;
      try {
        withValueKey = Object.keys(item[fieldItemKey]).includes('value');
      } catch (err) {
        withValueKey = false;
      }

      if (withValueKey) {
        if (item[fieldItemKey].value === '') {
          formattedItem.push({
            label: matchDetailItemLabels[fieldItemKey],
            value: null,
            type: fieldItemKey,
          });
        }
      }
    } else {
      let value = item[fieldItemKey];
      if (fieldItemKey === COUNTRY_FORM_KEY) {
        const country = COUNTRIES.find(
          ({ country }) => country === item[fieldItemKey]
        );
        value = country?.name ?? null;
      }

      if (fieldItemKey === WITH_GK_FORM_KEY) {
        value =
          item[fieldItemKey] === true
            ? 'yes'
            : item[fieldItemKey] === false
            ? 'no'
            : null;
      }

      if (value !== null)
        formattedItem.push({
          label: matchDetailItemLabels[fieldItemKey],
          value,
          type: fieldItemKey,
        });
    }
  });

  return formattedItem;
};

const formatCustomRules = data => ({
  label: CUSTOM_RULES_LABEL,
  value: data[CUSTOM_RULES_INPUTS],
  type: CUSTOM_RULES_INPUTS,
  highlighted: false,
});

const formatTimedateInputs = data => ({
  label: TIMEDATE_LABEL,
  value: data[TIMEDATE_INPUTS],
  type: TIMEDATE_INPUTS,
  highlighted: false,
});

export const formatMatchDateDD_MM_YYYY = mm_dd_yyyy_string => {
  const [month, day, year] = mm_dd_yyyy_string.split('-');
  const dd_mm_yyyy = `${day}-${month}-${year}`;

  return dd_mm_yyyy;
};

const formatMatchDateItem = data => {
  const [hours, minutes] = splitTimeslotIntoHoursMinutesPair(
    data[MATCH_DATE]?.timeslot
  );

  // dd-mm-yyy
  const [month, day, year] = data[MATCH_DATE]?.dayKey.split('-');
  const formattedDateView = `${day}-${month}-${year}`;

  return {
    label: MATCH_DATE_LABEL,
    value: `${hours}:${minutes}, ${formattedDateView}`,
    type: MATCH_DATE,
    highlighted: false,
  };
};

const formatGameResultInputs = (data, teamNames) => {
  const score =
    (data?.[GAME_RESULT_INPUTS]?.homeGoals ||
      data?.[GAME_RESULT_INPUTS]?.homeGoals == 0) &&
    (data?.[GAME_RESULT_INPUTS]?.awayGoals ||
      data?.[GAME_RESULT_INPUTS]?.awayGoals == 0)
      ? `(${data?.[GAME_RESULT_INPUTS]?.homeGoals} : ${data?.[GAME_RESULT_INPUTS]?.awayGoals})`
      : '';

  const outcomeToRender =
    data[GAME_RESULT_INPUTS].outcome === HOME_TEAM_WIN
      ? teamNames?.homeTeam || 'Home Team'
      : data[GAME_RESULT_INPUTS].outcome === AWAY_TEAM_WIN
      ? teamNames?.awayTeam || 'Away Team'
      : 'Draw';

  return {
    label: RESULT_LABEL,
    highlighted: true,
    value: `${outcomeToRender} ${score}`,
    type: MATCH_RESULT_KEY,
  };
};

export const formatReadOnlyMatchData = (data, teamNames, itemsOrder) => {
  const keys = Object.keys(data);
  const matchDateSet = data?.[MATCH_DATE];
  const shouldSkipTimedateInputs = matchDateSet;
  let formattedData = [];

  const orderedKeys = itemsOrder ? itemsOrder : keys;

  orderedKeys.forEach(key => {
    const isInputWithValidationType =
      STANDARD_INPUT_FORMAT_INPUT_FIELDS.includes(key);

    if (isInputWithValidationType) {
      const formattedInputData = formatClassicInputField(data[key], key);
      formattedData = [...formattedData, ...formattedInputData];
    } else {
      let dataBasedOnKey = {};
      if (key === CUSTOM_RULES_INPUTS) {
        dataBasedOnKey = formatCustomRules(data);
      } else if (key === TIMEDATE_INPUTS && !shouldSkipTimedateInputs) {
        dataBasedOnKey = formatTimedateInputs(data);
      } else if (key === MATCH_DATE && matchDateSet) {
        dataBasedOnKey = formatMatchDateItem(data);
      } else if (key === GAME_RESULT_INPUTS && data[key]?.outcome) {
        dataBasedOnKey = formatGameResultInputs(data, teamNames);
      }

      formattedData = [...formattedData, dataBasedOnKey];
    }
  });

  return formattedData;
};

export const validateExistenceOfDatetimeSlot = (data, onlyDate) => {
  try {
    const dates = Object.keys(data);

    if (onlyDate) {
      return dates?.length > 0;
    } else {
      let timeslotExists = false;

      for (let i = 0; i < dates.length; i++) {
        if (data[dates[i]]?.timeslots?.length > 0) {
          timeslotExists = true;
          break;
        }
      }

      return timeslotExists;
    }
  } catch (err) {
    console.err('catching errors');
    return false;
  }
};

export const splitTimeslotIntoHoursMinutesPair = timeslotStr => {
  try {
    return [timeslotStr.slice(0, 2), timeslotStr.slice(2)];
  } catch (err) {
    return [];
  }
};

export const getFormattedMatchProps = (apiData, userGMClub) => {
  const homeTeam =
    typeof apiData?.homeTeam === 'object' ? apiData?.homeTeam : userGMClub;
  const awayTeam =
    typeof apiData?.awayTeam === 'object' ? apiData?.awayTeam : userGMClub;
  const matchStatus = apiData?.status;
  const initMatchData = {
    customRulesData: apiData?.customRules ? [...apiData?.customRules] : [],
    durationData: { duration: apiData?.duration || undefined },
    gameResultData: {
      outcome: apiData?.outcome,
      homeGoals: apiData?.homeGoals ?? undefined,
      awayGoals: apiData?.awayGoals ?? undefined,
    },
    locationFormData: {
      city: apiData?.city,
      country: apiData?.country,
      location: apiData?.location,
    },
    playerRulesData: {
      numOfPlayers: apiData?.numOfPlayersInField,
      withGoalkeeper: apiData?.withGoalkeeper,
    },
    timedateData: apiData?.proposedTimedates
      ? { ...apiData?.proposedTimedates }
      : {},
    matchDate: apiData?.matchDate,
  };

  return {
    homeTeam,
    awayTeam,
    matchStatus,
    initMatchData,
  };
};

export const getInitMatchModalForMatchSectionVariant = (
  status,
  roleId,
  homeTeam,
  awayTeam
) => {
  const viewerType =
    roleId === homeTeam
      ? HOME_TEAM_VIEWER
      : roleId === awayTeam
      ? AWAY_TEAM_VIEWER
      : PUBLIC_VIEWER;
  const isHomeTeamViewer = viewerType === HOME_TEAM_VIEWER;
  const isAwayTeamViewer = viewerType === AWAY_TEAM_VIEWER;

  switch (status) {
    case ARRANGING_MATCH_STATUS:
      if (isHomeTeamViewer || isAwayTeamViewer) {
        return MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL;
      } else {
        return null;
      }

    case MATCH_SCHEDULED_STATUS:
      if (isHomeTeamViewer) {
        return PROPOSE_MATCH_RESULT_MODE_EDIT_FORM;
      } else {
        return RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL;
      }

    case MATCH_RESOLVED_STATUS:
    case MATCH_ABANDONED_STATUS:
      return RESOLVED_MATCH_MODAL;

    case MATCH_NOT_ARRANGED_STATUS:
    case MATCH_CANCELED_STATUS:
    default:
      return null;
  }
};

/*
{
  eventName: String,
  location: String,
  city: String,
  country: String,
  matchDate: {
    dayKey: '11-11-2021',
    timeslot: '2000'
  },
  timezone: String,
  country: String
}
*/
export const getAddToCalendarProps = data => {
  const locationString = `${data?.location ? `${data?.location}, ` : ''}${
    data?.city ? `${data?.city}, ` : ''
  }${data?.country ? `${COUNTRY_NAMES[data?.country]}` : ''}`;

  const startDate = rearrangeDateString(
    data?.matchDate?.dayKey,
    ['m', 'd', 'y'],
    ['y', 'm', 'd']
  );

  const startTime = formatTimeslotForCalendar(data?.matchDate?.timeslot);

  return {
    name:
      data?.eventName ||
      `c-11: ${data?.awayTeam?.name} @ ${data?.homeTeam?.name}`,
    description: `[url]${BASE_URL}[/url]`,
    location: locationString,
    timezone: data?.timezone || getLocalTimezone(),
    startDate: startDate,
    endDate: startDate, // TODO TEMP
    startTime: startTime,
    endTime: startTime, // TODO TEMP
  };
};

export const formatOutcome = data => {
  if (data?.outcome === HOME_TEAM_WIN) {
    return data?.homeTeam?.name;
  } else if (data?.outcome === AWAY_TEAM_WIN) {
    return data?.awayTeam?.name;
  } else {
    return 'Draw';
  }
};
