import { apiDelete, apiGet, apiPost, apiPut } from './apiWrapper';
import { GAME_EVENTS_ENDPOINT, PUBLIC_GAME_EVENTS_ENDPOINT } from './constants';

export const createGameEventService = apiPost(GAME_EVENTS_ENDPOINT);

export const editGameEventService = id => data =>
  apiPut(`${GAME_EVENTS_ENDPOINT}/${id}`)(data);

export const deleteGameEventService = id =>
  apiDelete(`${GAME_EVENTS_ENDPOINT}/${id}`);

export const getGameEventsService = queries =>
  apiGet(`${GAME_EVENTS_ENDPOINT}?${queries}`);

export const getSingleGameEventService = (id, isGuestView) => {
  const url = isGuestView ? PUBLIC_GAME_EVENTS_ENDPOINT : GAME_EVENTS_ENDPOINT;
  return apiGet(`${url}/${id}`);
};

export const subscribeToGameEventService = id =>
  apiGet(`${GAME_EVENTS_ENDPOINT}/${id}/subscriptions?type=subscribe`);

export const unsubscribeFromGameEventService = id =>
  apiGet(`${GAME_EVENTS_ENDPOINT}/${id}/subscriptions?type=unsubscribe`);
