import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loading from 'common/components/loading';
import { createPortal } from 'react-dom';
import ErrorBoundary from 'pages/error-boundary';
import Modal from './components/modal';
import { PORTAL_NODE_ID } from 'common/constants/domData';
import { useMetaContext } from 'contexts/meta';

const portal = document.getElementById(PORTAL_NODE_ID);

const ModalContainer = ({ children, fullScreen, ...props }) => {
  const { loading } = useMetaContext();

  return createPortal(
    <Suspense fallback={Loading}>
      <ErrorBoundary>
        <Modal
          {...props}
          fullScreen={fullScreen}
          actionsDisabled={!!loading}
          isConfirmDisabled={!!loading}
          loading={loading}
        >
          {children}
        </Modal>
      </ErrorBoundary>
    </Suspense>,
    portal
  );
};

ModalContainer.propTypes = {
  fullScreen: PropTypes.bool,
};

export default ModalContainer;
