import { MEDIA_URL } from '_config';

export const banners = [
  {
    jpgURL: MEDIA_URL + '/jpg/c-11-players-view-m.jpeg',
    jpgDesktopURL: MEDIA_URL + '/jpg/c-11-players-view-d.jpeg',
    webpURL: MEDIA_URL + '/webp/c-11-players-view-m.webp',
    webpDesktopURL: MEDIA_URL + '/webp/c-11-players-view-d.webp',
    alt: 'player banner',
  },
  {
    webpURL: MEDIA_URL + '/webp/player-profile-banner-v3.webp',
    jpgURL: MEDIA_URL + '/jpg/player-profile-banner-v3.jpeg',
    jpgDesktopURL: MEDIA_URL + '/jpg/player-profile-banner-v4.jpeg',
    webpDesktopURL: MEDIA_URL + '/webp/player-profile-banner-v4.webp',
    alt: 'player banner',
  },
];
