/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

const MenuIcon = ({ color }) => (
  <svg
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='#000000'
    className='c-MenuIcon'
  >
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path
      fill={color}
      d='M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z'
    />
  </svg>
);

MenuIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

MenuIcon.propTypes = {
  color: PropTypes.string,
};

export default MenuIcon;
