import PropTypes from 'prop-types';
import Image, { ImagePropTypes } from '../image';
import { formatImageWithWebp } from 'common/utils/formatting';
import './Banner.scss';
import { useCallback } from 'react';

const className = 'c-Banner';

const Banner = ({ img }) => {
  const renderLayers = useCallback(layers => {
    const items = new Array(layers).fill(layers);

    return items.map(i => (
      <div key={Math.random()} className={`${className}__layer`}></div>
    ));
  }, []);

  return (
    <div className={className}>
      <Image {...formatImageWithWebp(img)} />
      {renderLayers(3)}
    </div>
  );
};

Banner.propTypes = {
  img: PropTypes.shape(ImagePropTypes),
};

export default Banner;
