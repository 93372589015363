import PropTypes from 'prop-types';
import { useCallback } from 'react';
import EventInputCheckmarkDone from '../event-input-checkmark-done';
import EventInputQuestionMark from '../event-input-question-mark';
import style from './EventInputBox.module.scss';
import EventInputWarningMark from '../event-input-warning-mark';

const EventInputBox = ({ inputLabel, hasValue, optional, error, children }) => {
  const renderFlagMark = useCallback(() => {
    if (hasValue) {
      return <EventInputCheckmarkDone />;
    } else if (!optional) {
      return error ? <EventInputWarningMark /> : <EventInputQuestionMark />;
    } else {
      return null;
    }
  }, [error, hasValue, optional]);

  const renderInputLabel = useCallback(() => {
    if (typeof inputLabel === 'string') return <label>{inputLabel}</label>;

    return (
      <label>
        {inputLabel?.label}
        <span className={style['label-description']}>
          {inputLabel?.description}
        </span>
      </label>
    );
  }, [inputLabel]);

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        {renderInputLabel()}
        {renderFlagMark()}
      </div>
      <div className={style.input}>{children}</div>
    </div>
  );
};

EventInputBox.propTypes = {
  hasValue: PropTypes.bool,
  inputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  optional: PropTypes.bool,
  error: PropTypes.bool,
};

export default EventInputBox;
