import style from './MatchPage.module.scss';
import SmartPageWrapper from 'features/page-wrapper/variants/smart-page-wrapper';
import Match from 'features/match';
import { useLocation } from 'react-router-dom';
import { useCommonClassNames, useMatches } from 'common/hooks';
import { useEffect, useMemo } from 'react';
import { splitPath } from 'common/utils/routing';
import { REACT_GA_MATCH_PAGE } from 'common/constants/react-google-analytics/pages';
import ReactGA from 'react-ga';

const MatchPage = () => {
  const location = useLocation();
  const matchId = useMemo(
    () => splitPath(location?.pathname)?.[2],
    [location?.pathname]
  );

  const { animationClassName } = useCommonClassNames(false);
  const { matches, loadMatch } = useMatches();

  useEffect(() => {
    if (matchId) loadMatch(matchId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId]);

  useEffect(() => {
    ReactGA.pageview(REACT_GA_MATCH_PAGE);
  }, []);

  return (
    <SmartPageWrapper fullscreen>
      <div
        className={`${style.wrapper} ${style[`wrapper${animationClassName}`]}`}
      >
        <Match withoutModalVariant data={matches?.[0]} />
      </div>
    </SmartPageWrapper>
  );
};

export default MatchPage;
