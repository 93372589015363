import Button, { BUTTON_NO_BG_VARIANT } from 'common/components/button';
import { useNavigation } from 'common/hooks';
import { useAuthContext } from 'contexts/auth';
import style from './JoinInButton.module.scss';

const JoinInButton = () => {
  const { isGuestView } = useAuthContext();
  const { navigateToLandingPage } = useNavigation();

  return (
    isGuestView && (
      <div className={style.wrapper}>
        <Button
          standardWidth
          onClick={navigateToLandingPage}
          variant={BUTTON_NO_BG_VARIANT}
        >
          Join in
        </Button>
      </div>
    )
  );
};

export default JoinInButton;
