import { useForm } from 'common/hooks';
import { useCallback, useState } from 'react';
import InlineRulesSection from '../components/inline-rules-section';
import { playersRulesFormFields } from '../constants';
import { playersRulesSchema } from '../schemas';

const usePlayerRulesProps = initState => {
  const [playersRulesFormData, setPlayersRulesFormData] = useState(initState);

  const savePlayerRulesData = useCallback(data => {
    setPlayersRulesFormData(data);
  }, []);

  const { submit: onSave, getInputProps: getPlayersRulesFormInputProps } =
    useForm(
      initState,
      playersRulesFormFields,
      playersRulesSchema,
      savePlayerRulesData,
      true,
      true,
      true
    );

  return {
    Component: InlineRulesSection,
    inputs: getPlayersRulesFormInputProps(),
    onSave,
    playerRulesData: playersRulesFormData,
  };
};

export default usePlayerRulesProps;
