import { MatchModalPropTypes } from 'features/match/propTypes';
import Modal from 'features/modal';
import { CENTER_MODAL_VARIANT, DARK_THEME } from 'features/modal/constants';
import PropTypes from 'prop-types';
import style from '../../Match.module.scss';
import ModalHeadline from '../modal-headline';

const RenderWrapper = ({
  children,

  // control flow
  matchModal,
  withoutModalVariant,
  isMobileView,
  isSubmitMatchTermsEnabled,

  // formatting functions
  getHeadlineProps,
  getModalControlButtonProps,
  matchResolvableBasedOnTime,

  // data
  homeTeam,
  awayTeam,
  onClose,
  activeRoleId,

  // analytics
  reactGAModalView,
}) => {
  if (!matchModal && !withoutModalVariant) return null;

  return withoutModalVariant ? (
    <Modal
      fullScreen={isMobileView}
      CustomHeadline={ModalHeadline}
      customHeadlineProps={getHeadlineProps(homeTeam, awayTeam)}
      variant={CENTER_MODAL_VARIANT}
      theme={DARK_THEME}
      customClassName={style.modalWrapper}
      actionsDisabled
      reactGAModalView={reactGAModalView}
    >
      {children}
    </Modal>
  ) : (
    <Modal
      // key={isSubmitMatchTermsEnabled}
      fullScreen={isMobileView}
      CustomHeadline={ModalHeadline}
      customHeadlineProps={getHeadlineProps(homeTeam, awayTeam)}
      {...getModalControlButtonProps(
        matchModal,
        isSubmitMatchTermsEnabled,
        awayTeam?._id === activeRoleId,
        !matchResolvableBasedOnTime
      )}
      variant={CENTER_MODAL_VARIANT}
      theme={DARK_THEME}
      onClose={onClose}
      customClassName={style.modalWrapper}
    >
      {children}
    </Modal>
  );
};

RenderWrapper.propTypes = {
  matchModal: MatchModalPropTypes,
  withoutModalVariant: PropTypes.bool,
  isMobileView: PropTypes.bool,
  getHeadlineProps: PropTypes.func.isRequired,
  getModalControlButtonProps: PropTypes.func.isRequired,
  matchResolvableBasedOnTime: PropTypes.bool,
  isSubmitMatchTermsEnabled: PropTypes.bool,
  homeTeam: PropTypes.object,
  awayTeam: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  activeRoleId: PropTypes.string.isRequired,
  reactGAModalView: PropTypes.string,
};

export default RenderWrapper;
