import { usePasswordVisibilityToggler } from 'common/hooks';
import PropTypes from 'prop-types';
import Input from '../../common/components/input';
import PasswordVisibilityToggler from '../../common/components/password-visibility-toggler';

import './PasswordInput.scss';

const className = 'c-PasswordInput';

const PasswordInput = ({ label, name, value, onChange }) => {
  const {
    passwordVisible,
    togglePasswordVisibility,
  } = usePasswordVisibilityToggler();

  const onVisibilityToggle = e => {
    e.stopPropagation();
    e.preventDefault();
    togglePasswordVisibility();
  };

  const getType = () => (passwordVisible ? 'text' : 'password');

  return (
    <div className={className}>
      <Input
        type={getType()}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
      />
      <PasswordVisibilityToggler
        isVisible={passwordVisible}
        onClick={onVisibilityToggle}
      />
    </div>
  );
};

export const PasswordInputPropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

PasswordInput.propTypes = PasswordInputPropTypes;

export default PasswordInput;
