import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Children, useCallback } from 'react';
import ReadOnlyItemsModalContent, {
  ReadOnlyItemModalContentPropTypes,
} from '../read-only-items-modal-content';
import style from './MatchForm.module.scss';
import ExpandIcon from 'common/components/icons/expand-icon';
import IconWithLabelCTA from 'common/components/icon-with-label-cta';
import { ICON_DARK_THEME } from 'common/constants/theming';

const MatchForm = ({ formProps, readOnlyItems }) => {
  const [readonlyExpanded, setReadonlyExpanded] = useState(false);

  const toggleReadOnlySection = useCallback(() => {
    setReadonlyExpanded(curr => !curr);
  }, []);

  const renderSections = useCallback(
    () =>
      Children.toArray(
        formProps.map(({ Component, ...restProps }) => (
          <Component {...restProps} />
        ))
      ),
    [formProps]
  );

  const collapseIconClassName = useMemo(
    () =>
      readonlyExpanded
        ? style['collapseIcon--collapse']
        : style['collapseIcon--expand'],
    [readonlyExpanded]
  );

  const collapsedReadOnlyItems = useMemo(
    () =>
      readonlyExpanded
        ? style[`readOnlySection--expanded`]
        : style[`readOnlySection--collapsed`],
    [readonlyExpanded]
  );

  return (
    <div className={style.wrapper}>
      <div className={''}>
        {readOnlyItems.length > 0 && (
          <div
            className={`${style['toggleButtonWrapper']} ${style.collapseIcon} ${collapseIconClassName}`}
          >
            <IconWithLabelCTA
              Icon={ExpandIcon}
              iconProps={{ fill: ICON_DARK_THEME }}
              handler={toggleReadOnlySection}
            />
          </div>
        )}
        <div className={`${style.readOnlyWrapper} ${collapsedReadOnlyItems}`}>
          <ReadOnlyItemsModalContent items={readOnlyItems} />
        </div>
      </div>
      <div>{renderSections()}</div>
    </div>
  );
};

MatchForm.defaultProps = {
  formProps: [],
  readOnlyItems: [],
};

MatchForm.propTypes = {
  formProps: PropTypes.array,
  readOnlyItems: PropTypes.arrayOf(
    PropTypes.shape(ReadOnlyItemModalContentPropTypes)
  ),
};

export default MatchForm;
