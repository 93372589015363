import { POSITION_AREA_TYPES } from '../constants';

export const formatPlayerAPIBody = (generalData, positionsData, skillsData) => {
  const { description, ...playerGeneralInfo } = generalData;

  // filter out invalid values
  Object.keys(playerGeneralInfo).forEach(key => {
    if (!playerGeneralInfo[key]) {
      delete playerGeneralInfo[key];
    }
  });

  const positionPrimaryArea = POSITION_AREA_TYPES[positionsData.primaryArea];

  const formattedResult = {
    description,
    playerGeneralInfo,
    positions: { ...positionsData, primaryArea: positionPrimaryArea },
    skills: skillsData,
  };

  Object.keys(formattedResult).forEach(key => {
    if (!formattedResult[key] || !Object.keys(formattedResult[key]).length) {
      delete formattedResult[key];
    }
  });

  return formattedResult;
};
