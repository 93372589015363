import { useMetaContext } from 'contexts/meta';
import { useCallback, useMemo } from 'react';

const useApi = () => {
  const {
    setLocalLoading,
    setGlobalLoading,
    unsetLoading,
    setNonCrashErrorToastMessage,
    setSuccessToastMessage,
    handleError,
  } = useMetaContext();

  const apiWrapper = useCallback(
    async (apiService, successMsg, successCb, skipLoading, errorMsg) => {
      if (!skipLoading) setLocalLoading();
      try {
        const response = await apiService();
        const successMsgToRender = successMsg || response?.data?.message;

        if (successMsgToRender) setSuccessToastMessage(successMsgToRender);

        if (successCb) {
          successCb(response);
        }
        return response;
      } catch (err) {
        const errMsg = errorMsg
          ? errorMsg
          : err?.response?.data?.errors?.[0]?.message ||
            err?.response?.data?.message ||
            err.message ||
            JSON.stringify(err) ||
            'Error.';
        setNonCrashErrorToastMessage(errMsg);

        throw err;
      } finally {
        unsetLoading();
      }
    },
    [
      setLocalLoading,
      setNonCrashErrorToastMessage,
      setSuccessToastMessage,
      unsetLoading,
    ]
  );

  const _asyncWrapper = useCallback(
    loadingFn => async cb => {
      try {
        loadingFn();
        await cb();
      } catch (err) {
        handleError(err);
      } finally {
        unsetLoading();
      }
    },
    [handleError, unsetLoading]
  );

  const asyncWrapper = useMemo(
    () => _asyncWrapper(setLocalLoading),
    [_asyncWrapper, setLocalLoading]
  );

  const asyncGlobalWrapper = useMemo(
    () => _asyncWrapper(setGlobalLoading),
    [_asyncWrapper, setGlobalLoading]
  );

  return {
    apiWrapper,
    asyncWrapper,
    asyncGlobalWrapper,
  };
};

export default useApi;
