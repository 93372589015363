import { useEffect, useState } from 'react';

const useCommonClassNames = modalOpened => {
  const [animationClassName, setAnimationClassName] = useState('__blowUpExit');

  useEffect(() => {
    if (modalOpened) {
      setAnimationClassName('__blowUp');
    } else {
      setAnimationClassName('__blowUpExit');
    }
  }, [modalOpened]);

  return {
    animationClassName,
  };
};

export default useCommonClassNames;
