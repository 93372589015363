import Button, {
  BUTTON_LIGHT_VARIANT,
  BUTTON_REGULAR_VARIANT,
} from 'common/components/button';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import EventInputBox from '../base-components/event-input-box';
import style from './EventInputWithOptions.module.scss';

const EventInputWithOptions = ({ inputLabel, optionProps, error }) => {
  const options = optionProps?.map(
    ({ value, description, onSelect, isSelected }) => {
      const variant = isSelected
        ? BUTTON_LIGHT_VARIANT
        : BUTTON_REGULAR_VARIANT;

      return (
        <div className={style.optionItem}>
          <Button rounded variant={variant} onClick={onSelect} key={value}>
            {value}
            <label>{description}</label>
          </Button>
        </div>
      );
    }
  );

  const hasValue = useMemo(
    () => optionProps?.find(item => item?.isSelected === true),
    [optionProps]
  );

  return (
    <EventInputBox inputLabel={inputLabel} hasValue={hasValue} error={error}>
      <div className={style.wrapper}>{options}</div>
    </EventInputBox>
  );
};

EventInputWithOptions.defaultProps = {
  optionProps: [{ isSelected: !true }],
};

export const EventInputWithOptionsPropTypes = {
  inputLabel: PropTypes.string.isRequired,
  optionProps: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      description: PropTypes.string,
      onSelect: PropTypes.func.isRequired,
      isSelected: PropTypes.bool,
    })
  ),
  error: PropTypes.bool,
};

EventInputWithOptions.propTypes = EventInputWithOptionsPropTypes;

export default EventInputWithOptions;
