import Button from 'common/components/button';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import '../../style.scss';

const SkillSelector = ({ name, options }) => {
  const baseClassName = useMemo(() => `player-form-option`, []);
  const renderOptions = useCallback(
    () =>
      options.map(({ name, onClick, isDisabled, isSelected }) => {
        const customClassName = isSelected
          ? `${baseClassName}__option--selected`
          : '';

        return (
          <div className={`${baseClassName}__option`}>
            <Button
              key={name}
              disabled={isDisabled}
              onClick={onClick}
              customClassNames={customClassName}
            >
              {name}
            </Button>
          </div>
        );
      }),
    [baseClassName, options]
  );

  return (
    <div className={`${baseClassName}__wrapper`}>
      <label className={`${baseClassName}__label`}>{name}</label>
      {renderOptions()}
    </div>
  );
};

const SkillOptionPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
});

SkillSelector.defaultProps = {
  options: [],
};

SkillSelector.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(SkillOptionPropTypes).isRequired,
};

export default SkillSelector;
