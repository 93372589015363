import React from 'react';
import PropTypes from 'prop-types';

import style from './MaxWidthContainer.module.scss';

const MaxWidthContainer = ({ children, classNames }) => (
  <div className={`${style.wrapper} ${classNames}`}>{children}</div>
);

MaxWidthContainer.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.string,
};

MaxWidthContainer.defaultProps = {
  classNames: '',
};

export default MaxWidthContainer;
