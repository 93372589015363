import React, { Children } from 'react';
import PropTypes from 'prop-types';
import './ButtonSelectors.scss';
import Button, {
  BUTTON_LIGHT_VARIANT,
  BUTTON_REGULAR_VARIANT,
} from '../button';

const className = 'c-ButtonSelectors';

const ButtonSelectors = ({ name, options, label }) => {
  const renderOptions = () =>
    Children.toArray(
      options.map(({ onClick, text, isSelected }) => {
        const buttonVariant = isSelected
          ? BUTTON_LIGHT_VARIANT
          : BUTTON_REGULAR_VARIANT;

        return (
          <Button withRipples onClick={onClick} narrow variant={buttonVariant}>
            {text}
          </Button>
        );
      })
    );

  return (
    <div className={className}>
      {label && (
        <label htmlFor={name} className={`${className}__label`}>
          {label}
        </label>
      )}
      <div className={`${className}--buttons`}>{renderOptions()}</div>
    </div>
  );
};

export const SelectPropTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
};

ButtonSelectors.propTypes = SelectPropTypes;
ButtonSelectors.defaultProps = {
  options: [],
};

export default ButtonSelectors;
