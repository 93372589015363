import { useCallback, Children } from 'react';
import PropTypes from 'prop-types';
import style from './PlayModesSection.module.scss';
import { FEATURE_STATUS_COMING_SOON } from 'pages/landing-page/constants';
import ComingSoonOverlay from '../coming-soon-overlay';
import Headline from 'common/components/headline';

const PlayModesSection = ({ title, cards }) => {
  const renderCard = useCallback(
    ({ Icon, name, description, mode }) => (
      <div className={style.card}>
        {mode === FEATURE_STATUS_COMING_SOON && <ComingSoonOverlay />}
        <Icon />

        <h3>{name}</h3>

        <p>{description}</p>
      </div>
    ),
    []
  );

  const renderCards = useCallback(
    () => Children.toArray(cards.map(renderCard)),
    [cards, renderCard]
  );

  return (
    <div className={style.wrapper}>
      <Headline mainText={[{ text: title }]} size={1} />
      <div className={style.cards}>{renderCards()}</div>
    </div>
  );
};

const PlayModeCardPropTypes = PropTypes.shape({
  Icon: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

PlayModesSection.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PlayModeCardPropTypes),
};

export default PlayModesSection;
