import { COUNTRIES } from 'common/constants/geoData';
import {
  MATCH_OFFER_TYPE,
  MATCH_RESOLVE_TYPE,
  MATCH_RESULT_PROPOSE_TYPE,
} from 'common/constants/offers';
import { BUTTON_SELECTORS } from 'common/hooks/constants';
import { formatCountryOptions } from 'common/utils/formatting';

export const CITY_FORM_KEY = 'city';
export const COUNTRY_FORM_KEY = 'country';
export const LOCATION_FORM_KEY = 'location';
export const DURATION_FORM_KEY = 'duration';
export const MATCH_DATE = 'matchDate';
export const NUM_OF_PLAYERS_FORM_KEY = 'numOfPlayers';
export const WITH_GK_FORM_KEY = 'withGoalkeeper';
export const MATCH_RESULT_KEY = 'result';

// location form
export const LOCATION_SECTION_FORM_KEYS = [
  CITY_FORM_KEY,
  COUNTRY_FORM_KEY,
  LOCATION_FORM_KEY,
];
export const locationFormFields = [
  {
    key: CITY_FORM_KEY,
    label: CITY_FORM_KEY,
    type: 'text',
  },
  {
    key: COUNTRY_FORM_KEY,
    label: COUNTRY_FORM_KEY,
    type: 'select',
    options: formatCountryOptions(COUNTRIES),
  },
  { key: LOCATION_FORM_KEY, label: LOCATION_FORM_KEY, type: 'text' },
];

// match duration form
export const DURATION_SECTION_FORM_KEYS = [DURATION_FORM_KEY];

export const durationFormFields = [
  {
    key: DURATION_FORM_KEY,
    label: `${DURATION_FORM_KEY} (minutes)`,
    type: 'number',
    min: 1,
  },
];

// players rules form
export const NUM_OF_PLAYERS_LABEL = 'number of players';
export const WITH_GK_LABEL = 'with goalkeeper';
export const PLAYERS_RULES_SECTION_FORM_KEYS = [
  NUM_OF_PLAYERS_FORM_KEY,
  WITH_GK_FORM_KEY,
];
export const playersRulesFormFields = [
  {
    key: NUM_OF_PLAYERS_FORM_KEY,
    label: NUM_OF_PLAYERS_LABEL,
    type: 'number',
    min: 2,
  },
  {
    key: WITH_GK_FORM_KEY,
    type: BUTTON_SELECTORS,
    options: [
      { value: true, text: 'with goalkeeper' },
      { value: false, text: 'no goalkeeper' },
    ],
  },
];

// -- custom rules form
export const CUSTOM_RULES_LABEL = 'messages';
export const CUSTOM_RULES_FORM_TITLE = 'add messages';
export const CUSTOM_RULES_MAX_LENGTH = 100;
export const CUSTOM_RULES_ACTION_BUTTON_LABEL = 'ADD';
export const CUSTOM_RULE_ADDED = 'message added';
// modals
export const CUSTOM_RULES_MODAL = 'CUSTOM_RULES_MODAL';

// action types
export const ADD_CUSTOM_RULE = 'ADD_CUSTOM_RULE';
export const REMOVE_CUSTOM_RULE = 'REMOVE_CUSTOM_RULE';

// -- outcome form
export const OUTCOME_FORM_KEY = 'outcome';
export const HOME_GOALS_FORM_KEY = 'homeGoals';
export const HOME_GOALS_LABEL = 'home goals';
export const AWAY_GOALS_FORM_KEY = 'awayGoals';
export const AWAY_GOALS_LABEL = 'away goals';
export const RESULT_LABEL = 'result';

export const HOME_TEAM_WIN = 'HOME_TEAM_WIN';
export const DRAW = 'DRAW_OUTCOME';
export const AWAY_TEAM_WIN = 'AWAY_TEAM_WIN';
// actions
export const SET_OUTCOME = 'SET_OUTCOME';
export const SET_HOME_GOALS = 'SET_HOME_GOALS';
export const SET_AWAY_GOALS = 'SET_AWAY_GOALS';

// -- timedate form
export const TIMEDATE_LABEL = 'time slots';
export const MATCH_DATE_LABEL = 'match time';
export const TIMESLOT_REQUIRED_LABEL = '(add at least one timeslot option)';
export const SELECT_TIMESLOT_LABEL = '(pick a timeslot)';
export const TIME_SELECTOR_TITLE = 'select time slot';
export const TIME_SELECTOR_MODAL = 'TIME_SELECTOR_MODAL';
export const DATE_SELECTOR_MODAL = 'DATE_SELECTOR_MODAL';

// actions
export const ADD_DAY = 'ADD_DAY';
export const REMOVE_DAY = 'REMOVE_DAY';
export const ADD_TIME = 'ADD_TIME';
export const REMOVE_TIME = 'REMOVE_TIME';

// match mode modal types (new)
// arrange match mode modals
export const ARRANGE_MATCH_MODE_EDIT_FORM = 'ARRANGE_MATCH_MODE_EDIT_FORM';
export const ARRANGE_MATCH_MODE_CONFIRM_MODAL =
  'ARRANGE_MATCH_MODE_CONFIRM_MODAL';
export const ARRANGE_MATCH_MODE_RESPONSE_MESSAGE_MODAL =
  'ARRANGE_MATCH_MODE_RESPONSE_MESSAGE_MODAL';

// match terms response mode modals
export const MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL =
  'MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL';
export const MATCH_TERMS_RESPONSE_MODE_RESPONSE_MESSAGE_MODAL =
  'MATCH_TERMS_RESPONSE_MODE_RESPONSE_MESSAGE_MODAL';

// propose match result mode modals
export const PROPOSE_MATCH_RESULT_MODE_EDIT_FORM =
  'PROPOSE_MATCH_RESULT_MODE_EDIT_FORM';
export const PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL =
  'PROPOSE_MATCH_RESULT_MODE_CONFIRM_MODAL';
export const PROPOSE_MATCH_RESULT_MODE_RESPONSE_MESSAGE_MODAL =
  'PROPOSE_MATCH_RESULT_MODE_RESPONSE_MESSAGE_MODAL';

// resolve match result mode modals
export const RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL =
  'RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL';
export const RESOLVE_MATCH_RESULT_MODE_RESPONSE_MESSAGE_MODAL =
  'RESOLVE_MATCH_RESULT_MODE_RESPONSE_MESSAGE_MODAL';

// resolved match modals
export const RESOLVED_MATCH_MODAL = 'RESOLVED_MATCH_MODAL';

// initMatchModal based on match status
// -- match statuses
export const ARRANGING_MATCH_STATUS = 'ARRANGING_MATCH';
export const MATCH_SCHEDULED_STATUS = 'MATCH_SCHEDULED';
export const MATCH_RESOLVED_STATUS = 'MATCH_RESOLVED';
export const MATCH_ABANDONED_STATUS = 'MATCH_ABANDONED';
export const MATCH_NOT_ARRANGED_STATUS = 'MATCH_NOT_ARRANGED';
export const MATCH_CANCELED_STATUS = 'MATCH_CANCELED';

// match status modals
export const matchStatusModals = {
  ARRANGING_MATCH: 'arranging',
  MATCH_SCHEDULED: 'scheduled',
  MATCH_RESOLVED: 'resolved',
  MATCH_ABANDONED: 'abandoned',
};

export const shareableMatchesBasedOnStatus = [
  MATCH_SCHEDULED_STATUS,
  MATCH_RESOLVED_STATUS,
];

export const initMatchModalBasedOnType = {
  [MATCH_OFFER_TYPE]: MATCH_TERMS_RESPONSE_MODE_CONFIRM_MODAL,
  [MATCH_RESULT_PROPOSE_TYPE]: PROPOSE_MATCH_RESULT_MODE_EDIT_FORM,
  [MATCH_RESOLVE_TYPE]: RESOLVE_MATCH_RESULT_MODE_CONFIRM_MODAL,
};

// ----------------------------------------------------
// confirmation modal types (legacy)
export const CONFIRM_ARRANGE_MATCH_MODAL = 'CONFIRM_ARRANGE_MATCH_MODAL';
export const CONFIRM_MATCH_TERMS_RESPONSE_MODAL =
  'CONFIRM_MATCH_TERMS_RESPONSE_MODAL';
export const CONFIRM_MATCH_RESULT_PROPOSAL_MODAL =
  'CONFIRM_MATCH_RESULT_PROPOSAL_MODAL';
export const CONFIRM_MATCH_RESULT_RESPONSE_MODAL =
  'CONFIRM_MATCH_RESULT_RESPONSE_MODAL';
// ----------------------------------------------------

// data section keys
const LOCATION_DATA_SECTION = 'locationFormData';
const TIMEDATE_DATA_SECTION = 'timedateData';
const MATCH_DATE_DATA_SECTION = 'MATCH_DATE';
const GAME_RESULT_DATA_SECTION = 'gameResultData';
const PLAYER_RULES_DATA_SECTION = 'playerRulesData';
const DURATION_DATA_SECTION = 'durationData';
const CUSTOM_RULES_DATA_SECTION = 'customRulesData';

// input fields
export const CUSTOM_RULES_INPUTS = CUSTOM_RULES_DATA_SECTION;
export const DURATION_INPUTS = DURATION_DATA_SECTION;
export const GAME_RESULT_INPUTS = GAME_RESULT_DATA_SECTION;
export const LOCATION_INPUTS = LOCATION_DATA_SECTION;
export const PLAYER_RULES_INPUTS = PLAYER_RULES_DATA_SECTION;
export const TIMEDATE_INPUTS = TIMEDATE_DATA_SECTION;

// input labels
export const matchDetailItemLabels = {
  [CITY_FORM_KEY]: CITY_FORM_KEY,
  [COUNTRY_FORM_KEY]: COUNTRY_FORM_KEY,
  [LOCATION_FORM_KEY]: LOCATION_FORM_KEY,
  [MATCH_DATE]: MATCH_DATE,
  [DURATION_FORM_KEY]: DURATION_FORM_KEY,
  [NUM_OF_PLAYERS_FORM_KEY]: NUM_OF_PLAYERS_LABEL,
  [WITH_GK_FORM_KEY]: WITH_GK_LABEL,
  [OUTCOME_FORM_KEY]: OUTCOME_FORM_KEY,
  [HOME_GOALS_FORM_KEY]: HOME_GOALS_LABEL,
  [AWAY_GOALS_FORM_KEY]: AWAY_GOALS_LABEL,
};

export const STANDARD_INPUT_FORMAT_INPUT_FIELDS = [
  LOCATION_INPUTS,
  PLAYER_RULES_INPUTS,
  DURATION_INPUTS,
  // GAME_RESULT_INPUTS,
];

export const STANDARD_INPUTS_WITH_VALIDATION = [
  LOCATION_INPUTS,
  PLAYER_RULES_INPUTS,
  DURATION_INPUTS,
];

// messages
export const MATCH_PROPOSED_MESSAGE = 'match proposed';
export const MATCH_ACCEPTED_MESSAGE = 'match accepted';
export const MATCH_CANCELED_MESSAGE = 'match canceled';
export const MATCH_REJECTED_MESSAGE = 'match rejected';
export const MATCH_ABANDONED_MESSAGE = 'match abandoned';
export const MATCH_RESULT_PROPOSED_MESSAGE = 'match result proposed';
export const MATCH_RESOLVED_MESSAGE = 'match resolved';

// form sections orders
export const READONLY_MATCH_DATA_SECTIONS_ORDER = [
  GAME_RESULT_DATA_SECTION,
  LOCATION_DATA_SECTION,
  TIMEDATE_DATA_SECTION,
  MATCH_DATE_DATA_SECTION,
  PLAYER_RULES_DATA_SECTION,
  DURATION_DATA_SECTION,
  CUSTOM_RULES_DATA_SECTION,
];

export const MATCH_READONLY_ITEMS_ORDER = {
  [MATCH_RESULT_KEY]: 0,
  [CITY_FORM_KEY]: 1,
  [COUNTRY_FORM_KEY]: 2,
  [LOCATION_FORM_KEY]: 3,
  [MATCH_DATE]: 4,
  [TIMEDATE_DATA_SECTION]: 5,
  [NUM_OF_PLAYERS_FORM_KEY]: 6,
  [WITH_GK_FORM_KEY]: 7,
  [DURATION_FORM_KEY]: 8,
  [CUSTOM_RULES_DATA_SECTION]: 9,
};

// viewer roles

export const HOME_TEAM_VIEWER = 'HOME_TEAM_VIEWER';
export const AWAY_TEAM_VIEWER = 'AWAY_TEAM_VIEWER';
export const PUBLIC_VIEWER = 'PUBLIC_VIEWER';
