/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

const ArrowBackIcon = ({ color }) => (
  <svg height='48' viewBox='0 96 960 960' width='48'>
    <path
      fill={color}
      d='M627 948 276 597q-5-5-7-10t-2-11q0-6 2-11t7-10l351-351q11-11 28-11t28 11q12 12 12 28.5T683 261L368 576l315 315q13 13 12 29t-12 27q-12 12-28.5 12T627 948Z'
    />
  </svg>
);

ArrowBackIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

ArrowBackIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ArrowBackIcon;
