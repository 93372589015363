import Button from 'common/components/button';
import { BUTTON_REGULAR_VARIANT } from 'common/components/button/constants';
import CopyToClipboardIcon from 'common/components/icons/copy-to-clipboard-icon';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import style from './CopyToClipboard.module.scss';

const CopyToClipboard = ({ onClick, className }) => {
  const [toastClassName, setToastClassName] = useState('');

  const showToast = useCallback(() => {
    setToastClassName(style['show-toast']);

    const timeout = setTimeout(() => {
      setToastClassName('');
      clearTimeout(timeout);
    }, 1000);
  }, []);

  const handleClick = useCallback(() => {
    onClick();
    showToast();
  }, [onClick, showToast]);

  return (
    <div className={`${style.wrapper} ${className}`}>
      <div className={`${style.toast} ${toastClassName}`}>
        copied to clipboard
      </div>
      <Button onClick={handleClick} variant={BUTTON_REGULAR_VARIANT}>
        <CopyToClipboardIcon />
      </Button>
    </div>
  );
};

CopyToClipboard.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CopyToClipboard;
