import PropTypes from 'prop-types';
import { useMemo } from 'react';
import IconWithLabelCTA from '../icon-with-label-cta';
import './ControlButtons.scss';

const className = 'c-ControlButtons';

const ControlButtons = ({ items }) => {
  const itemsToRender = useMemo(
    () => items?.map(item => <IconWithLabelCTA key={item.id} {...item} />),
    [items]
  );

  if (items.length === 0) return null;
  return <div className={className}>{itemsToRender}</div>;
};

ControlButtons.defaultProps = {
  items: [],
};

export const ControlButtonsPropTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      Icon: PropTypes.any.isRequired,
      iconProps: PropTypes.object,
      label: PropTypes.string,
      handler: PropTypes.func,
    })
  ),
};

ControlButtons.propTypes = ControlButtonsPropTypes;

export default ControlButtons;
