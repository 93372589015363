import CloseIcon from 'common/components/icons/close-icon';
import DoneIcon from 'common/components/icons/done-icon';
import MenuIcon from 'common/components/icons/menu-icon';

export const CONFIRM_ICON = 'confirm';
export const REJECT_ICON = 'reject';
export const MORE_ICON = 'menu';

export const icons = {
  confirm: DoneIcon,
  reject: CloseIcon,
  menu: MenuIcon,
};

export const COMPONENT_VARIANT_MEDIUM = 'variant-md';
export const COMPONENT_VARIANT_SMALL = 'variant-sm';
