import React, { Children, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button/Button';
import Headline from 'common/components/headline';
import { setupPortalTestEnvironment } from 'common/utils/dom';
import { CENTER_MODAL_VARIANT, DARK_THEME, LIGHT_THEME } from '../../constants';
import { TextPropTypes } from 'common/components/text/Text';
import CloseButton from 'common/components/use-case-buttons/close-button';
import ReactGA from 'react-ga';
import './Modal.scss';
import { useAnimationExit } from 'common/hooks';
import { useEffect } from 'react';
import Loading, { LOCAL_LOADING_TYPE } from 'common/components/loading';

const className = 'c-Modal';

setupPortalTestEnvironment();

const Modal = ({
  children,
  onClose,
  confirmLabel,
  isConfirmDisabled,
  onConfirm,
  cancelLabel,
  onCancel,
  actionsDisabled,
  customButtons,
  title,
  titleSize,
  CustomHeadline,
  customHeadlineProps,
  withoutWrapper,
  variant,
  customClassName,
  fullScreen,
  theme,
  customModalContentClassName,
  reactGAModalView,
  highlightCloseButton,
  loading,
}) => {
  const [shouldClose, setShouldClose] = useState(false);

  const handleClose = useCallback(() => {
    setShouldClose(true);
  }, []);

  const { exitFlag, exitClassNamesArr } = useAnimationExit(
    shouldClose,
    `${className}__overlay--exit ${className}__dialog--exit`,
    300
  );

  const [overlayExitClassName, dialogExitClassName] = exitClassNamesArr;

  useEffect(() => {
    if (exitFlag) {
      onClose();
    }
  }, [exitFlag, onClose, overlayExitClassName, shouldClose]);

  const withoutWrapperClassName = withoutWrapper
    ? `${className}__content--expanded`
    : '';

  const dialogVariantClassName =
    variant === CENTER_MODAL_VARIANT ? `${className}--center` : '';

  const closeButtonHighlightedClassName = highlightCloseButton
    ? `${className}__dialog--highlighted`
    : '';

  const fullScreenClassName = fullScreen
    ? `${className}__dialog--full-screen`
    : '';

  const renderControls = useCallback(() => {
    const buttonsToRender = [...customButtons];

    if (onConfirm) {
      buttonsToRender.push({
        onClick: onConfirm,
        isDisabled: isConfirmDisabled,
        label: confirmLabel,
      });
    }

    buttonsToRender.push({
      onClick: onCancel ? onCancel : onClose,
      label: cancelLabel,
    });

    return Children.toArray(
      buttonsToRender.map(({ onClick, label, isDisabled }) => (
        <Button
          narrow
          variant='no-bg'
          textType='primary'
          isDisabled={isDisabled}
          onClick={onClick}
        >
          {label}
        </Button>
      ))
    );
  }, [
    cancelLabel,
    confirmLabel,
    customButtons,
    isConfirmDisabled,
    onCancel,
    onClose,
    onConfirm,
  ]);

  const hideHeadline = useMemo(() => withoutWrapper, [withoutWrapper]);
  const hideCTASection = useMemo(() => withoutWrapper, [withoutWrapper]);

  useEffect(() => {
    if (reactGAModalView) ReactGA.modalview(reactGAModalView);
  }, [reactGAModalView]);

  return (
    <div className={`${className} ${customClassName}`}>
      <div
        className={`${className}__overlay ${overlayExitClassName}`}
        onClick={handleClose}
      ></div>
      <div
        className={`${className}__dialog 
        ${customClassName}__dialog 
        ${dialogExitClassName} 
        ${dialogVariantClassName} 
        ${fullScreenClassName} 
        ${closeButtonHighlightedClassName}`}
      >
        {loading === LOCAL_LOADING_TYPE && (
          <div className='global-meta-loadings'>
            <Loading variant={loading} />
          </div>
        )}
        {!actionsDisabled && <CloseButton onClose={handleClose} />}
        {!hideHeadline && (
          <header className={`${className}__header`}>
            {CustomHeadline ? (
              <CustomHeadline {...customHeadlineProps} />
            ) : (
              <Headline size={titleSize} mainText={title} variant='primary' />
            )}
          </header>
        )}
        <section
          // eslint-disable-next-line max-len
          className={`${className}__content ${customModalContentClassName} ${customClassName}__content ${withoutWrapperClassName} ${`${className}__content--${theme}`}`}
        >
          {children}
        </section>
        {!hideCTASection && (
          <section
            className={`${className}__cta-section ${className}__cta-section--${theme}`}
          >
            {renderControls()}
          </section>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  isConfirmDisabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  actionsDisabled: PropTypes.bool,
  customButtons: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.shape(TextPropTypes),
  titleSize: PropTypes.oneOf([1, 2, 3, 4]),
  CustomHeadline: PropTypes.any,
  customHeadlineProps: PropTypes.object,
  withoutWrapper: PropTypes.bool,
  customClassName: PropTypes.string,
  variant: PropTypes.oneOf(['default', CENTER_MODAL_VARIANT]),
  theme: PropTypes.oneOf([DARK_THEME, LIGHT_THEME]),
  fullScreen: PropTypes.bool,
  customModalContentClassName: PropTypes.string,
  reactGAModalView: PropTypes.string,
  highlightCloseButton: PropTypes.bool,
  loading: PropTypes.bool,
};

Modal.defaultProps = {
  cancelLabel: 'cancel',
  confirmLabel: 'confirm',
  title: [],
  titleSize: 3,
  customHeadlineProps: {},
  customClassName: '',
  variant: 'default',
  theme: LIGHT_THEME,
  customButtons: [],
};

export default Modal;
