import { MATCH_READONLY_ITEMS_ORDER } from 'features/match/constants';

export const sortMatchSections = (
  items,
  orderedKeys = MATCH_READONLY_ITEMS_ORDER
) => {
  try {
    const sortedItems = new Array(items.length);
    items.forEach(item => {
      const orderNum = orderedKeys[item?.type];
      sortedItems[orderNum] = item;
    });

    return sortedItems;
  } catch (err) {
    return items;
  }
};
