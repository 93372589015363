import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CalendarTable from './components/calendar-table';
import CurrentDayInfo from './components/current-day-info';
import Navigation from './components/navigation';
import SubmitHandlers from './components/submit-handlers';
import Modal from 'features/modal';
import {
  ctaLabels,
  DECADES_IN_PAST,
  DECADES_IN_FUTURE,
  TEN_YEARS,
  NO_PAST_DATE_MESSAGE,
} from './constants';
import { useCurrentDates } from './hooks';
import { getTextFormat } from 'common/utils/formatting';
import { MONTHS } from 'common/constants/dateData';
import AnimateSwitch from 'common/components/animation-components/animate-switch';
import { useWindowSizeContext } from 'contexts/window-size';
import { sub } from 'date-fns';

import style from './Calendar.module.scss';
import { useMetaContext } from 'contexts/meta';

const monthMenuItems = MONTHS.map((item, i) => ({ label: item, value: i }));

const Calendar = ({ isOpen, onClose, onConfirm, date, disablePast }) => {
  const {
    selectedDate,
    selectDate,
    dateInView,
    switchToPrevMonth,
    switchToNextMonth,
    setMonthInView,
    setYearInView,
    selectDateInView,
    getYears,
  } = useCurrentDates(date);
  const { isMobileView } = useWindowSizeContext();
  const { setNeutralToastMessage } = useMetaContext();
  const [monthSelectorOpened, setMonthSelectorOpened] = useState(false);
  const [yearSelectorOpened, setYearSelectorOpened] = useState(false);
  const [years, setYears] = useState([]);
  const [reversedDateSwitchDirection, setReversedDateSwitchDirection] =
    useState(false);

  const openMonthSelector = e => {
    e.stopPropagation();
    setMonthSelectorOpened(true);
    closeYearSelector();
  };

  const closeMonthSelector = () => {
    setMonthSelectorOpened(false);
  };

  const openYearSelector = e => {
    e.stopPropagation();
    setYearSelectorOpened(true);
    closeMonthSelector();
    setYears(getYears(DECADES_IN_PAST, DECADES_IN_FUTURE).flat());
  };

  const closeYearSelector = () => {
    setYearSelectorOpened(false);
  };

  const closeAllDropdownSelectors = () => {
    closeMonthSelector();
    closeYearSelector();
  };

  const handleDateSelect = date => {
    if (date) {
      selectDate(date);
    } else {
      selectDate(dateInView.dateObj);
    }
  };

  const handleDateInViewSelect = day => {
    const { year, monthNumber } = dateInView;
    const selectedDate = new Date(year, monthNumber, day);

    const yesterday = sub(new Date(), { days: 1 });

    if (disablePast && yesterday > selectedDate) {
      setNeutralToastMessage(NO_PAST_DATE_MESSAGE);
    } else {
      selectDateInView(selectedDate);
      handleDateSelect(selectedDate); // INVESTIGATE are selected & in view dates both needed?
    }
  };

  const switchYears = direction => {
    let targetYear;
    const { year, monthNumber } = dateInView;

    if (direction === 'prev') {
      targetYear = year - TEN_YEARS;
    } else {
      targetYear = year + TEN_YEARS;
    }

    const selectedDate = new Date(targetYear, monthNumber, 1);

    selectDateInView(selectedDate);
    setYears(getYears(DECADES_IN_PAST, DECADES_IN_FUTURE).flat());
  };

  const handleCancel = () => {
    // TODO add feature logic
    onClose();
  };
  const handleConfirm = () => {
    handleDateSelect();
    onConfirm(selectedDate);
    onClose();
  };

  const handleSwitchToPrevMonth = () => {
    switchToPrevMonth();
    setReversedDateSwitchDirection(true);
  };

  const handleSwitchToNextMonth = () => {
    switchToNextMonth();
    setReversedDateSwitchDirection(false);
  };

  const getMonthMenuProps = () => ({
    isOpened: monthSelectorOpened,
    onOpen: openMonthSelector,
    onClose: closeMonthSelector,
    onSelect: month => {
      setMonthInView(month);
      setReversedDateSwitchDirection(month < dateInView?.monthNumber);
      closeMonthSelector();
    },
    items: monthMenuItems,
    label: () => (
      <>
        <label className={style.monthMenu}>{dateInView.month}</label>
      </>
    ),
    selected: dateInView?.monthNumber,
  });

  const getYearMenuProps = () => ({
    isOpened: yearSelectorOpened,
    onOpen: openYearSelector,
    onClose: closeYearSelector,
    onSelect: year => {
      setYearInView(year);
      setReversedDateSwitchDirection(year < dateInView.year);
    },
    items: years,
    label: () => (
      <>
        <label className={style.monthMenu}>{dateInView.year}</label>
      </>
    ),
    onShowPrevYears: e => {
      e.stopPropagation();
      switchYears('prev');
    },
    onShowNextYears: e => {
      e.stopPropagation();
      switchYears('next');
    },
    selected: dateInView?.year,
  });

  if (!isOpen) return null;

  return (
    <div className={style.outsideWrapper}>
      <Modal
        fullScreen={isMobileView}
        withoutWrapper
        onClose={onClose}
        onConfirm={onConfirm}
        customClassName='calendarModal'
      >
        <div className={style.wrapper} onClick={closeAllDropdownSelectors}>
          {/* Day Info Wrapper */}
          <div className={style.events}>
            <CurrentDayInfo
              year={getTextFormat(selectedDate?.year)}
              date={getTextFormat(selectedDate?.dayDescriptive)}
            />

            {/* Events - desktop */}
          </div>

          <div className={style.tableWrapper}>
            {/* Calendar Box */}
            <div className={style.table}>
              <Navigation
                onPrevMonthClick={handleSwitchToPrevMonth}
                onNextMonthClick={handleSwitchToNextMonth}
                monthMenuProps={getMonthMenuProps()}
                yearMenuProps={getYearMenuProps()}
              />
              <AnimateSwitch
                Component={CalendarTable}
                targetPropValue={dateInView?.weeks}
                targetPropKey='weeks'
                onSelect={handleDateInViewSelect}
                selected={dateInView?.dateObj?.getDate()}
                flagProps={[dateInView?.monthNumber, dateInView?.year]}
                isReversed={reversedDateSwitchDirection}
              />
            </div>

            {/* Events - mobile TODO */}

            <div className={style.submitHandlersWrapper}>
              <SubmitHandlers
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                {...ctaLabels}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Calendar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  disablePast: PropTypes.bool,
};

export default Calendar;
