import { useCallback, useReducer } from 'react';
import ResultSection from '../components/result-section';
import {
  AWAY_TEAM_WIN,
  DRAW,
  HOME_TEAM_WIN,
  SET_AWAY_GOALS,
  SET_HOME_GOALS,
  SET_OUTCOME,
} from '../constants';
import { getResultBasedOnGoals } from '../utils';

const useOutcomeProps = (homeTeamName, awayTeamName, proposedResult) => {
  const [gameResult, setGameResult] = useReducer(
    (state, action) => {
      let homeGoals;
      let awayGoals;

      switch (action.type) {
        case SET_OUTCOME:
          return {
            outcome: action.payload,
            homeGoals: '',
            awayGoals: '',
          };
        case SET_HOME_GOALS:
          homeGoals = action.payload;
          awayGoals = !(!state.awayGoals && state.awayGoals !== 0)
            ? state.awayGoals
            : 0;

          return getResultBasedOnGoals(homeGoals, awayGoals);

        case SET_AWAY_GOALS:
          awayGoals = action.payload;
          homeGoals = !(!state.homeGoals && state.homeGoals !== 0)
            ? state.homeGoals
            : 0;

          return getResultBasedOnGoals(homeGoals, awayGoals);
        default:
          return state;
      }
    },
    {
      outcome: proposedResult?.outcome,
      homeGoals: proposedResult?.homeGoals || 0,
      awayGoals: proposedResult?.awayGoals || 0,
    }
  );

  const setOutcome = useCallback(payload => {
    setGameResult({ type: SET_OUTCOME, payload });
  }, []);
  const setHomeTeamWin = useCallback(() => {
    setOutcome(HOME_TEAM_WIN);
  }, [setOutcome]);
  const setAwayTeamWin = useCallback(() => {
    setOutcome(AWAY_TEAM_WIN);
  }, [setOutcome]);
  const setDrawOutcome = useCallback(() => {
    setOutcome(DRAW);
  }, [setOutcome]);

  const onHomeGoalsChange = useCallback(e => {
    setGameResult({ type: SET_HOME_GOALS, payload: e.target.value });
  }, []);
  const onAwayGoalsChange = useCallback(e => {
    setGameResult({ type: SET_AWAY_GOALS, payload: e.target.value });
  }, []);

  return {
    Component: ResultSection,
    outcomeButtons: [
      {
        label: homeTeamName || 'Home Team',
        onClick: setHomeTeamWin,
        isSelected: gameResult?.outcome === HOME_TEAM_WIN,
      },
      {
        label: 'Draw',
        onClick: setDrawOutcome,
        isSelected: gameResult?.outcome === DRAW,
      },
      {
        label: awayTeamName || 'Away Team',
        onClick: setAwayTeamWin,
        isSelected: gameResult?.outcome === AWAY_TEAM_WIN,
      },
    ],
    goalInputProps: [
      {
        name: 'homeGoals',
        label: '',
        value: gameResult?.homeGoals,
        onChange: onHomeGoalsChange,
        type: 'number',
        min: 0,
      },
      {
        name: 'awayGoals',
        label: '',
        value: gameResult?.awayGoals,
        onChange: onAwayGoalsChange,
        type: 'number',
        min: 0,
      },
    ],
    readOnlyOutcome: gameResult,
    gameResultData: gameResult,
  };
};

export default useOutcomeProps;
