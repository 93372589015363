import ClubIcon from 'common/components/icons/club-icon';
import LogoutIcon from 'common/components/icons/logout-icon';
import MatchIcon from 'common/components/icons/match-icon';
import PersonsGroupIcon from 'common/components/icons/persons-group-icon';
import SettingsIcon from 'common/components/icons/settings-icon';
import SwitchAccessIcon from 'common/components/icons/switch-access-icon';
import { SETTINGS_PATH } from 'routes/constants';

// ITEM TYPES
export const CREATE_CLUB_ITEM_TYPE = 'CREATE_CLUB_ITEM_TYPE';

// MODAL TYPES
export const CREATE_CLUB_MODAL_TYPE = 'CREATE_CLUB_MODAL_TYPE';
export const CREATE_GAME_EVENT_MODAL_TYPE = 'CREATE_GAME_EVENT_MODAL_TYPE';
export const PLAYER_PROFILE_SETUP_MODAL_TYPE =
  'PLAYER_PROFILE_SETUP_MODAL_TYPE';

export const modalStaticProps = {
  [CREATE_CLUB_MODAL_TYPE]: {
    title: [{ text: 'Create team' }],
  },
};

// BUTTONS - static
const MATCH_ICON = { Icon: MatchIcon, label: 'matches', handler: () => {} };
const CLUB_ICON = { Icon: ClubIcon, label: 'teams', handler: () => {} };
export const PERSON_GROUP_ICON = {
  Icon: PersonsGroupIcon,
  handler: () => {},
};
export const SETTINGS_ITEM = {
  Icon: SettingsIcon,
  label: 'settings',
  link: SETTINGS_PATH,
};
export const LOGOUT_ITEM = {
  Icon: LogoutIcon,
  label: 'logout',
};

export const SWITCH_ROLE_ITEM = {
  Icon: SwitchAccessIcon,
};

export const LINK_DROPDOWN_ITEMS = [MATCH_ICON, CLUB_ICON, PERSON_GROUP_ICON];

// CREATE CLUB FORM CONSTANTS -------------------------------
const CLUB_NAME_FORM_KEY = 'name';
export const CREATE_CLUB_FORM_KEYS = [CLUB_NAME_FORM_KEY];
export const createClubFormFields = [
  {
    key: CLUB_NAME_FORM_KEY,
    label: 'team name',
    type: 'text',
  },
];
export const CLUB_CREATED_MESSAGE = 'Team created.';
// ----------------------------------------------------------
