import {
  GM_USER_ROLE,
  PLAYER_USER_ROLE,
} from 'contexts/user-session/hooks/constants';

// tooltips
export const TRANSFER_BUTTON_MAKE_OFFER_TOOLTIP = 'ask to join';
export const TRANFER_BUTTON_CANCEL_OFFER_TOOLTIP = 'cancel offer';
export const TRANSFER_BUTTON_END_CLUB_MEMBERSHIP_TOOLTIP = 'end membership';

// transfer button types
export const TRANSFER_BUTTON_MAKE_OFFER_TYPE =
  'TRANSFER_BUTTON_MAKE_OFFER_TYPE';
export const TRANSFER_BUTTON_CANCEL_OFFER_TYPE =
  'TRANSFER_BUTTON_CANCEL_OFFER_TYPE';
export const TRANSFER_BUTTON_END_CLUB_MEMBERSHIP_TYPE =
  'TRANSFER_BUTTON_END_CLUB_MEMBERSHIP_TYPE';

// transfer types
export const TRANSFER_ACTIVE_TYPE = 'active';
export const TRANSFER_INACTIVE_TYPE = 'inactive';

// messages
export const TRANSFER_OFFER_SENT_MESSAGE = 'Offer to join club sent.';
export const TRANSFER_OFFER_SENT_SUCCESSFULLY_MESSAGE =
  'Offer to join club created.';
export const TRANSFER_OFFER_RESPONSE_SENT_MESSAGE = 'Response sent.';
export const TRANSFER_OFFER_SUCCESSFULLY_ANSWERED_MESSAGES =
  'Offer to join club resolved.';
export const TRANSFER_OFFER_CANCELED_MESSAGE = 'Offer to join club canceled.';
export const CLUB_MEMBERSHIP_ENDED_SUCCESSFULLY_MESSAGE =
  'Team membership deactivated.';

export const CLUB_TRANSFER_ENTITY = 'club';
export const PLAYER_TRANSFER_ENTITY = 'player';

export const TRANSFER_OFFER_ACCEPTED_STATUS = 'accepted';
export const TRANSFER_OFFER_REJECTED_STATUS = 'rejected';

export const roleTypeMapping = {
  [PLAYER_USER_ROLE]: PLAYER_TRANSFER_ENTITY,
  [GM_USER_ROLE]: CLUB_TRANSFER_ENTITY,
};
