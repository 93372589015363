import PropTypes from 'prop-types';
import { ICON_DARK_THEME } from 'common/constants/theming';

/* eslint-disable max-len */
const PersonsGroupIcon = ({ color }) => (
  <svg
    className='c-PersonsGroupIcon'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='#000000'
  >
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path
      fill={color}
      d='M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05.02.01.03.03.04.04 1.14.83 1.93 1.94 1.93 3.41V18c0 .35-.07.69-.18 1H22c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5z'
    />
  </svg>
);

PersonsGroupIcon.defaultProps = { color: ICON_DARK_THEME };

PersonsGroupIcon.propTypes = {
  color: PropTypes.string,
};

export default PersonsGroupIcon;
