import {
  getClubMatchesService,
  getMatchService,
  getPlayerMatchesService,
} from 'common/api/matchesService';
import { useAuthContext } from 'contexts/auth';
// import { formatKeyValuePairsIntoAPIQuery } from 'common/utils/formatting';
import { useCallback, useState } from 'react';
import useApi from './useApi';

const useMatches = () => {
  const { isGuestView } = useAuthContext();
  const [matches, setMatches] = useState([]);
  const [shouldReloadMatches, setShouldReloadMatches] = useState(false);
  const { asyncWrapper } = useApi();

  // TODO
  const getMatches = useCallback(params => {
    // const queryParams = formatKeyValuePairsIntoAPIQuery(params);
    // call api service
    // set matches
  }, []);

  const _loadMatches = useCallback(
    service => entityId => {
      asyncWrapper(async () => {
        const matchesData = await service(entityId)();
        if (typeof matchesData === 'object') setMatches(matchesData?.data);
        setShouldReloadMatches(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const loadClubMatches = useCallback(
    clubId => _loadMatches(getClubMatchesService)(clubId),
    [_loadMatches]
  );

  const loadPlayerMatches = useCallback(
    playerId => _loadMatches(getPlayerMatchesService)(playerId),
    [_loadMatches]
  );

  const loadMatch = useCallback(
    id => {
      asyncWrapper(async () => {
        const data = await getMatchService(id, isGuestView)();
        if (typeof data === 'object') setMatches([data?.data]);
      });
    },
    [asyncWrapper, isGuestView]
  );

  return {
    matches,
    loadMatch,
    loadClubMatches,
    loadPlayerMatches,
    getMatches,

    shouldReloadMatches,
    setShouldReloadMatches,
  };
};

export default useMatches;
