import { useState, useCallback } from 'react';

const useToggler = initState => {
  const [opened, setOpened] = useState(initState);
  const close = useCallback(() => {
    setOpened(false);
  }, []);
  const open = useCallback(() => {
    setOpened(true);
  }, []);

  const toggle = useCallback(() => {
    setOpened(curr => !curr);
  }, []);

  return {
    opened,
    close,
    open,
    toggle,
  };
};

export default useToggler;
