import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button/index.js';
import MeteorIcon from 'common/components/icons/meteor-icon/index.js';
import Headline, { HeadlineTextDataType } from 'common/components/headline';

import style from './ErrorPage.module.scss';
import { staticProps } from 'pages/error-boundary/constants';

const ErrorPage = ({ mainMsg, subMsg, onGoBack, goBackBtnLabel }) => (
  <main className={style.wrapper}>
    <MeteorIcon size='4x' />
    <div className={style.text}>
      <Headline mainText={mainMsg} subText={subMsg} />
    </div>
    <Button variant='no-bg' textType='primary' onClick={onGoBack}>
      {goBackBtnLabel}
    </Button>
  </main>
);

ErrorPage.propTypes = {
  mainMsg: HeadlineTextDataType,
  subMsg: HeadlineTextDataType,
  onGoBack: PropTypes.func.isRequired,
  goBackBtnLabel: PropTypes.string.isRequired,
};

ErrorPage.defaultProps = {
  ...staticProps,
};

export default ErrorPage;
