/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

const TimeIcon = ({ color }) => (
  <svg height='24' viewBox='0 96 960 960' width='24'>
    <path
      fill={color}
      d='M606 747q9 9 21 9t22-10q10-10 10.5-23t-9.5-22L513 564V392q0-12-8.5-20.5T483 363q-13 0-21.5 8.5T453 393v183q0 6 2 11t6 10l145 150ZM480 976q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-82 31.5-155t86-127.5Q252 239 325 207.5T480 176q82 0 155 31.5t127.5 86Q817 348 848.5 421T880 576q0 82-31.5 155t-86 127.5Q708 913 635 944.5T480 976Zm0-400Zm0 340q140 0 240-100t100-240q0-140-100-240T480 236q-140 0-240 100T140 576q0 140 100 240t240 100Z'
    />
  </svg>
);

TimeIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

TimeIcon.propTypes = {
  color: PropTypes.string,
};

export default TimeIcon;
