import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from './apiWrapper';
import { MATCHES_ENDPOINT, PUBLIC_MATCHES_ENDPOINT } from './constants';

export const createMatchService = apiPost(MATCHES_ENDPOINT);

export const editMatchTermsService = (id, data) =>
  apiPut(`${MATCHES_ENDPOINT}/${id}`)(data);

export const resolveMatchService = id =>
  apiPatch(`${MATCHES_ENDPOINT}/${id}`)({});

export const cancelMatchService = id => apiDelete(`${MATCHES_ENDPOINT}/${id}`);

export const getClubMatchesService = club =>
  apiGet(`${MATCHES_ENDPOINT}?forClub=${club}`);

export const getPlayerMatchesService = player =>
  apiGet(`${MATCHES_ENDPOINT}?forPlayer=${player}`);

export const getMatchService = (id, isGuestView) => {
  const url = isGuestView ? PUBLIC_MATCHES_ENDPOINT : MATCHES_ENDPOINT;
  return apiGet(`${url}/${id}`);
};
