/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { isInViewport } from 'common/utils/dom';

const useClassNamesOnViewportChange = (ref, classNames, verticalPadding) => {
  const { onEnter, onLeave } = classNames;
  const [entered, setEntered] = useState(true);

  const [activeClassNames, setActiveClassNames] = useState('');

  const activate = () => {
    if (ref.current && isInViewport(ref.current, verticalPadding)) {
      setEntered(true);
    } else {
      setEntered(false);
    }
  };

  useEffect(() => {
    if (entered) {
      const classNamesToSet = onEnter ? onEnter : '';
      setActiveClassNames(classNamesToSet);
    }
    if (!entered) {
      const classNamesToSet = onLeave ? onLeave : '';
      setActiveClassNames(classNamesToSet);
    }
  }, [entered]);

  useEffect(() => {
    window.addEventListener('scroll', activate, true);
    return window.removeEventListener('scroll', activate);
  });

  return activeClassNames;
};

export default useClassNamesOnViewportChange;
