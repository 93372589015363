import { useCallback, useState } from 'react';
import {
  createGameEventService,
  editGameEventService,
  deleteGameEventService,
  unsubscribeFromGameEventService,
  subscribeToGameEventService,
} from 'common/api/gameEventsService';
import { useApi } from 'common/hooks';
import { CREATE_GAME_EVENT_MODE, EDIT_GAME_EVENT_MODE } from '../constants';
import { isUserSubscribedToGameEvent } from '../utils';
import { useAuthContext } from 'contexts/auth';
import {
  GAME_EVENT_DELETE,
  GAME_EVENT_UPDATE,
} from 'contexts/events/constants';
import { useEventsContext } from 'contexts/events';

const services = {
  [CREATE_GAME_EVENT_MODE]: {
    apiHandler: () => createGameEventService,
    successMessage: 'Game Created',
  },
  [EDIT_GAME_EVENT_MODE]: {
    apiHandler: id => editGameEventService(id),
    successMessage: 'Game Updated',
  },
};

const useGameEventAPI = (mode, initGameData) => {
  const { apiWrapper } = useApi();
  const { user, setUser } = useAuthContext();
  const { emit } = useEventsContext();

  const [createdGameEvent, setCreatedGameEvent] = useState(null);

  const createEditGameEvent = useCallback(
    async data => {
      const apiService = services[mode].apiHandler(initGameData?._id);
      const successMessage = services[mode].successMessage;

      await apiWrapper(
        () => apiService(data),
        successMessage,
        response => {
          if (response?.data) {
            setCreatedGameEvent(response.data);

            emit(GAME_EVENT_UPDATE, response.data);
          }
        }
      );
    },
    [apiWrapper, emit, initGameData?._id, mode]
  );

  const deleteGameEvent = useCallback(
    async (id, cb) => {
      const successMessage = 'Game Deleted';

      await apiWrapper(
        () => deleteGameEventService(id),
        successMessage,
        response => {
          if (response?.status === 204) {
            cb(id);
            emit(GAME_EVENT_DELETE, id);
          }
        }
      );
    },
    [apiWrapper, emit]
  );

  const toggleGameEventSubscriptions = useCallback(
    async gameEventId => {
      const serviceToUse = isUserSubscribedToGameEvent(
        gameEventId,
        user?.gameEventSubscriptions
      )
        ? unsubscribeFromGameEventService(gameEventId)
        : subscribeToGameEventService(gameEventId);

      await apiWrapper(serviceToUse, null, response => {
        if (response?.status === 200) {
          setUser({
            ...user,
            gameEventSubscriptions: response?.data.gameEventSubscriptions,
          });
          // TODO publish event
        }
      });
    },
    [apiWrapper, setUser, user]
  );

  return {
    createdGameEvent,
    createEditGameEvent,
    deleteGameEvent,
    toggleGameEventSubscriptions,
  };
};

export default useGameEventAPI;
