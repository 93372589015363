import React from 'react';
import PropTypes from 'prop-types';
import Image from 'common/components/image';
import { logoSources } from './constants';

import './Cluster11Image.scss';

const className = 'c-Cluster11Image';

// use svg icon
const Cluster11Image = ({ iconMode, variant }) => {
  return (
    <div
      className={`${className} ${iconMode ? `${className}__icon-mode` : ''}`}
    >
      <Image {...logoSources[variant]} />
    </div>
  );
};

Cluster11Image.defaultProps = {
  variant: 'classic',
};

Cluster11Image.propTypes = {
  iconMode: PropTypes.bool,
  variant: PropTypes.oneOf(['classic', 'blue-stars']),
};

export default Cluster11Image;
