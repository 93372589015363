import React from 'react';
import Text, { TextPropTypes } from '../text';

import './DangerText.scss';

const className = 'c-DangerText';

const DangerText = ({ text }) => (
  <div className={className}>
    <Text data={text} />
  </div>
);

DangerText.propTypes = TextPropTypes;

export default DangerText;
