import {
  GLOBAL_LOADING_TYPE,
  LOCAL_LOADING_TYPE,
} from 'common/components/loading';
import { SERVER_ERROR_STATUS_CODE } from 'common/constants/error-codes';
import { useEventsContext } from 'contexts/events';
import { OUTSIDE_CLICK_EVENT } from 'contexts/events/constants';
import { useInstallApp } from 'features/install-button/hooks';
import { createContext, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_ERROR_MESSAGE,
  LONG_TOAST_MESSAGE_TIMEOUT_IN_MS,
  NEUTRAL_TOAST_MESSAGE_TYPE,
  NON_CRASH_ERROR_TOAST_MESSAGE_TYPE,
  SUCCESS_TOAST_MESSAGE_TYPE,
  TOAST_MESSAGE_TIMEOUT_IN_MS,
} from './constants';

const MetaContext = createContext();

export const MetaContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { emit } = useEventsContext();

  const { shouldShowInstallButton, installAppHandler, clearInstallPrompt } =
    useInstallApp();

  // loading system ----------------
  const [loading, setLoading] = useState(null);

  const setGlobalLoading = useCallback(() => {
    setLoading(GLOBAL_LOADING_TYPE);
  }, []);

  const setLocalLoading = useCallback(() => {
    setLoading(LOCAL_LOADING_TYPE);
  }, []);

  const unsetLoading = useCallback(() => {
    setLoading(null);
  }, []);

  // toasts system ------------------
  const [toastMessages, setToastMessages] = useState({});

  const removeToastMessage = useCallback(key => {
    setToastMessages(current => {
      const next = { ...current };
      delete next[key];
      return next;
    });
  }, []);

  const addToastMessage = useCallback(
    (type, message, longTimeout, link) => {
      const timeoutDuration = longTimeout
        ? LONG_TOAST_MESSAGE_TIMEOUT_IN_MS
        : TOAST_MESSAGE_TIMEOUT_IN_MS;
      const key = Symbol();
      setToastMessages(current => {
        const next = { ...current };
        next[key] = { type, message };
        if (link)
          next[key].onClick = () => {
            removeToastMessage(key);
            navigate(link);
          };
        return next;
      });

      const timeout = setTimeout(() => {
        removeToastMessage(key);
        clearTimeout(timeout);
      }, timeoutDuration);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeToastMessage]
  );

  const checkIfNonCrashingErrorExists = useCallback(() => {
    let nonCrashingErrorExists = false;
    const keysToIterate = Object.getOwnPropertySymbols(toastMessages);

    keysToIterate.forEach(key => {
      if (toastMessages[key].type === NON_CRASH_ERROR_TOAST_MESSAGE_TYPE) {
        nonCrashingErrorExists = true;
      }
    });

    return nonCrashingErrorExists;
  }, [toastMessages]);

  const setSuccessToastMessage = useCallback(
    message => {
      addToastMessage(SUCCESS_TOAST_MESSAGE_TYPE, message);
    },
    [addToastMessage]
  );
  const setNonCrashErrorToastMessage = useCallback(
    (message = DEFAULT_ERROR_MESSAGE) => {
      addToastMessage(NON_CRASH_ERROR_TOAST_MESSAGE_TYPE, message);
    },
    [addToastMessage]
  );
  const setNeutralToastMessage = useCallback(
    (message, link) => {
      addToastMessage(NEUTRAL_TOAST_MESSAGE_TYPE, message, true, link);
    },
    [addToastMessage]
  );

  // dom interaction ---------------
  const outsideClick = useCallback(() => {
    emit(OUTSIDE_CLICK_EVENT);
  }, [emit]);

  // --------------------------------------

  // crash error system
  const [crashError, setCrashError] = useState(false);
  const clearCrashError = useCallback(() => {
    setCrashError(false);
  }, []);

  const handleError = useCallback(
    err => {
      const status = err?.response?.status;

      if (!status || status >= SERVER_ERROR_STATUS_CODE) {
        // TODO not implemented well
        // setCrashError(true);
      } else {
        setNonCrashErrorToastMessage(
          err?.response?.data?.errors?.[0]?.message ||
            err?.response?.data?.message ||
            'Error'
        );
      }
    },
    [setNonCrashErrorToastMessage]
  );

  // --------------------------------------

  const value = {
    // loading system
    loading,
    setGlobalLoading,
    setLocalLoading,
    unsetLoading,

    // toast messaging system
    toastMessages,
    toastMessageKeys: Object.getOwnPropertySymbols(toastMessages),
    setSuccessToastMessage,
    setNonCrashErrorToastMessage,
    setNeutralToastMessage,
    isNonCrashingError: checkIfNonCrashingErrorExists(),
    handleError,

    // (crash) error system
    crashError,
    setCrashError,
    clearCrashError,

    // dom interaction
    outsideClick,

    // install app
    shouldShowInstallButton,
    installAppHandler,
    clearInstallPrompt,
  };

  return <MetaContext.Provider value={value}>{children}</MetaContext.Provider>;
};

export const useMetaContext = () => useContext(MetaContext);
