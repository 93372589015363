import axios from 'axios';
import { API_BASE } from '_config';

axios.defaults.baseURL = API_BASE;
axios.defaults.withCredentials = true;

export const apiGet = url => cb => axios.get(url).then(cb);

export const apiPost = url => data => axios.post(url, data).then(res => res);

export const apiPut = url => data => axios.put(url, data).then(res => res);

export const apiPatch = url => data => axios.patch(url, data).then(res => res);

export const apiDelete = url => axios.delete(url).then(res => res);
