import Image from 'common/components/image';
import { formatImage } from 'common/utils/formatting';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import style from './ModalHeadline.module.scss';

const renderLogo = imageURL => (
  <div className={style['logo-wrapper']}>
    {imageURL && <Image {...formatImage(imageURL)} />}
  </div>
);

const ModalHeadline = ({ homeTeam, awayTeam }) => {
  const renderTeamInfo = useCallback((team, reversed) => {
    const headlineClassNames = `${style['team-headline']} ${
      style[`team-headline${reversed}`]
    }`;

    const reversedTextClassName = reversed
      ? style['team-headline__text--reversed']
      : '';

    return (
      <div className={headlineClassNames} onClick={team?.onClick}>
        {!reversed && renderLogo(team?.imageURL)}
        <span
          className={`${style['team-headline__text']} ${reversedTextClassName}`}
        >
          {team?.name}
        </span>
        {reversed && renderLogo(team?.imageURL)}
      </div>
    );
  }, []);

  return (
    <p className={style.wrapper}>
      {renderTeamInfo(homeTeam, '--reversed')} {renderTeamInfo(awayTeam, '')}
    </p>
  );
};

const TeamDataPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  imageURL: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ModalHeadline.propTypes = {
  homeTeam: PropTypes.shape(TeamDataPropTypes),
  awayTeam: PropTypes.shape(TeamDataPropTypes),
};

export default ModalHeadline;
