export const ctaLabels = {
  cancelLabel: 'cancel',
  confirmLabel: 'confirm',
};

export const DECADES_IN_PAST = 1;
export const DECADES_IN_FUTURE = 1;
export const TEN_YEARS = 10;

export const NO_PAST_DATE_MESSAGE = `Can't select date in the past.`;
