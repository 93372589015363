import {
  cancelMatchService,
  createMatchService,
  editMatchTermsService,
  resolveMatchService,
} from 'common/api/matchesService';
import { useApi } from 'common/hooks';
import { useCallback, useState } from 'react';
import {
  MATCH_ABANDONED_MESSAGE,
  MATCH_ACCEPTED_MESSAGE,
  MATCH_CANCELED_MESSAGE,
  MATCH_PROPOSED_MESSAGE,
  MATCH_REJECTED_MESSAGE,
  MATCH_RESOLVED_MESSAGE,
  MATCH_RESULT_PROPOSED_MESSAGE,
} from '../constants';

const useMatchServices = () => {
  const { apiWrapper } = useApi();

  // meta // TODO use meta loading
  const [isLoading, setIsLoading] = useState(false);

  const serviceCallWrapper = useCallback(
    (service, successMsg, successCb) => async data => {
      setIsLoading(true);
      await apiWrapper(() => service(data), successMsg, successCb);
      setIsLoading(false);
    },
    [apiWrapper]
  );

  // services
  const createMatch = useCallback(
    (data, successCb) => {
      serviceCallWrapper(
        createMatchService,
        MATCH_PROPOSED_MESSAGE,
        successCb
      )(data);
    },
    [serviceCallWrapper]
  );

  const acceptMatch = useCallback(
    (id, data, successCb) => {
      serviceCallWrapper(
        () => editMatchTermsService(id, data),
        MATCH_ACCEPTED_MESSAGE,
        successCb
      )();
    },
    [serviceCallWrapper]
  );

  // NEXT-VER not used at the time, but will be needed when matches are rendered to profile and when you are able to take a look at a single match
  const cancelMatch = useCallback(
    (id, successCb) => {
      serviceCallWrapper(
        () => cancelMatchService(id),
        MATCH_CANCELED_MESSAGE,
        successCb
      )();
    },
    [serviceCallWrapper]
  );

  const rejectMatch = useCallback(
    (id, successCb) => {
      serviceCallWrapper(
        () => cancelMatchService(id),
        MATCH_REJECTED_MESSAGE,
        successCb
      )();
    },
    [serviceCallWrapper]
  );

  const abandonMatch = useCallback(
    (id, successCb) => {
      serviceCallWrapper(
        () => cancelMatchService(id),
        MATCH_ABANDONED_MESSAGE,
        successCb
      )();
    },
    [serviceCallWrapper]
  );

  const proposeMatchResult = useCallback(
    (id, data, successCb) => {
      serviceCallWrapper(
        () => editMatchTermsService(id, data),
        MATCH_RESULT_PROPOSED_MESSAGE,
        successCb
      )();
    },
    [serviceCallWrapper]
  );

  const acceptMatchResult = useCallback(
    (id, successCb) => {
      serviceCallWrapper(
        () => resolveMatchService(id),
        MATCH_RESOLVED_MESSAGE,
        successCb
      )();
    },
    [serviceCallWrapper]
  );

  return {
    // meta
    isLoading,

    // services
    createMatch,
    acceptMatch,
    cancelMatch,
    rejectMatch,
    abandonMatch,
    proposeMatchResult,
    acceptMatchResult,
  };
};

export default useMatchServices;
