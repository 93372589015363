const date = require('date-fns');

export default function Calendar() {}

Calendar.prototype.getMonthDays = function (currentDate) {
  const days = [];
  const month = date.getMonth(currentDate);
  const year = date.getYear(currentDate);
  const startOfMonth = new Date(year, month, 1);
  const firstDayInWeek = date.getDay(startOfMonth);

  // set empty days for previous week days
  for (let i = 0; i < firstDayInWeek; i++) {
    days.push(null);
  }

  const lastDay = date.getDaysInMonth(currentDate);

  // add month days to days array
  for (let d = 1; d <= lastDay; d++) {
    days.push(d);
  }

  const endOfMonth = new Date(year, month, lastDay);
  const lastDayInWeek = date.getDay(endOfMonth);
  const remainingDays = 6 - lastDayInWeek;

  // set empty days for the rest of the week days
  for (let r = 0; r < remainingDays; r++) {
    days.push(null);
  }

  return days;
};

Calendar.prototype.getWeeks = function (currentDate) {
  let days = this.getMonthDays(currentDate);
  const weeks = [];

  while (days.length > 0) {
    const currentWeek = days.slice(0, 7);
    weeks.push(currentWeek);
    days = days.slice(7);
  }

  return weeks;
};

Calendar.prototype.getDecadeYears = function (decade) {
  const decadeYears = [];

  for (let i = 1; i <= 10; i++) {
    const year = decade + i;
    decadeYears.push(year);
  }

  return decadeYears;
};

Calendar.prototype.getHalfDecadeYears = function (decade) {
  const decadeYears = this.getDecadeYears(decade);

  const firstHalf = decadeYears.slice(0, 5);
  const secondHalf = decadeYears.slice(5);

  return [firstHalf, secondHalf];
};

Calendar.prototype.getYearsByDecades = function (
  currentDate,
  pastDecades,
  futureDecades,
  full = false
) {
  const decades = [];
  const currentDecade = date.getDecade(currentDate);

  // prepend past decades
  for (let p = pastDecades; p > 0; --p) {
    const currentPastDecade = currentDecade - p * 10;
    decades.push(currentPastDecade);
  }

  decades.push(currentDecade);

  // append future decades
  for (let f = 1; f <= futureDecades; f++) {
    const currentFutureDecade = currentDecade + f * 10;
    decades.push(currentFutureDecade);
  }

  const decadeVariantMethod = full ? 'getDecadeYears' : 'getHalfDecadeYears';
  const years = decades.map(item => this[decadeVariantMethod](item));

  if (full) return years;

  return years.flatMap(decade => decade);
};
