import { useMemo, useState } from 'react';
import { gameTypeStaticProps, skillLevelStaticProps } from '../constants';

const formatDynamicOptionProps = (staticData, setFn, selectedOption) =>
  staticData.map(({ type, description }) => ({
    value: type,
    description,
    onSelect: () => {
      setFn(type);
    },
    isSelected: type === selectedOption,
  }));

const useOptionInputs = initGameData => {
  // game type
  const [selectedGameType, setSelectedGameType] = useState(
    initGameData?.gameType || null
  );

  const gameTypeOptions = useMemo(
    () => ({
      inputLabel: gameTypeStaticProps.inputLabel,
      optionProps: formatDynamicOptionProps(
        gameTypeStaticProps.optionProps,
        setSelectedGameType,
        selectedGameType
      ),
    }),
    [selectedGameType]
  );

  // skill level
  const [selectedSkillLevel, setSelectedSkillLevel] = useState(
    initGameData?.skillLevel || null
  );

  const skillLevelOptionProps = useMemo(
    () => ({
      inputLabel: skillLevelStaticProps.inputLabel,
      optionProps: formatDynamicOptionProps(
        skillLevelStaticProps.optionProps,
        setSelectedSkillLevel,
        selectedSkillLevel
      ),
    }),
    [selectedSkillLevel]
  );

  return {
    // game type
    selectedGameType,
    gameTypeOptions,

    // skill level
    selectedSkillLevel,
    skillLevelOptionProps,
  };
};

export default useOptionInputs;
