import React from 'react';
import PropTypes from 'prop-types';

import './Input.scss';

const className = 'c-Input';

const Input = props => {
  const { label, name, value, onChange, onClick, type, ...customProps } = props;

  return (
    <div className={className} onClick={onClick}>
      <input
        type={type}
        className={`${className}__input`}
        placeholder={label}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        {...customProps}
      />
      <label htmlFor={name} className={`${className}__label`}>
        {label}
      </label>
    </div>
  );
};

export const InputPropTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
};

Input.propTypes = InputPropTypes;

Input.defaultProps = {
  onChange: () => {},
  onClick: () => {},
};

export default Input;
