/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

const MapIcon = ({ color }) => (
  <svg height='48' viewBox='0 96 960 960' width='48'>
    <path
      fill={color}
      d='m592 929-243-86-179 71q-17 9-33.5-1T120 883V325q0-13 7.5-23t19.5-15l182-64q10-3 20-3t20 3l243 85 178-71q17-8 33.5 1.5T840 268v565q0 11-7.5 19T814 864l-182 65q-10 3-20 3t-20-3Zm-14-68V356l-196-66v505l196 66Zm60 0 142-47V302l-142 54v505Zm-458-12 142-54V290l-142 47v512Zm458-493v505-505Zm-316-66v505-505Z'
    />
  </svg>
);

MapIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

MapIcon.propTypes = {
  color: PropTypes.string,
};

export default MapIcon;
