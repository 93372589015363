import Button from 'common/components/button';
import { BUTTON_DARK_BLACK_VARIANT } from 'common/components/button/constants';
import Input from 'common/components/input';
import {
  CUSTOM_RULES_ACTION_BUTTON_LABEL,
  CUSTOM_RULES_FORM_TITLE,
  CUSTOM_RULES_LABEL,
  CUSTOM_RULES_MAX_LENGTH,
} from 'features/match/constants';
import Modal, { DARK_THEME } from 'features/modal';
import PropTypes from 'prop-types';
import { Children, useCallback } from 'react';
import style from './CustomRulesSection.module.scss';

const CustomRulesSection = ({
  customRulesData,
  addCustomRule,
  removeCustomRule,
  customRuleInputValue,
  onCustomRuleInputChange,
  onClose,
  isEditMode,
  onEdit,
  isMobileView,
}) => {
  const renderAddedRules = useCallback(
    () =>
      Children.toArray(
        customRulesData.map(item => (
          <Button
            withRipples
            onClick={() => removeCustomRule(item)}
            variant={BUTTON_DARK_BLACK_VARIANT}
            narrow
          >
            {item}
          </Button>
        ))
      ),
    [customRulesData, removeCustomRule]
  );

  return (
    <div className={style.wrapper}>
      <label className={style.label}>{CUSTOM_RULES_LABEL}</label>
      {isEditMode ? (
        <Modal
          onClose={onClose}
          cancelLabel='close'
          fullScreen={isMobileView}
          theme={DARK_THEME}
          title={[{ text: CUSTOM_RULES_FORM_TITLE }]}
          titleSize={4}
          customClassName={style.modalWrapper}
          customButtons={[{ onClick: addCustomRule, label: 'add' }]}
        >
          <div className={style.modalContent}>
            <Input
              type='text'
              placeholder='new message'
              name='custom-rule'
              value={customRuleInputValue}
              maxlength={CUSTOM_RULES_MAX_LENGTH}
              onChange={onCustomRuleInputChange}
            />
          </div>
        </Modal>
      ) : (
        <div className={style.actionButtonWrapper}>
          <Button
            standardWidth
            withRipples
            narrow
            variant={BUTTON_DARK_BLACK_VARIANT}
            onClick={onEdit}
          >
            {CUSTOM_RULES_ACTION_BUTTON_LABEL}
          </Button>
        </div>
      )}

      <div className={style.rulesWrapper}>{renderAddedRules()}</div>
    </div>
  );
};

CustomRulesSection.defaultProps = {
  customRulesData: [],
};

CustomRulesSection.propTypes = {
  customRulesData: PropTypes.arrayOf(PropTypes.string),
  addCustomRule: PropTypes.func.isRequired,
  removeCustomRule: PropTypes.func.isRequired,
  customRuleInputValue: PropTypes.string,
  onCustomRuleInputChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool,
};

export default CustomRulesSection;
