export const REACT_GA_LOGIN_MODAL = 'login-modal';
export const REACT_GA_SIGNUP_MODAL = 'signup-modal';
export const REACT_GA_CREATE_TEAM_MODAL = 'create-team-modal';

// NOTE include these if needed
// export const REACT_GA_PROFILES_DROPDOWN = 'profile-roles-dropdown';
// export const REACT_GA_ACTIVE_PROFILE_ROLE_DROPDOWN =
// 'active-profile-role-dropdown';
// export const REACT_GA_ACTIVE_PROFILE_MOBILE_DROPDOWN =
// 'active-profile-mobile-dropdown';
// export const REACT_GA_MATCH_MODAL = 'match-modal';

export const REACT_GA_SCHEDULED_MATCH_MODAL = 'scheduled-match-modal';
export const REACT_GA_PLAYED_MATCH_MODAL = 'played-match-modal';
export const REACT_GA_PLAYER_CARD_MODAL = 'player-card-modal';
export const REACT_GA_TEAM_CARD_MODAL = 'team-card-modal';
export const REACT_GA_PASSWORD_MODAL = 'password-modal';
export const REACT_GA_DELETE_PROFILE_MODAL = 'delete-profile-modal';
export const REACT_GA_UPLOAD_PROFILE_IMAGE_MODAL = 'upload-user-image-modal'; //
export const REACT_GA_EDIT_PROFILE_BIO_MODAL = 'edit-user-bio-modal';
export const REACT_GA_EDIT_CLUB_BIO_MODAL = 'edit-club-bio-modal';
export const REACT_GA_DEACTIVATE_CLUB_MODAL = 'deactivate-club-modal';
export const REACT_GA_DELETE_CLUB_MODAL = 'delete-club-modal';
