import { useState, useReducer, useCallback, useMemo } from 'react';
import { formFielsdActionTypes } from 'common/constants/actionTypes';
import {
  formFields,
  initLoginState,
  loginLabel,
  loginModalDynamicTitle,
  LOGIN_MODE,
  onSignUpLabel,
  resetPasswordLabel,
  RESET_PASSWORD_MODE,
  validateAccountLabel,
  VALIDATE_ACCOUNT_MODE,
} from '../constants';
import { validateForm } from 'common/utils/validations';
import { loginSchema } from '../schemas';
import { useAuthContext } from 'contexts/auth';
import { useNavigate } from 'react-router-dom';
import { SIGNUP_PATH } from 'routes/constants';
import { useWindowSizeContext } from 'contexts/window-size';
import { REACT_GA_LOGIN_MODAL } from 'common/constants/react-google-analytics/modals';
import {
  REACT_GA_FORGOTTEN_PASSWORD_EVENT,
  REACT_GA_LOGIN_EVENT,
  REACT_GA_SIGN_UP_FROM_BANNER_EVENT,
  REACT_GA_SIGN_UP_FROM_CTA_SECTION_EVENT,
  REACT_GA_VALIDATE_ACCOUNT_EVENT,
} from 'common/constants/react-google-analytics/events';
import ReactGA from 'react-ga';

const { CHANGE_FIELD_VALUE, VALIDATE_FIELDS, CLEAR_FIELDS } =
  formFielsdActionTypes;

const useProps = () => {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSizeContext();
  const { login, requestAccountValidationLink, requestPasswordResetLink } =
    useAuthContext();

  const [loginModalActiveMode, setLoginModalActiveMode] = useState(null);

  const switchToLoginMode = useCallback(() => {
    setLoginModalActiveMode(LOGIN_MODE);
  }, []);

  const switchToValidateAccountMode = useCallback(() => {
    setLoginModalActiveMode(VALIDATE_ACCOUNT_MODE);
  }, []);

  const switchToResetPasswordMode = useCallback(() => {
    setLoginModalActiveMode(RESET_PASSWORD_MODE);
  }, []);

  const closeLoginModal = useCallback(() => {
    setLoginModalActiveMode(null);
  }, []);

  const [loginState, setLoginState] = useReducer((loginState, action) => {
    switch (action.type) {
      case CHANGE_FIELD_VALUE:
        return Object.assign({}, loginState, {
          [action.key]: { value: action.value, error: action.error },
        });
      case VALIDATE_FIELDS:
        return Object.assign({}, loginState, {
          [action.key]: { value: action.value, error: action.error },
        });
      case CLEAR_FIELDS:
        return initLoginState;
      default:
        return loginState;
    }
  }, initLoginState);

  const validate = validateForm(
    loginState,
    loginSchema,
    setLoginState,
    VALIDATE_FIELDS
  );

  const onLoginChange = key => e =>
    setLoginState({ type: CHANGE_FIELD_VALUE, value: e.target.value, key });

  const getInputProps = useCallback(
    () =>
      formFields[loginModalActiveMode].map(({ key, label, type }) => ({
        name: key,
        label,
        type,
        value: loginState[key].value,
        error: loginState[key].error,
        onChange: onLoginChange(key),
      })),
    [loginModalActiveMode, loginState]
  );

  const confirmLogin = useCallback(
    async e => {
      e.preventDefault();
      const validatedFormData = await validate(loginState);
      let submitHandler;
      let rGAnalyticsEvent;

      if (loginModalActiveMode === LOGIN_MODE) {
        submitHandler = login;
        rGAnalyticsEvent = REACT_GA_LOGIN_EVENT;
      } else if (loginModalActiveMode === VALIDATE_ACCOUNT_MODE) {
        submitHandler = requestAccountValidationLink;
        rGAnalyticsEvent = REACT_GA_VALIDATE_ACCOUNT_EVENT;
      } else if (loginModalActiveMode === RESET_PASSWORD_MODE) {
        submitHandler = requestPasswordResetLink;
        rGAnalyticsEvent = REACT_GA_FORGOTTEN_PASSWORD_EVENT;
      }

      if (validatedFormData) {
        submitHandler(validatedFormData);
        setLoginState({ type: CLEAR_FIELDS });
        ReactGA.event(rGAnalyticsEvent);
        closeLoginModal();
      }
    },
    [
      closeLoginModal,
      login,
      loginModalActiveMode,
      loginState,
      requestPasswordResetLink,
      validate,
      requestAccountValidationLink,
    ]
  );

  const signUpCTAPropsBanner = useMemo(
    () => ({
      onClick: () => {
        ReactGA.event(REACT_GA_SIGN_UP_FROM_BANNER_EVENT);
        navigate(SIGNUP_PATH);
      },
      label: onSignUpLabel,
    }),
    [navigate]
  );

  const signUpCTAPropsCTASection = useMemo(
    () => ({
      onClick: () => {
        ReactGA.event(REACT_GA_SIGN_UP_FROM_CTA_SECTION_EVENT);
        navigate(SIGNUP_PATH);
      },
      label: onSignUpLabel,
    }),
    [navigate]
  );

  const loginCTAProps = useMemo(
    () => ({
      onClick: switchToLoginMode,
      label: loginLabel,
    }),
    [switchToLoginMode]
  );

  const loginModalProps = useMemo(
    () => ({
      fullScreen: isMobileView,
      title: loginModalDynamicTitle[loginModalActiveMode],
      onClose: closeLoginModal,
      variant: 'center',
      customButtons: [
        {
          label: resetPasswordLabel,
          onClick: switchToResetPasswordMode,
        },
        {
          label: validateAccountLabel,
          onClick: switchToValidateAccountMode,
        },
      ],
      reactGAModalView: REACT_GA_LOGIN_MODAL,
    }),
    [
      closeLoginModal,
      isMobileView,
      loginModalActiveMode,
      switchToResetPasswordMode,
      switchToValidateAccountMode,
    ]
  );

  return {
    loginModalActiveMode,
    loginCTAProps,
    signUpCTAPropsBanner,
    signUpCTAPropsCTASection,

    closeLoginModal,
    getInputProps,
    confirmLogin,
    loginModalProps,
  };
};

export default useProps;
