import { onEnter } from 'common/utils/event-listeners';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TAGS_INPUT_LABEL } from '../constants';

const createTag = value => ({
  value: value,
  id: value + String(Math.random()),
});

const useTags = initGameData => {
  const [currentTag, setCurrentTag] = useState('');

  const [tags, setTags] = useState([]);

  const setInitTags = useCallback(() => {
    if (!initGameData?.tags) return [];
    const formattedInitTags = initGameData.tags.map(item => createTag(item));
    setTags(formattedInitTags);
  }, [initGameData?.tags]);

  const addTag = useCallback(() => {
    if (!currentTag) return;

    tags.push(createTag(currentTag));
    setCurrentTag('');
  }, [currentTag, tags]);

  const addTagOnEnterKeyPress = useCallback(e => onEnter(addTag)(e), [addTag]);

  const removeTag = useCallback(
    id => {
      const nextTags = tags.filter(tag => tag.id !== id);
      setTags(nextTags);
    },
    [tags]
  );

  const onCurrentTagChange = useCallback(e => {
    setCurrentTag(e.target.value);
  }, []);

  useEffect(() => {
    setInitTags(initGameData?.tags);
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInitTags]);

  const tagsProps = useMemo(
    () => ({
      inputLabel: TAGS_INPUT_LABEL,
      tags,
      addTag,
      addTagOnEnterKeyPress,
      removeTag,
      onChange: onCurrentTagChange,
      currentTag,
    }),
    [
      addTag,
      addTagOnEnterKeyPress,
      currentTag,
      onCurrentTagChange,
      removeTag,
      tags,
    ]
  );

  const tagValues = useMemo(
    () => tags.map(({ value }) => value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tags.length, tags]
  );

  return {
    tagValues,
    tagsProps,
  };
};

export default useTags;
