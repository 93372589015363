import PropTypes from 'prop-types';
import { Children } from 'react';
import style from './ReadonlyCustomRules.module.scss';

const ReadonlyCustomRules = ({ data, label }) => {
  const rulesToRender = Children.toArray(
    data?.map(ruleItem => <li>{ruleItem}</li>)
  );

  if (data.length === 0) return null;

  return (
    <div className={style.wrapper}>
      <label className={style.label}>{label}</label>
      <ul className={style.list}>{rulesToRender}</ul>
    </div>
  );
};

ReadonlyCustomRules.defaultProps = {
  data: [],
};

ReadonlyCustomRules.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array,
};

export default ReadonlyCustomRules;
