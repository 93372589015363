import ListItem from 'common/components/list-item';
import Loading, { LOCAL_LOADING_TYPE } from 'common/components/loading';
import JoinInButton from 'features/join-in-button';
import {
  CUSTOM_RULES_INPUTS,
  LOCATION_FORM_KEY,
  TIMEDATE_INPUTS,
} from 'features/match/constants';
import PropTypes from 'prop-types';
import { Children, useCallback, useMemo } from 'react';
import ChooseTimeslot, {
  SelectedTimeslotItemPropTypes,
} from '../choose-timeslot';
import ReadonlyCustomRules from '../readonly-custom-rules';
import ReadonlyTimeslots from '../readonly-timeslots';
import style from './ReadOnlyItemsModalContent.module.scss';
import { sortMatchSections } from './utils';

const ReadOnlyItemsModalContent = ({
  items,
  isLoading,
  withTimeChosing,
  selectedTimeslot,
  onTimeslotSelect,
  withHomepageCTA,
}) => {
  const sortedItems = useMemo(() => sortMatchSections(items), [items]);

  const renderItems = useCallback(
    () =>
      Children.toArray(
        sortedItems?.map(({ type, label, value, highlighted }) => {
          if (type === TIMEDATE_INPUTS) {
            return withTimeChosing ? (
              <ChooseTimeslot
                data={value}
                selectedItem={selectedTimeslot}
                onSelect={onTimeslotSelect}
              />
            ) : (
              <ReadonlyTimeslots data={value} />
            );
          } else if (type === CUSTOM_RULES_INPUTS) {
            return <ReadonlyCustomRules label={label} data={value} />;
          } else {
            return (
              <ListItem
                highlighted={highlighted}
                fieldValue={value}
                label={label}
                addClipboardCopy={label === LOCATION_FORM_KEY}
                skipEmpty
              />
            );
          }
        })
      ),
    [onTimeslotSelect, selectedTimeslot, sortedItems, withTimeChosing]
  );

  return (
    <div className={style.wrapper}>
      {renderItems()}
      {isLoading && <Loading variant={LOCAL_LOADING_TYPE} />}
      {withHomepageCTA && <JoinInButton />}
    </div>
  );
};

export const ReadOnlyItemModalContentPropTypes = {
  value: PropTypes.any,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

ReadOnlyItemsModalContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ReadOnlyItemModalContentPropTypes)),
  isLoading: PropTypes.bool,
  withTimeChosing: PropTypes.bool,
  selectedTimeslot: PropTypes.shape(SelectedTimeslotItemPropTypes),
  onTimeslotSelect: PropTypes.func.isRequired,
  withHomepageCTA: PropTypes.bool,
};

export default ReadOnlyItemsModalContent;
