/* eslint-disable max-len */
// TODO read s3Assets from .env
const s3Assets = 'https://c-11-app-assets.s3.me-central-1.amazonaws.com';

export const SURFACE_TYPES = [
  {
    index: 0,
    value: 'grass-field',
    image: {
      alt: 'grass-field',
      jpgURL: `${s3Assets}/jpg/grass.jpg`,
      jpgDesktopURL: `${s3Assets}/jpg/grass-desktop.jpg`,
      webpURL: `${s3Assets}/webp/grass.webp`,
      webpDesktopURL: `${s3Assets}/webp/grass-desktop.webp`,
    },
  },
  {
    index: 1,
    value: 'indoor-wooden-field',
    image: {
      alt: 'indoor-wooden-field',
      jpgURL: `${s3Assets}/jpg/wooden.jpg`,
      jpgDestkopURL: `${s3Assets}/jpg/wooden-desktop.jpg`,
      webpURL: `${s3Assets}/webp/wooden.webp`,
      webpDesktopURL: `${s3Assets}/webp/wooden-desktop.webp`,
    },
  },
  {
    index: 2,
    value: 'concrete-field',
    image: {
      alt: 'concrete-field',
      jpgURL: `${s3Assets}/jpg/concrete-surface.jpg`,
      jpgDesktopURL: `${s3Assets}/jpg/concrete-surface-desktop.jpg`,
      webpURL: `${s3Assets}/webp/concrete-surface.webp`,
      webpDesktopURL: `${s3Assets}/webp/concrete-surface-desktop.webp`,
    },
  },
  {
    index: 3,
    value: 'sand-field',
    image: {
      alt: 'sand-field',
      jpgURL: `${s3Assets}/jpg/beach-field.jpg`,
      jpgDesktopURL: `${s3Assets}/jpg/beach-field-desktop.jpg`,
      webpURL: `${s3Assets}/webp/beach-field.webp`,
      webpDesktopURL: `${s3Assets}/webp/beach-field-desktop.webp`,
    },
  },
];

// feature modes
export const CREATE_GAME_EVENT_MODE = 'create-game-event-mode';
export const EDIT_GAME_EVENT_MODE = 'edit-game-event-mode';
