import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import Menu, { MenuPropTypes } from 'common/components/menu';
import ChevronRightIcon from 'common/components/icons/chevron-right-icon';
import YearsMenu, { YearsMenuPropTypes } from '../years-menu';

import style from './Navigation.module.scss';

const Navigation = ({
  onPrevMonthClick,
  onNextMonthClick,
  monthMenuProps,
  yearMenuProps,
}) => {
  const handlerProps = [
    {
      handler: onPrevMonthClick,
      renderFn: () => <ChevronRightIcon reversed />,
    },
    {
      handler: onNextMonthClick,
      renderFn: () => <ChevronRightIcon />,
    },
  ];

  const renderHandlers = () =>
    Children.toArray(
      handlerProps.map(({ handler, renderFn }) => (
        <Button transparent variant='light' narrow onClick={handler}>
          {renderFn()}
        </Button>
      ))
    );

  // TODO animate years switch

  return (
    <div className={style.wrapper}>
      <Menu {...monthMenuProps} />
      <YearsMenu {...yearMenuProps} />
      <div className={style.handlers}>{renderHandlers()}</div>
    </div>
  );
};

Navigation.propTypes = {
  onPrevMonthClick: PropTypes.func.isRequired,
  onNextMonthClick: PropTypes.func.isRequired,
  monthMenuProps: PropTypes.shape(MenuPropTypes),
  yearMenuProps: PropTypes.shape(YearsMenuPropTypes),
};

export default Navigation;
