import { useCallback, useState } from 'react';
import {
  ATTACKING_POSITION_AREA,
  DEFENSE_POSITION_AREA,
  FIELD_SIDE_POSITION_TYPES,
  GOALKEEPER_POSITION_AREA,
  MIDFIELD_POSITION_AREA,
  POSITION_AREA_LABELS,
  positionsMap,
} from '../constants';

const usePositions = initPlayerData => {
  // field sides
  const [fieldSides, setFieldSides] = useState(
    initPlayerData?.fieldSides || []
  );

  const addFieldSide = useCallback(side => {
    setFieldSides(prev => {
      if (prev.includes(side)) return prev;
      return [...prev, side];
    });
  }, []);

  const removeFieldSide = useCallback(side => {
    setFieldSides(prev => {
      return prev.filter(s => s !== side);
    });
  }, []);

  // natural position
  const [primaryArea, setPrimaryArea] = useState(
    POSITION_AREA_LABELS?.[initPlayerData?.primaryArea]
  );
  const [natural, setNatural] = useState(initPlayerData?.natural);

  const handleNaturalPositionChange = useCallback(value => {
    let primaryArea;

    if (value === 'Goalkeeper') {
      primaryArea = GOALKEEPER_POSITION_AREA;
    } else if (positionsMap.defense.includes(value)) {
      primaryArea = DEFENSE_POSITION_AREA;
    } else if (positionsMap.midfield.includes(value)) {
      primaryArea = MIDFIELD_POSITION_AREA;
    } else if (positionsMap.attacking.includes(value)) {
      primaryArea = ATTACKING_POSITION_AREA;
    }

    setPrimaryArea(primaryArea);
    setNatural(value);
  }, []);

  // secondary positions
  const [secondary, setSecondary] = useState(initPlayerData?.secondary || []);
  const toggleSecondaryPosition = useCallback(value => {
    setSecondary(prev => {
      if (prev.includes(value)) {
        return prev.filter(p => p !== value);
      } else {
        return [...prev, value];
      }
    });
  }, []);

  return {
    data: { primaryArea, natural, secondary, fieldSides },

    primaryArea,
    naturalPositionProps: {
      selected: natural,
      set: handleNaturalPositionChange,
    },

    secondaryPositionsProps: {
      onToggle: toggleSecondaryPosition,
      selectedPositions: secondary,
    },

    fieldSidesProps: {
      add: addFieldSide,
      remove: removeFieldSide,
      selected: fieldSides,
      options: FIELD_SIDE_POSITION_TYPES,
    },
    positions: positionsMap,
  };
};

export default usePositions;
