import {
  MISSING_EMAIL_ERR_MSG,
  INVALID_EMAIL_FORMAT_ERR_MSG,
} from 'common/constants/validations';
const yup = require('yup');

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email(INVALID_EMAIL_FORMAT_ERR_MSG)
    .required(MISSING_EMAIL_ERR_MSG),
});
