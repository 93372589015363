import TabsGroup from './components/tabs-group';
import PropTypes from 'prop-types';
import { useProps } from './hooks';

const Tabs = ({ tabs, initTab }) => {
  const { tabProps } = useProps(tabs, initTab);

  return <TabsGroup tabsData={tabProps} />;
};

const TabPropTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(TabPropTypes),
  initTab: PropTypes.string,
};

export default Tabs;
