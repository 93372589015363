import PropTypes from 'prop-types';
import { InputPropTypes } from 'common/components/input';
import Form from 'common/components/form';

const InlineRulesSection = ({ inputs }) => (
  <div>
    <Form inputs={inputs} />
  </div>
);

InlineRulesSection.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.shape(InputPropTypes)),
};

InlineRulesSection.defaultProps = {
  inputs: [],
};

export default InlineRulesSection;
