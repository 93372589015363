import { apiGet, apiPatch, apiPost, apiPut } from './apiWrapper';
import {
  UPDATE_USER_BIO_ENDPOINT,
  USERS_ENDPOINT,
  UPLOAD_USER_PROFILE_IMAGE_ENDPOINT,
  UPDATE_USER_BIO_ENDPOINT_V2,
  GET_USER_ENDPOINT_V2,
} from './constants';

export const getUser = (id, roleType, roleId, cb) => {
  const queryString =
    roleType && roleId ? `?roleType=${roleType}&roleId=${roleId}` : '';

  return apiGet(`${USERS_ENDPOINT}/${id}${queryString}`)(cb);
};

export const updateUserBio = apiPatch(UPDATE_USER_BIO_ENDPOINT); // TODO stop using this
export const updateUser = apiPatch(UPDATE_USER_BIO_ENDPOINT); // TODO is this still active?
export const updateUserV2 = apiPut(UPDATE_USER_BIO_ENDPOINT_V2);
export const getUserV2 = (userId, cb) =>
  apiGet(`${GET_USER_ENDPOINT_V2}/${userId}`)(cb);

export const uploadUserProfileImage = apiPost(
  UPLOAD_USER_PROFILE_IMAGE_ENDPOINT
);
