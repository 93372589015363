/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

const ShareIcon = ({ color }) => (
  <svg height='48' viewBox='0 96 960 960' width='48'>
    <path
      fill={color}
      d='M478.825 709Q466 709 457.5 700.375T449 679V252l-67 67q-8.25 8-20.625 8t-21.766-9.053q-8.609-9.052-8.609-21.5Q331 284 340 275l118-118q5-5 10.217-7 5.218-2 10.783-2 5.565 0 10.783 2Q495 152 500 157l119 119q8 8 8.5 20.5t-8.553 21.5q-9.052 9-21.5 9Q585 327 576 318l-67-66v427q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM220 1016q-24 0-42-18t-18-42V447q0-24 18-42t42-18h139q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T359 447H220v509h520V447H599q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T599 387h141q24 0 42 18t18 42v509q0 24-18 42t-42 18H220Z'
    />
  </svg>
);

ShareIcon.defaultProps = {
  color: ICON_DARK_THEME,
};

ShareIcon.propTypes = {
  color: PropTypes.string,
};

export default ShareIcon;
