import { PUBLIC_PAGE_TYPE } from 'common/constants/general';
import PageWrapper from '../../PageWrapper';

const PublicPageWrapper = ({ children, classNames }) => (
  <PageWrapper type={PUBLIC_PAGE_TYPE} classNames={classNames} fullscreen>
    {children}
  </PageWrapper>
);

export default PublicPageWrapper;
