import CalendarIcon from 'common/components/icons/calendar-icon';
import TimeIcon from 'common/components/icons/time-icon';
import {
  formatDayKeyAsDateInput,
  getDateMM_DD_YYYY,
  getMatchDateItems,
} from 'common/utils/dates';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CONFIRM_SCREEN, FORM_SCREEN, SHARE_SCREEN } from '../constants';
import useTimeDate from './useTimeDate';
import useSelectSurface from './useSelectSurface';
import AddCircleIcon from 'common/components/icons/add-circle-icon';
import { goToExternalURL } from 'common/utils/routing';
import useLocation from './useLocation';
import useOptionInputs from './useOptionInputs';
import useClassicInputs from './useClassicInputs';
import useTags from './useTags';
import { useUserSessionContext } from 'contexts/user-session';
import { DEFAULT_USER_IMAGE } from 'common/constants/users';
import useGameEventAPI from '../../hooks/useGameEventAPI';
import { getGameEventURL } from 'features/share/utils';
import useError from './useError';

const useProps = (initGameData, mode) => {
  const { activeRole } = useUserSessionContext();
  const userId = activeRole?.id;

  const hostData = {
    name: activeRole?.label || 'Guest User',
    imageURL: activeRole?.image?.url || DEFAULT_USER_IMAGE,
  };

  const { gameEventFormError, setGameEventFormError } = useError();

  // game preview
  const [showingGamePreview, setShowingGamePreview] = useState(false);
  const showGamePreview = useCallback(() => {
    setShowingGamePreview(true);
  }, []);

  const hideGamePreview = useCallback(() => {
    setShowingGamePreview(false);
  }, []);

  // surface type input
  const surfaceTypeInputProps = useSelectSurface(initGameData);

  // timedate props
  const {
    // date handling
    selectedDate,
    selectDate,
    calendarOpen,

    // time handling
    selectedTime,
    selectTime,
    timeSlotModalOpen,

    // modal handling
    openCalendar,
    openTimeSlotModal,
    closeTimeDateModal,
  } = useTimeDate(initGameData);

  // time and date
  const dayKey = useMemo(() => getDateMM_DD_YYYY(selectedDate), [selectedDate]);
  const { date, dayInWeek, time } = useMemo(
    () =>
      getMatchDateItems({
        dayKey,
        timeslot: selectedTime,
      }),
    [dayKey, selectedTime]
  );

  const timedateProps = useMemo(() => {
    return {
      hasValue: true,
      inputLabel: 'Time & Date',
      value: {
        firstRow: [
          {
            isLarge: true,
            text: `${dayInWeek} @ ${time}`,
          },
        ],
        secondRow: [{ text: date }],
      },
      buttons: [
        {
          onClick: openCalendar,
          Icon: CalendarIcon,
        },
        {
          onClick: openTimeSlotModal,
          Icon: TimeIcon,
        },
      ],
    };
  }, [date, dayInWeek, openCalendar, openTimeSlotModal, time]);

  // location props
  const {
    nameAndAddress,
    city,
    googleMapsLink,
    openLocationModal,

    // modal props
    locationModalOpen,
    locationFormProps,
  } = useLocation(initGameData, activeRole?.city);

  const locationProps = useMemo(() => {
    return {
      optional: true,
      inputLabel: 'Location',
      value: {
        firstRow: [
          {
            text: nameAndAddress,
          },
        ],
        secondRow: [{ text: city }],
      },
      buttons: [
        {
          onClick: () => {
            goToExternalURL(googleMapsLink, true);
          },
          isDisabled: !googleMapsLink,
          label: 'Test Maps Link',
        },
        {
          onClick: () => {
            openLocationModal();
          },
          Icon: AddCircleIcon,
        },
      ],
    };
  }, [city, googleMapsLink, nameAndAddress, openLocationModal]);

  // option input props
  const {
    // game type
    selectedGameType,
    gameTypeOptions,

    // skill level
    selectedSkillLevel,
    skillLevelOptionProps,
  } = useOptionInputs(initGameData);

  const gameTypeProps = useMemo(() => gameTypeOptions, [gameTypeOptions]);

  const skillLevelProps = useMemo(
    () => skillLevelOptionProps,
    [skillLevelOptionProps]
  );

  // classic input props
  const {
    // game duration
    gameDurationInMinutes,
    gameDurationProps,

    // number of players
    numberOfPlayers,
    numberOfPlayersProps,

    // host contact
    hostContact,
    hostContactProps,
  } = useClassicInputs(initGameData);

  // tags props
  const { tagsProps, tagValues } = useTags(initGameData);

  // game event preview props
  const gameEventProps = useMemo(
    () => ({
      coverImgData:
        surfaceTypeInputProps?.inputItems[surfaceTypeInputProps?.selectedItem]
          ?.image,
      matchDate: {
        dayKey: getDateMM_DD_YYYY(selectedDate),
        timeslot: selectedTime,
      },
      location: nameAndAddress,
      locationURL: googleMapsLink,
      city,
      organizer: {
        name: hostData?.name,
        contact: hostContact,
        imgURL: hostData?.image?.url,
      },
      tags: tagValues,
      duration: gameDurationInMinutes,
      skillLevel: selectedSkillLevel,
      eventBadge: { label: selectedSkillLevel },
      numberOfPlayers: numberOfPlayers,
      gameType: selectedGameType,
    }),
    [
      city,
      gameDurationInMinutes,
      googleMapsLink,
      hostContact,
      hostData?.image?.url,
      hostData?.name,
      nameAndAddress,
      numberOfPlayers,
      selectedDate,
      selectedGameType,
      selectedSkillLevel,
      selectedTime,
      surfaceTypeInputProps?.inputItems,
      surfaceTypeInputProps?.selectedItem,
      tagValues,
    ]
  );

  // share props
  const { createdGameEvent, createEditGameEvent } = useGameEventAPI(
    mode,
    initGameData
  );

  const shareProps = useMemo(
    () => ({
      title: `football game - ${dayInWeek}, ${time} @ ${nameAndAddress}`,
      description: `powered by cluster-11`,
      url: getGameEventURL(createdGameEvent?._id),
    }),
    [createdGameEvent?._id, dayInWeek, nameAndAddress, time]
  );

  // flow props
  const [currentScreen, setCurrentScreen] = useState(FORM_SCREEN);

  const gameEventAPIBody = useMemo(() => {
    return {
      coverImgData:
        surfaceTypeInputProps?.inputItems[surfaceTypeInputProps?.selectedItem]
          ?.image,
      date: new Date(formatDayKeyAsDateInput(dayKey)),
      matchDate: {
        dateKey: dayKey,
        timeslot: selectedTime,
      },
      location: nameAndAddress,
      locationURL: googleMapsLink,
      city,
      organizer: {
        user: userId,
        isGuest: !activeRole.id,
        contact: hostContact,
      },
      tags: tagValues,
      duration: gameDurationInMinutes,
      skillLevel: selectedSkillLevel,
      numberOfPlayers: numberOfPlayers,
      gameType: selectedGameType,
    };
  }, [
    activeRole.id,
    city,
    dayKey,
    gameDurationInMinutes,
    googleMapsLink,
    hostContact,
    nameAndAddress,
    numberOfPlayers,
    selectedGameType,
    selectedSkillLevel,
    selectedTime,
    surfaceTypeInputProps,
    tagValues,
    userId,
  ]);

  const isFormValid = useCallback(() => {
    const requiredFields = [
      selectedDate,
      selectedTime,
      selectedGameType,
      selectedSkillLevel,
      gameDurationInMinutes,
      numberOfPlayers,
    ];

    const result = requiredFields.reduce((acc, curr) => {
      const isCurrentEmptyString =
        typeof curr === 'string' ? curr.trim() === '' : false;

      const isCurrentValid =
        curr !== null && curr !== undefined && !isCurrentEmptyString;

      return acc && isCurrentValid;
    }, true);

    return result;
  }, [
    gameDurationInMinutes,
    numberOfPlayers,
    selectedDate,
    selectedGameType,
    selectedSkillLevel,
    selectedTime,
  ]);

  const handleGameSetting = useCallback(() => {
    if (isFormValid()) {
      setCurrentScreen(CONFIRM_SCREEN);
    } else {
      setGameEventFormError(true);
    }
  }, [isFormValid, setGameEventFormError]);

  useEffect(() => {
    if (gameEventFormError) {
      if (isFormValid()) {
        setGameEventFormError(false);
      }
    }
  }, [isFormValid, setGameEventFormError, gameEventFormError]);

  const screensProps = useMemo(
    () => ({
      [FORM_SCREEN]: {
        goBack: null,
        goForward: {
          handler: handleGameSetting,
          label: 'SET GAME',
        },
        gameFormProps: {
          surfaceTypeInputProps,
          timedateProps,
          locationProps,
          gameTypeProps,
          skillLevelProps,
          gameDurationProps,
          numberOfPlayersProps,
          hostContactProps,
          tagsProps,
        },
      },
      [CONFIRM_SCREEN]: {
        goBack: {
          handler: () => {
            setCurrentScreen(FORM_SCREEN);
          },
          label: 'Edit Game',
        },
        goForward: {
          handler: () => {
            createEditGameEvent(gameEventAPIBody);
          },
          label: 'PLAY!',
        },
      },
      [SHARE_SCREEN]: {
        goBack: null,
        goForward: null,
      },
    }),
    [
      createEditGameEvent,
      gameDurationProps,
      gameEventAPIBody,
      gameTypeProps,
      handleGameSetting,
      hostContactProps,
      locationProps,
      numberOfPlayersProps,
      skillLevelProps,
      surfaceTypeInputProps,
      tagsProps,
      timedateProps,
    ]
  );

  useEffect(() => {
    if (createdGameEvent) {
      setCurrentScreen(SHARE_SCREEN);
    }
  }, [createdGameEvent]);

  return {
    gameEventFormError,

    currentScreen,
    screensProps,

    // game preview
    showingGamePreview,
    showGamePreview,
    hideGamePreview,

    // modals
    // -- calendar
    selectDate,
    selectedDate,
    calendarOpen,

    // -- time
    selectTime,
    selectedTime: {
      initHours: selectedTime?.substring(0, 2),
      initMinutes: selectedTime?.substring(2),
    },
    timeSlotModalOpen,

    closeTimeDateModal,

    // -- location
    locationModalOpen,
    locationFormProps,

    // -- game event preview
    gameEventProps,

    // -- share props
    shareProps,

    // game event api body
    gameEventAPIBody,
  };
};

export default useProps;
