import Button, {
  BUTTON_LIGHT_VARIANT,
  BUTTON_REGULAR_VARIANT,
} from 'common/components/button';
import { BUTTON_DARK_BLACK_VARIANT } from 'common/components/button/constants';
import { formatAsMonthDayString } from 'common/utils/dates';
import CalendarContainer from 'features/calendar';
import {
  TIMESLOT_REQUIRED_LABEL,
  TIME_SELECTOR_TITLE,
} from 'features/match/constants';
import { splitTimeslotIntoHoursMinutesPair } from 'features/match/utils';
import TimeSelector from 'features/time-selector';
import PropTypes from 'prop-types';
import { Children, useCallback } from 'react';
import style from './TimedateSection.module.scss';

const TimedateSection = ({
  dayItems,
  timeslotItems,
  controlButtons,
  addDay,
  addTime,
  timeSelectorOpened,
  dateSelectorOpened,
  closeSelectorModal,
}) => {
  const renderDayItems = useCallback(
    () =>
      Children.toArray(
        dayItems?.map(({ date, onClick, isSelected }) => {
          const buttonVariant = isSelected
            ? BUTTON_LIGHT_VARIANT
            : BUTTON_REGULAR_VARIANT;

          return (
            <div className={style.dayItem}>
              <Button
                withRipples
                narrow
                variant={buttonVariant}
                onClick={onClick}
              >
                {formatAsMonthDayString(date)}
              </Button>
            </div>
          );
        })
      ),
    [dayItems]
  );

  const renderTimedateItems = useCallback(
    () =>
      Children.toArray(
        timeslotItems?.map(({ value, onClick }) => {
          const [hours, minutes] = splitTimeslotIntoHoursMinutesPair(value);

          return (
            <div className={style.timeItem}>
              <Button narrow onClick={onClick} withRipples>
                {hours} : {minutes}
              </Button>
            </div>
          );
        })
      ),
    [timeslotItems]
  );

  const renderEditControls = useCallback(
    () =>
      Children.toArray(
        controlButtons?.map(({ label, onClick, isDisabled }) =>
          isDisabled ? null : (
            <Button
              narrow
              variant={BUTTON_DARK_BLACK_VARIANT}
              onClick={onClick}
              withRipples
            >
              {label}
            </Button>
          )
        )
      ),
    [controlButtons]
  );

  return (
    <div className={style.wrapper}>
      <label className={style.labelNote}>{TIMESLOT_REQUIRED_LABEL}</label>

      {/* Edit Buttons */}
      <div className={style.controls}>{renderEditControls()}</div>

      {/* Content */}
      <div className={style.items}>
        <div className={style.daysWrapper}>{renderDayItems()}</div>
        <div className={style.timeItemsWrapper}>{renderTimedateItems()}</div>
      </div>

      {/* -- Modals -- */}

      {timeSelectorOpened && (
        <TimeSelector
          onConfirm={addTime}
          onClose={closeSelectorModal}
          title={TIME_SELECTOR_TITLE}
        />
      )}

      <CalendarContainer
        isOpen={dateSelectorOpened}
        onClose={closeSelectorModal}
        onConfirm={addDay}
        date={new Date()}
        disablePast={true}
      />
    </div>
  );
};

TimedateSection.propTypes = {
  dayItems: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      isSelected: PropTypes.bool,
    })
  ),
  timeslotItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  controlButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      isDisabled: PropTypes.bool,
    })
  ),
  addTime: PropTypes.func.isRequired,
  addDay: PropTypes.func.isRequired,
  timeSelectorOpened: PropTypes.bool,
  dateSelectorOpened: PropTypes.bool,
  closeSelectorModal: PropTypes.func.isRequired,
};

export default TimedateSection;
