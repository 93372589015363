import PropTypes from 'prop-types';
import Modal, { DARK_THEME } from 'features/modal';
import { useProps } from './hooks';
import {
  CONFIRM_SCREEN,
  FORM_SCREEN,
  SHARE_SCREEN,
  TIME_SELECTOR_TITLE,
} from './constants';
import { useCallback } from 'react';
import GameEventForm from './components/game-event-form';
import GameEventConfirmScreen from './components/game-event-confirm-screen';
import GameEventShareScreen from './components/game-event-share-screen';
import style from './CreateEditGameEvent.module.scss';
import Button from 'common/components/button';
import { useWindowSizeContext } from 'contexts/window-size';
import { BUTTON_EMPHASIZED_VARIANT } from 'common/components/button/constants';
import GameEvent from '../game-event';
import CalendarContainer from 'features/calendar';
import TimeSelector from 'features/time-selector';
import LocationForm from './components/location-form';
import { useMetaContext } from 'contexts/meta';
import { CREATE_GAME_EVENT_MODE, EDIT_GAME_EVENT_MODE } from '../constants';
import ErrorMessage from './components/error-message';

// the feature is used for both creating and editing game event
// currently supports only user created events
const CreateEditGameEvent = ({ onClose, gameData, mode }) => {
  const { isMobileView } = useWindowSizeContext();
  const { loading } = useMetaContext();
  const {
    gameEventFormError,

    currentScreen,
    screensProps,
    showingGamePreview,
    showGamePreview,
    hideGamePreview,

    // modals
    closeTimeDateModal,

    // -- calendar
    calendarOpen,
    selectDate,
    selectedDate,

    // -- time
    timeSlotModalOpen,
    selectTime,
    selectedTime,

    // -- location
    locationFormProps,
    locationModalOpen,

    // -- gameEventProps
    gameEventProps,

    // -- shareProps
    shareProps,
  } = useProps(gameData, mode);

  const renderScreen = useCallback(() => {
    switch (currentScreen) {
      case FORM_SCREEN:
        const {
          gameFormProps,
          goBack: formGoBack,
          goForward: formGoForward,
        } = screensProps[FORM_SCREEN];
        return (
          <div className={style['view-wrapper']}>
            {formGoBack && (
              <div className={style.backButton}>
                <Button narrow rounded onClick={formGoBack.handler}>
                  {formGoBack.label}
                </Button>
              </div>
            )}
            <GameEventForm {...gameFormProps} error={gameEventFormError} />
            {formGoForward && (
              <div className={style.forwardButton}>
                {gameEventFormError ? (
                  <ErrorMessage />
                ) : (
                  <Button
                    onClick={formGoForward.handler}
                    rounded
                    narrow
                    variant={BUTTON_EMPHASIZED_VARIANT}
                  >
                    {formGoForward.label}
                  </Button>
                )}
              </div>
            )}
          </div>
        );
      case CONFIRM_SCREEN:
        const { goBack: confirmGoBack, goForward: confirmGoForward } =
          screensProps[CONFIRM_SCREEN];
        return (
          <div
            className={`${style['view-wrapper']} ${style['confirm-screen']}`}
          >
            {confirmGoBack && (
              <div className={style.confirmScreenButton}>
                <Button onClick={confirmGoBack.handler} narrow rounded>
                  {confirmGoBack.label}
                </Button>
              </div>
            )}
            <div className={style.confirmScreenButton}>
              <GameEventConfirmScreen
                showGamePreview={showGamePreview}
                buttonLabel='SHOW GAME PREVIEW'
              />
            </div>
            {confirmGoForward && (
              <div className={style.confirmScreenButton}>
                <Button
                  onClick={confirmGoForward.handler}
                  rounded
                  narrow
                  variant={BUTTON_EMPHASIZED_VARIANT}
                >
                  {confirmGoForward.label}
                </Button>
              </div>
            )}
          </div>
        );
      case SHARE_SCREEN:
        const { goBack: shareGoBack, goForward: shareGoForward } =
          screensProps[SHARE_SCREEN];
        return (
          <div className={`${style['view-wrapper']} ${style['share-screen']}`}>
            {shareGoBack && (
              <div className={style.backButton}>
                <Button onClick={shareGoBack.handler} narrow rounded>
                  {shareGoBack.label}
                </Button>
              </div>
            )}

            <GameEventShareScreen shareProps={shareProps} />

            {shareGoForward && (
              <div className={style.forwardButton}>
                <Button
                  onClick={shareGoForward.handler}
                  rounded
                  narrow
                  variant={BUTTON_EMPHASIZED_VARIANT}
                >
                  {shareGoForward.label}
                </Button>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  }, [
    currentScreen,
    gameEventFormError,
    screensProps,
    shareProps,
    showGamePreview,
  ]);

  return (
    <Modal
      theme={DARK_THEME}
      fullScreen={isMobileView}
      withoutWrapper
      onClose={onClose}
      customModalContentClassName={style.modalContent}
      customClassName={style.modal}
      highlightCloseButton
      loading={loading}
    >
      <div className={style.wrapper}>{renderScreen()}</div>

      {/* -- Inner Modals -- */}
      {showingGamePreview && (
        <GameEvent gameData={gameEventProps} onClose={hideGamePreview} />
      )}

      {/* TODO - fix double opening bug */}
      <CalendarContainer
        isOpen={calendarOpen}
        onClose={closeTimeDateModal}
        onConfirm={selectDate}
        date={selectedDate}
        disablePast={true}
      />

      {timeSlotModalOpen && (
        <TimeSelector
          onConfirm={selectTime}
          onClose={closeTimeDateModal}
          title={TIME_SELECTOR_TITLE}
          {...selectedTime}
        />
      )}

      {locationModalOpen && <LocationForm {...locationFormProps} />}
    </Modal>
  );
};

CreateEditGameEvent.defaultProps = {
  mode: CREATE_GAME_EVENT_MODE,
};

CreateEditGameEvent.propTypes = {
  onClose: PropTypes.func.isRequired,
  gameData: PropTypes.object,
  mode: PropTypes.oneOf([CREATE_GAME_EVENT_MODE, EDIT_GAME_EVENT_MODE]),
};

export default CreateEditGameEvent;
