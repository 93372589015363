import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { STREET_PATH } from 'routes/constants';

export const getRoutes = data =>
  data.map((props, index) => <Route key={index} {...props} />);

export const setRouting = routes => <Routes>{routes}</Routes>;

export const goToWithReload = path => {
  window.location.assign(path);
};

export const goToStreetPage = () => {
  goToWithReload(STREET_PATH);
};

export const splitPath = path => {
  try {
    return path.split('/');
  } catch (err) {
    return [];
  }
};

export const goToExternalURL = (url, newTab) => {
  const httpProtocol = url.substring(0, 7);
  const httpsProtocol = url.substring(0, 8);
  let urlToUse = url;

  if (!(httpProtocol === 'http://' || httpsProtocol === 'https://'))
    urlToUse = 'https://' + url;
  if (newTab) {
    window.open(urlToUse, '_blank');
  } else {
    window.location.assign(urlToUse);
  }
};
