import Button, { BUTTON_LIGHT_VARIANT } from 'common/components/button';
import { BUTTON_DARK_BLACK_VARIANT } from 'common/components/button/constants';
import Input from 'common/components/input';
import PropTypes from 'prop-types';
import { useCallback, Children } from 'react';
import style from './ResultSection.module.scss';

const ResultSection = ({ outcomeButtons, goalInputProps }) => {
  const renderOutcomeButtons = useCallback(
    () =>
      Children.toArray(
        outcomeButtons.map(({ label, onClick, isSelected }) => (
          <Button
            withRipples
            variant={
              isSelected ? BUTTON_LIGHT_VARIANT : BUTTON_DARK_BLACK_VARIANT
            }
            onClick={onClick}
          >
            {label}
          </Button>
        ))
      ),
    [outcomeButtons]
  );

  const renderGoalInputs = useCallback(
    () =>
      Children.toArray(
        goalInputProps.map(goalInputProps => <Input {...goalInputProps} />)
      ),
    [goalInputProps]
  );

  return (
    <div className={style.wrapper}>
      <div className={style.form}>
        <div className={style.outcomeButtons}>{renderOutcomeButtons()}</div>

        <div className={style.goalInputs}>{renderGoalInputs()}</div>
      </div>
    </div>
  );
};

const OutcomeButtonPropTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

const GoalInputPropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

ResultSection.defaultProps = {
  outcomeButtons: [],
  goalInputProps: [],
};

ResultSection.propTypes = {
  outcomeButtons: PropTypes.arrayOf(PropTypes.shape(OutcomeButtonPropTypes)),
  goalInputProps: PropTypes.arrayOf(PropTypes.shape(GoalInputPropTypes)),
};

export default ResultSection;
