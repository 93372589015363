/* eslint-disable max-len */
import { SURFACE_TYPES } from 'features/game-event-features/constants';
import { useState } from 'react';

const getInitSurfaceTypeIndex = value => {
  if (value === null || value === undefined) return 0;

  const surfaceObject = SURFACE_TYPES.find(item => item.value === value);
  return surfaceObject?.index;
};

const useSelectSurface = initGameData => {
  const [selectedSurface, setSelectedSurface] = useState(
    getInitSurfaceTypeIndex(initGameData?.coverImgData?.alt)
  );

  return {
    selectedItem: selectedSurface,
    selectItem: setSelectedSurface,
    inputItems: SURFACE_TYPES,
  };
};

export default useSelectSurface;
