import { useCallback, Children } from 'react';

const componentsToPreload = [];

const usePreload = images => {
  const preloadComponents = useCallback(() => {
    const toPreload = Children.toArray(
      componentsToPreload?.map(Component => <Component />)
    );

    return <div>{toPreload}</div>;
  }, []);

  return { preloadComponents };
};

export default usePreload;
