import { useCallback, useState } from 'react';
import { CALENDAR_MODAL, DEFAULT_TIME, TIMESLOT_MODAL } from '../constants';

const getInitDate = initDate => {
  try {
    if (!initDate) throw Error;
    return new Date(initDate);
  } catch (error) {
    return new Date();
  }
};

const useDate = initGameData => {
  // date handling
  const [selectedDate, setSelectedDate] = useState(
    getInitDate(initGameData?.matchDate?.dateKey)
  );

  const selectDate = useCallback(date => {
    setSelectedDate(date?.dateObj);
  }, []);

  // time handling
  const [selectedTime, setSelectedTime] = useState(
    initGameData?.matchDate?.timeslot || DEFAULT_TIME
  );
  const selectTime = useCallback((hours, minutes) => {
    setSelectedTime(`${String(hours)}${String(minutes)}`);
  }, []);

  // modal handling
  const [timeDateModal, setTimeDateModal] = useState(null);

  const openCalendar = useCallback(() => {
    setTimeDateModal(CALENDAR_MODAL);
  }, []);

  const openTimeSlotModal = useCallback(() => {
    setTimeDateModal(TIMESLOT_MODAL);
  }, []);

  const closeTimeDateModal = useCallback(() => {
    setTimeDateModal(null);
  }, []);

  return {
    // date handling
    selectedDate,
    selectDate,
    calendarOpen: timeDateModal === CALENDAR_MODAL,

    // time handling
    selectedTime,
    selectTime,
    timeSlotModalOpen: timeDateModal === TIMESLOT_MODAL,

    // modal handling
    timeDateModal,
    openCalendar,
    openTimeSlotModal,
    closeTimeDateModal,
  };
};

export default useDate;
