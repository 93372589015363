import { useCallback, useMemo, useState } from 'react';
import {
  durationStaticProps,
  hostContactStaticProps,
  numberOfPlayersStaticProps,
} from '../constants';

const getDynamicInputProps = (staticData, setFn, currentValue) => ({
  inputLabel: staticData?.inputLabel,
  valueInlineLabel: staticData?.valueInlineLabel,
  value: currentValue,
  type: staticData?.type,
  onChange: e => {
    setFn(e.target.value);
  },
  optional: staticData.optional,
});

const useClassicInputs = initGameData => {
  // game duration
  const [gameDurationInMinutes, setGameDurationInMinutes] = useState(
    initGameData?.duration || durationStaticProps.defaultValue
  );

  const setAndValidateGameDurationInMinutes = useCallback(val => {
    const valueToSet = val ? Math.max(1, val) : val;
    setGameDurationInMinutes(valueToSet);
  }, []);

  const gameDurationProps = useMemo(
    () =>
      getDynamicInputProps(
        durationStaticProps,
        setAndValidateGameDurationInMinutes,
        gameDurationInMinutes
      ),
    [setAndValidateGameDurationInMinutes, gameDurationInMinutes]
  );

  // number of players
  const [numberOfPlayers, setNumberOfPlayers] = useState(
    initGameData?.numberOfPlayers || numberOfPlayersStaticProps.defaultValue
  );

  const setAndValidatedNumberOfPlayers = useCallback(val => {
    const valueToSet = val ? Math.max(1, val) : val;
    setNumberOfPlayers(valueToSet);
  }, []);

  const numberOfPlayersProps = useMemo(
    () =>
      getDynamicInputProps(
        numberOfPlayersStaticProps,
        setAndValidatedNumberOfPlayers,
        numberOfPlayers
      ),
    [numberOfPlayers, setAndValidatedNumberOfPlayers]
  );

  // host contact
  const [hostContact, setHostContact] = useState(
    initGameData?.organizer?.contact || ''
  );
  const hostContactProps = useMemo(
    () =>
      getDynamicInputProps(hostContactStaticProps, setHostContact, hostContact),
    [hostContact]
  );

  return {
    // game duration
    gameDurationInMinutes,
    gameDurationProps,

    // number of players
    numberOfPlayers,
    numberOfPlayersProps,

    // host contact
    hostContact,
    hostContactProps,
  };
};

export default useClassicInputs;
