import React, { Children } from 'react';
import PropTypes from 'prop-types';

import './Select.scss';

const className = 'c-Select';

const Select = ({ name, options, onChange, value, label }) => {
  const renderOptions = () =>
    Children.toArray(
      options.map(({ value, text }) => <option value={value}>{text}</option>)
    );

  return (
    <div className={className}>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={`${className}__select`}
      >
        {renderOptions()}
      </select>
      <label htmlFor={name} className={`${className}__label`}>
        {label}
      </label>
    </div>
  );
};

export const SelectPropTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
};

Select.propTypes = SelectPropTypes;
Select.defaultProps = {
  options: [],
};

export default Select;
