export const validateForm =
  (state, schema, dispatch, actionType) => async values => {
    try {
      const keys = Object.keys(state);
      const valuesToValidate = {};

      keys.forEach(key => {
        valuesToValidate[key] = values[key].value;
      });

      return await schema.validate(valuesToValidate, {
        abortEarly: false,
        recursive: true,
      });
    } catch (err) {
      err?.inner.forEach(item => {
        dispatch({
          type: actionType,
          key: item.path,
          value: item.value,
          error: item.message,
        });
      });
    }
  };

export const validateFormSync =
  (state, schema, dispatch, actionType) => values => {
    try {
      const keys = Object.keys(state);
      const valuesToValidate = {};

      keys.forEach(key => {
        valuesToValidate[key] = values[key].value;
      });

      return schema.testSync(valuesToValidate, {
        abortEarly: false,
        recursive: true,
      });
    } catch (err) {
      err?.inner.forEach(item => {
        dispatch({
          type: actionType,
          key: item.path,
          value: item.value,
          error: item.message,
        });
      });
    }
  };

export const formatFormWithoutValidation = values => {
  const keys = Object.keys(values);
  const formattedState = {};

  keys.forEach(key => {
    formattedState[key] = values[key].value;
  });

  return formattedState;
};
