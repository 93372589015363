import { apiDelete, apiGet, apiPatch, apiPost } from './apiWrapper';
import {
  CLUBS_ENDPOINT,
  UPLOAD_CLUB_PROFILE_IMAGE_ENDPOINT,
} from './constants';

export const createClubService = apiPost(CLUBS_ENDPOINT);

export const fetchClub = (id, roleType, cb) =>
  apiGet(`${CLUBS_ENDPOINT}/${id}?roleType=${roleType}`)(cb);

export const updateClubService = (id, data) =>
  apiPatch(`${CLUBS_ENDPOINT}/${id}`)(data);

export const deleteClubService = id => apiDelete(`${CLUBS_ENDPOINT}/${id}`);

export const postClubLogo = (data, id) =>
  apiPost(`${CLUBS_ENDPOINT}/${id}/logo`)(data);

export const uploadClubProfileImage = clubId =>
  apiPost(`${UPLOAD_CLUB_PROFILE_IMAGE_ENDPOINT}/${clubId}`);
